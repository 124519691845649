import React from 'react'
import { Link } from 'react-router-dom';

import OutlinedButton from 'src/components/OutlinedButton';

import ParkingIllustration from 'public/assets/img/parking-illustration.gif'

const NumberDisplay = ({ nb }) => (
  <div className="number">
    {nb}
  </div>
)

const NumbersSection = ({ title, content, colorBck, colorFont, gradientButton }) => (
  <div className="row px-5 bg-image" style={{ backgroundImage: `url(${ParkingIllustration})` }}>
    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
      <div className="row mt-4">
        <div className="col-12">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="row mb-2">
        {content.map((elem, index) => (
          <div className="col-lg-6 col-md-6 offset-md-0 col-sm-8 offset-sm-2 col-12 my-3" key={index}>
            <NumberDisplay nb={index + 1} color="#0dbc5f" />
            <div className="text mt-2" style={{ color: colorFont }}>{elem.title}</div>
            {elem.subtitle && <div className="text section-subtitle mt-2" style={{ color: colorFont }}>{elem.subtitle}</div>}
          </div>
        ))}
      </div>
      <div className="row pb-5">
        <div className="col d-flex justify-content-center text-center">
          {gradientButton ?
            <Link to="/add" className='gradient-button'>Enregistrer mon parking</Link>
            : <OutlinedButton text="Plus d'infos" link="/trouver-un-parking" />
          }
        </div>
      </div>
    </div>
  </div>
)

export default NumbersSection
