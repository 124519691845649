import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import IBAN from 'iban'
import { captureException as Sentry } from '@sentry/browser'
import { PHYSICAL_PERSON, TYPES_INFO } from './DocumentsService'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tooltip from 'src/components/Tooltip'
import DocumentStatus from './DocumentStatus'

import UserApi from 'src/api/user'
import { Consumer } from 'src/services/store';

function DocumentDialog({ isOpen, handleClose, type, setType, docs, data, setDocs }) {
  const [file, setFile] = useState(null)
  const [b64File, setB64File] = useState(null)
  const [iban, setIban] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [extension, setExtension] = useState('')

  const acceptedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf']

  useEffect(() => {
    transformFile(file)
  }, [file])

  function transformFile(file) {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var base64result = reader.result.split(',');
        setB64File(base64result[1])
        setExtension(file.name.split('.').pop().toLowerCase())
      }
      reader.onerror = function (error) {
        console.log('Error: ', error);
        Sentry(new Error("loadImage: " + error))
      };
    }
  }

  function submitDocuments() {
    if (validateForm()) {
      let data = { image: b64File, iban, type }
      if (extension === 'pdf') {
        data = { ...data, ext: extension }
      }
      uploadDocument({ ...data })
    }
  }

  function validateForm() {
    if (file && acceptedExtensions.indexOf(extension) === -1) {
      data.snackRef.open('warning', "Ce format de fichier n'est pas supporté (." + extension + ").")
      return false
    }
    if (type === 'IBAN' && (!IBAN.isValid(iban) || iban.length < 5)) {
      data.snackRef.open('warning', "Veuillez saisir un IBAN français et valide.")
      return false
    }
    if (!file) {
      data.snackRef.open('warning', "Veuillez sélectionner un document à envoyer.")
      return false
    }
    return true
  }

  async function uploadDocument(dataDoc) {
    setIsLoading(true)
    const res = await UserApi.uploadDocument(dataDoc)
    setIsLoading(false)
    if (res && !res.error) {
      setDocs(res.doc)
      onClose()
      data.snackRef.open('success', 'Document enregistré.')
    } else if (res.error.message && res.error.message === 'Cannot upload a doc now: limit is 1 every 24h') {
      data.snackRef.open('error', "Vous ne pouvez envoyer qu'un seul document par type par 24h.")
    }
    else {
      data.snackRef.open('error', "Une erreur est survenue, veuillez rééssayer plus tard.")
    }
  }

  function handleTypeSelected(event) {
    setType(event.target.value)
  }

  function onClose() {
    setFile(null)
    setB64File(null)
    setIban('')
    setExtension('')
    handleClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      {type &&
        <>
          <DialogTitle id="form-dialog-title">{TYPES_INFO[type].title}</DialogTitle>
          <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogContentText>
              {TYPES_INFO[type].desc}
            </DialogContentText>
            {type === 'IDENTITY' &&
              <Tooltip text="Le recto et le verso de la carte d'identité doivent apparaître sur le même document." />
            }
            {type === 'IBAN' &&
              <TextField
                autoComplete="off"
                autoFocus
                margin="dense"
                id="name"
                label="Iban"
                type="text"
                fullWidth
                onChange={(event) => setIban(event.target.value)}
              />
            }
            <UploadButton file={file} setFile={setFile} id="upload-button" />
            <DocumentStatus doc={docs[type.toLowerCase()]} />
            {(docs[type.toLowerCase()] && docs[type.toLowerCase()].comment !== '') &&
              <Tooltip color='red' text={docs[type.toLowerCase()].comment} />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} className="no-outline" color="primary" style={{ textTransform: 'none', margin: 10 }}>
              Annuler
        </Button>
            <Button disabled={isLoading} onClick={submitDocuments} className="no-outline" color="primary" variant="contained" style={{ textTransform: 'none', color: 'white', margin: 10 }}>
              Envoyer
        </Button>
          </DialogActions>
        </>
      }
      {!type &&
        <>
          <DialogTitle id="form-dialog-title">Type de document</DialogTitle>
          <FormControl style={{ margin: 40 }}>
            <InputLabel htmlFor="type-selector">Type</InputLabel>
            <Select
              value={'IDENTITY'}
              onChange={handleTypeSelected}
              inputProps={{
                name: 'type',
                id: 'type-selector',
              }}
            >
              {
                PHYSICAL_PERSON.map((item, index) => (
                  <MenuItem value={item} key={index}>{TYPES_INFO[item].title}</MenuItem>
                )
                )
              }
            </Select>
          </FormControl>
          <DialogActions style={{ minWidth: 300 }}>
            <Button onClick={onClose} className="no-outline" color="primary"
              style={{ textTransform: 'none', marginTop: 30 }}>
              Annuler
            </Button>
          </DialogActions>
        </>
      }
    </Dialog >
  )
}

function UploadButton({ file, setFile, id }) {

  return (
    <>
      <input
        accept="image/jpg,image/jpeg,image/gif,image/png,application/pdf"
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={(event) => setFile(event.target.files[0])}
      />
      <label htmlFor={id}>
        <Button variant="contained" style={{ textTransform: 'none' }} component="span" className="no-outline my-3">
          {(file && file.name) || "Choisir un fichier"}
          {file ?
            <CheckIcon className="ml-4" color="primary" />
            : <CloudUploadIcon className="ml-4" />}
        </Button>
      </label>
    </>
  )
}

export default (props) => (
  <Consumer>
    {ctx => <DocumentDialog {...props} data={ctx} />}
  </Consumer>
)
