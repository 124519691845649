import React from 'react';
import Logo from 'public/assets/img/parkmatch-light.png';
import Lemonway from 'public/assets/img/lemonway.svg';
import { Link } from 'react-router-dom';


import { COLORS } from 'src/stylesheet/colors';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="row bg-dark mx-auto p-4 pt-5" style={{ width: "100%", color: COLORS.colorFooter }}>
        <div className="col-12 col-sm-6 col-md-3">
          <img defer width={150} height={33} src={Logo} alt="ParkmatchFooter" />
          <div className="ml-4">
            <div>6 bis, rue de la Paroisse, GALAXY</div>
            <div>78000 Versailles</div>
            <div><a style={{ color: 'inherit' }} href="mailto:contact@parkmatch.eu">contact@parkmatch.eu</a></div>
            <div>01 80 84 44 20</div>
            <div className="mt-5">
              <div className="my-2">
                <div>Intermédiaire de paiement</div>
                <div><a href="https://www.lemonway.com/"><img defer width={150} src={Lemonway} alt="Lemonway" /></a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
          <h2 style={{ fontSize: '1.3rem' }}>PARKINGS</h2>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/paris">Parkings à Paris</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/lyon">Parkings à Lyon</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/montreuil">Parkings à Montreuil</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/bagnolet">Parkings à Bagnolet</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/saint-denis">Parkings à Saint-Denis</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/charenton-le-pont">Parkings à Charenton le pont</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/vincennes">Parkings à Vincennes</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/saint-mande">Parkings à Saint-Mandé</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/ivry-sur-seine">Parkings à Ivry sur Seine</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/kremlin-bicetre">Parkings à Kremlin-Bicêtre</Link></h3>
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
          <h3 style={{ fontSize: '1rem', marginTop: '2em' }}><Link style={{ color: 'inherit' }} to="/location/france/toulouse">Parkings à Toulouse</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/bordeaux">Parkings à Bordeaux</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/lille">Parkings à Lille</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/nice">Parkings à Nice</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/montpellier">Parkings à Montpellier</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/strasbourg">Parkings à Strasbourg</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/rennes">Parkings à Rennes</Link></h3>
          <h3 style={{ fontSize: '1rem' }}><Link style={{ color: 'inherit' }} to="/location/france/grenoble">Parkings à Grenoble</Link></h3>
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
          <h3 style={{ fontSize: '1.3rem', marginTop: '1em' }}>PROPRIÉTAIRES</h3>
          <h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="/louer-mon-parking">Comment ça marche?</a></h4>
          <h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="/estimation-parking">Estimer un parking</a></h4>
          <h3 style={{ fontSize: '1.3rem', marginTop: '1em' }}>CONDUCTEURS</h3>
          <h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="/trouver-un-parking">Comment louer?</a></h4>
          <h3 style={{ fontSize: '1.3rem', marginTop: '1em' }}>À PROPOS</h3>
          <h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="/contact">Contact</a></h4>
          <h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="/faq">FAQ</a></h4>
          {/*<h4 style={{ fontSize: '1rem' }}><a style={{ color: 'inherit' }} href="https://help.parkmatch.eu/hc?lang=fr" target="_blank" rel="noopener noreferrer">Centre d'aide</a></h4>*/}
        </div>
        <div className="col-12 d-flex flex-column text-center mt-4">
          <div style={{ color: COLORS.lightWhite }}>
            <a style={{ color: 'inherit' }} href="/mentions">Mentions légales</a> - <a style={{ color: 'inherit' }} href="/droits">Vos Droits & Obligations</a> - <a style={{ color: 'inherit' }} href="/modalites">(dé)référencement/classement</a> - <a style={{ color: 'inherit' }} href="/informations-legales">Politique de confidentialité</a> - <a style={{ color: 'inherit' }} href="/cgu">CGU</a>
          </div>
          {/*<div style={{ color: COLORS.gray }}>*/}
          {/*  Parkmatch est noté <span style={{ color: 'white' }}>4.7/5</span> par ses utilisateurs sur <a href="https://apps.apple.com/fr/app/parkmatch/id1327371269">*/}
          {/*    App Store</a> et <a href="https://play.google.com/store/apps/details?id=eu.parkmatch.parkmatch&hl=fr">Google Play</a>.*/}
        {/*(65 avis)*/}
        {/*    </div>*/}

          <div style={{ color: COLORS.gray }}>Copyrights Parkmatch 2017 - 2022 - Tous droits réservés</div>
        </div>
      </div>
    )
  }
}
