import React from "react";

const SvgIndoor = props => (
  <svg viewBox="0 0 648 648" {...props} width={props.size} height={props.size}>
    <path fill={props.color} d="M619.2 54.2c-1.3-2.8-2.5-5.5-3.9-8.3-4.2-8.6-11.2-14.3-19.6-18.4-3.7-1.7-7.5-2.5-11.7-2.5-174.8.1-349.4.1-524 0-4.1 0-7.7.9-11.5 2.5-10.3 4.4-17.4 12-22.1 21.9-1.2 2.6-2.2 5.4-2.2 8.6v556.5c0 2.8.7 4.7 2.9 6.6 1.7 1.5 3.3 1.3 5.1 1.3h289.4c96 0 192.2 0 288.2.1h3.2c1.2 0 2-.4 3.1-1.2 2.6-1.9 3.2-4.1 3.2-7.1V58.4v-2.5c0-.5.1-1.3-.1-1.7zM50.8 81.4c0-4 .7-7.8 2-11.5 2.4-6.5 7.1-10.1 14.2-11.4 5.1-1 10.1-.9 15.2-.9h487.2c4.4 0 8.8.4 12.8 2.3 5.7 2.7 9 7.3 10.1 13.8.8 4.9.8 9.6.9 14.3H50.8v-6.6zm0 31.7h542.4V148H50.8v-34.9zm0 60l542.4-.1v42.9l-542.4.1v-42.9zm542.4 424.1c0 1.6-1.3 2.8-2.8 2.8H53.6c-1.5 0-2.8-1.2-2.8-2.7v-8.8V241l542.4-.1v356.3z" />
    <path fill={props.color} d="M532.1 389.4c-18.1-3.8-30.6-4.6-35.7-1.1-1.9 1.3-5 5-6.4 11.4-12.1-29.4-38.7-83.9-75.4-94.5-31.4-9.1-157.5-11.3-185.4 0C194 319.4 164 370.9 152 400c-1.4-6.5-4.1-10.6-6-11.9-5.1-3.5-19.8-5-37.9-1.1-18.1 3.8-12.3 34.5-.3 42.4 3.2 2.1 23.7 3.6 38.7 1.8v40.2c0 3.4-5.2.9-5.2 11.9v21.4c0 13.5 5 22.4 13.1 28.2v32.9c0 16.2 13.2 29.4 29.4 29.4h9.4c16.2 0 29.4-13.2 29.4-29.4v-23.6h196.6v23.6c0 16.2 13.2 29.4 29.4 29.4h9.4c16.2 0 29.4-13.2 29.4-29.4v-32.7c8.3-5.8 13.4-14.7 13.4-28.3v-21.4c0-11.1-5.2-8.5-5.2-11.9v-40.2c15.1 1.8 36.3-2.5 39.6-4.6 11.9-8 15-33.5-3.1-37.3zm-281.5 84.4c-3.9 2.6-57.6-1.8-61.8-6.4-7.4-8-11.8-32.2-6.9-35.5s28.6-3.7 46.2 0 34.1 34.2 22.5 41.9zm-53.1-84.6c-6.3-13.3 13.9-48.6 37.1-61.1 23.2-12.5 157.4-12.5 177.7 0 19.4 12 45.2 51.3 37.1 61.1-12.4 15.2-245.5 13.3-251.9 0zm194.8 84.6c-11.6-7.7 4.9-38.2 22.5-41.9 17.6-3.7 41.2-3.4 46.2 0s.6 27.5-6.8 35.5c-4.4 4.6-58 9-61.9 6.4z" />
  </svg>
);

export default SvgIndoor;
