import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';

import { IconButton } from './styles'

/*
  HOW TO USE:
  import SnackBar from 'src/components/SnackBar'
  Use it in your render with:
  <SnackBar ref={ref => this.snack = ref} />

  then, just call this.snack.open(*snack type*, *your custom message*)
  snack possible types:
  success, error, info, warning
*/

const variants = {
  success: {
    icon: CheckCircleIcon,
    backgroundColor: green[500],
  },
  error: {
    icon: ErrorIcon,
    backgroundColor: red[500],
  },
  info: {
    icon: InfoIcon,
    backgroundColor: blue[500],
  },
  warning: {
    icon: WarningIcon,
    backgroundColor: amber[700],
  },
}

class SnackBarCustom extends React.Component {
  state = {
    open: false,
    type: 'success',
  }

  open = (type = 'error', message = 'Une erreur est survenue') => {
    this.setState({
      open: true,
      type,
      message,
    })
  }

  handleClose = () => this.setState({ open: false })

  render() {
    const { type, message } = this.state
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackWrapper type={type} message={message} handleClose={this.handleClose} />
      </Snackbar>
    )
  }
}

function RenderSnackbarContent({ type, message, handleClose, classes }) {
  const Icon = variants[type].icon
  return (
    <SnackbarContent
      style={{ backgroundColor: variants[type].backgroundColor }}
      aria-describedby="client-snackbar"
      message={<span className={classNames(classes.message)}>
        <Icon className={classNames(classes.icon, classes.iconVariant)} />
        {message}
      </span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

const styles = theme => ({
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
  }
})

const SnackWrapper = withStyles(styles)(RenderSnackbarContent)

export default SnackBarCustom;
