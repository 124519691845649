import React from 'react';

import Tooltip from 'src/components/Tooltip';
import PhoneButton from 'src/components/PhoneButton';
import BookingTip from './BookingTip';
import { DayMonth } from 'src/utils/DateUtils';

const TEXT = {
  oneOwner: "Contactez le locataire pour récupérer l'accès au parking (clé / télécommande / badge).",
  oneTenant: "Contactez le propriétaire pour récupérer l'accès au parking (clé / télécommande / badge).",
  two: "L'abonnement prendra automatiquement fin le",
  self: "Vous avez demandé la résiliation ce cet abonnement.",
  TERMINATING_OWNER: "Le propriétaire à demandé la résiliation de cet abonnement.",
  TERMINATING_TENANT: "Le locataire à demandé la résiliation de cet abonnement.",
}

function getTerminatingStatus(endStatus, userType) {
  if (endStatus === "TERMINATING_OWNER") {
    if (userType === "tenant") {
      return TEXT.TERMINATING_OWNER
    }
    return TEXT.self
  } else if (endStatus === "TERMINATING_TENANT") {
    if (userType === "tenant") {
      return TEXT.self
    }
    return TEXT.TERMINATING_TENANT
  }
}

function TerminatingBooking({ booking, onCodeValidated, parkingId, data, userType }) {
  const { status } = booking
  const textStatus = getTerminatingStatus(status.toUpperCase(), userType)

  return (
    <div className="h-100 text-center w-100 pt-4 px-4">
      <Tooltip text={textStatus} color="yellow" />
      <BookingTip number="1" text={userType === "tenant" ? TEXT.oneTenant : TEXT.oneOwner} />
      <PhoneButton phone={userType === "tenant" ? booking.owner_phone : booking.tenant_phone} />
      <BookingTip number="2" text={`${TEXT.two} ${DayMonth(booking.termination_date)}.`} />
    </div>
  )
}

export default TerminatingBooking
