import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function CardsModal({ children, cardsNb, switchAddCard }) {
  const [open, setOpen] = useState(false)

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = value => {
    setOpen(false);
  };

  return (
    <>
      <Button className="no-outline" style={{ textTransform: 'none' }} color="primary"
        onClick={cardsNb === 0 ? switchAddCard : handleClickOpen}
      >
        {cardsNb === 0 ? 'Ajouter' : 'Changer'}
      </Button>
      <Dialog onClose={handleClose} open={open} style={{ padding: 15 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
          <IconButton aria-label="Close" onClick={handleClose} className="no-outline" style={{ position: 'absolute', left: 10, top: 0 }}>
            <CloseIcon />
          </IconButton>
          <DialogTitle>Cartes enregistrées</DialogTitle>
          <Button className="no-outline mr-4" style={{ textTransform: 'none' }} color="primary"
            onClick={switchAddCard}
          >
            Ajouter une carte
          </Button>
        </div>
        {children}
      </Dialog>
    </>
  )
}

export default CardsModal
