import idx from 'idx'

// import { parseSingleConfig } from 'src/api/config/configApiParser'

export const parseSingleParking = (parking = {}) => {
  if (parking.access_management === 'in_hand') {
    return parseNewSingleParking(parking)
  } else if (parking.access_management === 'parkmatch' || parking.access_management === null) {
    return parseOldSingleParking(parking)
  }
}

export const parseNewSingleParking = (parking = {}) => {
  return {
    accessType: parking.access_type,
    address: parking.address,
    camera: parking.camera,
    description: parking.description,
    id: parking.parking_id,
    indoor: parking.indoor,
    images: parking.images,
    lighting: parking.lighting,
    location: {
      latitude: idx(parking, _ => _.coordinates.latitude),
      longitude: idx(parking, _ => _.coordinates.longitude),
    },
    monthlyPrice: parking.monthly_price,
    ownerName: parking.owner_name,
    pedestrianExit: parking.pedestrian_exit,
    powerSupply: parking.power_supply,
    size: parking.size,
    status: parking.status,
    street: parking.street,
    ...parking,
  }
}

export const parseOldSingleParking = (parking = {}) => ({
  id: parking.parking_id,
  camera: parking.camera,
  compatibility: parking.compatibility,
  description: parking.description,
  hourlyPrice: parking.hourly_price,
  monthlyPrice: parking.monthly_price,
  indoor: parking.indoor,
  images: parking.images,
  instructions: (parking.instructions || []).map(instruction => ({
    id: instruction.instruction_id,
    type: instruction.type,
    urlImage: instruction.url_image,
  })),
  lighting: parking.lighting,
  address: parking.address,
  location: {
    coordinates: {
      latitude: idx(parking, _ => _.coordinates.latitude),
      longitude: idx(parking, _ => _.coordinates.longitude),
    },
  },
  pedestrianExit: parking.pedestrian_exit,
  powerSupply: parking.power_supply,
  size: parking.size,
  status: parking.status,
  statusInstructions: parking.status_instructions,
  statusRemote: parking.status_remote,
  copied: parking.copied,
  tested: parking.tested,
  unload_warning: parking.unload_warning,
})

// export const parseSingleInstruction = (instruction = {}) => ({
//   id: instruction.instruction_id,
//   description: instruction.description,
//   norder: instruction.norder,
//   type: instruction.type,
//   urlImage: instruction.url_image,
//   config: instruction.config && parseSingleConfig(instruction.config),
// })
