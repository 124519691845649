import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import Marker from 'public/assets/img/marker.png';

import { GOOGLE_API_KEY } from 'src/environement';

import './index.css';

const MarkerComponent = () => (
  <div style={{ position: 'absolute', top: '45%', left: '45%' }}>
    <img width={35} src={Marker} alt="marker" />
  </div>
)

const createMapOptions = (maps) => ({
  zoomControl: false,
  disableDefaultUI: true,
  clickableIcons: false,
})

const Map = ({ onChange, lat, lng }) => (
  <div className="my-3 map" style={{ zIndex: 1, display: 'flex', position: 'relative', padding: 15 }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      center={{ lat: lat, lng: lng }}
      zoom={18}
      options={createMapOptions}
      onChange={onChange}
    />
    <MarkerComponent />
  </div>
)

Map.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
}

export default Map;
