import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import SnackBar from 'src/components/SnackBar';
import ParkingApi from 'src/api/parking'

class ImageGridList extends React.Component {
  state = {
    images: [],
    isDeleteLoading: false,
    isImageUploading: false,
  }

  componentWillMount() {
    const { parking } = this.props
    if (parking.images) {
      this.setState({ images: parking.images })
    }
  }

  getGridListCols = () => {
    if (isWidthUp('xl', this.props.width)) {
      return 4;
    } else if (isWidthUp('lg', this.props.width)) {
      return 3;
    } else if (isWidthUp('md', this.props.width)) {
      return 2;
    }
    return 1;
  }

  updateParking = async (id) => {
    const res = await ParkingApi.getParking({ parkingId: id })
    if (!res.error && this.props.onEdition) {
      this.props.onEdition(res)
    }
  }

  uploadImage = async (base64) => {
    var base64result = base64.split(',');
    if (base64result.length > 1) {
      const finaleImage = base64result[1];
      const { parking } = this.props
      const res = await ParkingApi.uploadImage(parking.id, finaleImage)
      this.setState({ isImageUploading: false })
      if (!res.error) {
        this.setState({ images: res })
        this.snack.open('success', 'Image enregistrée.')
        this.updateParking(parking.id)
        return
      }
    }
    this.setState({ isImageUploading: false })
    this.snack.open('error')
  }

  transformImageToB64 = (event) => {
    this.setState({ isImageUploading: true })
    const { files } = event.target
    Object.keys(files).forEach(key => {
      const file = files[key]
      if (file && file.type.match('image.*')) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => this.uploadImage(reader.result);
        reader.onerror = function (error) {
          this.snack.open('error', "Le format de l'image semble incorrect.")
        };
      }
    });
  }

  cleanImageArray = (images) => {
    const order = []
    images.forEach((e) => {
      order.push({ image_id: e.image_id })
    })
    return order
  }

  setDefaultImage = async (imageId) => {
    const { parking } = this.props
    const tempImages = [...parking.images]
    const i = tempImages.findIndex(e => e.image_id === imageId)
    const tempDefault = tempImages.splice(i, 1)
    tempImages.unshift(tempDefault[0])
    const res = await ParkingApi.updateOrderImages({
      parkingId: parking.id,
      orderArray: JSON.stringify(this.cleanImageArray(tempImages)),
    })
    if (!res.error) {
      this.setState({ images: tempImages })
      this.snack.open('success', 'Image de couverture modifiée.')
      this.updateParking(parking.id)
      return
    }
    this.snack.open('error')
  }

  deleteImage = async (imageId) => {
    const { parking } = this.props
    this.setState({ isDeleteLoading: true })
    const res = await ParkingApi.deleteImage(imageId)
    if (!res.error) {
      this.setState({ images: res, isDeleteLoading: false })
      this.snack.open('success', 'Image suprimée.')
      this.updateParking(parking.id)
      return
    }
    this.setState({ isDeleteLoading: false })
    this.snack.open('error')
  }

  render() {
    const { classes, edit } = this.props;
    const { images, isImageUploading } = this.state
    return (
      <div className={classes.root}>
        <GridList cols={this.getGridListCols()}>
          {images.map((tile, index) => (
            <StyledGridListTile key={tile.large} cols={1}>
              <img src={tile.large} alt={tile.image_id.toString()} />
              <GridListTileBar
                titlePosition="top"
                actionIcon={
                  <IconButton onClick={() => this.setDefaultImage(tile.image_id)} className={`no-outline ${this.props.classes.icon}`}>
                    {index === 0 ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                }
                actionPosition="left"
                className={this.props.classes.titleBar}
              />
              <div className="delete-button" style={{ position: 'absolute', top: 0, right: 0 }}>
                <IconButton disabled={this.state.isDeleteLoading} onClick={() => this.deleteImage(tile.image_id)} className={`no-outline ${this.props.classes.icon}`}>
                  {this.state.isDeleteLoading ?
                    <CircularProgress size={30} thickness={5} />
                    : <DeleteIcon />
                  }
                </IconButton>
              </div>
            </StyledGridListTile>
          ))}
        </GridList>
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={this.transformImageToB64}
        />
        <label htmlFor="raised-button-file">
          <Button disabled={isImageUploading} variant="contained" color="primary" component="span" className={classes.button}>
            {isImageUploading ?
              <CircularProgress size={30} thickness={5} />
              : <>
                <PhotoCamera className={classes.button} />
                Ajouter une photo
              </>
            }
          </Button>
        </label>
        {edit && <SnackBar ref={ref => this.snack = ref} />}
      </div>
    );
  }
}

const StyledGridListTile = styled(GridListTile)`
  .delete-button {
    display: none;
  }
  :hover {
    .delete-button {
      display: block;
    }
  }
`

ImageGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',

  },
  input: {
    display: 'none',
  },
  button: {
    color: 'white',
    margin: theme.spacing(1),
  },
});

export default withWidth()(withStyles(styles)(ImageGridList));
