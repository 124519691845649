import React from 'react';
import { Link } from 'react-router-dom';

import InputSearch from 'src/components/InputSearch';
import LandingIllustration from './LandingIllustration';

import Car from 'public/assets/img/car.png';
import Parking from 'public/assets/img/parking.png';
import Parkman from 'public/assets/img/parkman.png';
import AppleStore from 'public/assets/img/app-store-transparent.png';
import AndroidStore from 'public/assets/img/google-play-transparent.png';
import TextLoop from 'react-text-loop'

import '../styles.css'


const LandingSection = ({ registerParking }) => (
  <div className="row background min-vh-100" style={{ paddingTop: 60 }}>
    <div className="col landing-section-container d-flex flex-column justify-content-around" style={{ textAlign: 'center', position: 'relative' }}>
      <div className="row">
        <div className="col-md-7 offset-md-1 col-sm-10 offset-sm-1 mt-lg-5 mt-4">
          <h1 className="title-landing">Réservation de parking entre particuliers</h1>
          <div className="subtitle-landing">Courte ou longue durée</div>
        </div>
        <div className="col-md-4 d-none d-md-block text-left">
          <img className="parkman" alt="Parkman" src={Parkman} />
        </div>
      </div>
      <div className="row mt-1 mt-lg-2">
        <div className="col-md-5 col-lg-4 col-sm-8 offset-sm-2 text-center offset-md-1 offset-lg-2 pr-md-5">
          <LandingIllustration className="car-illustration" src={Car} alt="car" />
          <div className="col" style={{ height: '115px' }}>
            <div className="py-2 search">Rechercher un parking à louer</div>
            <InputSearch placeholder="ex: 2 rue de la Place" />
          </div>
        </div>
        <div className="col-md-0 d-none d-md-block">
          <div className="borderSeparator" />
        </div>
        <div className="col-lg-4 col-md-5 offset-md-0 col-sm-10 offset-sm-1 d-flex flex-column text-center">
          <LandingIllustration src={Parking} alt="parking" />
          <div className="col" style={{ height: '115px' }}>
            <Link to="/add">
              <button className="mt-3 py-3 addParkingButton" onClick={registerParking}>
                Enregistrer mon parking
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/*<div className="row h-20 mt-3">*/}
      {/*  <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 text-center">*/}
      {/*    <div className="row applications text-center">*/}
      {/*      <div className="col applications text-center">*/}
      {/*        Aussi disponible sur*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="row mb-4">*/}
      {/*      <div className="col-12 col-sm-6 mt-3">*/}
      {/*        <a className="store mx-auto" href="https://itunes.apple.com/us/app/parkmatch/id1327371269" target="_blank" rel="noopener noreferrer">*/}
      {/*          <img style={{ width: '100%' }} src={AppleStore} alt="parking" />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-sm-6 mt-3">*/}
      {/*        <a className="store mx-auto" href="https://play.google.com/store/apps/details?id=eu.parkmatch.parkmatch" target="_blank" rel="noopener noreferrer">*/}
      {/*          <img style={{ width: '100%' }} src={AndroidStore} alt="parking" />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  </div>
)

export default LandingSection
