import React from 'react';
import PropTypes from 'prop-types';
import { FaUserAlt } from 'react-icons/fa';
import { COLORS } from 'src/stylesheet/colors';

const OverlayUser = ({ overlaysWidth, iconColor = COLORS.iconsGray, backgroundColor = COLORS.lightWhite, itemsWidth, borderColor = 'white' }) => (
  <div style={{ flex: `0 0 ${overlaysWidth}px`, width: overlaysWidth, backgroundColor: backgroundColor, height: overlaysWidth, border: `1px solid ${borderColor}` }} className="d-flex align-items-center justify-content-center rounded-circle text-center">
    <FaUserAlt color={iconColor} size={itemsWidth} />
  </div>
)

OverlayUser.propTypes = {
  overlaysWidth: PropTypes.number,
  itemsWidth: PropTypes.number,
  borderColor: PropTypes.string,
}

export default OverlayUser;