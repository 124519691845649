import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { withRouter } from 'react-router-dom'

import formatMetaData from 'src/utils/formatMetaData';
import PublicPage from 'src/templates/PublicPage';
import Breadcrumb from 'src/components/Breadcrumb';
import Analytics from 'src/services/Analytics'

import ParkingDetail from './components/ParkingDetail';
import ParkingBook from './components/ParkingBook';
import RentalModeInfo from './components/RentalModeInfo';
import { Helmet } from "react-helmet";
import SearchProvider from 'src/services/store/SearchStore';
import { Consumer } from 'src/services/store/SearchStore';
import ParkingPanel from 'src/components/ParkingPanel'
import { parkingMarkup } from 'src/utils/structuredData'

class ParkingPage extends React.Component {
  state = {
    isLoading: true,
  }

  componentDidMount() {
    this.props.setSelectedParking(this.props.parking)
    this.getParkingDetail()
  }

  getParkingDetail = async () => {
    const { parking } = this.props
    // const formatedSlug = this.formatSlugForParkingPage(slug, parking.street)
    formatMetaData({
      title: `Parking à louer ${parking.address}`,
      description: `Parking à louer ${parking.address} sur Parkmatch: location de parking et garage entre particuliers. Réservation gratuite sans caution !`,
    });
    this.setSeoData(parking)
    Analytics.sendParkingView(parking.parking_id)
    this.setState({ isLoading: false })
  }

  setSeoData = (parking) => {
    if (parking && parking.status !== "INCOMPLETE") {
      const ParkingFacilityScript = this.createParkingFacilityScriptContent(parking)
      this.manageDomScript('ParkingFacility', ParkingFacilityScript, parking)
    }
  }

  manageDomScript = (type, script, parking) => {
    const tag = document.getElementById(type)
    if (tag) {
      tag.innerHTML = script
    } else {
      const script = document.createElement("script");
      script.type = 'application/ld+json'
      script.id = type
      if (type === 'ParkingFacility') {
        script.innerHTML = this.createParkingFacilityScriptContent(parking)
      } else if (type === 'Product') {
        script.innerHTML = this.createProductScriptContent(parking)
      }
      document.head.appendChild(script);
    }
  }

  createParkingFacilityScriptContent = (parking) => (
    `
    {
      "@context": "http://schema.org",
      "@type": "ParkingFacility",
      "name": "${parking.street}",
      "url": "https://www.parkmatch.eu/${parking.parking_url}",
      "description": "Parking à louer ${parking.address} sur Parkmatch: location de parking et garage entre particuliers. Réservation gratuite sans caution !",
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "${parking.location.latitude}",
        "longitude": "${parking.location.longitude}"
      },
      "openingHours": "Mo-Su",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "${parking.city}",
        // "addressCountry": "FR",
        "postalCode": "${parking.zip_code}",
        "streetAddress": "${parking.street}"
      },
      "photo": {
        "@type": "ImageObject",
        "contentUrl": [${this.extractImagesFromParking(parking.images)}]
      }
    }
    `
  )

  extractImagesFromParking = (images) => {
    const imagesArray = []
    images.forEach(elem => {
      imagesArray.push(`"${elem.large}"`)
    })
    return imagesArray
  }

  render() {
    const { isLoading } = this.state;
    const { rentalMode, selectedParking, parking } = this.props
    return (
      <PublicPage isLoading={isLoading} navbarPush
        metaTitle={`Parking à louer ${parking.address}`}
        metaDescription={`Parking à louer ${parking.address} sur Parkmatch: location de parking et garage entre particuliers. Réservation gratuite sans caution !`}>
        <Helmet>
          <link rel="canonical" href={`https://www.parkmatch.eu/${parking.parking_url}`} />
          {parkingMarkup(parking)}
        </Helmet>

        <Breadcrumb />
        <div className="row mb-4">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-5 offset-lg-1">
            <ParkingDetail data={selectedParking} />
          </div>
          <div className="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-5 my-5 my-md-0">
            {(selectedParking && selectedParking.status !== "INCOMPLETE") &&
              <ParkingBook parking={selectedParking} history={this.props.history} />
            }
            {(selectedParking && selectedParking.status === "INCOMPLETE") &&
              <ParkingPanel parking={selectedParking}>
                <p>Demande de location indisponible.</p>
              </ParkingPanel>
            }
          </div>
        </div>
        {(selectedParking && selectedParking.status !== "INCOMPLETE") &&
          <>
            <div className="row mb-4">
              <div className="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-5 offset-lg-1">
                <RentalModeInfo rentalMode={rentalMode} />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-5 my-5 my-md-0">
                <div>
                  <h2>Notice d'information</h2>
                  <p>La description du parking et les autres éléments relèvent uniquement des informations fournies par le propriétaire. Ils ne font pas l'objet de vérifications de la part de Parkmatch. La disponibilité réelle du parking peut varier et relève également des disponibilités précisées par le propriétaire. </p>
                  <p>Nous vous rappellons les dispositions du code civil relatives au droit des obligations et de la responsabilité civile applicables à la relation contractuelle telles que prévues aux articles 1101 et suivants du code civil. <a href="https://www.legifrance.gouv.fr/affichCode.do?cidTexte=LEGITEXT000006070721&dateTexte=&categorieLien=cid" target="_blank">Voir les dispositions</a></p>
                  <p>S'agissant d'une réservation de parking et relation contractuelle entre particuliers, vous ne bénéficierez donc pas de droit de rétractation une fois la relation contractuelle établie entre vous et le propriétaire du parking.</p>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                {/* <ParkingSearch rentalMode={rentalMode} /> => TODO Show other parkings */}
              </div>
            </div>
          </>
        }
      </PublicPage>
    );
  }
}


export default withRouter((props) => (
  <SearchProvider>
    <Consumer>
      {ctx => <PublicRoute component={ParkingPage} {...props}
        rentalMode={ctx.rentalMode}
        setRentalMode={ctx.setRentalMode}
        startDate={ctx.startDate}
        setStartDate={ctx.setStartDate}
        endDate={ctx.endDate}
        setEndDate={ctx.setEndDate}
        selectedParking={ctx.selectedParking}
        setSelectedParking={ctx.setSelectedParking}
      />}
    </Consumer>
  </SearchProvider>
));

