import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Switch from '@material-ui/core/Switch';
import Tooltip from 'src/components/Tooltip'

import { Consumer } from 'src/services/store';

import PmButton from 'src/components/StyledButton'
import styled from 'styled-components'
import { COLORS } from 'src/stylesheet/colors';
import ParkingApi from 'src/api/parking'

function AvailabilityInput({ onChangeStep, step, edit, parking, data, onEdition }) {

    const [isLoading, setIsLoading] = useState(false)
    const [isModified, setIsModified] = useState(false)

    const [h24, setH24] = useState(true);
    function handleChangeH24(event) {
        setH24(event.target.checked);
        setIsModified(true)
    };

    const [monthly, setMonthly] = useState(false);
    function handleChangeMonthly(event) {
        setMonthly(event.target.checked);
        setIsModified(true)
    };

    const [oneshot, setOneshot] = useState(false);
    function handleChangeOneshot(event) {
        setOneshot(event.target.checked);
        setIsModified(true)
    };

    const [durationLimit, setDurationLimit] = useState(0);
    function handleChangeDurationLimit(event) {
        setDurationLimit(event.target.value);
        setIsModified(true)
    };

    useEffect(() => {
        if (parking) {
            setH24(nullToFalse(parking.h24_availability))
            setMonthly(nullToFalse(parking.allow_subscription))
            setOneshot(nullToFalse(parking.allow_one_shot))
            setDurationLimit(parking.minimum_rent_duration === null ? 0 : parking.minimum_rent_duration)
        }
        init()
        setIsModified(false)
    }, []);

    const nullToFalse = (apiValue) => {
        return (apiValue === null) ? false : apiValue
    }

    const init = () => {
        let lParking = parking
        if (!edit) {
            if (typeof window !== 'undefined') { // client
                lParking = JSON.parse(sessionStorage.getItem('parking'));
            }
        }
        if (lParking && typeof lParking.h24 !== "undefined" && !edit) {
            setH24(lParking.h24)
            setMonthly(lParking.monthly)
            setOneshot(lParking.oneshot)
            setDurationLimit(lParking.durationLimit)
        }
    }

    const formValidate = () => {
        if (!monthly && !oneshot) {
            data.snackRef.open('warning', "Veuillez sélectionner au moins un des deux modes de location.")
            return false
        }
        return true
    }

    async function setParkingAvailability() {
        setIsLoading(true)

        if (edit) {
            const info = {
                h24_availability: h24,
                allow_one_shot: oneshot,
                allow_subscription: monthly,
                minimum_rent_duration: durationLimit
            }
            const res = await ParkingApi.updateParkingInfo(parking.id, info)
            setIsLoading(false)
            if (!res.error) {
                onEdition(res)
                data.snackRef.open('success', "Disponibilités mises à jour.")
            } else {
                data.snackRef.open('error', "Une erreur est survenue.")
            }
        }
        else {
            let lParking = null
            if (typeof window !== 'undefined') { // client
                lParking = JSON.parse(sessionStorage.getItem('parking'));
            }
            lParking = { ...lParking, h24, monthly, oneshot, durationLimit, currentStep: step + 1 };
            sessionStorage.setItem('parking', JSON.stringify(lParking));
        }
    }

    const onSubmit = () => {
        if (formValidate()) {
            setParkingAvailability()
            if (!edit) {
                onChangeStep(step + 1)
            }
        }

    }

    return (
        <>
            <div className="container-fluid">
                <StyledDiv className="row py-1">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div><strong>Disponible</strong> tout le temps 24h/24<br />
                            <span style={{ color: '#AAAAAA', fontSize: '1rem' }}>Nécessaire pour recevoir des demandes d'autres utilisateurs</span></div>
                        <GreenSwitch color='primary' checked={h24} onChange={e => handleChangeH24(e)} />
                    </div>
                </StyledDiv>
                <hr />
                <StyledDiv className="row py-1">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div>J'accepte les <strong>locations au mois</strong><br />
                            <span style={{ color: '#AAAAAA', fontSize: '1rem' }}>30 jours de préavis</span></div>
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleChecked />}
                            color='primary'
                            checked={monthly}
                            onChange={e => handleChangeMonthly(e)}
                        />
                    </div>
                </StyledDiv>
                {(parking && parking.monthly_price === '0' && edit && monthly) &&
                    <Tooltip color="yellow" text="Pensez à renseigner un prix mensuel dans la partie Edition => Prix." />
                }
                <hr />
                <StyledDiv className="row pt-1">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div>J'accepte les <strong>réservations ponctuelles</strong></div>
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleChecked />}
                            color='primary'
                            checked={oneshot}
                            onChange={e => handleChangeOneshot(e)}
                        />
                    </div>
                </StyledDiv>
                <StyledDiv className="row pb-1 mb-3">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="ml-4">À partir de </div>
                        <FormControl variant="outlined" style={{ width: 130 }}>
                            <InputLabel shrink htmlFor="min-duration" style={{ backgroundColor: "transparent", width: 50 }}>
                                Jours
                            </InputLabel>
                            <Select
                                native
                                value={durationLimit}
                                onChange={e => handleChangeDurationLimit(e)}
                                input={
                                    <OutlinedInput labelWidth={55} name="min-duration" id="min-duration" />
                                }
                                disabled={!oneshot}
                                style={{ fontSize: '1rem' }}

                            >
                                <option value={0}>Pas de limite</option>
                                {function () {
                                    let rows = [];
                                    for (let i = 1; i < 31; i++) {
                                        rows.push(<option key={i} value={i}>{i}</option>)
                                    }
                                    return rows
                                }()}
                            </Select>
                        </FormControl>
                    </div>
                </StyledDiv>
                {(parking && parking.daily_price === '0' && edit && oneshot) &&
                    <Tooltip color="yellow" text="Pensez à renseigner un prix jour dans la partie Edition => Prix." />
                }

            </div>
            <PmButton style={{ display: 'block', margin: 'auto' }}
                isLoading={isLoading}
                onClick={onSubmit}
                disabled={(edit && !isModified)}>
                {edit ? "Enregistrer" : "Suivant"}
            </PmButton>
        </>
    );
}

export default React.forwardRef((props, ref) => (
    <Consumer>
        {ctx => <AvailabilityInput {...props} data={ctx} ref={ref} />}
    </Consumer>
));


const StyledDiv = styled.div`
    color: '#BBBBBB';
    font-size: 1rem;
`

const GreenSwitch = withStyles({
    root: {
        width: 80,
        height: 40,
        overflow: 'visible',
    },
    switchBase: {
        marginTop: -7,
        width: 54,
        height: 54,
        color: COLORS.lightGrey,
        '&$checked': {
            color: COLORS.green,
            //transform: "translateX(100%)",
        }
    },
    checked: {

        //transform: "translateX(100%)",
    },
    // track: {}
    thumb: {
        height: 30,
        width: 30,
    }

})(Switch);
