import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';
import CONSTANTS from 'src/constants';
import PublicPage from 'src/templates/PublicPage';
import PublicRoute from 'src/containers/PublicRoute';
import { withRouter } from 'react-router-dom'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Fab from '@material-ui/core/Fab';

import StepsForm from './components/stepsForm';
import BackButton from './components/backButton';
import ToolTips from './components/toolTips';

import Analytics from 'src/services/Analytics'

import ParkingInputs from 'src/components/ParkingInputs'
import { FaInfoCircle } from 'react-icons/fa';
import GLOBAL from 'src/stylesheet';

import './index.css';


class AddParkingQuickPage extends React.Component {
  state = {
    Component: ParkingInputs.AddressInput,
    stepTitle: 'Adresse',
    step: 1,
    tooltip: null,
  }

  componentWillMount() {
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    if (parking && parking.currentStep) {
      this.getComponent(parking.currentStep);
    } else {
      Analytics.sendViewAddParking()
      this.getComponent(1);
    }
  }

  getComponent = (step) => {
    step = step ? step : 1
    this.setState({
      Component: CONSTANTS.STEP_COMPONENT[step],
      stepTitle: CONSTANTS.STEP_TITLE[step],
      step: step,
      tooltip: CONSTANTS.STEP_TOOLTIP[step]
    });
  }

  onChangeStep = (step) => {
    if (step > 0) {
      this.getComponent(step);
    } else {
      this.props.history.goBack();
    }
  }

  goBack = () => {
    this.onChangeStep(this.state.step - 1)
  }

  render() {
    const { Component, step, stepTitle, tooltip } = this.state;
    const width = (typeof window !== 'undefined') ? document.getElementById('root').offsetWidth : 200;
    return (
      <PublicPage fullHeight footer={false} navbarPush
        metaTitle="Ajouter un parking"
        metaDescription="Ajouter un parking pour le mettre en location sur Parkmatch.">
        <div className="row vh-100 d-flex flex-column align-items-center justify-content-center" style={{ backgroundImage: GLOBAL.gradient }}>
          <div className="d-none d-sm-block"><StepsForm step={step} width={(width - 100) / 5} /></div>
          <div className="rounded p-1 p-sm-4 mt-3 d-flex justify-content-center card-custom" style={{ backgroundColor: COLORS.lightWhite, position: 'relative' }}>
            <div className="d-none d-md-block">
              <Fab className="no-outline" style={{ backgroundColor: 'white', position: 'absolute', left: -75 }} onClick={this.goBack}>
                <KeyboardArrowLeft style={{ fontSize: 45, backgroundColor: COLORS.green, borderRadius: 25, color: 'white' }} />
              </Fab>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center w-100 h-100" style={{ minHeight: 399 }}>
              <BackButton onChangeStep={this.onChangeStep} step={step} resp={true} />
              <div style={{ fontSize: 22 }} className="text-center mb-1">{stepTitle}</div>
              {tooltip.title && <div className="d-none d-lg-block"><ToolTips data={tooltip} /></div>}
              {tooltip.title &&
                <div className="d-lg-none mb-3">
                  <div className="d-flex justify-content-center align-content-center mb-2">
                    <FaInfoCircle size={24} color={COLORS.green} />
                    <div className="ml-2" style={{ fontSize: 18, color: COLORS.green }}>{tooltip.title}</div>
                  </div>
                  <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: tooltip.text }} />
                </div>
              }
              <Component step={step} data={tooltip} history={this.props.history} onChangeStep={this.onChangeStep} edit={false} />
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

AddParkingQuickPage.propTypes = {
  component: PropTypes.any,
}

export default () => <PublicRoute component={withRouter(AddParkingQuickPage)} />
