import React from 'react'
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BackGroundWorking from 'public/assets/img/work.jpg';

import '../styles.css'

const TeamSection = () => (
  <div className="row" style={{ backgroundImage: `url(${BackGroundWorking})`, backgroundSize: "cover", backgroundPosition: "center", minHeight: "30vh" }}>
    <div className="col">
      <div className="row py-4" style={{ backgroundColor: 'rgba(31, 222, 145, .8)', minHeight: "30vh"}} >
        <div className="col-12 col-sm-2 offset-sm-2 py-2 my-auto text-center text-sm-right">
          <FaHeart color="#ff396c" size="5em" />
        </div>
        <div className="col-12 col-sm-5 py-2 my-auto text-center text-sm-left">
          <div className="row">
            <div className="col team-title align-middle">
              &Agrave; votre service
            </div>
          </div>
          <div className="row">
            <div className="col py-2">
              <Link className="contact-button" to="/contact">
                Nous contacter
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TeamSection
