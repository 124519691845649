import React from 'react';
import { Link } from 'react-router-dom'
import { FaImage } from 'react-icons/fa';

import { Consumer } from 'src/services/store';

import { COLORS } from 'src/stylesheet/colors';

const CardParking = ({ parking, data }) => (
  <div className="d-flex flex-column justify-content-center align-items-center mx-auto" style={{ maxWidth: '340px', position: 'relative' }}>
    <div style={{ width: '100%', maxWidth: '190px', maxHeight: 140, height: 140, borderRadius: 5, backgroundColor: COLORS.lightGray, overflow: 'hidden', display: 'relative' }}>
      {parking.image ?
        <img className="image rounded w-100" style={{ width: '100%', height: 'auto' }} src={parking.image.small} alt={'Parking ' + parking.street} />
        :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', width: '100%', height: '100%' }}>
          <FaImage size="50" color={COLORS.lightWhite} />
        </div>
      }
      {parking.total_price && <span className="shadow" style={{ borderRadius: 15, backgroundColor: COLORS.lightWhite, color: COLORS.green, border: `1px solid ${COLORS.green}`, fontWeight: 'bold', position: 'absolute', padding: 5, top: 90, right: 50, zIndex: 1 }}>{parking.total_price}€/mois</span>}
    </div>
    <div style={{ fontSize: '1.2rem' }}>
      <Link to={`/${parking.parking_url}`}>
        Parking {parking.address}<br /><span style={{ fontSize: '1.1rem' }}>{parking.city}</span>
      </Link>
    </div>
  </div>
)

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <CardParking {...props} data={ctx} ref={ref} />}
  </Consumer>
));
