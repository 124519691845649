import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'

const addVehicle = async ({ numberplate, size }) => {
  try {
    const res = await Api.parkmatch.post('/vehicle/create', { numberplate, size })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'ADD_VEHICLE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const getVehicleList = async () => {
  try {
    const res = await Api.parkmatch.get('/vehicle/list')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_LIST_VEHICLE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const selectVehicle = async ({ vehicle_id }) => {
  try {
    await Api.parkmatch.put('/vehicle/select', { vehicle_id })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'SELECT_VEHICLE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const editVehicle = async ({ vehicle_id, numberplate, size }) => {
  try {
    await Api.parkmatch.put('/vehicle/update', { vehicle_id, numberplate, size })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'EDIT_VEHICLE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const deleteVehicle = async ({ vehicle_id }) => {
  try {
    await Api.parkmatch.delete('/vehicle/delete', { vehicle_id })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'DELETE_VEHICLE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

export default {
  addVehicle,
  getVehicleList,
  selectVehicle,
  editVehicle,
  deleteVehicle,
}
