import React from 'react'
import styled from 'styled-components'


const StyledList = ({ elements, icon = '✓' }) => {

    return (
        <StyledUl icon={icon}>
            {
                elements.map(e =>
                    <li key={e}>{e}</li>
                )
            }
        </StyledUl >
    )
}

export default StyledList

const StyledUl = styled.ul`
    list-style: none;
    font-size: 1.2em;
    text-align: left;

    & li:before {
        content: '${props => props.icon}';
        margin: 10px;
        color: #1ACD71;
    }

    & li {
        margin-top: 5px;
        margin-bottom: 5px;
    }

`
