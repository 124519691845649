import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import Parking from 'public/assets/img/parking.png';

const CardRegisterParking = ({ history }) => (
  <div className="col-12 col-md-6 bg-white">
    <div className="m-2 p-4 text-center border rounded h-md-100">
      <img className="py-2" style={{ width: '40%' }} src={Parking} alt="parking" />
      <div><Button text="Enregistrer un parking" onClick={() => history.push('/add')} /></div>
    </div>
  </div>
)

CardRegisterParking.propTypes = {
  history: PropTypes.object.isRequired,
}

export default CardRegisterParking;
