import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import Iframe from "./Iframe"
import UserApi from 'src/api/user'

function FormDialog({ visible: open, switchDialogOpen, fullScreen, onIframeEndProcess, setWkToken }) {
  const [isLoading, setIsLoading] = useState(false)
  const [lemonWayUrl, setLemonWayUrl] = useState("")

  async function getUrlWebView() {
    setIsLoading(true)
    const res = await UserApi.addPaymentCard()
    setIsLoading(false)
    if (!res.error) {
      setLemonWayUrl(res['url'])
      setWkToken(res['wk_token'])
    }
  }

  useEffect(() => {
    if (open) {
      getUrlWebView()
    }
  }, [open])

  return (
    <Dialog open={open} onClose={switchDialogOpen} fullScreen={fullScreen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Ajouter une carte de paiement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Afin de vérifier la validité de votre carte bancaire, nous allons procéder à une tentative de paiement de 1 euro.
          Vous ne serez pas débité et votre carte sera activée immédiatement.
        </DialogContentText>
        {isLoading && <Grid container spacing={24} style={{ justifyContent: 'center' }}>
          <CircularProgress size={50} className="my-5" />
        </Grid>}
        {!isLoading && <Iframe src={lemonWayUrl} onIframeEndProcess={onIframeEndProcess} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={switchDialogOpen} color="primary" className="no-outline">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withMobileDialog()(FormDialog);
