const OVERRIDES = {
  MuiStepIcon: {
    text: {
      fill: 'white'
    },
  },
  MuiButton: {
    root: {
      fontSize: '1.05rem',
      textTransform: 'none'
    }
  }
}

export default OVERRIDES
