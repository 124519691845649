import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'moment/locale/fr';
import "../node_modules/animate.css/animate.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'react-dates/lib/css/_datepicker.css';
import "./App.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import AppProvider from './services/store';
import * as serviceWorker from "./serviceWorker";
import { COLORS } from './stylesheet/colors'
import Analytics from 'src/services/Analytics'
import * as Sentry from '@sentry/browser'
import OVERRIDES from 'src/stylesheet/MUIOverrides'
import 'react-dates/initialize';
import './environement'



// Initialize Sentry
Sentry.init({
  dsn: process.env.RAZZLE_SENTRY_DSN,
  release: "prod-web@" + process.env.RAZZLE_RELEASE_VERSION
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.green,
    },
    secondary: {
      main: '#818181',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 15,
    fontFamily: [
      'Bariol',
    ].join(','),
  },
  overrides: {
    ...OVERRIDES,
  },
});

// Initialize Analytics service (Google + Facebook)
Analytics.initialize()



const jssStyles = document.querySelector('#jss-server-side');
if (jssStyles) {
  jssStyles.parentElement.removeChild(jssStyles);
}

hydrate(
  <BrowserRouter>
    <AppProvider value={window.INITIAL_STATE}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}

//enable hot reloading if needed
// if (module.hot) {
//   module.hot.accept('./AppRouter', () => {
//     const NextApp = require('./AppRouter').default;
//     render(NextApp);
//   });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

