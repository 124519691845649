import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';

const Point = ({ isSelected }) => (
  <div className="rounded-circle d-flex justify-content-center align-items-center" style={{ width: 20, height: 20, backgroundColor: COLORS.lightWhite }}>
    {isSelected && <div className="rounded-circle" style={{ width: 14, height: 14, backgroundColor: COLORS.green }} />}
  </div>
)

const RenderStepsForm = (step, width) => {
  var renderSteps = [];
  const tmp = width > 80 ? 80 : width;
  for (let i = 1; i <= 6; i++) {
    renderSteps.push(
      <div key={i} className="d-flex flex-row align-items-center">
        <Point isSelected={step === i ? true : false} />
        {i !== 6 && <div style={{ width: tmp, height: 5, backgroundColor: COLORS.lightWhite }} />}
      </div>
    );
  }
  return renderSteps;
}

const StepsForm = ({ step, width }) => (
  <div className="d-flex align-items-center justify-content-center">
    {RenderStepsForm(step, width)}
  </div>
)

StepsForm.propTypes = {
  step: PropTypes.number,
}

export default StepsForm;
