import React from 'react'
import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';

const MapMarker = ({ selected, price, onPressItem }) => (
  <div style={{ zIndex: selected ? 3 : 1, position: 'absolute', width: 80, height: 40, marginLeft: -40, marginTop: -40 }}>
    <button style={{ position: 'relative', border: !selected ? `1px solid ${COLORS.green}` : 'none', backgroundImage: selected && GLOBAL.gradientBlue, backgroundColor: !selected && 'white' , outline: 'none', cursor: 'pointer', width: 80, height: 30, borderRadius: 5, color: selected ? COLORS.realLightGray : COLORS.mediumGreen, fontWeight: 'bold', fontSize: 16 }} onClick={onPressItem}>
      {Math.trunc(price)} €
      <div style={styles.triangle} />
    </button>
  </div>
)

const styles = {
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '7px 7px 0 7px',
    borderColor: `${COLORS.green} transparent transparent transparent`,
    position: 'absolute',
    bottom: 0,
    top: 29,
    left: 30,
  },
}

export default MapMarker
