import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components'

import './index.css'

const DivStyled = styled.div`

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

`;

export default class OpenVideo extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  }

  render() {
    return (
      <>
        <DivStyled onClick={() => this.toggle()}>Le concept en vidéo</DivStyled>
        <Modal centered isOpen={this.state.modal} toggle={this.toggle} modalClassName="modal">
          <ModalBody className="w-100" style={{ padding: 0, height: 300 }}>
            <iframe title="parkmatch comment ça marche" className="w-100 h-100" style={{ border: 'none', borderRadius: 5 }}
              src="https://www.youtube.com/embed/3fytF41Re6k">
            </iframe>
          </ModalBody>
        </Modal>
      </>
    )
  }
}
