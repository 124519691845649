import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleLeft } from 'react-icons/fa';
import './index.css';

import { COLORS } from 'src/stylesheet/colors';

const BackButton = ({ onChangeStep, step, resp = false }) => (
  !resp ?
  <div
    className="rounded-circle d-flex justify-content-center align-items-center"
    style={{ width: 50, height: 50, backgroundColor: COLORS.lightWhite, position: 'absolute', left: -75 }}
  >
    <div
      className="rounded-circle d-flex justify-content-center align-items-center"
      style={{ width: 40, height: 40, backgroundColor: COLORS.green }}
      onClick={ () => onChangeStep(step-1)}
    >
      <FaAngleLeft size={30} color={COLORS.lightWhite} />
    </div>
 </div> :
 <div className="displayyy" style={{ cursor: 'pointer' }} onClick={ () => onChangeStep(step-1)}>
   <FaAngleLeft size={40} color={COLORS.green} />
 </div>
)

BackButton.propTypes = {
  onChangeStep: PropTypes.func,
  step: PropTypes.number,
}

export default BackButton;