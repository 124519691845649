import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';

import BookingStatus from 'src/components/BookingStatus';
import UserAvatar from 'src/components/UserAvatar';
import { formatBookingDates } from 'src/utils/DateUtils';
import Tooltip from 'src/components/Tooltip';
import BookingType from 'src/components/BookingType'
import BookingDrawer from 'src/components/BookingDrawer';
import RENTAL_MODE from 'src/services/store/SearchStore'

class BookingPanel extends React.Component {
  state = {
    drawerBooking: false,
    bookingId: null,
    rentalMode: null
  }

  openDrawer = ({ bookingId, rentalMode }) => this.setState({ drawerBooking: true, bookingId: bookingId, rentalMode: rentalMode })
  closeDrawer = () => {
    this.setState({ drawerBooking: false })
    this.props.refreshList(this.props.parkingId)
  }

  render() {
    const { bookings, parkingId } = this.props
    const { drawerBooking, bookingId, rentalMode } = this.state

    return (
      <>
        {bookings.length > 0 &&
          <Paper>
            <Table>
              <HeaderTable />
              <TableBody>
                {bookings.map(row => (
                  <TableRow
                    hover
                    onClick={() => this.openDrawer({ bookingId: row.booking_id, rentalMode: row.recurrence_type })}
                    key={row.booking_id}
                  >
                    <UserCell component="th">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <UserAvatar url={row.tenant_image && `${row.tenant_image}_small.jpg`} small />
                        <span style={{ marginLeft: 15 }}>
                          {row.tenant_name}
                        </span>
                      </div>
                    </UserCell>
                    <Hidden smDown>
                      <TableCell align="right">
                        <BookingType type={row.type} />
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell align="right">{formatBookingDates(row)}</TableCell>
                    </Hidden>
                    <TableCell align="right">{row.recurrence_type === RENTAL_MODE.monthly ? row.monthly_price : row.total_price} €</TableCell>
                    <StyledCell align="right">
                      <BookingStatus status={row.status} />
                    </StyledCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        }
        {
          bookings.length === 0 &&
          <Tooltip text="Vous n'avez aucune réservation pour le moment." />
        }
        <Drawer anchor="right" open={drawerBooking} onClose={this.closeDrawer}>
          <BookingDrawer closeDrawer={this.closeDrawer} parkingId={parkingId} bookingId={bookingId} rentalMode={rentalMode} />
        </Drawer>
      </>
    )
  }
}


const HeaderTable = () => (
  <TableHead>
    <TableRow>
      <TableCell>Utilisateur</TableCell>
      <Hidden smDown>
        <TableCell align="right">Type</TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="right">Dates</TableCell>
      </Hidden>
      <TableCell align="right">Prix</TableCell>
      <TableCell align="right">Status</TableCell>
    </TableRow>
  </TableHead>
)

const StyledCell = styled(TableCell)`
  position: relative;
`

const UserCell = styled(TableCell)`
  align-items: center;
`

export default BookingPanel
