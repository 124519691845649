import React from "react";

const SvgSetLarge = props => (
  <svg viewBox="0 0 141.73 85.04" {...props} width={props.size} height={props.size}>
    <defs>
      <clipPath id="set_large_svg__a">
        <path fill="none" d="M1 10.59h139.76v66.97H1z" />
      </clipPath>
    </defs>
    <g clipPath="url(#set_large_svg__a)">
      <path fill={props.color} d="M140.72 56.28c0-7.58-1.93-6.41-1.93-6.41 0-12.42-1.17-7-9.91-15.75-7.74-7.77-27.82-18-32.3-20.29a5.15 5.15 0 0 0-1.89-.53c-9.13-.77-78.52-6.36-85.77 1.25-7.77 8.16-6.8 43.09-6.8 43.09-4.65 7.19 6.61 6 6.61 6 1.8.2 3.82.38 6 .52H16v.16a12.38 12.38 0 1 0 24.76 0v-.25l60-.21v.84a12.38 12.38 0 1 0 24.76 0v-.92h1.85c2.85-.08 4.89-.13 5.82-.13 7.92 0 7.53.19 7.53-7.37zM28.42 70.94a6.24 6.24 0 1 1 6.24-6.24 6.25 6.25 0 0 1-6.24 6.24zm84.74.37a6.24 6.24 0 1 1 6.24-6.24 6.25 6.25 0 0 1-6.25 6.25zm8-34.86H95.49a1 1 0 0 1-1-1V20.61c0-.95 1-1.34 2-1 7 2.3 22.65 12 25.36 14.87.71.79.48 1.97-.68 1.97z" />
    </g>
  </svg>
);

export default SvgSetLarge;
