// import I18n from 'ex-react-native-i18n'

import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'

const searchParkings = async ({ latitude, longitude, diameter = 10000,
  size = 'MOTORBIKE', type = "monthly",
  startDate, endDate, limit,
}) => {
  try {
    const res = await Api.parkmatch.get('/parking/search', {
      latitude,
      longitude,
      diameter,
      size,
      type,
      start_date: startDate,
      end_date: endDate,
      limit,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'SEARCH_PARKINGS' },
      extra: { error: e },
    })
    return { error: { message: 'genericErrorWithCode' } }
    // return { error: { message: I18n.t('genericErrorWithCode') } }
  }
}

export default {
  searchParkings,
}
