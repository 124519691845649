import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import ReactPixel from 'react-facebook-pixel';

const isAnalyticsActivated = () => process.env.RAZZLE_ENV_TYPE === 'production' && typeof window !== 'undefined'

class Analytics extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static initialize() {

        // Facebook options
        const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };

        // Google analytics options
        const GAOptions = { debug: false }

        if (isAnalyticsActivated()) {
            ReactGA.initialize('UA-74493012-1', GAOptions)
            ReactPixel.init('1023963711132049', advancedMatching, options)
        }
    }

    componentDidMount() {
        this.sendPageView(this.props.history.location)
        this.props.history.listen(this.sendPageView)
    }

    sendPageView() {
        if (isAnalyticsActivated()) {
            const currentPage = window.location.pathname + window.location.search
            ReactGA.set({ page: currentPage })
            ReactGA.pageview(currentPage)
            ReactPixel.pageView()
        }
    }

    // GA Events:
    // category: Typically the object that was interacted with (e.g. 'Video')
    // action: The type of interaction (e.g. 'play')
    // label: Useful for categorizing events (e.g. 'Fall Campaign')
    // value: A numeric value associated with the event (e.g. 42)

    // Facebook Events:
    // value: Valeur de conversion
    // currency: Devise (code de devise iso standard à 3 lettres)
    // content_ids: Champs texte libre acceptant plusieurs valeurs
    // content_type: Type de produit impliqué dans l'interraction

    static sendParkingView(parkingId) {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Parking',
                action: 'ViewContent page parking',
                label: 'Locataire',
                value: 0
            });
            ReactPixel.track('ViewContent', {
                content_type: 'Parking',
                content_ids: parkingId,
                currency: '',
                value: 0
            })
        }
    }

    static sendCompleteRegistration() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'User',
                action: 'CompleteRegistration',
                label: '',
                value: 0
            });
            ReactPixel.track('CompleteRegistration')
        }
    }

    static sendPhoneValidation() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'User',
                action: 'PhoneValidation',
                label: '',
                value: 0
            });
            ReactPixel.trackCustom('PhoneValidation')
        }
    }

    static sendViewAddParking() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Parking',
                action: 'ViewAddParking',
                label: 'Propriétaire',
                value: 0
            });
            ReactPixel.trackCustom('ViewAddParking')
        }
    }

    static sendAddParking() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Parking',
                action: 'AddParking',
                label: 'Propriétaire',
                value: 0
            });
            ReactPixel.trackCustom('AddParking')
        }
    }

    static clickMonthlyBookParking() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Booking',
                action: 'clickMonthlyBookParking',
                label: 'Locataire',
                value: 0
            });
            ReactPixel.trackCustom('clickMonthlyBookParking')
        }
    }

    static clickOneshotBookParking() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Booking',
                action: 'clickOneshotBookParking',
                label: 'Locataire',
                value: 0
            });
            ReactPixel.trackCustom('clickOneshotBookParking')
        }
    }

    static sendMonthlyBookingRequest() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Booking',
                action: 'sendMonthlyBookingRequest',
                label: 'Locataire',
                value: 0
            });
            ReactPixel.trackCustom('sendMonthlyBookingRequest')
        }
    }

    static sendOneshotBookingRequest() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Booking',
                action: 'sendOneshotBookingRequest',
                label: 'Locataire',
                value: 0
            });
            ReactPixel.trackCustom('sendOneshotBookingRequest')
        }
    }

    static launchEstimation() {
        if (isAnalyticsActivated()) {
            ReactGA.event({
                category: 'Parking',
                action: 'launchEstimation',
                label: 'Propriétaire',
                value: 0
            });
            ReactPixel.trackCustom('launchEstimation')
        }
    }


    static setUserId(UserId) {
        if (isAnalyticsActivated()) {
            ReactGA.set({ userId: UserId });
            // TODO Facebook Pixel set 'uid'
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(Analytics)
