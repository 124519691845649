import React from 'react';
import { FaInfoCircle, FaFacebookSquare } from 'react-icons/fa';

import { COLORS } from 'src/stylesheet/colors';
import styles from './styles';

const ToolTips = () => (
  <div className="rounded p-3 d-flex flex-column justify-content-center position-absolute" style={{ backgroundColor: COLORS.lightWhite, width: 232, right: -247 }}>
    <div style={styles.triangle} />
    <div className="d-flex align-content-center justify-content-between mb-2">
      <FaInfoCircle size={30} color={COLORS.green} />
      <div style={{ fontSize: 22, color: COLORS.green }}>Créez un compte !</div>
    </div>
    <div>
      "Créez un compte ou connectez-vous pour pouvoir enregistrer votre parking!"
    </div>
    <div className="my-3" style={{ fontSize: 22, color: COLORS.green }}>En simple visite ?</div>
    <div>
      "Si vous êtes arrivé jusqu'ici par simple curiosité ou que vous ne souhaitez pas passer le pas tout de suite, vous pouvez nous soutenir en allant <strong>liker notre page Facebook !</strong>"
    </div>
    <div className="d-flex my-2 align-items-center justify-content-center">
      <a href="https://www.facebook.com/parkmatchapp/" target="_blank" rel="noopener noreferrer">
        <FaFacebookSquare color="#464D98" size={45} />
      </a>
      {/* <span role="img" aria-label="yeah" style={{ fontSize: 45, marginTop: 10 }}>🤙</span> */}
    </div>
  </div>
)

export default ToolTips;
