import React from 'react';
import PropTypes from 'prop-types';
import { FaImage } from 'react-icons/fa';
import checkDistance from 'src/utils/checkDistance';

import { COLORS } from 'src/stylesheet/colors';

const ImageParking = ({ image = false, distance = false, price = false, height = 134 }) => (
  <div style={{ backgroundColor: COLORS.lightGray }} className="col col-md-6 p-0 rounded d-inline-block">
    {
      image ?
        <div style={{ width: '100%', height: height, borderRadius: 5, backgroundColor: COLORS.lightGray, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
          <img className="image rounded w-100" style={{ height: height, objectFit: 'cover' }} src={ image.small } alt='Parking' />
        </div> :
        <div style={{ width: '100%', height: height, borderRadius: 5, backgroundColor: COLORS.lightGray, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
          <FaImage size="50" color={COLORS.lightWhite} />
        </div>
    }
    { distance && <span className="shadow" style={{ borderRadius: 15, backgroundColor: COLORS.lightWhite, position: 'absolute', padding: 5, bottom: 10, right: -10 }}>à {checkDistance(distance)}</span> }
    { price && <span className="shadow d-md-none" style={{ borderRadius: 15, backgroundColor: COLORS.green, color: COLORS.lightWhite, fontWeight: 'bold', position: 'absolute', padding: 5, bottom: 10, right: -10 }}>{price}€</span> }
  </div>
)

ImageParking.propTypes = {
  image: PropTypes.object,
  distance: PropTypes.number,
}

export default ImageParking;
