import React from 'react';
import PrivateRoute from 'src/containers/PrivateRoute'

import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { FaImage } from 'react-icons/fa';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { COLORS } from 'src/stylesheet/colors';
import PrivatePageTemplate from 'src/templates/PrivatePage';
import { Consumer } from 'src/services/store';
import ParkingApi from 'src/api/parking'

import ParkingEdition from './components/EditPanel';
import ParkingAvailability from './components/AvailabilityPanel';
import BookingPanel from './components/BookingPanel';
import ParkingStatus from 'src/components/ParkingStatus/ParkingStatus';

class EditParking extends React.Component {
  state = {
    parking: null,
    bookings: [],
    tabNb: 1,
    isLoading: true,
    error: false,
    isBookingsLoading: true,
  }

  _tabs = [
    'bookings',
    'edit',
    'availability'
  ]

  async componentDidMount() {
    this.setPageTabFromMode(this.props.match.params.mode)
    await this.getParkingData(this.props.match.params.id)
    await this.getBookingsData(this.props.match.params.id)
    this.setState({ isLoading: false })
  }

  componentWillReceiveProps(nextProps) {
    const routeChanged = nextProps.location !== this.props.location
    if (routeChanged) {
      const mode = nextProps.location.pathname.split('/')[4]
      this.setPageTabFromMode(mode)
    }
  }

  setPageTabFromMode = (mode) => {
    if (this._tabs.includes(mode)) {
      this.setState({ tabNb: this._tabs.indexOf(mode) })
    } else {
      // TODO: display not found
    }
  }

  getParkingData = async (id) => {
    const res = await ParkingApi.getParking({ parkingId: id })
    if (!res.error) {
      const image = this.getParkingImage(res)
      this.setState({ parking: res, image, isLoading: false })
    } else {
      this.setState({ error: true })
    }
  }

  getBookingsData = async (id) => {
    const { data } = this.props
    const res = await data.getBookingsListForParking(id)
    if (res && !res.error) {
      this.setState({ isBookingsLoading: false })
    }
  }

  handleTabChange = (event, tabNb) => {
    if (tabNb !== this.state.tabNb) {
      this.props.history.push(this._tabs[tabNb]);
      this.setState({ tabNb })
    }
  }

  getParkingImage = (parking) => {
    if (parking.images && parking.images.length > 0) {
      return parking.images[0].small
    }
    return null
  }

  onEdition = (parking) => {
    const image = this.getParkingImage(parking)
    this.setState({ parking, image })
  }

  handleGoBack = () => {
    this.props.history.push('/account/parkings');
  }

  render() {
    const { parking, tabNb, image, isLoading, error, isBookingsLoading } = this.state
    const { data: { bookings } } = this.props
    return (
      <PrivatePageTemplate isLoading={isLoading} error={error} metaTitle="Mon parking">
        <Paper square className="pt-4 mb-4 row">
          <ParkingHeader parking={parking} image={image} goBack={this.handleGoBack} />
          <Tabs
            style={{ color: COLORS.green }}
            indicatorColor="primary"
            value={tabNb}
            onChange={this.handleTabChange}
            variant="fullWidth"
            centered
            className="col-12"
          >
            {/* <Tab label="Vue d'ensemble" className="no-outline" disabled /> */}
            <Tab label="Réservations" className="no-outline" />
            <Tab label="Édition" className="no-outline" />
            <Tab label="Disponibilité" className="no-outline" />
          </Tabs>
        </Paper>
        {!isLoading && !isBookingsLoading && !error && tabNb === 0 && <BookingPanel bookings={bookings} parkingId={parking.id} refreshList={this.getBookingsData} />}
        {!isLoading && !error && tabNb === 1 && <ParkingEdition parking={parking} onEdition={this.onEdition} />}
        {!isLoading && !error && tabNb === 2 && <ParkingAvailability parking={parking} onEdition={this.onEdition} />}
      </PrivatePageTemplate>
    )
  }
}

const ParkingHeader = ({ parking, image, height = 100, goBack }) => (
  <div className="my-3 row col-12">
    <div className="col-2 offset-md-1">
      <Fab className="no-outline" style={{ backgroundColor: 'white' }} onClick={goBack}>
        <KeyboardArrowLeft style={{ fontSize: 45, backgroundColor: COLORS.green, borderRadius: 25, color: 'white' }} />
      </Fab>
    </div>
    <div className="col-9">
      <div className="d-flex">
        <div style={{
          width: '150px', height: height, borderRadius: 5,
          backgroundColor: COLORS.lightGray, display: 'flex',
          justifyContent: 'center', alignItems: 'center', alignContent: 'center',
          marginRight: '20px'
        }} >
          {
            image ?
              <img className="image rounded w-100" style={{ height: height }} src={image} alt='Parking' />
              :
              <FaImage size="50" color={COLORS.lightWhite} />
          }
        </div>
        <div className="d-flex flex-column justify-content-between">
          <h1 style={{ fontSize: '1.6rem', marginBottom: 0, marginLeft: -10 }}>Votre parking</h1>
          <div style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{parking.address || ""}</div>
          <div style={{ maxWidth: 90 }}>
            <ParkingStatus id={parking.id} labelType={parking.status} toolTip={true} />
          </div>
        </div>
      </div>

    </div>
  </div>
)

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PrivateRoute component={EditParking} {...props} user={ctx.user} data={ctx} ref={ref} />}
  </Consumer>
));

