export const COLORS = {
  // Blue
  realLightGray: '#F5F6F7',
  white: "#FFFFFF",
  gray: '#6c6c6c',
  middleGray: '#7a797a',
  iconsGray: '#4C4C4C',
  lightGray: '#f2f2f2',
  grayGradient2: '#efefef',
  grayBlue: '#8695A0',
  auroMetal: '#647785',
  paynessGray: '#536877',
  lightBlue: '#42596A',
  mediumBlueMarine: '#566e8b',
  blueMarine: '#1c426b',
  mediumBlue: '#2D4759',
  darkBlue: '#193549',
  lighBlue: '#4ECDC4',
  colorFooter: '#d7d7d7',

  // White
  lightWhite: '#F8F8F9',

  // Orange
  orange: '#fcb43f',
  darkSun: '#EA9000',

  // Green
  realLightGreen: '#EBFBF1',
  lightGreen: '#E6F5EB',
  green: '#0dbc5f',
  greenGradient1: '#10DC78',
  greenGradient2: '#06B85D',
  mediumGreen: '#5CC380',
  darkGreen: '#41954D',

  blueGradient1: '#02CCF5',
  blueGradient2: '#23E8AA',

  // Red
  red: '#d0021b',
  smoothRed: '#FF6472',
  darkRed: '#cc2910',
  tulip: '#FF808B',
  melon: '#FFAAB2',
}
