import React from 'react'
import Divider from '@material-ui/core/Divider';


const s = {
    h2: {
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        margin: 20,

    },
    p: {
        color: 'white',
        fontSize: '1.2rem',

    }
}

const EmojiTick = () => (
    <span role="img" aria-label="✓">✅</span>
)


const HelpContents = {

    'ownermonthly': () => (
        <>
            <h2 style={s.h2} id="modal-title">Abonnement</h2>
            <Divider color="white" />
            <p style={s.p}>1 - Après réception de la demande d’abonnement sur votre parking, vous prenez contact avec la personne intéressée pour organiser une visite.</p>
            <p style={s.p}>2 - Lors de la visite, si le parking convient, remettez les accès en échange du codes à 4 chiffres.</p>
            <p style={s.p}>3 - Rentrez le code à 4 chiffres dans le champ de validation dans le menu « Mes parkings ». Le paiement est validé et l’abonnement sera reconduit chaque mois à date d’échéance.</p>
            <p style={s.p}>4 - Recevez votre revenu sur votre compte bancaire sous trois jours ouvrés depuis le menu « Mes virements ». Vous pouvez résilier l’abonnement à tout moment avec 30 jours de préavis.</p>
        </>
    ),

    'tenantmonthly': () => (
        <>
            <p style={s.h2} id="modal-title">Abonnement</p>
            <Divider />
            <p style={s.p}>1 - Après avoir envoyé votre demande d’abonnement sur un parking, vous prenez contact avec le propriétaire pour organiser une visite.</p>
            <p style={s.p}>2 - Lors de la visite, si le parking convient, remettez au propriétaire le code à 4 chiffres reçu dans votre mail de confirmation de demande en échange des accès au parking.</p>
            <p style={s.p}>3 - Avec le code à 4 chiffres, le propriétaire valide l’abonnement et le premier paiement est validé. L’abonnement sera reconduit chaque mois à date d’échéance.</p>
            <p style={s.p}>4 - Recevez votre reçu par mail à chaque date d’échéance. Vous pouvez résilier l’abonnement à tout moment avec 30 jours de préavis.</p>
        </>
    ),

    'ownerone_shot': () => (
        <>
            <p style={s.h2} id="modal-title">Réservation ponctuelle</p>
            <Divider />
            <p style={s.p}>1 - Réception d’une demande de location: vous avez 72h pour l’accepter ou la refuser. Passé ce délai, la demande expire.</p>
            <p style={s.p}>2 - Si vous acceptez la demande, vous recevez les coordonnées du locataire pour organiser la remise des accès au parking (bip, clé...) ainsi que leur récupération à la fin de la réservation.</p>
            <p style={s.p}>3 - 48h après la fin de la location, votre revenu sera débloqué et vous pourrez effectuer un virement vers votre compte bancaire.</p>
        </>
    ),

    'tenantone_shot': () => (
        <>
            <p style={s.h2} id="modal-title">Réservation ponctuelle</p>
            <Divider />
            <p style={s.p}>1 - Après envoi d’une demande de réservation ponctuelle, vous recevez sous 72h une réponse de la part du propriétaire (ou avant la date de début de réservation si elle intervient avant ce délai). Si le propriétaire ne confirme pas ou refuse votre demande, votre carte n’est pas débitée.</p>
            <p style={s.p}>2 - En cas d’acceptation de la demande, vous êtes débité du montant de la réservation et obtenez le contact direct du propriétaire afin d’organiser avec lui la remise des accès (bip, clé…).</p>
            <p style={s.p}>3 - A la fin de la réservation ponctuelle, organisez avec le propriétaire la restitution des accès.</p>
        </>
    ),

    'ownervalueprop': () => (
        <>
            <p style={s.h2} id="modal-title">Pourquoi utiliser Parkmatch ?</p>
            <Divider />
            <p style={s.p}><EmojiTick /> Visibilité de votre parking sur plus de 30 plateformes partenaires (Leboncoin, Paruvendu, LeSiteImmo...).</p>
            <p style={s.p}><EmojiTick /> Paiements simplifiés et sécurisés en ligne.</p>
            <p style={s.p}><EmojiTick /> Gestion locative simplifiée: plus besoin de contrat!</p>
        </>
    ),

    'tenantvalueprop': () => (
        <>
            <p style={s.h2} id="modal-title">Pourquoi utiliser Parkmatch ?</p>
            <Divider />
            <p style={s.p}><EmojiTick /> Trouvez un parking facilement sur l'appli.</p>
            <p style={s.p}><EmojiTick /> Paiements simplifiés et sécurisés par CB.</p>
            <p style={s.p}><EmojiTick /> Réservation et location simplifiées: plus besoin de contrat!</p>
            <p style={s.p}><EmojiTick /> Flexibilité: pas de caution et préavis de 30 jours</p>
        </>
    ),


}

export default HelpContents
