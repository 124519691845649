import React, { useState, useEffect } from 'react';

import PmButton from 'src/components/StyledButton'
import ParkingApi from 'src/api/parking';
import { COLORS } from 'src/stylesheet/colors';
import Input from './components/input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components'
import Tooltip from 'src/components/Tooltip'

import { Consumer } from 'src/services/store';

function PriceInput({ onChangeStep, step, edit, parking, data, onEdition }) {

  const [isLoading, setIsLoading] = useState(false)
  const [parkingData, setParkingData] = useState({})

  const [earnedOrDisplayed, setEarnedOrDisplayed] = useState('displayed')
  function handleChangeEarnedOrDisplayed(event) {
    calculatePrices(earnedOrDisplayed);
    setEarnedOrDisplayed(event.target.value);
  };

  const [prices, setPrices] = useState({
    daily: {
      earned: '',
      displayed: '',
      commissionLvl: 1.08
    },
    weekly: {
      earned: '',
      displayed: '',
      commissionLvl: 1.08
    },
    monthly: {
      earned: '',
      displayed: '',
      commissionLvl: 1.05,
    },
    recommendedMonthly: {
      earned: 105,
      displayed: '',
      commissionLvl: 1.05,
    }
  });
  function handleChangePrices(event) {
    let lPrices = { ...prices }
    lPrices[event.target.id][earnedOrDisplayed] = event.target.value === '0' ? '' : event.target.value
    setPrices(lPrices);
    if (earnedOrDisplayed === 'displayed') {
      calculatePrices(earnedOrDisplayed);
    }
  };

  useEffect(() => {
    init()
  }, []);


  const init = () => {
    let lParking = { ...parking }
    if (!edit) {
      if (typeof window !== 'undefined') { // client
        lParking = JSON.parse(sessionStorage.getItem('parking'));
      }
    }
    setParkingData({ ...lParking })
    if (lParking && typeof lParking.daily_price !== "undefined") {
      let lPrices = { ...prices }
      lPrices.daily.earned = lParking.daily_price === '0' ? '' : lParking.daily_price
      lPrices.weekly.earned = lParking.weekly_price === '0' ? '' : lParking.weekly_price
      lPrices.monthly.earned = lParking.monthly_price === '0' ? '' : lParking.monthly_price
      setPrices(lPrices)
      calculatePrices('earned')
    }
    if (edit) {
      getSuggestedPrice({ longitude: lParking.coordinates.longitude, latitude: lParking.coordinates.latitude })
    } else {
      getSuggestedPrice({ longitude: lParking.longitude, latitude: lParking.latitude })
    }
  }

  const getSuggestedPrice = async (location) => {
    const res = await ParkingApi.fetchSuggestedPrice({ ...location })
    if (!res.error) {
      let lPrices = { ...prices }
      lPrices.recommendedMonthly.commissionLvl = res.subscription_commission_multiplier
      lPrices.recommendedMonthly.displayed = parseInt(res.recommended_price, 10)
      lPrices.recommendedMonthly.earned = Math.round(res.recommended_price * res.subscription_commission_multiplier);
      lPrices.monthly.commissionLvl = res.subscription_commission_multiplier
      lPrices.weekly.commissionLvl = res.one_shot_commission_multiplier
      lPrices.daily.commissionLvl = res.one_shot_commission_multiplier
      setPrices(lPrices)
      calculatePrices('earned')
    }
  }

  const calculatePrices = (pricesType) => {
    let lPrices = { ...prices }
    let price = ''
    if (pricesType === 'earned') {
      Object.keys(lPrices).forEach(key => {
        price = Math.round(lPrices[key]['earned'] * lPrices[key].commissionLvl);
        price = price === parseInt(lPrices[key]['earned'], 10) ? price + 1 : price;
        lPrices[key]['displayed'] = price === 0 ? '' : price;
      })
    } else if (pricesType === 'displayed') {
      Object.keys(lPrices).forEach(key => {
        price = Math.round(lPrices[key]['displayed'] / lPrices[key].commissionLvl)
        price = price === parseInt(lPrices[key]['displayed'], 10) ? price - 1 : price
        lPrices[key]['earned'] = price === 0 ? '' : price;
      })
    }
    setPrices(lPrices)
  }

  const formValidate = () => {
    if (!edit) {
      const lParking = JSON.parse((sessionStorage.getItem('parking')));
      if (lParking.monthly && (parseInt(prices.monthly.earned, 10) === 0 || prices.monthly.earned === '')) {
        data.snackRef.open('warning', "Veuillez entrer un prix mensuel.")
        return false
      }
      if (lParking.oneshot && (parseInt(prices.daily.earned, 10) === 0 || prices.daily.earned === '')) {
        data.snackRef.open('warning', "Veuillez entrer un prix journalier.")
        return false
      }
    }
    return true
  }

  async function setParkingPrices() {
    setIsLoading(true)
    if (edit) {
      const info = {
        daily_price: (prices.daily.earned === '0') ? '' : prices.daily.earned,
        weekly_price: (prices.weekly.earned === '0') ? '' : prices.weekly.earned,
        monthly_price: (prices.monthly.earned === '0') ? '' : prices.monthly.earned,
      }
      const res = await ParkingApi.updateParkingInfo(parking.id, info)
      onEdition(res)
      setIsLoading(false)
      if (!res.error) {
        data.snackRef.open('success', "Prix mis à jour.")
      } else {
        data.snackRef.open('error', "Une erreur est survenue.")
      }
    }
    else {
      let lParking = {}
      if (typeof window !== 'undefined') { // client
        lParking = JSON.parse(sessionStorage.getItem('parking'));
      }
      lParking = {
        ...lParking,
        daily_price: (prices.daily.earned === '0') ? '' : prices.daily.earned,
        weekly_price: (prices.weekly.earned === '0') ? '' : prices.weekly.earned,
        monthly_price: (prices.monthly.earned === '0') ? '' : prices.monthly.earned,
        currentStep: step + 1
      };
      if (typeof window !== 'undefined') { // client
        sessionStorage.setItem('parking', JSON.stringify(lParking));
      }
    }
  }

  const onSubmit = () => {
    if (formValidate()) {
      setParkingPrices()
      if (!edit) {
        onChangeStep(step + 1)
      }
    }

  }

  return (
    <>
      <Grid container spacing={2} justify="space-between" alignItems='center' style={{ height: '100%', width: '100%' }}>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="position" name="position" value={earnedOrDisplayed}
              onChange={handleChangeEarnedOrDisplayed} row>
              <StyledLabel
                value="displayed"
                control={<Radio color="primary" />}
                label="Prix"
                labelPlacement="end"
              />
              <StyledLabel
                value="earned"
                control={<Radio color="primary" />}
                label="Revenu après commission"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {(edit || (parkingData.oneshot)) &&
          <>
            <Grid item xs={12}>
              <h5 style={{ textAlign: 'center' }}>Réservations ponctuelles</h5>
            </Grid>
            {(prices.daily.earned && edit && !parking.allow_one_shot) &&
              <Tooltip color="yellow" text="Pensez à accepter les réservations ponctuelles depuis le menu Disponibilité si vous le souhaitez." />
            }
            <StyledGrid item xs={6}>Jour</StyledGrid>
            <Grid item xs={6}>
              <Input value={prices.daily[earnedOrDisplayed]}
                onChange={handleChangePrices}
                id='daily'
                disabled={false} />
            </Grid>
            <StyledGrid item xs={6}>Semaine</StyledGrid>
            <Grid item xs={6}>
              <Input value={prices.weekly[earnedOrDisplayed]}
                onChange={handleChangePrices}
                id='weekly'
                disabled={false} />
            </Grid>
          </>
        }
        {(edit || (parkingData.monthly)) &&
          <>
            <Grid item xs={12}>
              <h5 style={{ textAlign: 'center' }}>Locations au mois longue durée</h5>
            </Grid>
            {(prices.monthly.earned && edit && !parking.allow_subscription) &&
              <Tooltip color="yellow" text="Pensez à accepter les locations mensuelles depuis le menu Disponibilité si vous le souhaitez." />
            }
            <Grid item xs={12}>
              <Grid container alignItems='center'>
                <StyledGrid item xs={6}>Mois</StyledGrid>
                <Grid item xs={6}>
                  <Input value={prices.monthly[earnedOrDisplayed]}
                    onChange={handleChangePrices}
                    disabled={false}
                    id='monthly' />
                </Grid>
            {/*    <Grid item xs={12} style={{ color: COLORS.green }}>*/}
            {/*      <span role="img" aria-label="money" style={{ paddingTop: 4, paddingRight: 4 }}>💸</span>*/}
            {/*      Prix recommandé:*/}
            {/*<span style={{ fontWeight: 'bold', marginLeft: 5 }}>{prices.recommendedMonthly[earnedOrDisplayed] || '-'} €</span>*/}

            {/*    </Grid>*/}
              </Grid>

            </Grid>
          </>
        }

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <PmButton isLoading={isLoading} disabled={null} onClick={onSubmit}>{edit ? "Enregistrer" : "Suivant"}</PmButton>
        </Grid>
      </Grid>
    </>
  );
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PriceInput {...props} data={ctx} ref={ref} />}
  </Consumer>
));

const StyledLabel = withStyles({
  root: {
    marginBottom: 0,
  },

  label: {
    fontSize: '1.05rem',

  }
})(FormControlLabel);

const StyledGrid = styled(Grid)({
  fontSize: '1.2rem',
  fontWeight: 'bold',

  '& span': {
    fontSize: '1rem',
    color: 'grey',
    fontWeight: 'normal',
    paddingLeft: 4
  }
});
