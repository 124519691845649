import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/containers/PrivateRoute'

import Api from 'src/services/api';
import Parking from 'public/assets/img/parking.png';
import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors';
import PrivatePageTemplate from 'src/templates/PrivatePage';

import Button from 'src/components/Button';
import LoaderSimple from 'src/components/LoaderSimple';

import Card from './components/card';
import AddButton from './components/addButton';

import './index.css';
import ModalHelp from 'src/components/ModalHelp/ModalHelp';

class MyParkingsPage extends React.Component {
  state = {
    parkings: null,
    isLoading: false,
  }

  componentWillMount() {
    this.getParkingList();
  }

  getParkingList = async () => {
    try {
      this.setState({ isLoading: true });
      const res = await Api.parkmatch.get('/parking/list');
      this.setState({ parkings: res.parkings });
    } catch (e) {
      alert('Error server try again.')
    }
    this.setState({ isLoading: false });
  }

  publishParking = async (parking_id, available) => {
    try {
      await Api.parkmatch.put('/parking/publish', { parking_id, available });
      this.getParkingList();
    } catch (e) {
      alert('Error server try again.')
    }
  }

  render() {
    const { history } = this.props;
    const { parkings, isLoading } = this.state;
    return (
      <PrivatePageTemplate metaTitle="Mes parkings">
        <div className="p-5 d-flex flex-row justify-content-between align-items-center" style={{ borderBottom: GLOBAL.separator }}>
          <div>
            <img className="picture" src={Parking} alt="parkings" />
            <h1 className="titleParking d-inline-block">Mes parkings</h1>
          </div>
          <div className="w-100 d-sm-none">
            <Button resp={true} text="Ajouter un parking" onClick={() => this.props.history.push('/add')} />
          </div>
          <AddButton history={history} />
        </div>
        <div className="row">
          {
            parkings && !isLoading &&
            parkings.map((e) =>
              <div key={e.parking_id.toString()} className="col-12 col-lg-6 col-md-12 col-sm-6 p-3">
                <Card data={e} publishParking={this.publishParking} history={this.props.history} />
              </div>
            )
          }
          {parkings && parkings.length === 0 && <div className="m-3 w-100 h-100 d-flex align-items-center justify-content-center" style={{ color: COLORS.green, fontSize: 24, textAlign: 'center' }}>Pas encore de parking ? Cliquez sur le bouton "+" pour ajouter le vôtre !</div>}
          {parkings && !isLoading && parkings.length % 2 === 1 && <div className="col-12 col-sm-5 my-3 mx-2 p-3" />}
          {isLoading && <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100%' }}><LoaderSimple /></div>}
        </div>
        <div className="row">
          <div className="col-12 text-center my-5">
            <ModalHelp helpContent="ownervalueprop" />
          </div>
        </div>
      </PrivatePageTemplate>
    )
  }
}


export default () => <PrivateRoute component={MyParkingsPage} />;
