import React from 'react';
import PrivateRoute from 'src/containers/PrivateRoute';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PublicPage from 'src/templates/PublicPage';

import fetchApi from 'src/services/fetchApi';
import StyledButton from 'src/components/StyledButton'

import GLOBAL from 'src/stylesheet';

import './styles.css';

class AddPhonePage extends React.Component {
  state = {
    phone: '',
    error: '',
    status: false,
  }

  checkPhoneNumber = () => {
    const { status } = this.state;
    this.setState({ error: "" });
    if (status) {
      this.fetchPhone();
    } else {
      this.setState({ error: "Le format de numéro de téléphone que vous avez indiqué est incorrect." });
    }
  }

  fetchPhone = () => {
    this.setState({ isLoading: true })
    const { number } = this.state;
    const phone = number.replace(/\s/g, '');
    const { onPhoneAdd, checkout } = this.props;
    fetchApi('/user/authentication/request', 'POST', { phone }).then((res) => {
      this.setState({ isLoading: false })
      if (res && res.error) {
        this.setState({ error: "Une erreur est survenue, veuillez contacter le support." });
      } else {
        if (checkout) {
          onPhoneAdd(phone)
        } else {
          this.props.history.push('/register/phone/confirmation', { phone });
        }
      }
    })
  }

  onChangePhoneNumber = (status, value, countryData, number) => {
    this.setState({ value, number, status, error: "" });
  }

  renderForm = () => {
    const { error, isLoading } = this.state;
    return (
      <>
        <div className="form-group input-group-lg text-center">
          <label style={{ color: '#05b65c' }} htmlFor="basic-url">Votre numéro de mobile</label>
          <IntlTelInput
            format
            defaultCountry="fr"
            onPhoneNumberChange={this.onChangePhoneNumber}
            onPhoneNumberBlur={this.onChangePhoneNumber}
            css={['intl-tel-input', 'form-control']}
          />
        </div>
        <div style={{ height: 42 }} className="text-danger mb-2 text-center">{error}</div>
        <div className="form-group text-center">
          <StyledButton onClick={this.checkPhoneNumber} isLoading={isLoading}>
            Suivant
          </StyledButton>
        </div>
        <div className="form-group text-center">
          <small>* Conformément à la législation en vigueur concernant le droit des consommateurs au démarchage téléphonique, nous vous informons de votre droit à vous inscrire sur la liste d'opposition au démarchage téléphonique. Cette inscription vous permet de ne pas être contacté par téléphone par des entreprises à des fins de démarchage commercial, à l'exception de celles avec lesquelles vous avez un contrat en cours. Plus d'information sur <a href="https://www.bloctel.gouv.fr/" target="_blank">Bloctel.gouv.fr</a>.</small>
        </div>
      </>
    )
  }

  render() {
    const { checkout } = this.props
    const PhoneInput = this.renderForm()
    if (checkout) {
      return PhoneInput
    }
    return (
      <PublicPage fullHeight footer={false}
        metaTitle="Ajouter un téléphone"
        metaDescription="Vérifiez votre numéro de téléphone afin de profiter pleinement du service.">
        <div className="row vh-100" style={{ backgroundImage: GLOBAL.gradient }}>
          <div className="col-sm-12 my-auto">
            <form className="card card-block mx-auto bg-light p-4 shadow blockForm">
              {PhoneInput}
            </form>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PrivateRoute component={AddPhonePage} />

