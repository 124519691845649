// import I18n from 'ex-react-native-i18n'

import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'
import { parseSingleParking } from 'src/api/parking/parkingApiParser'

const getOwnedParkings = async () => {
  try {
    const { parkings, income } = await Api.parkmatch.get('/parking/list')
    return {
      parkings: (parkings || []).map(parking => ({
        id: parking.parking_id,
        accessManagement: parking.access_management,
        location: {
          address: parking.address,
        },
        cover: parking.cover,
        status: parking.status,
        notification: parking.nb_awaiting_validation,
        // loaded: parking.loaded,
        // locked: parking.locked,
        // partiallyLoaded: parking.partially_loaded,
        // unload_warning: parking.unload_warning,
      })),
      income,
    }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_OWNED_PARKINGS' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('parkings.errors.fetchList') } }
  }
}

const getParking = async ({ parkingId }) => {
  try {
    const parking = await Api.parkmatch.get('/parking', { parking_id: parkingId })
    return parseSingleParking(parking)
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_PARKING' },
      extra: { error: e },
    })
    return { error: { message: 'Une erreur est survenue lors de la récupération de votre parking.' } }
    // return { error: { message: I18n.t('parkings.errors.fetchSingle') } }
  }
}

const getParkingWithoutAuth = async ({ parkingId }) => {
  try {
    const parking = await Api.parkmatch.get('/parking/details', { parking_id: parkingId })
    return parseSingleParking(parking)
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_PARKING_UNAUTH' },
      extra: { error: e },
    })
    return { error: { message: 'Une erreur est survenue lors de la récupération de votre parking.' } }
    // return { error: { message: I18n.t('parkings.errors.fetchSingle') } }
  }
}

const createParking = async (location = {}) => {
  try {
    const parking = await Api.parkmatch.post('/parking', {
      ...(location.street ? { street: location.street } : {}),
      ...(location.building ? { building: location.building } : {}),
      ...(location.address ? { address: location.address } : {}),
      ...(location.city ? { city: location.city } : {}),
      ...(location.zipCode ? { zip_code: location.zipCode } : {}),
      ...(location.country ? { country: location.country } : {}),
      ...(location.latitude ? { latitude: location.latitude } : {}),
      ...(location.longitude ? { longitude: location.longitude } : {}),
    })

    return { parking: parseSingleParking(parking) }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'CREATE_PARKING' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('parkings.errors.save') } }
  }
}

const updateParkingAddress = async (parkingId, location = {}) => {
  try {
    const parking = await Api.parkmatch.put('/parking/address', {
      parking_id: parkingId,
      ...(location.address ? { address: location.address } : {}),
      ...(location.city ? { city: location.city } : {}),
      ...(location.zipCode ? { zip_code: location.zip_code } : {}),
      ...(location.country ? { country: location.country } : {}),
      ...(location.latitude ? { latitude: location.latitude } : {}),
      ...(location.longitude ? { longitude: location.longitude } : {}),
    })

    return parseSingleParking(parking)
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'UPDATE_PARKING_ADDRESS' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('parkings.errors.save') } }
    return { error: { message: 'parkings.errors.save' } }
  }
}

const fetchSuggestedPrice = async ({ longitude, latitude }) => {
  try {
    const res = await Api.parkmatch.get('/parking/price', { longitude, latitude })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'FETCH_SUGGESTED_PRICE' },
      extra: { error: e },
    })
    return { error: { message: 'genericError' } }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const fetchEstimatedPrice = async ({ longitude, latitude }) => {
  try {
    const res = await Api.parkmatch.get('/parking/estimation', { longitude, latitude })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'FETCH_SUGGESTED_PRICE' },
      extra: { error: e },
    })
    return { error: { message: 'genericError' } }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const updateParkingInfo = async (parkingId, info) => {
  try {
    const parking = await Api.parkmatch.put('/parking', { parking_id: parkingId, ...info })
    return parseSingleParking(parking)
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'UPDATE_PARKING_INFO' },
      extra: { error: e },
    })
    return { error: { message: 'parkings.errors.save' } }
    // return { error: { message: I18n.t('parkings.errors.save') } }
  }
}

const updateParkingPrice = async (parkingId, price) => updateParkingInfo(
  parkingId,
  { hourly_price: price },
)

const updateParkingMonthlyPrice = async (parkingId, price) => updateParkingInfo(
  parkingId,
  { monthly_price: price },
)

const fetchParkingInstructionsAndConfigs = async (parkingId) => {
  try {
    const { instructions, configs } = await Api.parkmatch.get('/parking/config', { parking_id: parkingId })

    return {
      instructions: instructions.map(instruction => ({
        id: instruction.instruction_id,
        norder: instruction.norder,
        urlImage: instruction.url_image,
        type: 'DOOR',
        config: instruction.config && {
          id: instruction.config.config_id,
          button: instruction.config.button,
          tested: instruction.config.tested,
        },
      })),
      configs: configs.map(config => ({
        id: config.config_id,
        createdAt: config.date,
      })),
    }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'FETCH_PARKING_INSTRUCTIONS_AND_CONFIGS' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('parkings.errors.fetchInstructions') } }
  }
}

const deleteParkingInit = async (parkingId) => {
  try {
    const { loaded, copied } = await Api.parkmatch.get('/parking/delete/init', { parking_id: parkingId })

    return { loaded, copied }
  } catch (e) {
    let errorMessage = ''

    // if (e.type === 'InvalidAction') {
    //   errorMessage = I18n.t('parkings.errors.cantDelete')
    // } else {
    //   sentryCaptureMessage(e.message, {
    //     level: 'error',
    //     tags: { type: 'API', label: 'DELETE_PARKING_INIT' },
    //     extra: { error: e },
    //   })
    //   errorMessage = I18n.t('genericError')
    // }

    return { error: { message: errorMessage } }
  }
}

const deleteParking = async (parkingId, outOfService) => {
  try {
    await Api.parkmatch.delete('/parking/delete', {
      parking_id: parkingId,
      out_of_service: outOfService ? 1 : 0,
    })

    return { status: 'OK' }
  } catch (e) {
    let errorMessage = ''

    // if (e.type === 'InvalidAction') {
    //   errorMessage = I18n.t('parkings.errors.cantDelete')
    // } else {
    //   sentryCaptureMessage(e.message, {
    //     level: 'error',
    //     tags: { type: 'API', label: 'DELETE_PARKING' },
    //     extra: { error: e },
    //   })
    //   errorMessage = I18n.t('genericError')
    // }

    return { error: { message: errorMessage, userError: e.type === 'InvalidAction' } }
  }
}

const removeInstruction = async (parkingId, instructionId) => {
  try {
    await Api.parkmatch.delete('/parking/instruction', {
      parking_id: parkingId,
      instruction_id: instructionId,
    })
    return { status: true }
  } catch (e) {
    let errorMessage = ''

    // if (e.type === 'InvalidAction') {
    //   errorMessage = I18n.t('parkings.errors.cantDelete')
    // } else {
    //   sentryCaptureMessage(e.message, {
    //     level: 'error',
    //     tags: { type: 'API', label: 'DELETE_INSTRUCTION' },
    //     extra: { error: e },
    //   })
    //   errorMessage = I18n.t('genericError')
    // }

    return { error: { message: errorMessage, userError: e.type === 'InvalidAction' } }
  }
}

const removeAvailability = async (parkingId, availabilityId) => {
  try {
    await Api.parkmatch.delete('/parking/availability', {
      parking_id: parkingId,
      availability_id: availabilityId,
    })
    return { status: true }
  } catch (e) {
    let errorMessage = ''

    // if (e.type === 'InvalidAction') {
    //   errorMessage = I18n.t('parkings.errors.cantDelete')
    // } else {
    //   sentryCaptureMessage(e.message, {
    //     level: 'error',
    //     tags: { type: 'API', label: 'DELETE_AVAILABILITY' },
    //     extra: { error: e },
    //   })
    //   errorMessage = I18n.t('genericError')
    // }

    return { error: { message: errorMessage, userError: e.type === 'InvalidAction' } }
  }
}

const uploadImage = async (parkingId, base64) => {
  try {
    const res = await Api.parkmatch.put('/parking/image', {
      parking_id: parkingId,
      file: base64,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'UPLOAD_IMAGE' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const deleteImage = async (imageId) => {
  try {
    const res = await Api.parkmatch.delete('/parking/image', {
      image_id: imageId,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'DELETE_IMAGE' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const updateOrderImages = async ({ parkingId, orderArray }) => {
  try {
    await Api.parkmatch.put('/parking/image/order', {
      parking_id: parkingId,
      order: orderArray,
    })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'UPDATE_ORDER_IMAGE' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}


const toggleAvailability = async (parkingId, mode) => {
  try {
    await Api.parkmatch.put('/parking/toggle_availability', {
      parking_id: parkingId,
      available: mode,
    })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'TOGGLE_AVAILABILITY' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const getParkingBookings = async ({ parkingId }) => {
  try {
    const res = await Api.parkmatch.get('/parking/booking/list', { parking_id: parkingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_PARKING_BOOKINGS' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const getBookingData = async ({ parkingId, bookingId }) => {
  try {
    const res = await Api.parkmatch.get('/parking/booking', { parking_id: parkingId, booking_id: bookingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_SELECTED_PARKING_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const refuseBooking = async ({ parkingId, bookingId }) => {
  try {
    const res = await Api.parkmatch.put('/parking/booking/refuse', { parking_id: parkingId, booking_id: bookingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'PUT_REFUSE_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const acceptBooking = async ({ parkingId, bookingId }) => {
  try {
    const res = await Api.parkmatch.put('/parking/booking/accept', { parking_id: parkingId, booking_id: bookingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'PUT_ACCEPT_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'errorMessage' } }
  }
}

const submitTenantCode = async ({ parkingId, bookingId, code }) => {
  try {
    const res = await Api.parkmatch.put('/parking/booking/validate', {
      parking_id: parkingId,
      booking_id: bookingId,
      code,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'PUT_TENANT_CODE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const endBooking = async ({ parkingId, bookingId }) => {
  try {
    const res = await Api.parkmatch.put('/parking/booking/terminate', {
      parking_id: parkingId,
      booking_id: bookingId,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'END_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const parkingPrices = async ({ parkingId, rentalMode, startDate, endDate }) => {
  try {
    const res = await Api.parkmatch.get('/parking/search/price', {
      parking_id: parkingId,
      type: rentalMode,
      start_period: startDate,
      end_period: endDate,
    })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'PARKING_PRICES' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

export default {
  getOwnedParkings,
  getParking,
  getParkingWithoutAuth,
  createParking,
  updateParkingAddress,
  fetchSuggestedPrice,
  fetchEstimatedPrice,
  updateParkingInfo,
  updateParkingPrice,
  updateParkingMonthlyPrice,
  fetchParkingInstructionsAndConfigs,
  deleteParkingInit,
  deleteParking,
  removeInstruction,
  removeAvailability,
  uploadImage,
  deleteImage,
  updateOrderImages,
  toggleAvailability,
  getParkingBookings,
  getBookingData,
  refuseBooking,
  acceptBooking,
  submitTenantCode,
  endBooking,
  parkingPrices,
}
