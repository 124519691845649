import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import GLOBAL from 'src/stylesheet';


const PmButton = ({ onClick, children, className, isLoading = false, style, noGradient = false, disabled, outlined = false, ...rest }) => {
  const standardStyle = {
    textTransform: 'none',
    color: 'white',
    background: !noGradient && !disabled && GLOBAL.gradient
  }

  const outlinedStyle = {
    textTransform: 'none',
    color: '#0DBC5F',
    border: '2px solid #1ACD71',
    background: 'white',
    borderRadius: '6px',
    boxShadow: "0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08)"
  }

  return (
    <StyledButton
      style={{ ...(outlined ? outlinedStyle : standardStyle), ...style }}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={`no-outline ${className}`}
      variant="contained"
      color="primary"
      {...rest}
    >
      {
        isLoading ?
          <CircularProgress size={25} className="mx-5 my-1" color="white" />
          : children
      }
    </StyledButton>
  )

}


const StyledButton = styled(Button)`

`


PmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default PmButton
