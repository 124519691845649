import React from 'react'
import GLOBAL from 'src/stylesheet';

import OutlinedButton from 'src/components/OutlinedButton';

import ParkingDetail from 'public/assets/img/screens/details-parking.png'

const NumberDisplay = ({ nb }) => (
  <div className="number-parkmatch" style={{margin: "auto", backgroundImage: GLOBAL.gradient }}>
    {nb}
  </div>
)

const TenantSection = ({ title, content, colorBck, colorFont }) => (
  <React.Fragment>
    <div className="row py-5" style={{ backgroundColor: colorBck }}>
      <div className="col-12 col-md-8 offset-md-2">
        <h2>{title}</h2>
      </div>
    </div>
    <div className="row mb-5" style={{ backgroundColor: colorBck }}>
      <div className="col-12 col-sm-5 offset-sm-1 col-md-4 offset-md-2 pt-2">
      {content.map((elem, index) => (
        <div key={index} className="row">
          <div className="col-12" key={index}>
            <NumberDisplay nb={index + 1} color="#0dbc5f" />
            <div className="mt-2 mb-5" style={{ color: colorFont, textAlign: 'center', fontSize: '1.4em'}}>
              {elem.title}
            </div>
          </div>
        </div>
      ))}
      </div>
      <div className="col-12 col-sm-5 col-md-4">
        <div className="app-illustration">
          <img style={{ height: "86%", width: "86%", marginLeft: "7%", marginTop: "7%" }} src={ParkingDetail} alt="Mailto" />
        </div>
      </div>
    </div>
    <div className='row mb-5'>
      <div style={{margin: 'auto'}}>
        <OutlinedButton text="Plus d'infos" link="/trouver-un-parking" />
      </div>
    </div>
  </React.Fragment>
)

export default TenantSection
