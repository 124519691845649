import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'

const getAlertsList = async () => {
  try {
    const res = await Api.parkmatch.get('/alert/list')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_ALERT_LIST' },
      extra: { error: e },
    })
    return { error: { message: 'Error get alert list' } }
  }
}

const createAlert = async ({ longitude, latitude }) => {
  try {
    const res = await Api.parkmatch.post('/alert/create', { longitude, latitude })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'CREATE_ALERT' },
      extra: { error: e },
    })
    return { error: { message: 'Error create alert' } }
  }
}

const deleteAlert = async ({ alertId }) => {
  try {
    await Api.parkmatch.delete('/alert/delete', { alert_id: alertId })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'DELETE_ALERT' },
      extra: { error: e },
    })
    return { error: { message: 'Error delete alert' } }
  }
}

export default {
  getAlertsList,
  createAlert,
  deleteAlert,
}
