import React from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Map from './components/map';
import InputSearch from 'src/components/InputSearch';
import ParkingApi from 'src/api/parking';
import SnackBar from 'src/components/SnackBar';
import PmButton from 'src/components/StyledButton'

export default class AddressInput extends React.Component {
  state = {
    error: null,
    street: null,
    building: null,
    address: null,
    zip_code: null,
    position: { lat: 45.750000, lng: 4.850000 },
    isLoading: false,
  }

  componentWillMount() {
    let { parking } = this.props
    if (parking) {
      this.setState({
        parkingId: parking.id,
        address: parking.address || "",
        zip_code: parking.zip_code || null,
        street: parking.street,
        building: parking.building,
        position: { ...parking.coordinates },
        searchAddress: parking.address && parking.city && parking.country ? parking.address + ', ' + parking.city + ', ' + parking.country : ''
      });
    } else {
      if (typeof window !== 'undefined') { // client
        parking = JSON.parse(sessionStorage.getItem('parking'));
      }
      if (parking) {
        this.init();
      }
    }
  }

  init = () => {
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    const position = { latitude: parking.latitude, longitude: parking.longitude };
    this.setState({
      address: parking.address,
      zip_code: parking.zip_code,
      position,
      street: parking.street,
      building: parking.building,
      city: parking.city,
      country: parking.country,
      searchAddress: parking.address && parking.city && parking.country ? parking.address + ', ' + parking.city + ', ' + parking.country : ''
    });
  }

  handleChangeAddress = (searchAddress) => {
    this.setState({
      searchAddress: searchAddress,
      zip_code: null,
      street: '',
      building: '',
      city: '',
      country: '',
      error: null
    });
  }

  getAddressElement = (element, array) => {
    let longName = null
    array.forEach(elem => {
      if (elem.types.find(type => type === element)) {
        longName = elem.long_name
      }
    })
    return longName
  }

  checkAddressValidity = (address_components) => {
    const building = this.getAddressElement('street_number', address_components)
    const route = this.getAddressElement('route', address_components)
    const postal_code = this.getAddressElement('postal_code', address_components)
    const city = this.getAddressElement('locality', address_components)
    const country = this.getAddressElement('country', address_components)
    if (postal_code && route && city && country) {
      this.setState({
        building, street: route, zip_code: postal_code, city, country,
        searchAddress: (building ? building + ' ' : '') + route + ', ' + city + ', ' + country
      })
      return true
    }
    return false
  }

  handleSelectAddress = async (address, placeId) => {
    const [res] = await geocodeByAddress(address)
    let tempAdress = address.split(',')[0]
    getLatLng(res).then(({ lat: latitude, lng: longitude }) => this.setState({ position: { latitude, longitude }, address: tempAdress }))
    if (!this.checkAddressValidity(res.address_components)) {
      this.setState({ error: "L'adresse indiquée semble incorrecte.", zip_code: null })
      return false
    }
  }

  saveAddressInSession = async () => {
    const { address, zip_code, position, street, building, city, country } = this.state;
    let parking = {}
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    parking = { ...parking, address: address, country: country, zip_code, city: city, latitude: position.latitude, longitude: position.longitude, street, building, currentStep: this.props.step + 1 };

    this.setState({ error: false })
    sessionStorage.setItem('parking', JSON.stringify(parking));
    if (this.props.onChangeStep) {
      this.props.onChangeStep(this.props.step + 1);
    }
  }

  editParkingAddress = async () => {
    this.setState({ isLoading: true })
    const { address, zip_code, position, parkingId, city, country, building } = this.state;
    const location = { address, zip_code, city, country, building, ...position }
    const res = await ParkingApi.updateParkingAddress(parkingId, location)
    this.setState({ isLoading: false })
    if (!res.error) {
      if (this.props.onEdition) {
        this.props.onEdition(res)
      }
      this.snack.open('success', 'Adresse enregistrée')
    } else {
      this.snack.open('error', 'Une erreur est survenue')
    }
  }

  saveAddress = () => {
    if (this.props.edit) {
      this.editParkingAddress()
    } else {
      this.saveAddressInSession()
    }
  }

  onChange = (e) => {
    let position = { latitude: e.center.lat, longitude: e.center.lng };
    this.setState({ position });
  }

  render() {
    const { position, zip_code, isLoading, error, searchAddress } = this.state;
    const { edit } = this.props;
    return (
      <div className="d-flex flex-column align-items-center" style={{ maxWidth: '350px', margin: 'auto' }}>
        <InputSearch placeholder='ex: 2 rue de Laplace' address={searchAddress} handleChangeAddress={this.handleChangeAddress} handleSelectAddress={this.handleSelectAddress} />
        {position && <Map onChange={this.onChange} lat={position.latitude} lng={position.longitude} />}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <PmButton isLoading={isLoading} disabled={!zip_code} onClick={this.saveAddress}>{edit ? "Enregistrer" : "Suivant"}</PmButton>
        {edit && <SnackBar ref={ref => this.snack = ref} />}
      </div>
    )
  }
}
