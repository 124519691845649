import React from 'react';
import { FaMapMarkerAlt, FaRegCreditCard, FaRegEdit, FaRegClock } from 'react-icons/fa';

import Api from 'src/services/api';
import Analytics from 'src/services/Analytics'

import { COLORS } from 'src/stylesheet/colors';
import PmButton from 'src/components/StyledButton'
import IconSvg from 'src/svg';

import { vehicleSizesInfo, vehicleSizesHierarchy } from 'src/constants/car.js'

import { Consumer } from 'src/services/store';

class AddResume extends React.Component {

  state = {
    isLoading: false,
    address: '',
    latitude: '',
    longitude: '',
    zip_code: '',
    city: '',
    description: '',
    size: '',
    price: '',
  }

  componentWillMount() {
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    if (parking) {
      this.init();
    }
  }

  init = () => {
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    this.setState({
      address: parking.address,
      city: parking.city,
      country: parking.country,
      latitude: parking.latitude,
      longitude: parking.longitude,
      zip_code: parking.zip_code,
      h24: parking.h24,
      monthly: parking.monthly,
      oneshot: parking.oneshot,
      durationLimit: parking.durationLimit,
      description: parking.description,
      size: parking.sizeCar,
      daily_price: parking.daily_price,
      weekly_price: parking.weekly_price,
      monthly_price: parking.monthly_price
    });
  }

  addParking = async () => {
    this.setState({ isLoading: true });
    if (this.props.data.isLogged) {
      let parking = {}
      if (typeof window !== 'undefined') { // client
        parking = JSON.parse(sessionStorage.getItem('parking'));
      }
      const res = await Api.parkmatch.post('/parking', {
        address: parking.address,
        zip_code: parking.zip_code,
        city: parking.city,
        country: parking.country,
        latitude: parking.latitude,
        longitude: parking.longitude,
        street: parking.street,
        building: parking.building,
        h24_availability: parking.h24,
        allow_one_shot: parking.oneshot,
        allow_subscription: parking.monthly,
        minimum_rent_duration: parking.durationLimit,
        description: parking.description,
        size: parking.sizeCar,
        garage: parking.garage,
        indoor: parking.indoor,
        camera: parking.camera,
        power_supply: parking.power_supply,
        daily_price: parking.daily_price,
        weekly_price: parking.weekly_price,
        monthly_price: parking.monthly_price,
      })
      await Api.parkmatch.put('/parking/toggle_availability', {
        parking_id: res.parking_id,
        available: parking.h24,
      })
      if (res && !res.error) {
        sessionStorage.removeItem('parking');
        Analytics.sendAddParking()
        this.props.history.push('/account/parkings')
      } else {
        // ERREUR
      }
    } else {
      this.props.data.toggleIsAddingParking(true)
      this.props.history.push({ pathname: '/register' });
    }
  }

  render() {
    const { address, description, daily_price, weekly_price, monthly_price, size, isLoading, monthly, oneshot, durationLimit } = this.state;
    return (
      <>
        <div className="container-fluid">
          <div className="row my-3">
            <div className="col-2 align-center"><FaMapMarkerAlt size={26} color={COLORS.green} /></div>
            <div className="col-10 align-left">{address}</div>
          </div>
          <hr />
          <div className="row my-3">
            <div className="col-2 align-center"><FaRegClock size={26} color={COLORS.green} /></div>
            <div className="col-10 align-left">
              Accepte {oneshot ? <strong>les réservations ponctuelles</strong> : ''}
              {oneshot ? <span> à partir de <strong>{durationLimit} jours</strong></span> : ''}
              {oneshot && monthly ? ' et ' : ''}
              {monthly ? <strong>les locations au mois</strong> : ''}
            </div>
          </div>
          <hr />
          <div className="row my-3">
            <div className="col-2 align-center"><FaRegCreditCard size={26} color={COLORS.green} /></div>
            <div className="col-10 align-left">
              {daily_price && oneshot &&
                <Price value={daily_price} unit='€/jour' />
              }
              {weekly_price && oneshot &&
                <Price value={weekly_price} unit='€/semaine' />
              }
              {monthly_price && monthly &&
                <Price value={monthly_price} unit='€/mois' />
              }
            </div>
          </div>
          <hr />
          <div className="row my-3">
            <div className="col-2 align-center"><FaRegEdit size={26} color={COLORS.green} /></div>
            <div className="col-10 align-left">{description}</div>
          </div>
          <hr />

          <div className="row my-3">
            <div className="col-2 align-center" style={{ marginTop: -15 }}><IconSvg name={size} color={COLORS.green} style={{ width: '30%', height: '30%' }} /></div>
            <div className="col-10 align-left"><strong>Véhicules adaptés:</strong> {
              function () {
                let res = []
                let text = ''
                for (let i = 0; i <= vehicleSizesHierarchy.indexOf(size); i++) {
                  text = vehicleSizesInfo[vehicleSizesHierarchy[i]].name + ((i === vehicleSizesHierarchy.indexOf(size)) ? '' : ',')
                  res.push(<span key={i}> {text}</span>)
                }
                return res
              }()
            }</div>
          </div>
        </div >
        <PmButton isLoading={isLoading} onClick={this.addParking}>Publier mon parking  <span style={{ marginLeft: 5 }} role='img' aria-label='Félicitations'>🎉</span></PmButton>
      </>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <AddResume {...props} data={ctx} ref={ref} />}
  </Consumer>
));

const Price = ({ value, unit }) => {
  return (
    <span style={{ marginRight: 12 }}><strong>{value}</strong>{unit}</span>
  )
}
