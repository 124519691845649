import React from "react";

const SvgSetMoto = props => (
  <svg viewBox="0 0 141.73 85.04" {...props} width={props.size} height={props.size}>
    <defs>
      <clipPath id="set_moto_svg__a">
        <path fill="none" d="M0 8h141.73v71.27H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#set_moto_svg__a)">
      <path fill={props.color} d="M124.65 40.93l.8-.79c3.38-3.38 4.83-6.87 4.3-10.38s-3.2-6.81-7.68-9.18c-5.72-3-23.34-12.14-23.52-12.23a3.13 3.13 0 0 0-4.48 3.52l2.58 10.64-21-4.57a3.13 3.13 0 0 0-2.55.56l-12 9-13.21-2.8-11.57-11.13a3.14 3.14 0 0 0-2.55-.85l-31 3.76a3.13 3.13 0 0 0-1.13 5.86L39.39 43l-3.61 2.91A19.15 19.15 0 1 0 41.85 61h10.78l2.14 4.95a3.13 3.13 0 0 0 2.87 1.89h36.18a3.14 3.14 0 0 0 2.06-.77c.31-.27 3.27-2.86 7.31-6.44a19.16 19.16 0 1 0 21.45-19.69zM22.51 70.37A10.19 10.19 0 1 1 32.7 60.18a10.2 10.2 0 0 1-10.19 10.19zm99.83-3.8l.56 3.8a10.19 10.19 0 1 1 10.19-10.19 10.2 10.2 0 0 1-10.19 10.19z" />
    </g>
  </svg>
);

export default SvgSetMoto;
