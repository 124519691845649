function formatMetaData({ title, description, canonicalUrl }, defaultTitle = true) {
  // if (defaultTitle) {
  //   document.title = `${title} - Parkmatch`;
  // } else {
  //   document.title = `${title}`;
  // }
  // document.querySelector('meta[name="description"]').setAttribute("content", description);
  // document.querySelector('meta[property="og:description"]').setAttribute("content", description);
}

export default formatMetaData
