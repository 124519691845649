import React from 'react';
import PropTypes from 'prop-types';
import OverlayUser from 'src/components/OverlayUser';
import { Consumer } from 'src/services/store';

const InfoUser = ({ data: { user } }) => {
  return (user &&
    <div className="d-flex flex-row align-items-center mx-3 py-4 pb-4 mb-4 border-bottom border-light">
      <OverlayUser overlaysWidth={45} itemsWidth={20} />
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} className="ml-3 d-flex flex-column">
        <div style={{ fontSize: 22 }}>{user.first_name} {user.last_name[0].toUpperCase()}.</div>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.email}</div>
      </div>
    </div>
  )


}

InfoUser.propTypes = {
  user: PropTypes.object,
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <InfoUser {...props} data={ctx} ref={ref} />}
  </Consumer>
));
