import React from 'react'


const EmojiSection = ({ elements }) => (
  <>
    {elements.map((elem, index) => (
      <div key={elem.title} className="col-lg-3 col-12 d-flex flex-column align-items-center">
        <span role="img" aria-label="mains" className="d-flex justify-content-center align-items-center rounded-circle shadow emoji-badge">{elem.emoji}</span>
        <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{elem.title}</div>
        <div style={{ fontSize: '1.1em', textAlign: 'center' }}>{elem.text}</div>
      </div>
    ))}
  </>
)

export default EmojiSection
