import React from 'react';

import Motorbike from './components/svg-moto';
import Small from './components/svg-small';
import Medium from './components/svg-medium';
import Large from './components/svg-large';
import Box from './components/svg-box';
import Camera from './components/svg-camera';
import Indoor from './components/svg-indoor';
import Power from './components/svg-power';

const IconSvg = ({ name, color, width = 50, size }) => {
  switch (name) {
    case "MOTORBIKE":
      return <Motorbike color={color} size={size || width} />
    case "SMALL":
      return <Small color={color} size={size || width} />
    case "MEDIUM":
      return <Medium color={color} size={size || width} />
    case "LARGE":
      return <Large color={color} size={size || width} />
    case "BOX":
      return <Box color={color} size={size || width} />
    case "CAMERA":
      return <Camera color={color} size={size || width} />
    case "INDOOR":
      return <Indoor color={color} size={size || width} />
    case "POWER":
      return <Power color={color} size={size || width} />
    default:
      return null
  }
}

export default IconSvg;
