import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
//import { DateRangePicker } from 'react-dates';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { COLORS } from 'src/stylesheet/colors'



function DateAndTimePicker({ date, setDate, placeholder, disablePast, minDate, id }) {

  const [dayOpen, setDayOpen] = useState(false)
  const [timeOpen, setTimeOpen] = useState(false)

  const [day, setDay] = useState(minDate ? minDate : new Date())
  const [time, setTime] = useState('')

  useEffect(() => {
    if (date) {
      if (!moment(date).isSame(day)) {
        setDay(date)
      }
      if (time !== date.hours() + 'h' + date.minutes()) {
        setTime(date.hours() + 'h' + date.minutes())
      }
    }
    else {
      setDay(minDate ? minDate : new Date())
      setTime('')
    }
  }, [date])

  useEffect(() => {
    if (day && time && !dayOpen && !timeOpen) {
      let lDate = moment(day)
      lDate = lDate.hours(time.split('h')[0])
      lDate = lDate.minutes(time.split('h')[1])
      if (!moment(lDate).isSame(date)) {
        setDate(lDate)
      }
    }
  }, [day, time])

  const handleDayOpen = () => {
    setDayOpen(true)
  }
  const handleDayClose = () => {
    setDayOpen(false)
    setTimeOpen(true)
  }

  const handleTimeClose = () => {
    setTimeOpen(false)
  }

  const handleDayChange = (newDay) => {
    setDay(newDay)
  }

  const handleTimeChange = (event) => {
    setTime(event.target.value)
  }

  const classes = useStyles();
  return (
    <div className="d-flex align-items-center" style={{ position: 'relative', overflow: 'hidden' }}>
      <div>
        <StyledButton onClick={handleDayOpen} color="primary" style={{ textTransform: 'none' }} className={dayOpen || timeOpen ? classes.isSelected : null} >
          {date &&
            <span>{moment(date).format('lll')}</span>
          }
          {!date &&
            <span>{placeholder}</span>
          }
        </StyledButton>
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <InvisibleDate
          disableToolbar
          open={dayOpen}
          onClose={handleDayClose}
          onOpen={handleDayOpen}
          margin="normal"
          variant="inline"
          id={id}
          label="Date de début"
          value={day}
          autoOk
          onChange={handleDayChange}
          //onAccept={handleDayClose}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disablePast={disablePast}
          minDate={minDate}
        // MenuProps doesn't exist, ther should be another way to set Origin point
        // MenuProps={DateMenuProps}
        />

      </MuiPickersUtilsProvider>
      <InvisibleSelect
        value={time}
        onChange={handleTimeChange}
        open={timeOpen}
        onClose={handleTimeClose}
        MenuProps={SelectMenuProps}
      >
        {function () {
          let options = []
          for (let i = 0; i < 24; i++) {
            options.push(<MenuItem key={i * 2} value={`${i}h00`}>{i}h00</MenuItem>)
            options.push(<MenuItem key={i * 2 + 1} value={`${i}h30`}>{i}h30</MenuItem>)
          }
          return options
        }()
        }
      </InvisibleSelect>
    </div>
  )
}

DateAndTimePicker.propTypes = {
  date: PropTypes.object,
  setDate: PropTypes.func,
  placeholder: PropTypes.string,
}

export default DateAndTimePicker

const useStyles = makeStyles({
  isSelected: {
    backgroundColor: '#F0F9F4'
  }
});

const StyledButton = withStyles({
  root: {
    outline: 'none!important',
    color: COLORS.green,
  },

})(Button)

const InvisibleDate = withStyles({
  root: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    bottom: 43,
    height: 0,
    margin: 0,
    width: 0,
  }
})(KeyboardDatePicker)

// const DateMenuProps = {
//   anchorOrigin: {
//     vertical: "bottom",
//     horizontal: "left"
//   },
//   transformOrigin: {
//     vertical: "top",
//     horizontal: "right"
//   },
//   getContentAnchorEl: null
// };


const InvisibleSelect = withStyles({
  root: {
    position: 'absolute',
    left: 0,
    bottom: -20,
    visibility: 'hidden',
    height: 0,
    margin: 0,
    width: 0,
  },
  outlined: {
    visibility: 'hidden',
    border: 'none'
  },
  icon: {
    visibility: 'hidden',
  }

})(Select)

const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 270,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right"
  },
  getContentAnchorEl: null
};
