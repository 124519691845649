import React, { Component } from 'react';
import HelpContents from './HelpContents'
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { FaTimes, FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components'
import Backdrop from '@material-ui/core/Backdrop';

import PmButton from 'src/components/StyledButton'

const StyledBackdrop = styled(Backdrop)`
    filter: blur(8px);
    -webkit-filter: blur(8px);
`


export default class ModalHelp extends Component {
    state = {
        visible: false,
    }

    toggleModal = () => {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { helpContent } = this.props
        const { visible } = this.state
        return (
            <>
                <IconButton style={styles.buttonContainer} onClick={this.toggleModal} className="no-outline">
                    <FaQuestionCircle color={'grey'} size={30} />
                </IconButton>
                <Modal open={visible}
                    BackdropComponent={StyledBackdrop}
                    BackdropProps={{
                        style: styles.backDrop
                    }}
                    aria-labelledby="modal-title">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                                <div style={styles.body}>
                                    <IconButton aria-label="Delete" className="no-outline" style={styles.closeButton} onClick={this.toggleModal}>
                                        <FaTimes color={'white'} size={20} />
                                    </IconButton>
                                    {HelpContents[helpContent]()}
                                    <div className="w-100 text-center my-5">
                                        <PmButton onClick={this.toggleModal} style={{ minWidth: 180 }}>OK</PmButton>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

const styles = {
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
    body: {
        position: 'relative',
        borderWidth: 1,
        borderColor: 'white',
        borderStyle: 'solid',
        borderRadius: 4,
        padding: 15,
    },
    backDrop: {
        background: 'black',
        opacity: 0.7,
    }
}
