import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';
import IconSvg from 'src/svg';
import styled from "styled-components";

const Icons = props => (
  <div className="d-flex">
    <IconSvg color={COLORS.iconsGray} name={props.name} width={35} />
    <StyledSpan className="ml-2">
      { props.name === 'MOTORBIKE' && 'Moto' }
      { props.name === 'SMALL' && 'Petit' }
      { props.name === 'MEDIUM' && 'Moyen' }
      { props.name === 'LARGE' && 'Grand' }
    </StyledSpan>
  </div>
)

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
`

Icons.propTypes = {
  name: PropTypes.string,
}

export default Icons;
