import { useState } from 'react'

const useStateCustom = (defaultValue = null) => {
  const [value, valueSetter] = useState(defaultValue)

  const setValue = event => {
    valueSetter(event && event.target && event.target.value)
  }

  return [value, setValue]
}

export default useStateCustom
