import React from 'react';

const formatStatus = (status) => {
  switch (status.toUpperCase()) {
    case 'NOT_CONFIRMED':
      return { text: 'En attente', classes: "badge badge-pill badge-primary" }
    case 'PLANNED':
      return { text: 'Accepté', classes: "badge badge-pill badge-success" }
    case 'ONGOING':
      return { text: 'En cours', classes: "badge badge-pill badge-success" }
    case 'EXPIRED':
      return { text: 'Expiré', classes: "badge badge-pill badge-secondary" }
    case 'TERMINATING_TENANT':
      return { text: 'Résiliation', classes: "badge badge-pill badge-warning" }
    case 'TERMINATING_OWNER':
      return { text: 'Résiliation', classes: "badge badge-pill badge-warning" }
    case 'ENDED':
      return { text: 'Terminé', classes: "badge badge-pill badge-danger" }
    default:
      return { text: 'Erreur', classes: "badge badge-pill badge-danger" }
  }
}

export default function BookingStatus({ status }) {
  const formated = formatStatus(status)
  return (
    <span
      className={formated.classes}
      style={{ color: 'white' }}
    >
      {formated.text}
    </span>
  )
}
