import React from 'react';

import SnackBar from 'src/components/SnackBar';
import ParkingApi from 'src/api/parking';

import PmButton from 'src/components/StyledButton'
import TextArea from 'src/components/TextArea';

import { Consumer } from 'src/services/store';

class DescriptionInput extends React.Component {
  state = {
    isLoading: false,
    value: '',
    placeholder: 'Écrivez une description de votre parking à destination des futurs locataires.',
  }

  componentWillMount() {
    if (this.props.edit) {
      const { parking } = this.props
      this.setState({ value: parking.description || '', id: parking.id })
    } else {
      let parking = null
      if (typeof window !== 'undefined') { // client
        parking = JSON.parse(sessionStorage.getItem('parking'));
      }
      if (parking && parking.description) {
        this.init();
      }
    }
  }

  init = () => {
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    if (parking) {
      this.setState({ value: parking.description });
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
  }

  checkValue = () => {
    const { value } = this.state;
    return value ? false : true;
  }

  saveSteps = () => {
    const { value } = this.state;
    let parking = null
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    parking = { ...parking, description: value, currentStep: this.props.step + 1 };
    sessionStorage.setItem('parking', JSON.stringify(parking));
    this.props.onChangeStep(this.props.step + 1);
  }

  updateParkingDescription = async () => {
    this.setState({ isLoading: true })
    const { id, value: description } = this.state
    const res = await ParkingApi.updateParkingInfo(id, { description })
    if (!res.error) {
      if (this.props.onEdition) {
        this.props.onEdition(res)
      }
      this.snack.open('success', 'Description enregistrée.')
    } else {
      this.snack.open('error')
    }
    this.setState({ isLoading: false })
  }

  validateForm() {
    if (this.state.value === '') {
      this.props.data.snackRef.open('warning', "Veuillez entrer une description.")
      return false
    }
    return true
  }

  saveDescription = () => {
    if (this.validateForm()) {
      if (this.props.edit) {
        this.updateParkingDescription()
      } else {
        this.saveSteps()
      }
    }
  }

  render() {
    const { edit } = this.props;
    const { value, placeholder, isLoading } = this.state;
    return (
      <div className="d-flex flex-column justify-content-between align-items-center h-100">
        <div className="mb-3">
          <TextArea value={value} onChange={(e) => this.onChange(e)} placeholder={placeholder} />
        </div>
        <PmButton isLoading={isLoading} disabled={isLoading} onClick={this.saveDescription}>{edit ? "Enregistrer" : "Suivant"}</PmButton>
        {edit && <SnackBar ref={ref => this.snack = ref} />}
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <DescriptionInput {...props} data={ctx} ref={ref} />}
  </Consumer>
));