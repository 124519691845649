import React from 'react';
import { FaBell } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { COLORS } from 'src/stylesheet/colors';
import styled from 'styled-components';

const AlertIcon = ({ className }) => (
  <StyledDiv className={className}>
    <BellIcon size={20} color="white" />
    <MdLocationOn size={60} color={COLORS.green} />
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: absolute;
  z-index: 2;
  transform : translate(-50%, -97%);
`

const BellIcon = styled(FaBell)`
  position: absolute;
  top: 20%;
  left: 50%;
  transform : translate(-50%);
`

export default AlertIcon
