import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Consumer } from 'src/services/store';

import LoginForm from 'src/views/LoginPage'
import RegisterForm from 'src/views/RegisterPage'
import PhoneInput from 'src/views/AddPhonePage'
import CodeInput from 'src/views/PhonePage'

const AUTH_STATE = {
  login: 0,
  register: 1,
  phoneInput: 2,
  codeInput: 3,
}

function LoginStep({ user }) {
  const [tabValue, setTabValue] = useState(AUTH_STATE.login)
  const [phoneInput, setPhoneInput] = useState(AUTH_STATE.register)
  const [phone, setPhone] = useState('')
  function handleChange(event, newValue) {
    setTabValue(newValue);
  }

  function onRegister() {
    setPhoneInput(AUTH_STATE.phoneInput)
  }

  function onPhoneAdd(phone) {
    setPhone(phone)
    setPhoneInput(AUTH_STATE.codeInput)
  }

  return (
    <div className="p-2 px-lg-3 text-center">
      <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" className="mb-3" centered>
        <Tab label="Se connecter" className="no-outline" />
        <Tab label="S'enregistrer" className="no-outline" />
      </Tabs>
      {tabValue === AUTH_STATE.login &&
        <>
          {!user && <LoginForm checkout />}
          {user && !user.phone_validated && <PhoneInput checkout onPhoneAdd={onPhoneAdd} />}
          {user && !user.phone_validated && phoneInput === AUTH_STATE.codeInput &&
            <CodeInput checkout phone={phone} onRegister={onRegister}/>
          }
        </>
      }
      {tabValue === AUTH_STATE.register &&
        <>
          {phoneInput === AUTH_STATE.register && <RegisterForm checkout onRegister={onRegister} />}
          {phoneInput === AUTH_STATE.phoneInput && <PhoneInput checkout onPhoneAdd={onPhoneAdd} />}
          {phoneInput === AUTH_STATE.codeInput && <CodeInput checkout phone={phone} onRegister={onRegister} />}
        </>
      }
    </div>
  )
}

export default () => (
  <Consumer>
    {ctx => <LoginStep user={ctx.user} />}
  </Consumer>
);
