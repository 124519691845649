import React from "react"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components'

import UserAvatar from 'src/components/UserAvatar'
import { GOOGLE_API_KEY } from 'src/environement';
import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors'
import Marker from 'public/assets/img/marker.png';

const createMapOptions = (maps) => ({
  clickableIcons: false,
  disableDefaultUI: true,
})

const ParkingPanel = ({ parking, children = null }) => (
  <Paper elevation={0} className="h-100 shadow">
    <div className="rounded-top" style={{ width: '100%', height: 7, backgroundImage: GLOBAL.gradientBlue }} />
    <div style={{ height: 200, width: '100%', zIndex: 0 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={{ lat: parking.location.latitude, lng: parking.location.longitude }}
        zoom={15}
        options={createMapOptions}
      >

        <ParkmatchMarker
          lat={parking.location.latitude}
          lng={parking.location.longitude}
        />
        {/* <MarkerComponent
          price={parking.monthlyPrice}
          lat={parking.location.latitude}
          lng={parking.location.longitude}
        /> */}
      </GoogleMapReact>
    </div>
    <div style={{ position: 'relative' }}>
      <StyledHeader style={{ zIndex: 1 }} elevation={10} />
    </div>
    <Paper elevation={0} style={{ zIndex: 2, padding: 15, paddingTop: 41, position: 'relative' }}>
      <StyledHeader elevation={0} >
        <Grid container>
          <Grid item xs={5} style={{ padding: 10, paddingTop: 10 }}>
            <UserAvatar />
          </Grid>
          <Grid container item xs={7}>
            <Grid item xs={12}>
              <span style={{ fontSize: 16 }}>Parking de</span>
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: 22, color: COLORS.green, textTransform: 'capitalize' }}>
                {parking.ownerName}.
              </span>
            </Grid>
          </Grid>
        </Grid>
      </StyledHeader>
      {children}
    </Paper>
  </Paper>
)

const StyledHeader = styled(Paper)`
  && {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    min-height: 76px;
    border-radius: 41px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 220px;
    ${'' /* max-width: 220px; */}
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
const ParkmatchMarker = () => (
  <img style={{
    width: 40,
    height: 40,
    marginLeft: -20,
    marginTop: -40
  }} src={Marker} alt="Marker" />

)

export default ParkingPanel
