import React, { useState } from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import PublicPage from 'src/templates/PublicPage'

import { Consumer } from 'src/services/store';
import ParkingApi from 'src/api/parking';
import Background from 'public/assets/svg/estimation-parking.svg'
import PmButton from 'src/components/StyledButton'
import StarRating from './StarRating'
import Tooltip from 'src/components/Tooltip'
import StyledList from 'src/components/StyledList'
import Analytics from 'src/services/Analytics'

import IntegrationAutosuggest from './MapBoxAutocomplete'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { UncontrolledTooltip } from 'reactstrap';
import Chip from '@material-ui/core/Chip';

const RENTAL_TYPE = {
    'parking': 'rent_parking_price',
    'garage-box': 'rent_box_price'
}

const SELL_TYPE = {
    'parking': 'sell_parking_price',
    'garage-box': 'sell_box_price'
}


const useStyles = makeStyles(theme => ({
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    }
}));

function EstimationPage({ data, history }) {
    const [location, setLocation] = useState({
        lon: '',
        lat: '',
        address: ''
    })
    const [parkingType, setParkingType] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [rentPrice, setRentPrice] = useState('')
    const [rentReliability, setRentReliability] = useState(false)
    const [sellPrice, setSellPrice] = useState('')
    const [sellReliability, setSellReliability] = useState(false)
    const [estimationStage, setEstimationStage] = useState(0)
    const [error, setError] = useState(false)

    const handleChangeParkingType = event => {
        setParkingType(event.target.value)
    };

    const classes = useStyles()

    const estimate = async () => {
        if (validateForm()) {
            setIsLoading(true)
            Analytics.launchEstimation()
            const res = await ParkingApi.fetchEstimatedPrice({
                longitude: location.lon,
                latitude: location.lat
            })
            if (res.error) {
                setError(true)
                setIsLoading(false)
                return
            } else {
                setError(false)
            }
            if (res['rent_price'][RENTAL_TYPE[parkingType]]) {
                setRentPrice(res['rent_price'][RENTAL_TYPE[parkingType]])
                setRentReliability(parseInt(res['rent_price']['reliability']))
            } else {
                setRentPrice('0')
            }
            if (res['sell_price'][SELL_TYPE[parkingType]]) {
                setSellPrice(res['sell_price'][SELL_TYPE[parkingType]])
                setSellReliability(parseInt(res['sell_price']['reliability']))
            } else {
                setSellPrice('0')
            }

            setIsLoading(false)
            nextStage()

        }
    }

    const launchEstimate = () => {
        estimate()
    }

    const nextStage = () => {
        if (estimationStage === 1) { setEstimationStage(0) }
        else { setEstimationStage(estimationStage + 1) }
    }

    const newEstimation = () => {
        nextStage()
        setLocation({ lon: '', lat: '', address: '' })
    }

    const validateForm = () => {
        if (location.lon === '' || !location.lon || location.lat === '' || !location.lat) {
            data.snackRef.open('warning', 'Veuillez sélectionner une adresse ou un lieu valide.')
            return false
        }
        if (!parkingType) {
            data.snackRef.open('warning', 'Veuillez sélectionner un type de bien.')
            return false
        }
        return true
    }

    const goToAdd = () => {
        history.push('/add')
    }

    return (
        <PublicPage
            metaTitle="Estimez le revenu locatif et la valeur de votre parking"
            metaDescription="Un simulateur de rentabilité pour estimer le prix mensuel de location de votre parking et le revenu potentiel en utilisant ParkMatch."
            navbarPush
        >
            <div className="row min-vh-100" style={{ zIndex: 10, position: 'relative', overflow: 'hidden' }}>
                <div style={{
                    position: 'absolute',
                    top: 40,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '105%',
                    minWidth: 750,
                    zIndex: 5,
                    background: 'white',
                    opacity: 0.25
                }}>
                    <img src={Background} alt="Estimation Parking" style={{ zIndex: 0 }} />
                    <img src={Background} alt="Estimation Parking" style={{ zIndex: 0, marginLeft: -30, marginTop: 40 }} />
                </div>
                <div className="col" style={{ zIndex: 10 }}>

                    <div className="row pt-4">
                        <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2 text-center shadow rounded-lg p-5 bg-white mt-5">

                            {estimationStage === 0 &&
                                <>
                                    <h1>Estimez votre parking en 1 clic !</h1>
                                    <Tooltip className="my-3">
                                        <div style={{ fontSize: '1.2em' }}>
                                            Ce calculateur gratuit est développé par Parkmatch et vous permet d'estimer le plus précisément possible
                                            le <strong>revenu locatif</strong> pouvant être généré par votre parking, garage
                                            ou box ainsi que son <strong>prix de vente</strong>.
                                        </div>
                                    </Tooltip>

                                    <IntegrationAutosuggest setLocation={setLocation} />
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="parking-type">Type</InputLabel>
                                        <Select
                                            value={parkingType}
                                            onChange={handleChangeParkingType}
                                            inputProps={{
                                                name: 'parking-type',
                                                id: 'parking-type',
                                            }}
                                        >
                                            <MenuItem value={'parking'}>Place de parking</MenuItem>
                                            <MenuItem value={'garage-box'}>Garage/Box</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className="my-4">
                                        <PmButton disabled onClick={launchEstimate} isLoading={isLoading} style={{ fontSize: '1.3em' }}>Lancer l'estimation</PmButton>
                                    </div>

                                     <Tooltip color={'yellow'}>🚧 Le simulateur est en travaux. Nous sommes en train de le mettre à jour, revenez prochainement.</Tooltip>
                                    {error && <Tooltip color={'yellow'}>Le service ne répond pas, veuillez rééssayer plus tard.</Tooltip>}
                                </>
                            }
                            {estimationStage === 1 &&
                                <>
                                    <h2>Votre estimation</h2>
                                    <h3>{location.address}</h3>
                                    <Chip
                                        icon={null}
                                        label={parkingType === 'parking' ? 'Place de parking' : 'Box/Garage fermé'}
                                        color="primary"
                                        style={{ margin: '3px', padding: '0', color: 'white', fontSize: '1rem' }}
                                    />
                                    <Table aria-label="simple table" className="mb-5 mt-4">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" align="left" style={{ fontSize: '1.3em' }}>
                                                    Location mensuelle
                                                </TableCell>
                                                <TableCell align="right" style={{ fontSize: '1.3em' }}>
                                                    {rentPrice === '0' && <em>Données insuffisantes pour cette adresse</em>}
                                                    {rentPrice !== '0' && <>
                                                        <span id='rentPrice'>
                                                            <strong>{rentPrice}</strong> euros/mois
                                                        </span>
                                                        <UncontrolledTooltip placement="bottom" target="rentPrice">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ fontSize: '1.1em' }}>Fiabilité: </span><StarRating percent={rentReliability} />
                                                            </div>
                                                        </UncontrolledTooltip>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row" align="left" style={{ fontSize: '1.3em' }}>
                                                    Valeur achat/vente
                                                </TableCell>
                                                <TableCell align="right" style={{ fontSize: '1.3em' }}>
                                                    {sellPrice === '0' && <em>Données insuffisantes pour cette adresse</em>}
                                                    {sellPrice !== '0' &&
                                                        <>
                                                            <span id="sellPrice"><strong>{sellPrice}</strong> euros</span>
                                                            <UncontrolledTooltip placement="bottom" target="sellPrice">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ fontSize: '1.1em' }}>Fiabilité: </span><StarRating percent={sellReliability} />
                                                                </div>
                                                            </UncontrolledTooltip>
                                                        </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <h3 className="my-4">Vous souhaitez louer votre parking ?</h3>

                                    <p className="my-4" style={{ fontSize: '1.2em', textAlign: 'left' }}>
                                        Avec Parkmatch, profitez d'un service simple et performant pour gérer toutes vos location de parking garage ou box:
                                        </p>

                                    <StyledList elements={['Visibilité de votre parking sur plus de 30 plateformes partenaires (Leboncoin, Paruvendu, LeSiteImmo...).',
                                        'Paiements simplifiés et sécurisés en ligne.',
                                        'Plus simple: plus besoin de contrat!']} />


                                    <div className="row mt-5">
                                        <div className="col-6 pt-1">
                                            <PmButton outlined onClick={newEstimation} isLoading={isLoading} style={{ fontSize: '1.1em' }}>Nouvelle estimation</PmButton>
                                        </div>
                                        <div className="col-6">
                                            <PmButton onClick={goToAdd} isLoading={isLoading} style={{ fontSize: '1.3em' }}>Proposer mon parking</PmButton>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PublicPage >
    )
}


export default () =>
    <Consumer>
        {ctx => <PublicRoute component={EstimationPage} data={ctx} />}
    </Consumer>
