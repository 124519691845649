import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccessTime from '@material-ui/icons/AccessTime';
import LocalParking from '@material-ui/icons/LocalParking';
import CreditCard from '@material-ui/icons/CreditCard';
import EuroSymbol from '@material-ui/icons/EuroSymbol';

const DrawerItems = [
  {
    title: "Mon compte",
    icon: <AccountCircle />,
    disabled: false,
    routeName: "/account"
  },
  { title: "DIVIDER" },
  {
    title: "Mes réservations",
    icon: <AccessTime />,
    disabled: false,
    routeName: "/account/reservations"
  },
  {
    title: "Mes parkings",
    icon: <LocalParking />,
    disabled: false,
    routeName: "/account/parkings"
  },
  { title: "DIVIDER" },
  {
    title: "Moyens de paiement",
    icon: <CreditCard />,
    disabled: false,
    routeName: "/account/cards"
  },
  {
    title: "Mes virements",
    icon: <EuroSymbol />,
    disabled: false,
    routeName: "/account/bank-infos"
  },
]


export {
  DrawerItems,
}
