import React from 'react';

import Tooltip from 'src/components/Tooltip'
import AvailabilityInput from 'src/components/ParkingInputs/AvailabilityInput'



const ParkingAvailability = ({ parking, onEdition }) => (
    <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2" style={{ maxWidth: '100vw' }}>
            <div className="row py-3">
                <div className="col-12">
                    <Tooltip text="Lorsque votre parking est disponible, vous pouvez recevoir des demandes de location. Veillez à garder vos disponibiliés à jour."></Tooltip>
                </div>
            </div>

            <AvailabilityInput edit parking={parking} onEdition={onEdition} />


        </div>
    </div>
)

export default ParkingAvailability

