import React from 'react';
import GLOBAL from 'src/stylesheet';
import CircularProgress from '@material-ui/core/CircularProgress';


const LoaderPage = ({ height = 100, width = 100 }) => (
    <div className="row" style={{
        height: '100vh', backgroundImage: GLOBAL.gradient,
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
        <CircularProgress
            height={height} // Not working
            width={width} // Not working
            style={{ color: 'white' }}
        />
    </div>
)

export default LoaderPage;
