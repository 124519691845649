import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'

const getArticleByTree = async ({ tree }) => {
  try {
    const res = await Api.wordPress.get(`wp-json/wp/v2/posts?filter[meta_query][0][key]=tree&filter[meta_query][0][value]=${tree}`);
    return patchWordPressResponse(res);
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'SEO_API', label: 'GET_WORDPRESS_ARTICLE' },
      extra: { error: e },
    })
    return { error: { message: 'Error get wordpress article' } }
  }
}

const getDataByTree = async ({ tree }) => {
  try {
    const res = await Api.wordPress.get(`wp-json/acf/v3/posts?filter[meta_query][0][key]=tree&filter[meta_query][0][value]=${tree}`);
    return patchWordPressResponse(res);
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'SEO_API', label: 'GET_SLUG_DATA' },
      extra: { error: e },
    })
    return { error: { message: 'Error get tree data.' } }
  }
}

const getArticlesAround = async (articleId, lat, lng) => {
  try {
    // id permet d'exclure l'article en cours de la réponse
    let url = 'wp-json/pm/v1/nearby_posts?lat=' + lat + '&lng=' + lng
    url = articleId ? url + '&id=' + articleId : url

    const res = await Api.wordPress.get(url)
    return patchWordPressResponse(res);
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'SEO_API', label: 'GET_ARTICLES_AROUND' },
      extra: { error: e },
    })
    return { error: { message: 'Error get articles around point.' } }
  }
}

const getArticlesInTree = async (tree) => {
  try {
    let url = 'wp-json/acf/v3/posts?filter[meta_query][0][key]=tree&filter[meta_query][0][compare]=like&filter[meta_query][0][value]=' + tree
    url += '&filter[meta_query][1][key]=tree&filter[meta_query][1][compare]=!=&filter[meta_query][1][value]=' + tree
    url += '&filter[orderby]=link_text&filter[order]=ASC'
    url += '&filter[posts_per_page]=50'
    const res = await Api.wordPress.get(url)
    return patchWordPressResponse(res);
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'SEO_API', label: 'GET_ARTICLES_IN_TREE' },
      extra: { error: e },
    })
    return { error: { message: 'Error get articles in tree.' } }
  }
}

const getArticlesInCountry = async (country) => {
  try {
    let url = '/wp-json/pm/v1/city_posts?country=' + country
    const res = await Api.wordPress.get(url)
    return patchWordPressResponse(res);
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'SEO_API', label: 'GET_ARTICLES_IN_COUNTRY' },
      extra: { error: e },
    })
    return { error: { message: 'Error get articles in country.' } }
  }
}

export default {
  getArticleByTree,
  getDataByTree,
  getArticlesAround,
  getArticlesInTree,
  getArticlesInCountry,
}

/**
 * Fix API response.
 *
 * For unknown reasons WordPress API return extraneous <html> tags around the JSON payload.
 */
function patchWordPressResponse(res) {
  if(typeof res !=="string") {
    return res
  }
  const patchedWordPressResponse = res.replaceAll("<html> </html>","")
  const parsed = JSON.parse(patchedWordPressResponse)
  return parsed
}
