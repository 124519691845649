export default {
  content : {
    borderRadius: 5,
    width: 310,
    backgroundColor: '#FFFFFF',
    border: 'none',
    top : '50%',
    left : '50%',
    right : 'auto',
    bottom : 'auto',
    marginRight : '-50%',
    transform : 'translate(-50%, -50%)'
  },
  overlay : {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
}