import React from 'react'
import Slider from 'react-slick';

import Bfm from 'public/assets/img/media/bfm-business.png';
import BrefEco from 'public/assets/img/media/brefeco.png';
import CherieFm from 'public/assets/img/media/cheriefm.png';
import Digital from 'public/assets/img/media/digitalb2b.png';
import France3 from 'public/assets/img/media/France3.png';
import Echos from 'public/assets/img/media/lesechos.png';
import UsineDigitale from 'public/assets/img/media/logo-usine-digitale.png';
import m6 from 'public/assets/img/media/m6.png';
import MagLyon from 'public/assets/img/media/mag2lyon.png';
import Nrj from 'public/assets/img/media/nrj.png';
import Rcf from 'public/assets/img/media/rcf.png';
import Tl7 from 'public/assets/img/media/tl7.png';
import Virgin from 'public/assets/img/media/virgin-radio.png';

const MEDIAS = [
  Bfm,
  BrefEco,
  CherieFm,
  Digital,
  France3,
  Echos,
  UsineDigitale,
  m6,
  MagLyon,
  Nrj,
  Rcf,
  Tl7,
  Virgin,
]

const settings = {
   infinite: true,
   slidesToShow: 5,
   slidesToScroll: 1,
   autoplay: true,
   speed: 3000,
   autoplaySpeed: 3000,
   cssEase: "linear",
   arrows: false,
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
    ]

 };

const MediasSection = () => (
  <div className="row">
    <div className="col pb-5" style={{ flexDirection: 'column' }}>
      <div className="slick-title py-5 text-center">Ils parlent de nous</div>
      <Slider {...settings} >
        {MEDIAS.map((image, index) => (
          <div key={index}>
            <img alt="Medias" className="media-image" src={image} />
          </div>
        ))}
      </Slider>
    </div>
  </div>
)

export default MediasSection
