import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';

const TextArea = ({ value, onChange, placeholder, width = 300, height = 300 }) => (
  <textarea
    className="shadow-lg px-3 py-1"
    style={{ outline: 'none', resize: 'none', maxWidth: '75vw', width: width, height: height, textAlign: 'left', borderRadius: 20, border: `1px solid ${COLORS.green}` }}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
  />
)

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default TextArea;
