import Api from 'src/services/api'

const logout = ({ handleChangeLogged, history }) => {
  Api.parkmatch.removeAccessToken()
  localStorage.clear();
  sessionStorage.clear();
  if (handleChangeLogged) {
    handleChangeLogged(false);
  }
}

export default logout;
