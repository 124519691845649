import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Consumer } from 'src/services/store';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import logo from 'public/assets/img/logo-Parkmatch.png';
import AccountButton from './AccountButton';
import { default as ButtonDefault } from '@material-ui/core/Button';

const Button = styled(ButtonDefault)`
  font-weight: normal;
`

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
});

const NavigationBarNoStyle = ({ classes, openMenu, data, privatePage, navbarPush }) => (
  <>
    <AppBar position={"fixed"} className={classes.appBar}>
      <Toolbar style={{ justifyContent: 'space-between', backgroundColor: '#f8f9fa', color: 'rgba(142, 142, 147, 0.9)' }}>
        <Link to="/">
          <img className="d-inline-block" alt="logo" width="155" src={logo} />
        </Link>
        <div>
          <Hidden smUp>
            {openMenu &&
              <IconButton
                aria-label="Open drawer"
                onClick={openMenu}
                className="no-outline"
              >
                <MenuIcon />
              </IconButton>}
          </Hidden>
          <Hidden xsDown>

            {/*
            <Button component={Link} color="primary" to="/search" className="no-outline mr-3" variant="outlined" style={{ textTransform: 'none' }}>
              Rechercher un parking
            </Button>

            <Button component={Link} color="primary" to="/add" className="no-outline mr-3 d-sm-none d-md-inline-block" variant="outlined" style={{ textTransform: 'none' }}>
              Proposer mon parking
            </Button>

            */}

            {data.user && <AccountButton />}
            {!data.user && !data.isConnecting &&
              <Link to="/account">
                <Button color="primary" className="no-outline mr-3" style={{ textTransform: 'none' }}>Se connecter</Button>
              </Link>
            }
            {data.isConnecting && <CircularProgress className="mx-5" size={32} />}
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
    {
      navbarPush &&
      <Toolbar />
    }
  </>
)

const NavigationBar = withStyles(styles)(NavigationBarNoStyle);

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <NavigationBar {...props} data={ctx} ref={ref} />}
  </Consumer>
));
