const INFOS = {
  SMALL: "Petit",
  MEDIUM: "Moyen",
  LARGE: "Grand",
  MOTORBIKE: "Moto",
  INDOOR: 'Intérieur',
  LIGHTING: 'Eclairage',
  POWER: 'Borne',
  BOX: 'Box fermé',
  CAMERA: 'Caméra',
}

const vehicleSizesInfo = {
  MOTORBIKE: {
    name: "Deux roues",
    examples: "",
  },
  SMALL: {
    name: "Petit",
    examples: "",
  },
  MEDIUM: {
    name: "Moyen",
    examples: "",
  },
  LARGE: {
    name: "Grand",
    examples: "",
  },
}

const vehicleSizesHierarchy = [
  "MOTORBIKE",
  "SMALL",
  "MEDIUM",
  "LARGE",
]

export { INFOS, vehicleSizesInfo, vehicleSizesHierarchy };
