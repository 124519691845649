import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';
import { RENTAL_MODE } from 'src/services/store/SearchStore'


const RentalModeInfo = ({ rentalMode }) => (
    <StyledDiv>
        <div className="rounded-top" style={{ width: '100%', height: 7, backgroundImage: GLOBAL.gradientBlue, position: 'absolute', top: 0, left: 0 }} />
        <h2>Déroulement après la demande</h2>
        {rentalMode === RENTAL_MODE.monthly &&
            <>
                <h3>Abonnement mensuel</h3>
                <p>Après envoi de la demande, <strong>vous recevrez le contact du propriétaire</strong> et un code à 4 chiffres.</p>
                <p>Vous pourrez <strong>lui poser toutes vos questions</strong>, organiser une visite du parking et si celui-ci vous convient, le propriétaire vous remettra les accès (badge, clef, bip, télécommande, etc) en échange de votre code à 4 chiffres.</p>
                <p>L'abonnement démarre avec la saisi de votre code à 4 chiffres par le propriétaire, validant le paiement du premier mois de réservation.</p>
                <p>Un préavis de 30 jours est appliqué pour la résiliation, qu'elle soit demandée par vous ou par le propriétaire.</p>
            </>
        }
        {rentalMode === RENTAL_MODE.oneshot &&
            <>
                <h3>Réservation ponctuelle</h3>
                <p>Lors de l'envoi de la demande, vous pouvez ajouter <strong>un commentaire à destination du propriétaire</strong>.</p>
                <p>Celui-ci dispose alors de <strong>72 heures</strong> pour accepter ou décliner votre demande.</p>
                <p>S'il accepte, vous êtes débité du montant applicable de la réservation, sinon il n'y a <strong>aucun frais</strong>.</p>
            </>
        }
    </StyledDiv>
)

export default RentalModeInfo


const StyledDiv = styled.div`
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    padding: 20px;
    border-radius: 4px;
    position: relative;

    h2 {
        color: ${COLORS.green};
        font-size: 1.5rem;
    }

    h3 {
        text-align: center;
        font-size: 1.2rem;
        color: #666;
    }
`