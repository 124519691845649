import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import { FaCalendarAlt } from 'react-icons/fa'
import ICONS from './icons';
import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';

const tooltipText = {
  Réservations: "Téléchargez l’application mobile pour utiliser cette fonctionnalité!",
  Retirer: 'Je retire mon parking de Parkmatch, tout en le conservant.',
  Publier: 'Je publie mon parking, yepaaaah!',
  Editer: "Cette fonctionnalité n'est pas disponible",
}

class Button extends React.Component {
  state = {
    tooltipOpen: false,
  }

  toggle = () => {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen });
  }

  render() {
    const { resp, button, label, icon, textButton, id, disable, onClick } = this.props;
    const IconComponent = ICONS[icon];
    return (
      <div className="card-parking-btn">
        {
          !resp ?
            <div onClick={() => { !disable && onClick() }} className="d-flex flex-row align-items-center" id={`t-${id}`}
              style={!button ? { paddingBottom: 2, borderBottom: !button && `1px solid ${COLORS.green}` } : { backgroundImage: GLOBAL.gradient, borderRadius: 5, padding: '2px 10px' }}>
              {icon && <IconComponent color={button ? COLORS.lightWhite : COLORS.green} />}
              <div style={{ padding: '0px 5px', color: !button ? COLORS.green : COLORS.lightWhite }}>{textButton}</div>
              {label !== 0 && <div className="rounded d-flex align-items-center justify-content-center" style={{ height: 14, backgroundColor: COLORS.red, color: COLORS.lightWhite, fontSize: 12, padding: '4px 3px 1px 3px' }}>{label}</div>}
            </div> :
            <div>
              <div onClick={() => { !disable && onClick() }} className="d-none d-md-inline-block" id={`t-${id}`} style={!button ? { paddingBottom: 2, borderBottom: !button && `1px solid ${COLORS.green}` } : { backgroundImage: GLOBAL.gradient, borderRadius: 5, padding: '2px 10px' }}>
                {icon && <IconComponent color={button ? COLORS.lightWhite : COLORS.green} />}
                <div style={{ display: 'inline-block', padding: '0px 5px', color: !button ? COLORS.green : COLORS.lightWhite }}>{textButton}</div>
                {/* { label !== 0 && <div className="rounded d-flex align-items-center justify-content-center" style={{ height: 14, backgroundColor: COLORS.red, color: COLORS.lightWhite, fontSize: 12, padding: '4px 3px 1px 3px' }}>{label}</div> } */}
              </div>
              <div onClick={() => { !disable && onClick() }} className="d-md-none" id={`t-${id}`}>
                {icon ? <IconComponent color={COLORS.green} /> : <FaCalendarAlt size={20} color={COLORS.green} />}
              </div>
            </div>
        }
        {
          !button && id &&
          <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={`t-${id}`} toggle={this.toggle}>
            {tooltipText[textButton]}
          </Tooltip>
        }
      </div>
    )
  }
}

Button.defaultProps = {
  button: false,
  label: 0,
  icon: null,
  resp: false
}

Button.propTypes = {
  button: PropTypes.bool,
  textButton: PropTypes.string,
  label: PropTypes.number,
  icon: PropTypes.string,
}

export default Button;
