import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const LoaderPage = ({ height = 100, width = 100, ...rest }) => (
    <CircularProgress
        height={height}
        width={width}
        {...rest}
    />
)

export default LoaderPage;
