import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IntlTelInput from 'react-intl-tel-input';
import { FaCheck } from 'react-icons/fa';
import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';
import LinkButton from 'src/components/LinkButton/LinkButton';

import useStateCustom from 'src/utils/useState'
import { validateEmail } from 'src/utils/checkAuth'
import UserApi from 'src/api/user'

function ForgottenPassword({ data }) {
  const [dialogFormVisible, setDialogFormVisible] = useState(false)
  const [dialogMessageVisible, setDialogMessageVisible] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [email, setEmail] = useStateCustom('')
  const [tabValue, setTabValue] = useState(0)
  const [phoneData, setPhoneData] = useState({ value: '', number: '', status: false })

  const askPassword = async () => {
    let res = false
    if (validateEmail(email) && tabValue === 0) {
      res = await UserApi.resetUserPasswordEmail({ email })
    }
    else if (tabValue === 0) {
      data.snackRef.open('warning', "L'adresse email n'est pas valide.")
      setEmailError(true)
      return
    }

    if (phoneData.status && tabValue === 1) {
      res = await UserApi.resetUserPasswordPhone({ phone: phoneData.number.replace(/\s/g, '') })
    } else if (tabValue === 1) {
      data.snackRef.open('warning', "Ce numéro de téléphone n'est pas valide.")
      return
    }
    if (res && !res.error) {
      setDialogFormVisible(false)
      setDialogMessageVisible(true)
      return
    } else {
      data.snackRef.open('error', "Une erreur est survenue.")
    }
  }

  const onChangeEmail = (event) => {
    setEmailError(false)
    setEmail(event)
  }

  const _setTabValue = (event, newValue) => { setTabValue(newValue) }

  const switchDialogFormVisible = (e) => {
    e.preventDefault()
    setDialogFormVisible(!dialogFormVisible)
  }

  const switchDialogMessageVisible = () => { setDialogMessageVisible(!dialogMessageVisible) }

  function onChangePhoneNumber(status, value, countryData, number) {
    setPhoneData({ value, number, status })
  }

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <LinkButton onClick={(e) => switchDialogFormVisible(e)} >
          Mot de passe oublié
      </LinkButton>
      </div>
      <Dialog open={dialogFormVisible} onClose={switchDialogFormVisible} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Mot de passe oublié</DialogTitle>
        <DialogContent>
          <Tabs value={tabValue} onChange={_setTabValue} className="mb-4" indicatorColor="primary">
            <Tab label="Email" className="no-outline" />
            <Tab label="Téléphone" className="no-outline" />
          </Tabs>
          {tabValue === 0 &&
            <>
              <DialogContentText>
                Entrez l'adresse e-mail associée à votre compte, et nous vous enverrons un e-mail permettant de réinitialiser votre mot de passe.
          </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email"
                type="email"
                onChange={onChangeEmail}
                error={emailError}
                style={{ width: 250 }}
              />
            </>
          }
          {tabValue === 1 &&
            <>
              <DialogContentText>
                Entrez le numéro de téléphone associé à votre compte, et nous vous enverrons un SMS permettant de réinitialiser votre mot de passe.
        </DialogContentText>
              <IntlTelInput
                defaultCountry="fr"
                onPhoneNumberChange={onChangePhoneNumber}
                value={phoneData.value}
                css={['intl-tel-input', 'form-control']}
              />
              <FaCheck style={{ marginLeft: 15 }} color={phoneData.status ? COLORS.green : 'transparent'} />
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button className="no-outline" onClick={switchDialogFormVisible} color="secondary">
            Annuler
          </Button>
          <Button className="no-outline" onClick={askPassword} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogMessageVisible} onClose={switchDialogMessageVisible} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Email envoyé</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Nous avons envoyé un email de récupération de mot de passe à l'adresse {email}. Si cette adresse est valide, vous recevrez cet email d'ici quelques minutes...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="no-outline" onClick={switchDialogMessageVisible} color="primary">
            Revenir à la connexion
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <ForgottenPassword {...props} data={ctx} ref={ref} />}
  </Consumer>
));