import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import { MdLocationOn } from 'react-icons/md';
import styled from 'styled-components';

const AlertItem = ({ item, onClick, onDelete, onPressAlert }) => (
  <StyledItem button onClick={() => onPressAlert(item.coordinates)}>
    <ListItemIcon>
      <Badge badgeContent={item.nb_parking_matching} color="primary">
        <MdLocationOn size={25} />
      </Badge>
    </ListItemIcon>
    {item.title}
    <StyledSecondary>
      <IconButton onClick={() => onDelete(item.alert_id)} aria-label="Comments" className="no-outline">
        <DeleteIcon />
      </IconButton>
    </StyledSecondary>
  </StyledItem>
)

const StyledSecondary = styled(ListItemSecondaryAction)`
  visibility: hidden;
  &:hover {
    visibility: visible;
  }
`

const StyledItem = styled(ListItem)`
  &:hover + ${StyledSecondary} {
    visibility: inherit;
  }
`

export default AlertItem
