import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';
import IconSvg from 'src/svg';
import CONSTANTS from 'src/constants';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { FaRegHandshake } from 'react-icons/fa';

import Carousel from './Carousel';

const ParkingDetail = ({ data }) => (
  <div className="card h-100 shadow border-0 m-auto">
    <Carousel data={data.images} />
    <div className="card-body">
      <h1 className="card-title">Parking {data.address}</h1>
      {data.status !== "INCOMPLETE" &&
        <>

          <h2 style={{ color: COLORS.green, textAlign: "left" }}>Description</h2>
          <p className="card-text display-linebreak">{data.description ? data.description : "Pas de description pour ce parking."}</p>
          {/* <h3>Accès piéton au parking</h3> */}
          <h2 style={{ color: COLORS.green, textAlign: "left" }}>Taille du parking</h2>
          <p>Les véhicules suivants peuvent se garer dans ce parking:</p>
          <div className="d-flex justify-content-center">
            {data.size.map(size => (
              <RenderSize key={size} size={size} />
            ))}
          </div>
          <h2 className="mt-3" style={{ color: COLORS.green, textAlign: "left" }}>A propos</h2>
          <RenderInfos parking={data} />
          {
            data.id === 638 &&
            <>
              <h2 className="mt-3" style={{ color: COLORS.green, textAlign: "left" }}>Points d'intérêts</h2>
              <ul><li><a href="https://www.france-hotel-guide.com/fr/43367-novotel-paris-les-halles-paris-fr.php">Hôtel Novotel des Halles</a></li></ul>
            </>
          }
          <h2 className="mt-3" style={{ color: COLORS.green, textAlign: "left" }}>Remise d'accès</h2>
          <Chip
            size="small"
            color="primary"
            avatar={
              <Avatar>
                <FaRegHandshake size="30" color="white" />
              </Avatar>
            }
            label="En main propre"
            style={{ color: 'white' }}
          />
        </>
      }
      {data.status === "INCOMPLETE" &&
        <p>Cette annonce est incomplète.</p>
      }

    </div>
  </div>
)

const RenderIcon = ({ name, subtitle, selected }) => (
  <div className="text-center">
    <IconSvg color={selected ? COLORS.green : COLORS.colorFooter} name={name} width={50} />
    <p className="card-text"
      style={{ color: selected ? COLORS.green : COLORS.colorFooter }}>
      {subtitle}
    </p>
  </div>
)

const RenderInfos = ({ parking }) => (
  <div className="d-flex justify-content-around">
    <RenderIcon name="INDOOR" subtitle="Interieur" selected={parking.indoor} />
    <RenderIcon name="BOX" subtitle="Box fermé" selected={parking.garage} />
    <RenderIcon name="CAMERA" subtitle="Caméra" selected={parking.camera} />
    <RenderIcon name="POWER" subtitle="Borne" selected={parking.power_supply} />
  </div>
)

const RenderSize = ({ size }) => (
  <div className="text-center mx-3">
    <IconSvg color={COLORS.iconsGray} name={size.toUpperCase()} width={40} />
    <p className="card-text">{CONSTANTS.INFOS[size]}</p>
  </div>)

ParkingDetail.propTypes = {
  data: PropTypes.object,
}

export default ParkingDetail;
