import React, { useState } from 'react'
import { Consumer } from 'src/services/store';
import UserApi from 'src/api/user'
import PropTypes from 'prop-types';
import { checkNames } from 'src/utils/checkAuth';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PmButton from 'src/components/StyledButton'



function NameEdit({ data, onSubmit, onCancel }) {
    const [isLoading, setIsLoading] = useState(false)

    const [firstName, setFirstName] = useState(data.user.first_name)
    const [lastName, setLastName] = useState(data.user.last_name)

    function handleChangeFirstName(e) { setFirstName(e.target.value) }
    function handleChangeLastName(e) { setLastName(e.target.value) }

    async function nameUpdate() {
        if (!checkNames(firstName) || !checkNames(lastName)) {
            data.snackRef.open('error', 'Veuillez entrer un nom et un prénom valides.')
            return
        }
        setIsLoading(true)
        const res = await UserApi.updateUserInfo({ first_name: firstName, last_name: lastName })
        setIsLoading(false)
        if (res && !res.error) {
            data.snackRef.open('success', 'Prénom et nom mis à jour.')
            data.updateUserData(res.res)
            onSubmit()
        }
        else {
            data.snackRef.open('error', "Une erreur est survenue, veuillez rééssayer plus tard.")
        }
    }

    return (
        <>
            <form noValidate autoComplete="off" className="d-flex flex-row justify-content-around w-100">
                <TextField
                    id="first-name"
                    label="Prénom"
                    value={firstName}
                    onChange={e => handleChangeFirstName(e)}
                    margin="normal"
                />
                <TextField
                    id="last-name"
                    label="Nom"
                    value={lastName}
                    onChange={e => handleChangeLastName(e)}
                    margin="normal"
                />
            </form>
            <div className="w-100 text-right mt-4">
                <Button onClick={onCancel}>Annuler</Button>
                <PmButton style={{ marginLeft: 20 }} onClick={nameUpdate} isLoading={isLoading}>Enregistrer</PmButton>
            </div>
        </>

    )
}

NameEdit.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default (props) => (
    <Consumer>
        {ctx => <NameEdit {...props} data={ctx} />}
    </Consumer>
)
