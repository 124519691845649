import React from 'react';
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip';


const ArticleLinks = ({ title, linksType, classNames, links }) => (
    <>
        {
            links && links[linksType] && links[linksType].length > 0 &&
            <div className={classNames + " text-center"}>
                <h3 className="text-center">{title}</h3>

                {links[linksType].map((value, index) => {
                    return <Link to={value.formattedLink}
                        key={index}>
                        <Chip
                            icon={null}
                            label={'Parkings ' + value.acf.link_text}
                            clickable
                            color="primary"
                            style={{ margin: '3px', padding: '0', color: 'white', fontSize: '0.9rem' }}
                        />
                    </Link>
                })}

            </div>
        }
    </>
)



export default ArticleLinks
