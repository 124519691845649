import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Consumer } from 'src/services/store';

import ParkingApi from 'src/api/parking';
import BookingApi from 'src/api/booking'
import UserAvatar from 'src/components/UserAvatar';
import PendingBooking from './PendingBooking'
import TerminatingBooking from './TerminatingBooking'
import OngoingBooking from './OngoingBooking'
import BookingResume from './BookingResume'
import DefaultParkingImage from 'public/assets/img/parkmatch-social.jpg';
import { RENTAL_MODE } from 'src/services/store/SearchStore'
import ModalHelp from 'src/components/ModalHelp'

function BookingDrawer({ parkingId = null, bookingId, closeDrawer, userType = 'owner', data }) {
  const [isLoading, setLoading] = useState(true)
  const [bookingData, setBookingData] = useState(null)

  async function getBookingData() {
    setLoading(true)
    let res = null
    if (userType === 'tenant') {
      res = await BookingApi.getBooking({ bookingId })
    } else {
      res = await ParkingApi.getBookingData({ parkingId, bookingId })
    }
    setLoading(false)
    if (res && !res.error) {
      setBookingData(res)
    }
    else {
      closeDrawer()
      data.snackRef.open('error', 'Récupération de la réservation: ne erreur est survenue.')
    }
  }

  useEffect(() => {
    getBookingData()
  }, [])

  function refreshBooking() {
    getBookingData()
  }

  return (
    <StyledContainer>
      {!isLoading && bookingData &&
        <Grid container>
          <Header booking={bookingData} closeDrawer={closeDrawer} userType={userType} />
          <BookingResume booking={bookingData} userType={userType} />
          {bookingData && bookingData.status === 'NOT_CONFIRMED' &&
            <PendingBooking booking={bookingData} parkingId={parkingId} refreshBooking={refreshBooking} closeDrawer={closeDrawer} userType={userType} />}
          {bookingData && (bookingData.status === 'ONGOING' || (bookingData.status !== 'NOT_CONFIRMED' && bookingData.recurrence_type === 'one_shot')) &&
            <OngoingBooking booking={bookingData} parkingId={parkingId} refreshBooking={refreshBooking} userType={userType} />}
          {bookingData && bookingData.status.includes('TERMINATING') && bookingData.recurrence_type === RENTAL_MODE.monthly &&
            <TerminatingBooking booking={bookingData} userType={userType} />}
        </Grid>
      }
      {isLoading && <StyledCircularProgress />}
    </StyledContainer>
  )
}

const Header = ({ booking, closeDrawer, userType }) => (
  <Grid container item xs={12}>
    <Grid item xs={12} style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
      <IconButton onClick={closeDrawer} aria-label="Delete" className="no-outline">
        <CloseIcon fontSize="large" />
      </IconButton>
      <ModalHelp helpContent={userType + booking.recurrence_type} />
    </Grid>
    {!(userType === 'tenant') && <Grid item container xs={10} className="my-5">
      <StyledGrid item xs={2} >
        <UserAvatar url={booking.tenant_image ? `${booking.tenant_image}_small.jpg` : null} />
      </StyledGrid>
      <Grid item container xs={10}>
        <Grid item xs={12}>
          {booking.tenant_name}
        </Grid>
        <Grid item xs={12}>
          <CheckCircleIcon color="primary" />
          <span className="ml-2">
            Téléphone vérifié
          </span>
        </Grid>
      </Grid>
    </Grid>}
    {userType === 'tenant' && <StyledImageContainer>
      {booking.image && booking.image.medium && <img src={booking.image.medium} height="auto" width="100%" alt="Parking cover" />}
      {(!booking.image || !booking.image.medium) && <img src={DefaultParkingImage} height="auto" width="100%" alt="Default cover" />}
      <StyledInfosContainer container>
        <Grid item xs={6} style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-end' }}>
          <span style={{ fontSize: 20 }}>{booking.address}</span>
          <span>{booking.city}</span>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <UserAvatar url={booking.owner_image && `${booking.owner_image}_small.jpg`} />
            <span>{booking.owner_name}.</span>
          </div>
        </Grid>
      </StyledInfosContainer>
    </StyledImageContainer>}
  </Grid>
)

const StyledContainer = styled.div`
  width: 468px;
  max-width: 100vw;
`

const StyledImageContainer = styled.div`
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const StyledInfosContainer = styled(Grid)`
   position: absolute;
   bottom: 0px;
   left: 0px;
   right: 0px;
   padding: 5px;
   padding-left: 10px;
   padding-right: 10px;
   font-size: 18px;
   color: white;
   background: linear-gradient(transparent, rgba(0,0,0,0.1), rgba(0,0,0,0.5));
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 50%;
`

const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <BookingDrawer {...props} data={ctx} ref={ref} />}
  </Consumer>
));
