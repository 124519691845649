import React from 'react';

import PublicPage from 'src/templates/PublicPage';
import { Consumer } from 'src/services/store';

import NotFound from 'src/components/NotFound';
import ParkingPage from 'src/views/ParkingPage';
import BlogArticlePage from 'src/views/BlogArticlePage';
import { getPageContent } from 'src/utils/pagesContent'


class LocationParkingMiddleware extends React.Component {

  state = {
    ...this.props.data.pageData,
    location: this.props.location.pathname,
    isLoading: false
  }


  async componentDidMount() {
    const location = this.props.location.pathname
    window.scrollTo(0, 0)
    const lPageData = await this.props.data.loadPageData()
    this.setState({ ...lPageData, location })
    this.props.data.resetPageContent()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const prevLocation = prevState.location
    const location = nextProps.location.pathname
    if (prevLocation !== location) {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
      return { location, isParking: false, isArticle: false, notFound: false }
    }
    return null
  }

  async componentDidUpdate() {
    if (!this.state.isParking && !this.state.isArticle && !this.state.notFound) {
      let lPageData = await this.props.data.loadPageData()
      this.setState({ ...lPageData })
      //this.props.data.resetPageContent()
    }
  }

  render() {
    const { isParking, isArticle, notFound, isLoading, article, parking, parkingsAround, linksAround, linksInTree, breadcrumbData } = this.state
    if (isLoading) {
      <PublicPage isLoading={isLoading} />
    }
    if (isParking && parking) {
      return <ParkingPage parking={parking} />
    } else if (isArticle && article) {
      return <BlogArticlePage article={article} parkingsAround={parkingsAround} linksAround={linksAround} linksInTree={linksInTree} breadcrumbData={breadcrumbData} />
    } else if (notFound) {
      return <NotFound staticContext={this.props.staticContext ? this.props.staticContext : {}} />
    }
    return <PublicPage isLoading={true} />
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <LocationParkingMiddleware {...props} data={ctx} ref={ref} />}
  </Consumer>
));

// Called on server to fill store with data required for render
async function loadData(req) {
  const data = await getPageContent(req)
  return { pageData: data }
}

export { loadData }