import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function VehicleMenu({ setEditVehicle, deleteVehicle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function _setEditVehicle() {
    setEditVehicle()
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="More"
        aria-haspopup="true"
        className="no-outline"
        onClick={handleClick}
        style={{ position: 'absolute', top: 0, right: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={_setEditVehicle}>Editer</MenuItem>
        <MenuItem onClick={deleteVehicle}>Supprimer</MenuItem>
      </Menu>
    </>
  )
}

export default VehicleMenu
