import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from 'src/containers/PrivateRoute'

import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';

import CardRegisterParking from './components/cardRegisterParking';
import CardSearchParking from './components/cardSearchParking';
import CardDownloadApp from './components/cardDownloadApp';
import NameEdit from './components/NameEdit';
import EmailEdit from './components/EmailEdit';
import PasswordEdit from './components/PasswordEdit';
import PrivatePageTemplate from 'src/templates/PrivatePage';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from 'src/components/Tooltip'


function Account({ data, history }) {

  const [expanded, setExpanded] = useState(false)

  const closeAll = () => { setExpanded(false) }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <PrivatePageTemplate metaTitle="Mon compte">
      <div className="mt-5 h1 text-center">Bonjour {data.user.first_name} <span role="img" aria-label="sheep">👋</span></div>
      <div style={{ color: COLORS.gray }} className="h2 text-center">bienvenue sur ParkMatch</div>
      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 p-4">
          <h2 style={{ textAlign: "left" }}>Vos informations</h2>

          <ExpansionPanel expanded={expanded === 'name'} onChange={handleChange('name')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography>Prénom et Nom</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ color: 'grey' }}>{data.user.first_name + ' ' + data.user.last_name}</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="flex-column">
              <Tooltip text="Vous devez entrer vos vrais nom et prénom ou certaines fonctionnalités de Parkmatch pourraient mal fonctionner." />
              <NameEdit onSubmit={closeAll} onCancel={closeAll} />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'email'} onChange={handleChange('email')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography>Adresse email</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ color: 'grey' }}>{data.user.email}</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="flex-column">
              <EmailEdit onSubmit={closeAll} onCancel={closeAll} />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'password'} onChange={handleChange('password')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography>Mot de passe</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ color: 'grey' }}></Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="flex-column">
              <PasswordEdit onSubmit={closeAll} onCancel={closeAll} />
            </ExpansionPanelDetails>
          </ExpansionPanel>

        </div>
        <div className="col-12 col-sm-10 offset-sm-1 p-1 px-4">
          <h2 style={{ textAlign: "left" }}>Actions rapides</h2>
        </div>

      </div>
      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 px-2">
          <div className="row">
            <CardSearchParking placeholder="Rechercher un parking à louer" />
            <CardRegisterParking history={history} />
          </div>
          <div className="row">
            <div className="col-12">
              <CardDownloadApp />
            </div>
          </div>
        </div>
      </div>

    </PrivatePageTemplate >
  )
}

Account.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PrivateRoute component={Account} {...props} data={ctx} ref={ref} />}
  </Consumer>
));
