import React, { useState, useEffect } from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { withRouter } from 'react-router-dom'

import PublicPage from 'src/templates/PublicPage';
import queryString from 'query-string'
import UserApi from 'src/api/user'

import PmButton from 'src/components/StyledButton'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from 'src/components/Tooltip'
import GLOBAL from 'src/stylesheet';



function ActionPage() {
    const [actionDone, setActionDone] = useState(false)
    const [actionSuccess, setSuccess] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [apiMessage, setApiMessage] = useState('')

    useEffect(() => {
        const { token, action } = queryString.parse(window.location.search)
        if (token && action && typeof window !== 'undefined') {
            executeAction({ token })
        }
    }, [])

    const executeAction = async ({ token }) => {
        setIsLoading(true)
        const res = await UserApi.executeTokenAction({ token })
        if (!res.error) {
            setApiMessage(res.res.message)
            setSuccess(true)
        }
        else {
            setApiMessage(res.error.message)
            setSuccess(false)
        }
        setIsLoading(false)
        setActionDone(true)
    }

    return (
        <PublicPage fullHeight footer={false}
            metaTitle="Action"
            metaDescription="">
            <div className="row vh-100" style={{ backgroundImage: GLOBAL.gradient, height: '100vh' }}>
                <div className="col-sm-12 my-auto">
                    <div className="card card-block mx-auto bg-light p-4 shadow" style={{ maxWidth: 500, width: 500 }}>
                        {(!actionDone && !isLoading) &&
                            <>
                                <h2>Aucune action à exécuter</h2>
                                <PmButton className="mt-4" onClick={() => this.props.history.push('/')}>Retour au site</PmButton>
                            </>
                        }
                        {isLoading &&
                            <>
                                <h2>Action en cours...</h2>
                                <CircularProgress />
                            </>
                        }
                        {(actionDone && actionSuccess) &&
                            <>
                                <h2>Action réussie !</h2>
                                <Tooltip text={apiMessage} />
                                <PmButton className="mt-4" onClick={() => this.props.history.push('/')}>Retour au site</PmButton>
                            </>
                        }
                        {(actionDone && !actionSuccess) &&
                            <>
                                <h2>Erreur...</h2>
                                <Tooltip color='red' text={apiMessage} />
                                <PmButton className="mt-4" onClick={() => this.props.history.push('/')}>Retour au site</PmButton>
                            </>
                        }
                    </div>
                </div>
            </div>
        </PublicPage >
    )

}

export default () => <PublicRoute component={withRouter(ActionPage)} />

