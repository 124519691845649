import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CreditCard from '@material-ui/icons/CreditCard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import styled from 'styled-components'

import { Consumer } from 'src/services/store';
import StyledButton from 'src/components/StyledButton'
import StyledDivider from 'src/components/Divider'
import VehicleModal from 'src/components/VehicleModal'
import IconsInfo from 'src/components/IconsInfo'
import { MonthYear } from 'src/utils/DateUtils'
import CardsModal from 'src/views/CreditCardPage'
import { COLORS } from 'src/stylesheet/colors'
import Tooltip from 'src/components/Tooltip'
import { RENTAL_MODE } from 'src/services/store/SearchStore';
import moment from 'moment';
import CommentModal from 'src/views/CheckoutPage/components/CommentModal'


const checkVehicleSize = (vehicle, size) => {
  if (vehicle && size && size.indexOf(vehicle.size) > -1) {
    return false
  }
  return true
}

const DetailsStep = ({ user, onClick, error, fullBooking, saveComment }) => (
  <div className="p-3">
    <Typography variant="h5" color="primary" gutterBottom>
      Détails de la demande
    </Typography>
    {(fullBooking && fullBooking.rentalMode === RENTAL_MODE.oneshot) &&
      <>
        <StyledDivider />
        <Grid container>
          <Grid container item xs={12} style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" gutterBottom>
              Commentaire pour le propriétaire
        </Typography>
            <CommentModal saveComment={saveComment} />
          </Grid>
          <Grid container item xs={12} style={{ justifyContent: 'space-between' }}>
            {fullBooking.comment &&
              <StyledComment>{renderComment(fullBooking.comment)}</StyledComment>
            }
            {!fullBooking.comment &&
              <StyledComment>Votre note au propriétaire.</StyledComment>
            }
          </Grid>
        </Grid>
      </>
    }

    <StyledDivider />

    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Période de réservation
        </Typography>
      </Grid>
      {fullBooking.rentalMode === RENTAL_MODE.monthly &&
        <Grid item xs={12} container>
          <DateRangeIcon />
          <Typography variant="body1" gutterBottom className='ml-3'>
            Abonnement mensuel
        </Typography>
        </Grid>
      }
      {fullBooking.rentalMode === RENTAL_MODE.oneshot &&
        <Grid item xs={12} container>
          <AccessTime />
          <Typography variant="body1" gutterBottom className='ml-3'>
            Réservation du {moment(fullBooking.startDate).format('ll')} au {moment(fullBooking.endDate).format('ll')}.
        </Typography>
        </Grid>
      }
    </Grid>

    <StyledDivider />

    <Grid container>
      <Grid container item xs={12} style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>
          Votre véhicule (facultatif)
        </Typography>
        <VehicleModal />
      </Grid>
      <Grid container item xs={12} style={{ justifyContent: 'center' }}>
        {user && user.vehicle && user.vehicle.numberplate !== "IMMATRICULATION" ? <IconsInfo
          edit
          name={user.vehicle.size}
          numberplate={user.vehicle.numberplate}
          isSelect
        />
          : <Typography variant="body1" gutterBottom>
            Vous n'avez pas de véhicule sélectionné pour le moment
          </Typography>
        }
      </Grid>
      <Grid container item xs={12} style={{ justifyContent: 'center' }}>
        {user && user.vehicle && checkVehicleSize(user.vehicle, fullBooking.parking.size) &&
          <Tooltip color="yellow" className="my-4" text="Le véhicule séléctionné n'est pas adapté à la taille du parking." />}
      </Grid>
    </Grid>

    <StyledDivider />

    <Grid container>
      <Grid container item xs={12} style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>
          Votre carte de paiement
        </Typography>
        <CardsModal modal />
      </Grid>
      <Grid container item xs={12}>
        {user && user.credit_card ? <>
          <CreditCard />
          <Typography variant="body1" gutterBottom className='ml-3'>
            {user.credit_card.display_number}
          </Typography>
          <Typography variant="body1" gutterBottom className='ml-5'>
            {MonthYear(user.credit_card.expiration_date)}
          </Typography>
        </>
          : <Typography variant="body1" gutterBottom>
            Vous n'avez pas de carte de crédit enregistrée pour le moment. Ajoutez en une pour effectuer des demandes de réservation.
          </Typography>
        }
      </Grid>
    </Grid>
    <Grid container className="mt-4" style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <StyledButton onClick={onClick}>Suivant</StyledButton>
      {error && <span className="mt-4" style={{ color: COLORS.red }}>{error}</span>}
    </Grid>
  </div>
)

export default (props) => (
  <Consumer>
    {ctx => <DetailsStep {...props} user={ctx.user} />}
  </Consumer>
);

function renderComment(comment) {
  let newComment = comment.split('\n').map((item, i) => {
    return <p key={i}>{item}</p>;
  });
  return newComment
}

const StyledComment = styled.div`
  text-align: left;
  max-height: 70px;
  overflow-y: scroll;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  background-color:${COLORS.realLightGreen};
`
