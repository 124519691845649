import React from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Hidden from '@material-ui/core/Hidden';
import StepButton from '@material-ui/core/StepButton';

function CheckoutSteps({ step, goToDetails, goToConfirmation }) {
  return (
    <Stepper activeStep={step} alternativeLabel nonLinear>
      <Step completed={step > 0}>
        <StepLabel>
          <Hidden xsDown>
            Informations du compte
          </Hidden>
          <Hidden smUp>
            Info.
          </Hidden>
        </StepLabel>
      </Step>
      <Step completed={step > 1}>
        <StepButton onClick={goToDetails} className="no-outline">
          <Hidden xsDown>
            Details de la réservation
          </Hidden>
          <Hidden smUp>
            Details
          </Hidden>
        </StepButton>
      </Step>
      <Step>
        <StepButton onClick={goToConfirmation} className="no-outline">
          <Hidden xsDown>
            Confirmation de demande
          </Hidden>
          <Hidden smUp>
            Confirmation
          </Hidden>
        </StepButton>
      </Step>
    </Stepper>
  )
}

export default CheckoutSteps
