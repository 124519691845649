import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import { Consumer } from 'src/services/store';
import { COLORS } from 'src/stylesheet/colors';

import './styles.css'

const searchOptions = {}
if (process.env.BROWSER) {
  /*global google*/
  searchOptions = {
    location: new google.maps.LatLng(48.864716, 2.349014),
    radius: 2000,
  }
}

const InputSearch = ({ handleChangeAddress, handleSelectAddress, address, ...props }) => {
  if (!handleChangeAddress && !handleSelectAddress && !address) {
    handleChangeAddress = props.data.handleChangeAddress;
    handleSelectAddress = props.data.handleSelectAddress;
    address = props.data.address;
  }
  function onSubmit(e) {
    e.preventDefault()
    handleChangeAddress()
  }
  return (
    <PlacesAutocomplete
      value={address || ""}
      onChange={handleChangeAddress}
      searchOptions={searchOptions}
      onSelect={(e, ee) => {
        handleSelectAddress(e, ee)
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ width: '100%' }}>
          <form inline="true" onSubmit={e => onSubmit(e)} style={{ width: '100%' }}>
            <input
              className="shadow-lg px-3 py-1" style={{ width: '100%', textAlign: 'center', borderRadius: 20, border: `1px solid ${COLORS.green}` }}
              {...getInputProps({
                placeholder: props.placeholder,
              })}
            />
          </form>
          {suggestions && <div className="autocomplete-dropdown-container list-group position-absolute">
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active list-group-item'
                : 'suggestion-item list-group-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', zIndex: 2 }
                : { backgroundColor: '#ffffff', cursor: 'pointer', zIndex: 2 };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <InputSearch {...props} data={ctx} ref={ref} />}
  </Consumer>
));
