import styled from 'styled-components'


const QuoteParagraph = styled.p`

  text-align: left;
  position: relative;
  padding-left: 2.9em;
  font-size: 1.1em;
  font-style: italic;
  z-index: 1;
  color: #777777;
  quotes: "“" "”" "‘" "’";
  

  &:before {
    content: open-quote;
    font-family: ;
    font-size: 110px;
    color: #CCCCCC;
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 0;
  }

`;


export default QuoteParagraph
