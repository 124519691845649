import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import styled from 'styled-components'

import ParkingInputs from 'src/components/ParkingInputs'
import { COLORS } from 'src/stylesheet/colors';

const ParkingEdition = ({ parking, onEdition }) => (
  <div className="mb-3 row">
    <div className="col-12" style={{ maxWidth: '100vw' }}>
      <Panel title="Adresse du parking" subtitle={parking.address} valid={parking.address}>
        <ParkingInputs.AddressInput parking={parking} edit onEdition={onEdition} />
      </Panel>
      <Panel title="Description" subtitle={parking.description} valid={parking.description}>
        <div className="row">
          <div className="col-lg-6 col-12 order-2 order-lg-1">
            <ParkingInputs.DescriptionInput parking={parking} edit onEdition={onEdition} />
          </div>
          <div className="col-lg-6 col-12 mt-4 mt-sm-0 order-1 order-lg-2 mb-2">
            <ParkingInputs.ToolTip border type='description' />
          </div>
        </div>
      </Panel>
      <Panel title="Informations" valid={parking.size} subtitle={informationsSubtitle(parking)}>
        <ParkingInputs.VehicleInput parking={parking} edit onEdition={onEdition} />
      </Panel>
      <Panel title="Prix"
        valid={((parking.allow_subscription && parking.monthly_price > 0) || !parking.allow_subscription) && ((parking.allow_one_shot && parseInt(parking.daily_price) > 0) || !parking.allow_one_shot)}
        subtitle={<>
          {(parking.daily_price && parking.daily_price !== '0' ? <StyledSpan>{parking.daily_price}€/jour</StyledSpan> : null)}
          {(parking.weekly_price && parking.weekly_price !== '0' ? <StyledSpan>{parking.weekly_price}€/semaine</StyledSpan> : null)}
          {(parking.monthlyPrice && parking.monthlyPrice !== '0' ? <StyledSpan>{parking.monthlyPrice}€/mois</StyledSpan> : null)}
        </>}>
        <div className="row">
          <div className="offset-lg-1 col-lg-6 col-12 order-2 order-lg-1">
            <ParkingInputs.PriceInput parking={parking} edit onEdition={onEdition} />
          </div>
          <div className="col-lg-5 col-12 mt-4 mt-sm-0 order-1 order-lg-2 mb-2">
            <ParkingInputs.ToolTip border type='price' />
          </div>
        </div>
      </Panel>
      <Panel title="Photos" valid={parking.images.length > 0} subtitle={getPhotoSubtitle(parking)}>
        <ParkingInputs.ImagesInput parking={parking} edit onEdition={onEdition} />
      </Panel>
    </div>
  </div>
)

const Panel = ({ title, subtitle, children, valid, ...rest }) => (
  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className="col-12 col-lg-4">
        {valid ?
          <CheckCircleIcon style={{ color: COLORS.green }} className="mr-3" />
          : <RadioButtonUnchecked color='disabled' className="mr-3" />
        }
        {title}
      </Typography>
      <Typography className="d-none d-lg-block" color="textSecondary" style={{ maxWidth: 400 }} component="p" noWrap>
        <Subtitle content={subtitle} />
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails style={{ justifyContent: "center", flexDirection: 'column' }}>
      {children}
    </ExpansionPanelDetails>
  </ExpansionPanel>
)

const informationsSubtitle = (parking) => {
  let subtitle = ''
  if (parking.size) {
    switch (parking.size) {
      case 'MOTORBIKE':
        subtitle += 'Taille: moto';
        break;
      case 'SMALL':
        subtitle += 'Taille: petit';
        break;
      case 'MEDIUM':
        subtitle += 'Taille: moyen';
        break;
      case 'LARGE':
        subtitle += 'Taille: grand';
        break;
      default:
        subtitle = ''
    }
  }
  return subtitle
}

const getPhotoSubtitle = (parking) => {
  const { images } = parking
  if (!images) {
    return 'Aucune photo enregistrée'
  } else if (images.length === 1) {
    return '1 photo enregistrée'
  } else if (images.length > 1) {
    return `${images.length} photos enregistrées`
  }
  return ''
}

const StyledSpan = styled.span`
margin-right: 14px;
`

const Subtitle = props => <span>{props.content}</span>


export default ParkingEdition
