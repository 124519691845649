import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconsInfo from 'src/components/IconsInfo'
import VehicleApi from 'src/api/vehicle'
import ButtonBase from '@material-ui/core/ButtonBase';
import AddIcon from '@material-ui/icons/Add';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from '@material-ui/core/Fab';
import { Consumer } from 'src/services/store';

import VehicleMenu from './VehicleMenu'
import VehicleInputSection from './VehicleInputSection'

function VehicleModal({ fullScreen, data }) {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([])
  const [editVehicle, setEditVehicle] = useState(null)
  const [createMode, setCreateMode] = useState(false)

  async function getVehicleList() {
    const res = await VehicleApi.getVehicleList()
    if (!res.error) {
      const { handleChangeUser, user } = data
      user.vehicle = res.find(e => e.selected)
      handleChangeUser(user)
      setList(res)
    }
  }

  useEffect(() => {
    getVehicleList()
  }, [])

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCloseCreationSection() {
    setEditVehicle(null)
    setCreateMode(false)
  }

  async function selectVehicle(id) {
    const res = await VehicleApi.selectVehicle({ vehicle_id: id })
    if (!res.error) {
      getVehicleList()
    }
  }

  async function deleteVehicle({ vehicle_id }) {
    const res = await VehicleApi.deleteVehicle({ vehicle_id })
    if (!res.error) {
      getVehicleList()
    }
  }

  return (
    <div>
      <Button className="no-outline" style={{ textTransform: 'none' }} color="primary" onClick={handleClickOpen}>
        {list.length === 0 ? 'Ajouter' : 'Changer'}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>{"Mes véhicules"}</DialogTitle>
        <DialogContent style={{ width: 600, maxWidth: '100%' }}>
          <DialogContentText id="form-dialog-title">Véhicule sélectionné</DialogContentText>
          {list.length === 0 && <DialogContentText>
            Vous n'avez pas renseigné de véhicule pour le moment.
            Ajoutez-en un afin de profiter pleinement du service Parkmatch!
          </DialogContentText>}
          {list.map(elem => (
            <div key={elem.vehicle_id.toString()} style={{ position: 'relative', display: 'inline-block'}}>
              <ButtonBase
                className="no-outline"
                onClick={() => selectVehicle(elem.vehicle_id)}>
                <IconsInfo
                  edit
                  name={elem.size}
                  numberplate={elem.numberplate === "IMMATRICULATION" ? "Ma voiture" : elem.numberplate}
                  isSelect={elem.selected}
                />
              </ButtonBase>
              <VehicleMenu
                setEditVehicle={() => setEditVehicle(elem)}
                deleteVehicle={() => deleteVehicle(elem)}
              />
            </div>
          ))}
          {!(editVehicle || createMode) &&
            <Fab color="primary"
              className="no-outline"
              style={{ color: 'white', margin: 15 }}
              onClick={() => setCreateMode(true)}>
              <AddIcon />
            </Fab>}
        </DialogContent>
        {(editVehicle || createMode) &&
          <VehicleInputSection
            edit={editVehicle}
            handleClose={handleCloseCreationSection}
            getVehicleList={getVehicleList}
          />}
        {!(editVehicle || createMode) && <DialogActions>
          <Button onClick={handleClose} color="primary" className="no-outline">
            Terminer
          </Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}

export default withMobileDialog()((props) => (
  <Consumer>
    {ctx => <VehicleModal {...props} data={ctx} />}
  </Consumer>
))
