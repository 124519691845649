import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import PublicPage from 'src/templates/PublicPage';
import { Helmet } from 'react-helmet'

import { Consumer } from 'src/services/store';
import LandingSection from './containers/LandingSection';
import NumbersSections from './containers/NumbersSection';
import TenantSection from './containers/TenantSection';
import TeamSection from './containers/TeamSection';
import MediasSection from './containers/MediasSection';
import UsersSection from './containers/UsersSection';
import InformationsSection from './containers/InformationsSection';

import { COLORS } from 'src/stylesheet/colors'

const TENANT_SECTION = {
  title: "Parkmatch: de la recherche de places jusqu’au paiement des locations",
  sections: [
    { title: "Je trouve un parking au prix souhaité" },
    { title: "Je récupère les accès au parking" },
    { title: "Je gère mes paiements en ligne" },
  ]
}

const OWNER_SECTION = {
  title: "Louez votre parking en toute sérénité",
  sections: [
    { title: "Plus simple", subtitle: "Trouvez un locataire rapidement, pour une courte ou longue durée et diminuez les contraintes administratives." },
    { title: "Paiements sécurisés", subtitle: "Nous sécurisons le paiement des locations. Versez vos revenus locatifs vers votre compte bancaire quand vous le souhaitez." },
  ]
}

class LandingPageExit extends React.Component {

  render() {
    return (
      <PublicPage fullHeight
        metaTitle="Parkmatch : Location de parking, garage ou box entre particuliers"
        metaDescription="Des milliers de parkings, garages et box partout en France: location tous types de durée, contrat automatique, paiement sécurisé.">
        <LandingSection />
        <Helmet>
          <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Corporation",
          "name": "Parkmatch",
          "legalName": "Parkmatch SAS",
          "url": "https://www.parkmatch.eu",
          "logo": "https://static.parkmatch.eu/assets/img/logo-Parkmatch.png",
          "description": "Des milliers de parkings, garages et box partout en France: location tous types de durée, contrat automatique, paiement sécurisé.",
          "telephone": "+33769035897",
          "address": {
          	"@type": "PostalAddress",
            "addressLocality": "Lyon",
            "addressCountry": "FR",
            "postalCode": "69003",
            "streetAddress": "20 boulevard Eugène Deruelle"
          },
          "founders":
          [
            {
              "@type": "Person",
              "name": "Romain Fournier"
            },
            {
              "@type": "Person",
              "name": "Clément Favier"
            }
          ],
          "sameAs": [
                "https://www.facebook.com/parkmatchapp/",
                "https://twitter.com/parkmatch_app",
                "https://www.linkedin.com/company-beta/15245527/",
                "https://www.youtube.com/channel/UC4bYQseej25IUK8kMfj04cg",
                "https://plus.google.com/111899968218317853101",
                "http://www.viadeo.com/fr/company/parkmatch",
                "https://www.instagram.com/parkmatch/",
                "https://www.societe.com/societe/parkmatch-823709902.html",
                "https://www.crunchbase.com/organization/parkmatch#section-overview"
              ]
        }
    `}</script>
        </Helmet>
      </PublicPage>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PublicRoute component={LandingPageExit} {...props} data={ctx} ref={ref} />}
  </Consumer>
));
