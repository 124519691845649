import React from 'react';
import styled from 'styled-components'

const LandingImage = styled.img`

  height: 170px;
  align-self: center;
  padding-top: 1em;
  padding-bottom: 1em;

  &.car-illustration {
    margin-top: 2em;
    margin-bottom: 1em;
    height: 120px;
  }

  @media (max-width: 1200px) {
    height: 130px;
    align-self: center;
    padding-top: 0.9em;
    padding-bottom: 0.9em;

    &.car-illustration {
      margin-top: 1.5em;
      margin-bottom: 1em;
      height: 90px;
    }

  }

`;


const LandingIllustration = ({ className, src, alt }) => (
  <LandingImage className={className} src={src} alt={alt} />
)

export default LandingIllustration
