import React from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';
import IconSvg from 'src/svg';

const Icons = props => (
  <div className="d-flex align-items-center">
    <span className="mr-1">Taille</span>
    { props.name === 'SMALL' && <IconSvg color={COLORS.iconsGray} name="SMALL" width={35} /> }
    { props.name === 'MEDIUM' && <IconSvg color={COLORS.iconsGray} name="MEDIUM" width={35} /> }
    { props.name === 'LARGE' && <IconSvg color={COLORS.iconsGray} name="LARGE" width={35} /> }
  </div>
)

Icons.propTypes = {
  name: PropTypes.string,
}

export default Icons;
