import React from 'react';
import IconsDevice from 'src/components/IconsDevice';
import { COLORS } from 'src/stylesheet/colors';

const CardDownloadApp = () => (
  <div className='row'>
    {/*<div className="d-flex flex-row justify-content-around align-items-center m-4 p-2 text-center border rounded bg-white w-100">*/}
    {/*  <div style={{ fontSize: 20 }}>Téléchargez notre <span style={{ color: COLORS.green }}>application</span> ! </div>*/}
    {/*  <a style={{ color: 'black' }} href="https://itunes.apple.com/us/app/parkmatch/id1327371269" target="_blank" rel="noopener noreferrer">*/}
    {/*    <IconsDevice device="iOS" logoSize={25} fontSize={18} />*/}
    {/*  </a>*/}
    {/*  <a style={{ color: 'black', margin: '6px' }} href="https://play.google.com/store/apps/details?id=eu.parkmatch.parkmatch" target="_blank" rel="noopener noreferrer">*/}
    {/*    <IconsDevice device="Android" logoSize={25} fontSize={18} />*/}
    {/*  </a>*/}
    {/*</div>*/}
  </div>
)

export default CardDownloadApp;
