import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import ModalitesText from './modalites'
import PublicPage from 'src/templates/PublicPage';

class ModalitesPage extends React.Component {

  render() {
    return (
      <PublicPage
        metaTitle="Modalités de référencement, déréférencement et classement"
        metaDescription="Modalités de référencement, déréférencement et classement sur Parkmatch.">
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row py-5 gradient-header">
              <div className="col-md-8 py-4 offset-md-2 text-center title-faq">
                <h1>Modalités de référencement, déréférencement et classement sur Parkmatch</h1>
              </div>
            </div>
            <div className="row">
              <ModalitesText />
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={ModalitesPage} />
