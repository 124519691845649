import React from 'react'
import { FaCheckCircle, FaRegHourglass, FaSearch, FaTimes, FaFileAlt } from 'react-icons/fa';
import { COLORS } from 'src/stylesheet/colors'

const DOCUMENT_STATUS = {
  'NONE': {
    icon: <FaFileAlt color={COLORS.mediumGreen} />,
    text: 'Non renseigné',
  },
  'WAITING': {
    icon: <FaRegHourglass color={COLORS.orange} />,
    text: 'En attente des autres documents'
  },
  'ANALYZING': {
    icon: <FaSearch color={COLORS.orange} />,
    text: "En cours d'analyse",
  },
  'ACCEPTED': {
    icon: <FaCheckCircle color={COLORS.green} />,
    text: 'Accepté',
  },
  'FRAUD': {
    icon: <FaTimes color={COLORS.red} />,
    text: 'Document frauduleux',
  },
  'UNREADABLE': {
    icon: <FaTimes color={COLORS.red} />,
    text: 'Document illisible'
  },
  'EXPIRED': {
    icon: <FaTimes color={COLORS.red} />,
    text: 'Le document à éxpiré'
  },
  'WRONG_TYPE': {
    icon: <FaTimes color={COLORS.red} />,
    text: "Le document n'est pas du bon type"
  },
  'WRONG_PERSON': {
    icon: <FaTimes color={COLORS.red} />,
    text: 'Le nom présent sur le document ne correspond pas au nom du compte'
  },
  'IBAN_INVALID': {
    icon: <FaTimes color={COLORS.red} />,
    text: "L'IBAN saisi semble invalide ou ne correspond pas au document envoyé."
  },
}

const DOC_TYPES = ["IDENTITY", "TAX_SHEET", "IBAN", "PASSPORT", "FOREIGN_PASSPORT", "RESIDENCE_PERMIT", "KBIS", "DRIVING_LICENSE", "STATUTS", "SELFIE"]

const PHYSICAL_PERSON = ["IBAN", "IDENTITY", "TAX_SHEET", "PASSPORT", "RESIDENCE_PERMIT", "DRIVING_LICENSE"]

const TYPES_INFO = {
  IDENTITY: {
    title: "Carte d'identité",
    desc: '',
  },
  TAX_SHEET: {
    title: "Avis d'imposition",
    desc: '',
  },
  IBAN: {
    title: 'IBAN',
    desc: 'Afin de procéder aux virements, vous devez enregistrer votre IBAN.',
  },
  PASSPORT: {
    title: 'Passeport',
    desc: '',
  },
  FOREIGN_PASSPORT: {
    title: "Passeport étranger",
    desc: '',
  },
  RESIDENCE_PERMIT: {
    title: "Carte de séjour",
    desc: '',
  },
  KBIS: {
    title: "Kbis",
    desc: '',
  },
  DRIVING_LICENSE: {
    title: "Permis de conduire",
    desc: '',
  },
  STATUTS: {
    title: "Statuts",
    desc: '',
  },
  SELFIE: {
    title: "Selfie",
    desc: '',
  },
  RESIDENCY: {
    title: 'Justificatif de domicile',
    desc: '',
  },
}

export { DOCUMENT_STATUS, DOC_TYPES, TYPES_INFO, PHYSICAL_PERSON }