
import AddResume from 'src/views/AddParkingQuickPage/containers/AddResume';

import ParkingInputs from 'src/components/ParkingInputs'

const STEP_COMPONENT = {
  1: ParkingInputs.AddressInput,
  2: ParkingInputs.AvailabilityInput,
  3: ParkingInputs.PriceInput,
  4: ParkingInputs.DescriptionInput,
  5: ParkingInputs.VehicleInput,
  6: AddResume,
}

const STEP_TITLE = {
  1: 'Adresse',
  2: 'Disponibilité',
  3: 'Prix',
  4: 'Description',
  5: 'Informations',
  6: 'Résumé',
}

const STEP_TOOLTIP = {
  1: { title: "Bienvenue !", text: "Enregistrez votre parking sur Parkmatch: contrat automatique et paiements intégrés.<br/>La location de parking <strong>zéro contraintes ! </strong>😍<br /><br /><small><strong>Professionnel ?</strong> Le dépôt d'annonce sur notre plateforme est interdit aux professionnels. Nous vous rappelons que tout professionnel qui se présente comme un consommateur ou un non-professionnel est passible de sanctions pouvant aller jusqu'à deux ans d'emprisonnement et 300 000 euros d'amende selon l'<a href=\"https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032227120/\" target=\"_blank\">Article L. 132-2 du Code de la consommation</a>.</small>" },
  2: { title: "Restez à jour !", text: "Lorsque votre parking est disponible, vous pouvez <strong>recevoir des demandes de location</strong>. Veillez à garder vos disponibiliés à jour." },
  3: { title: "Choisissez votre prix !", text: "Avec Parkmatch, la gestion des paiements est intégrée.<br/>En un clic, demandez le virement de vos revenus vers votre compte bancaire quand vous le souhaitez !" },
  4: { title: "Soyez attractif !", text: "Votre description aidera votre futur locataire à se projeter et à choisir votre parking ! <br />" },
  5: { title: null, text: null },
  6: { title: null, text: null },
}

export { STEP_COMPONENT };
export { STEP_TITLE };
export { STEP_TOOLTIP };
