import React from 'react';
import { withRouter } from 'react-router-dom'
import GLOBAL from 'src/stylesheet';

import { COLORS } from 'src/stylesheet/colors'
import Car from 'public/assets/lottie/car.json'
import { Helmet } from 'react-helmet'

import PublicPage from 'src/templates/PublicPage';
import Button from 'src/components/Button';
import { captureException as Sentry } from '@sentry/browser'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Car,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class NotFound extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.staticContext) {
      this.props.staticContext.notFound = true
    }
  }

  componentDidMount() {
    // Test to debug 404 errors on articles
    Sentry(new Error("Page not found (404)"))
  }

  render() {
    const { isLoading } = this.props
    return (
      <PublicPage isLoading={isLoading}
        metaTitle="Page non trouvée - Erreur 404"
        metaDescription="Il semblerait que la page que vous cherchez n'existe pas."
      >
        <Helmet>
          <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.5.9/lottie.min.js" />
        </Helmet>
        <div style={{ backgroundImage: GLOBAL.gradient }} className="row vh-100">
          <div className="col-12 text-center">
            {/* <Lottie
              options={defaultOptions}
              height={400}
              width={400}
            /> */}
            <div style={{ width: '300px', height: '300px' }}
              className="lottie"
              //data-animation-path='https://static.parkmatch.eu/assets/lottie/car.json'
              data-anim-loop="true"
              data-anim-autoplay="true"
              data-name="car"></div>

            <h1 style={{ color: COLORS.lightWhite, marginBottom: 32 }}>Erreur 404, la page recherchée n'existe pas!</h1>
            <Button text="Retour au garage" onClick={() => this.props.history.push('/search')} />
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default withRouter(NotFound);
