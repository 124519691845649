import React from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components'
import { DOCUMENT_STATUS } from './DocumentsService'


const DocumentStatus = ({ doc }) => (
    <>
        {doc &&
            <List>
                {console.log(doc)}
                <ListItem>
                    <ListItemAvatar>
                        <StyledAvatar style={{ fontSize: '1.3rem' }}>
                            {DOCUMENT_STATUS[doc.status].icon}
                        </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText style={{ fontSize: '1rem' }}
                        primary={DOCUMENT_STATUS[doc.status].text}
                    />
                </ListItem>
            </List>
        }
    </>
)

export default DocumentStatus

const StyledAvatar = styled(Avatar)`
  && {
        background-color: transparent;
    }
  `