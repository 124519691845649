import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from 'src/components/Tooltip'
import TextArea from 'src/components/TextArea'



function CommentModal({ saveComment }) {
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState('')


    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        saveComment(comment)
        setOpen(false);
    }

    function handleCommentChange(e) {
        setComment(e.target.value)
    }


    return (
        <div>
            <Button className="no-outline" style={{ textTransform: 'none' }} color="primary" onClick={handleClickOpen}>
                {comment.length === 0 ? 'Ajouter' : 'Changer'}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Commentaire au propriétaire"}</DialogTitle>
                <DialogContent style={{ width: 600, maxWidth: '100%' }}>
                    <Tooltip text='Donnez toutes les indications nécessaires au propriétaire pour pouvoir accepter ou non votre demande de réservation.' />
                    <TextArea value={comment}
                        onChange={(e) => handleCommentChange(e)}
                        placehandler='Votre commentaire'
                        width='100%' />
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className="no-outline">
                            Terminer
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withMobileDialog()((props) => (
    <CommentModal {...props} />
))


