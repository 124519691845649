import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import base64 from 'base-64';
import { Link } from 'react-router-dom';
import PublicPage from 'src/templates/PublicPage';
import { withRouter } from 'react-router-dom'

import ToolTips from './components/toolTips';
import Button from 'src/components/Button';
import Api from 'src/services/api';
import { Consumer } from 'src/services/store';
import fetchApi from 'src/services/fetchApi';
import Analytics from 'src/services/Analytics'

import { validateEmail, checkPassword, checkNames, checkError, checkErrorServer } from 'src/utils/checkAuth';

import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';
import StyledButton from 'src/components/StyledButton'
import './styles.css';
import styles from './styles';
import { sentryCaptureMessage } from 'src/utils/utils'

class RegisterPage extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    error: false,
    message: '',
    modalIsOpen: false,
    isLoading: false,
  }

  checkRegister = () => {
    const { firstName, lastName, email, password } = this.state;
    this.closeModal();
    if (checkNames(firstName) && checkNames(lastName) && validateEmail(email) && checkPassword(password)) {
      this.setState({ error: false });
      this.register();
    } else {
      const message = checkError(firstName, lastName, password, email)
      this.setState({ error: true, message: message });
      sentryCaptureMessage(message, {
        level: 'warning',
        tags: { type: 'REGISTER', label: 'WRONG_REGISTER_INFO' },
        extra: { error: message, first_name: firstName, last_name: lastName, email },
      })
    }
  }

  register = () => {
    const { firstName, lastName, email, password } = this.state;
    const { location, checkout, onRegister } = this.props;
    this.setState({ isLoading: true });
    fetchApi('/user', 'POST', { first_name: firstName, last_name: lastName, email, password, notification_token: null }).then((res) => {
      if (res.error) {
        const message = checkErrorServer(res.error.type)
        this.setState({ error: true, message: message, isLoading: false });
        sentryCaptureMessage(message, {
          level: 'warning',
          tags: { type: 'REGISTER', label: 'WRONG_REGISTER_INFO' },
          extra: { error: message, first_name: firstName, last_name: lastName, email },
        })
      } else {
        const userCredential = base64.encode(`${email}:${password}`);
        localStorage.setItem('token', res.token);
        localStorage.setItem('auth', userCredential);
        localStorage.setItem('user', res);
        this.props.data.handleChangeUser(res);
        this.props.data.handleChangeLogged();
        Api.parkmatch.setAccessToken(res.token);
        Analytics.sendCompleteRegistration()
        this.setState({ isLoading: false });
        if (checkout) {
          onRegister()
        } else if (location.state && location.state.add) {
          this.props.history.push({ pathname: '/register/phone', state: { add: true, phone: true } });
        } else {
          this.props.history.push({ pathname: '/register/phone', state: { phone: true } });
        }
      }
    })
  }

  isAddingParking = () => {
    const { location } = this.props;
    if (location.state && location.state.add) {
      this.props.history.push({ pathname: '/login', state: { add: true } });
    } else {
      this.props.history.push('/login');
    }
  }

  handleOnChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  renderForm = () => {
    const { message, firstName, lastName, email, password, error, modalIsOpen, isLoading } = this.state;
    return (
      <>
        <div className="form-row my-3">
          <div className="col">
            <input value={firstName} onChange={(e) => this.handleOnChange(e, 'firstName')} type="text" className="form-control" id="firstName" aria-describedby="emailHelp" placeholder="Prénom" />
          </div>
          <div className="col">
            <input value={lastName} onChange={(e) => this.handleOnChange(e, 'lastName')} type="text" className="form-control" id="lastName" aria-describedby="emailHelp" placeholder="Nom" />
          </div>
        </div>
        <div className="form-group">
          <input value={email} onChange={(e) => this.handleOnChange(e, 'email')} type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Adresse email" />
        </div>
        <div className="form-group">
          <input value={password} onChange={(e) => this.handleOnChange(e, 'password')} type="password" className="form-control" id="password" placeholder="Mot de passe" />
        </div>
        <StyledButton onClick={() => this.setState({ modalIsOpen: true })} isLoading={isLoading}>S'enregistrer</StyledButton>
        {error && <div className="text-danger mt-3">{message}</div>}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={styles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div onClick={this.closeModal} style={{ position: 'absolute', top: 5, right: 5 }}>
            <FaTimes size={20} color="#979797" />
          </div>
          <div className="m-2">
            Pour créer un compte sur Parkmatch, vous devez accepter les
          <Link to="/cgu" target="_blank">
              <span style={{ color: '#05b65c', textDecoration: 'underline' }}> CGU </span>
            </Link>
            et la
          <Link to="/informations-legales" target="_blank">
              <span style={{ color: '#05b65c', textDecoration: 'underline' }}> politique de confidentialité des données</span>
            </Link>
            .
        </div>
          <div className="text-center mt-2">
            <Button text="J'accepte" onClick={() => this.checkRegister()} isLoading={isLoading} />
          </div>
        </Modal>
      </>
    )
  }

  render() {
    const { location, checkout } = this.props;
    const RegisterForm = this.renderForm()
    if (checkout) {
      return RegisterForm
    }
    return (
      <PublicPage fullHeight footer={false}
        metaTitle="Enregistrer un compte"
        metaDescription="Créez un compte pour accéder au service Parkmatch.">
        <div className="row vh-100" style={{ backgroundImage: GLOBAL.gradient }}>
          <div className="col-sm-12 my-auto">
            <form className="card card-block mx-auto bg-light p-4 shadow blockForm">
              <div style={{ color: COLORS.green, fontSize: 24, textAlign: 'center', marginBottom: 15 }}>Création de compte</div>
              {location.state && location.state.add && <ToolTips />}
              {/* <div className="form-group">
                <FacebookLogin
                  appId="322540114892623"
                  autoLoad={true}
                  fields="name,email,picture"
                  onClick={() => console.log("toto")}
                  textButton="S'enregistrer avec Facebook"
                  icon={ <div style={{ width: 20, padding: 5, display: 'inline' }}><FaFacebookF size="20" /></div>}
                  cssClass="facebook"
                />
                <GoogleLogin
                  clientId="975945960032-j987p3mqq446fmj34b8j03r0lq5t66gu.apps.googleusercontent.com"
                  buttonText="S'enregistrer avec Google"
                  className="google"
                  onSuccess={(res) => console.log(res)}
                  onFailure={(res) => console.log(res)}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', height: 11, borderBottom: '1px solid #cecece' }} />
                <div style={{ color: '#05b65c', marginLeft: 10, marginRight: 10 }}>OU</div>
                <div style={{ width: '100%', height: 11, borderBottom: '1px solid #cecece' }} />
            </div> */}
              {RegisterForm}
              <div style={{ width: '100%', height: 10, marginTop: 30, borderBottom: '1px solid #cecece' }} />
              <div className="d-flex align-items-center justify-content-center mt-2">
                <div style={{ width: 150 }}>Vous avez déjà un compte?</div>
                <button onClick={() => this.isAddingParking()} type="button" className="btn btn-light border">Se connecter</button>
              </div>
            </form>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PublicRoute component={withRouter(RegisterPage)} {...props} data={ctx} ref={ref} />}
  </Consumer>
));
