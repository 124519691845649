import React from 'react';
import { withRouter } from 'react-router-dom'

import AlertApi from 'src/api/alert';
import SearchApi from 'src/api/search';
import queryString from 'query-string'
import moment from 'moment'

const { Provider, Consumer } = React.createContext()

// Provider for SearchPage
export const RENTAL_MODE = {
  monthly: 'monthly',
  oneshot: 'one_shot',
}

class SearchProvider extends React.Component {
  _searchParkings = async ({ location = null, keepMapData }) => {
    const { diameter, startDate, endDate, rentalMode, center } = this.state
    const latitude = location ? location.latitude : center.latitude
    const longitude = location ? location.longitude : center.longitude
    const res = await SearchApi.searchParkings({
      latitude: latitude,
      longitude: longitude,
      diameter,
      startDate: startDate && startDate.unix(),
      endDate: endDate && endDate.unix(),
      type: rentalMode,
    })
    // console.log(location, diameter, rentalMode, startDate, endDate, res)
    if (res && !res.error) {
      const parkingArray = res.filter(e => e.status === "available")
      if (!keepMapData) {
        this.state.setMapCenter({ latitude, longitude })
        this.state.setSearchPosition({ latitude, longitude })
      }
      this.updateUrlParams({ latitude, longitude })
      const parkingPageParams = this.getParkingPageParams({ pStartDate: startDate, pEndDate: endDate, pRentalMode: rentalMode })
      this.setState({ parkings: parkingArray, parkingPageParams: parkingPageParams })
      return { status: true }
    }
    return { error: true }
  }

  updateUrlParams = (location) => {
    const { startDate, endDate, rentalMode } = this.state
    let searchParams = { rentalMode }
    searchParams = { ...searchParams, ...(location && { latitude: location.latitude, longitude: location.longitude, z: this.state.z }) }
    searchParams = { ...searchParams, ...((startDate && endDate) && { startDate: startDate.unix(), endDate: endDate.unix() }) }
    this.props.history.replace({ search: queryString.stringify(searchParams) })
  }

  defaultValuesFromParams = () => {
    const lSearch = typeof window !== 'undefined' ? window.location.search : null
    const defaultCenter = { latitude: 48.85661400000001, longitude: 2.3522219000000177 }

    if (lSearch && lSearch !== "") {
      let { startDate, endDate, rentalMode, longitude, latitude, z, location } = queryString.parse(lSearch)
      startDate = (moment.unix(startDate)._isValid && startDate !== '') ? moment.unix(startDate) : null
      endDate = (moment.unix(endDate)._isValid && startDate !== '') ? moment.unix(endDate) : null
      rentalMode = rentalMode ? rentalMode : RENTAL_MODE.monthly
      let center = defaultCenter
      if (longitude && latitude) {
        try {
          center = { longitude: parseFloat(longitude), latitude: parseFloat(latitude) }
        } catch { }
      }
      if (location) {
        try {
          center = { latitude: parseFloat(location.split(',')[0]), longitude: parseFloat(location.split(',')[1]) }
        } catch { }
      }
      return { startDate, endDate, rentalMode, center, z: z ? parseInt(z) : 14 }
    }
    else {
      return { startDate: null, endDate: null, rentalMode: RENTAL_MODE.monthly, z: 14, center: defaultCenter, searchPosition: defaultCenter }
    }
  }

  getParkingPageParams = ({ pStartDate, pEndDate, pRentalMode }) => {
    const startDate = pStartDate ? pStartDate : this.state.startDate
    const endDate = pEndDate ? pEndDate : this.state.endDate
    const rentalMode = pRentalMode ? pRentalMode : this.state.rentalMode

    return '?' + queryString.stringify({ rentalMode, ...((startDate && endDate) && { startDate: startDate.unix(), endDate: endDate.unix() }) })
  }

  updateParkingPageParams = ({ pStartDate, pEndDate, pRentalMode }) => {
    const newParkingPageParams = this.getParkingPageParams({ pStartDate, pEndDate, pRentalMode })
    this.props.history.replace({ search: newParkingPageParams })
  }

  state = {
    /* Map center, and setter */
    center: this.defaultValuesFromParams().center,
    setMapCenter: (location) => {
      this.setState({ center: { ...location } })
    },
    /* End - Map center */
    z: this.defaultValuesFromParams().z,
    searchPosition: this.defaultValuesFromParams().searchPosition,
    setSearchPosition: (location) => {
      this.setState({ searchPosition: location })
    },
    /* Alert List and Alert markers List */
    alertList: [],
    alertsMarkers: [],
    getAlertsList: async () => {
      const res = await AlertApi.getAlertsList()
      if (res && !res.error) {
        this.setState({ alertList: res })
        return true
      }
      return false
    },
    addAlertMarker: (location) => {
      this.setState({ alertsMarkers: [location] })
    },
    /* End - Alerts */
    parkings: [],
    searchParkings: this._searchParkings,
    startDate: this.defaultValuesFromParams().startDate,
    setStartDate: (startDate) => this.setState({ startDate }),
    endDate: this.defaultValuesFromParams().endDate,
    setEndDate: (endDate) => this.setState({ endDate }),
    diameter: 10000,
    setDiameter: (center, zoom) => {
      const diameter = 156543.03392 * Math.cos(center.lat * Math.PI / 180) / Math.pow(2, zoom)
      this.setState({ diameter: diameter * 1000, z: zoom })
    },
    rentalMode: this.defaultValuesFromParams().rentalMode,
    setRentalMode: (event, value) => this.setState({ rentalMode: value === 0 ? RENTAL_MODE.monthly : RENTAL_MODE.oneshot }),
    parkingPageParams: '',
    updateParkingPageParams: ({ pStartDate, pEndDate, pRentalMode } = {}) => this.updateParkingPageParams({ pStartDate, pEndDate, pRentalMode }),
    selectedParking: null,
    setSelectedParking: (parkingData) => this.setState({ selectedParking: { ...parkingData } }),
    isParkingAvailable: true,
    setIsParkingAvailable: (value) => this.setState({ isParkingAvailable: value })
  }

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

export default withRouter(SearchProvider);
export { Consumer };
