import React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import StyledButton from 'src/components/StyledButton';
import { GOOGLE_API_KEY } from 'src/environement';
import AlertApi from 'src/api/alert';
import AlertIcon from 'src/components/AlertIcon';
import { Consumer } from 'src/services/store/SearchStore';

const createMapOptions = (maps) => ({
  clickableIcons: false,
  disableDefaultUI: true,
})

class AlertPanelContent extends React.Component {
  state = {
    isLoading: false,
    location: null,
  }

  componentWillMount() {
    const { location } = this.props
    this.location = location
  }

  createNewAlert = async () => {
    const { switchDrawerState, data } = this.props;
    const { center } = data
    if (center && center.latitude && center.longitude) {
      this.setState({ isLoading: true })
      const res = await AlertApi.createAlert(this.location)
      this.setState({ isLoading: false })
      if (res && !res.error) {
        data.getAlertsList()
        switchDrawerState()
      }
    }
  }

  changeMapLocation = ({ center }) => {
    this.location = {
      latitude: center.lat,
      longitude: center.lng,
    }
  }

  render() {
    const { data: { center }, switchDrawerState, responsive } = this.props;
    const { isLoading } = this.state;
    return (
      <StyledPaper responsive={responsive}>
        <CloseButton className="no-outline" style={{ position: 'absolute' }} onClick={switchDrawerState} aria-label="Delete">
          <CloseIcon fontSize="large" />
        </CloseButton>
        <StyledDiv>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={{ lat: center.latitude, lng: center.longitude }}
            zoom={17}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onChange={this.changeMapLocation}
          />
          <StyledAlertIcon />
          <StyledContainer>
            <StyledButton style={{ left: '-50%' }}
              onClick={this.createNewAlert} isLoading={isLoading}>
              Créer une alerte
            </StyledButton>
          </StyledContainer>
        </StyledDiv>
      </StyledPaper>
    );
  }
}

const CloseButton = styled(IconButton)`
  z-index: 5;
  top: 7px;
  right: 7px;
`

const StyledAlertIcon = styled(AlertIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const StyledContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
`

const StyledPaper = styled(Paper)`
  height: 100%;
  position: relative;
`

const StyledDiv = styled.div`
  height: 100%;
`

export default props => (
  <Consumer>
    {ctx => <AlertPanelContent {...props} data={ctx} />}
  </Consumer>
);
