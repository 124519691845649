import React from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet } from 'react-helmet'

import { COLORS } from 'src/stylesheet/colors'



export default ({ article, locationTitle }) => {
    let FaqElements = []

    // TODO
    // const sellParkingQ = []
    // const sellParkingA = []
    // const sellBoxQ = []
    // const sellBoxA = []

    if (article.acf.rent_parking && article.acf.rent_parking !== '' && article.acf.rent_parking !== 'undefined') {
        const rentParkingQ = [
            "Combien coûte un parking à louer à " + locationTitle + " ?",
            "Quel est le prix moyen d'une location de parking à " + locationTitle + " ?",
            "Quel est le prix mensuel d'un parking à louer à " + locationTitle + " ?",
            "Quel est le prix d'une location de parking à " + locationTitle + " ?",
            "Combien coûte la location d'un parking à " + locationTitle + " ?"
        ]
        const rentParkingA = [
            "Louer un parking à " + locationTitle + " coûte en moyenne " + article.acf.rent_parking + " euros/mois.",
            "Le prix moyen d'un parking à louer à " + locationTitle + " est de " + article.acf.rent_parking + " euros/mois.",
        ]
        // Get a random element that will change only if the estimate price changes or if the page title changes.
        // Below formula will make pseudo random faq sets for each pages
        FaqElements.push({
            question: rentParkingQ[(locationTitle.length + article.acf.rent_parking) % rentParkingQ.length],
            answer: rentParkingA[(locationTitle.length + article.acf.rent_parking) % rentParkingA.length],
        })
    }
    if (article.acf.rent_box && article.acf.rent_box !== '' && article.acf.rent_box !== 'undefined') {
        const rentBoxQ = [
            "Combien coûte un garage ou un box à louer à " + locationTitle + " ?",
            "Quel est le prix moyen d'une location de box ou garage à " + locationTitle + " ?",
            "Quel est le prix mensuel d'un garage ou box à louer à " + locationTitle + " ?",
            "Quel est le prix d'une location de garage ou box à " + locationTitle + " ?",
            "Combien coûte la location d'un box ou garage à " + locationTitle + " ?"
        ]
        const rentBoxA = [
            "Louer un place de parking fermée à " + locationTitle + " coûte en moyenne " + article.acf.rent_box + " euros/mois.",
            "La location de garage ou box à " + locationTitle + " coûte en moyenne " + article.acf.rent_box + " euros/mois.",
            "Le prix moyen d'un garage ou box à louer à " + locationTitle + " est de " + article.acf.rent_box + " euros/mois.",
        ]
        FaqElements.push({
            question: rentBoxQ[(locationTitle.length + article.acf.rent_box) % rentBoxQ.length],
            answer: rentBoxA[(locationTitle.length + article.acf.rent_box) % rentBoxA.length],
        })
    }
    if (FaqElements.length === 0) { return <></> }

    return (
        <div className="col-12 col-sm-10 offset-sm-1 my-3">
            {renderStructuredData({ FaqElements })}
            {FaqElements.map((item, index) => {
                return (
                    <ExpansionPanel key={index} defaultExpanded={true} square={false}
                        className="shadow">
                        <ExpansionPanelSummary style={{ backgroundColor: COLORS.realLightGreen, fontSize: '1.2rem' }}
                            expandIcon={<ExpandMoreIcon />}>
                            {item.question}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {item.answer}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })}
        </div>)
}


const renderStructuredData = ({ FaqElements }) => (
    <Helmet>
        <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${FaqElements.map((item, index) => (`
                {
                    "@type": "Question",
                    "name": "${item.question}",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${item.answer}"
                    }
                }`))}  
                ]
            }
        `}</script>
    </Helmet>

)