import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Breadcrumb from 'src/components/Breadcrumb';
import { Consumer } from 'src/services/store';
import PublicPage from 'src/templates/PublicPage';
import { serviceMarkup } from 'src/utils/structuredData';

import ArticleLinks from './components/ArticleLinks';
// import CountryMapLinks from './components/CountryMapLinks';
import ArticlePageHeader from './containers/ArticlePageHeader';
import StyledArticle from './containers/StyledArticle';
import { Map } from './components/Map'
import { ParkingsList } from './components/ParkingsList'
import FaqEstimation from './components/FaqEstimation'
import * as Sentry from '@sentry/browser'

class BlogArticlePage extends React.Component {
  //For map header:
  getCoordinates(article) {
    const coordinates = Object.assign({}, article.location)
    coordinates.lat = parseFloat(coordinates.lat)
    coordinates.lng = parseFloat(coordinates.lng)
    return coordinates
  }

  formatCoordinates = () => {
    const coordinates = this.props.article.location
    if (coordinates && coordinates.lat && coordinates.lng) {
      const { lat, lng } = coordinates
      const location = `location=${lat},${lng}`
      return location
    }
  }

  state = {
    searchLink: `/search?${this.formatCoordinates()}`,
    coordinates: this.getCoordinates(this.props.article),
  }


  goToSearch = () => {
    this.props.history.push(`/search?${this.formatCoordinates()}`)
  }

  render() {
    const { article, parkingsAround, linksAround, linksInTree, breadcrumbData } = this.props
    const { coordinates, error, searchLink } = this.state
    return (
      <PublicPage error={error} navbarPush
        metaTitle={article.yoast_meta.yoast_wpseo_title}
        metaDescription={article.yoast_meta.yoast_wpseo_metadesc}>
        <Helmet>
          {serviceMarkup({ parkingsAround: parkingsAround, article: article, breadcrumbData: breadcrumbData })}
        </Helmet>
        <div className="row">
          <RenderHeaderWithMap coordinates={coordinates} parkings={parkingsAround.parkings} article={article} />
          <div className="col-12">
            <Breadcrumb />
          </div>
          <ParkingsList parkings={parkingsAround.parkings} article={article} searchLink={searchLink} />
          <FaqEstimation article={article} locationTitle={article.acf.link_text} />
          <div className="col-12 col-sm-10 offset-sm-1">
            <p>Information sur les prix indiqués : Tous les prix et montants locatifs indiqués sur cette page représentent les prix moyens constatés sur Parkmatch pour cette ville, ses arrondissements éventuels, ses quartiers ou les villes proches. </p>
          </div>
          <RenderArticle article={article} />
          {
            (linksAround || linksInTree) &&
            <RenderLinksBlocks linksAround={linksAround} linksInTree={linksInTree} article={article} />
          }
        </div>
      </PublicPage>
    )
  }
}

const RenderArticle = ({ article }) => (
  <div className="col-12 col-sm-10 offset-sm-1">
    {article && article.content &&
      <StyledArticle dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
    }
    {(!article || !article.content) &&
      <div style={{ fontWeight: 'bold', fontSize: 20 }}>Pas d'article pour cette ville...</div>
    }
  </div>
)

const RenderHeaderWithMap = ({ coordinates, parkings, article }) => {
  let zoom = 0
  switch (article.acf.article_type) {
    case 'pays':
      zoom = 5
      break
    case 'ville':
      if (['Paris', 'Lyon', 'Marseille'].includes(article.acf.link_text)) {
        zoom = 12
      } else {
        zoom = 13
      }
      break
    default:
      zoom = 14
  }
  return (
    <ArticlePageHeader className="col-12 p-0">
      <Map article={article} coordinates={coordinates} parkings={parkings} style={{ height: `100%`, width: '100%' }} zoom={zoom} />
      <div style={{ position: "absolute", top: "0", height: "100%", width: "100%", background: "rgba(130, 130, 130, 0.30)" }}>
        {
          article && article.content &&
          <div className="article-title-container">
            <h1>{article.title.rendered}</h1>
            <div className="article-subtitle">Parkmatch, location de parking et garage entre particuliers.</div>
          </div>
        }
      </div>
    </ArticlePageHeader>
  )
}

const RenderLinksBlocks = ({ linksAround, linksInTree, article }) => {
  return (

    <>
      {
        (linksAround || linksInTree) && article.acf.link_text !== "louer" &&
        <div className="col-12 mb-3">
          {
            article.acf.article_type === 'pays' &&
            <>
              <h2>Trouvez nos parkings par villes en {article.acf.link_text}</h2>
              {/*<div className="row">
              <CountryMapLinks articles={linksInTree}
                country={article.acf.link_text}
                classNames="" />
          </div>*/}
              <div className="row">
                <ArticleLinks links={linksInTree}
                  linksType='ville'
                  classNames="col-12 col-md my-4" />
              </div>
            </>
          }
          {
            article.acf.article_type === 'ville' &&
            <>
              <h2>Lieux autour de {article.acf.link_text}</h2>
              <div className="row">
                <ArticleLinks links={linksAround}
                  title={'Villes proches de ' + article.acf.link_text}
                  linksType='ville'
                  classNames="col-12 col-md my-4" />
                <ArticleLinks links={linksInTree}
                  title={'Arrondissements de ' + article.acf.link_text}
                  linksType='arrondissement'
                  classNames="col-12 col-md my-4" />
                <ArticleLinks links={linksInTree}
                  title={'Quartiers de ' + article.acf.link_text}
                  linksType='quartier'
                  classNames="col-12 col-md my-4" />
              </div>
            </>
          }
          {
            (article.acf.article_type === 'arrondissement' || article.acf.article_type === 'quartier') &&
            <>
              <h2>Lieux autour de {article.acf.link_text}</h2>
              <div className="row">
                <ArticleLinks className="col-12 col-md-auto" links={linksAround}
                  title={'Villes proches de ' + article.acf.link_text}
                  linksType='ville'
                  classNames="col-12 col-md my-4" />
                <ArticleLinks className="col-12 col-md-auto" links={linksAround}
                  title={'Arrondissements proches de ' + article.acf.link_text}
                  linksType='arrondissement'
                  classNames="col-12 col-md my-4" />
                <ArticleLinks className="col-12 col-md-auto" links={linksAround}
                  title={'Quartiers proches de ' + article.acf.link_text}
                  linksType='quartier'
                  classNames="col-12 col-md my-4" />
              </div>
            </>
          }
        </div>
      }
    </>
  )
}

export default withRouter(React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PublicRoute component={BlogArticlePage} {...props} data={ctx} ref={ref} />}
  </Consumer>
)));
