// import Account from 'src/views/AccountPage/containers/Account';
// import MyParkings from 'src/views/AccountPage/containers/MyParkings';

const NAV = {
  account: 'Mon compte',
  tParkings: 'PARKINGS',
  bookings: 'Mes réservations',
  parkings: 'Mes parkings',
  tPayment: 'PAIEMENTS',
  paymentType: 'Moyens de paiement',
  transfers: 'Mes virements',
  bonusCredit: 'Crédit bonus',
  tOther: 'AUTRE',
  help: 'Aide',
  logout: 'Se déconnecter',
}

// const NAV_ELEM = {
//   account: Account,
//   bookings: Account,
//   parkings: MyParkings,
//   paymentType: Account,
//   transfers: Account,
//   bonusCredit: Account,
//   help: Account,
// }

export { NAV };
// export { NAV_ELEM };
