import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LoaderSimple from 'src/components/LoaderSimple';
import NavigationBar from 'src/components/NavigationBar'
import DrawerMenu from 'src/components/DrawerMenu'
import formatMetaData from 'src/utils/formatMetaData';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
	},
	toolbar: theme.mixins.toolbar,
});

class PrivatePage extends React.Component {
	state = {
		drawerMenuOpen: false,
	}

	handleDrawerToggle = () => this.setState({ drawerMenuOpen: !this.state.drawerMenuOpen })

	componentWillMount() {
		formatMetaData({ title: this.props.metaTitle });
	}

	render() {
		const { classes, children, isLoading = false, error, title, headerComponent } = this.props;
		const { drawerMenuOpen } = this.state
		return (
			<div className={classes.root}>
				<NavigationBar openMenu={this.handleDrawerToggle} privatePage />
				<DrawerMenu openMenu={this.handleDrawerToggle} drawerMenuOpen={drawerMenuOpen} privatePage />
				<main className={classes.content}>
					<div className="container-fluid min-vh-100">
						<div className={classes.toolbar} />
						{title && <PageTitle title={title} headerComponent={headerComponent} />}
						{!isLoading && !error && children}
						{isLoading &&
							<div className="row justify-content-center vh-100" style={{ alignItems: 'center' }}>
								<LoaderSimple className="col-12" />
							</div>
						}
						{error &&
							<div className="row justify-content-center vh-100" style={{ alignItems: 'center' }}>
								Une erreur est survenue
              </div>
						}
					</div>
				</main>
			</div>
		);
	}
}

const PageTitle = ({ title, headerComponent: Component = null }) => (
	<Paper square className="pt-4 mb-4 row">
		<div className={`col ${!Component && "my-4"}`}>
			<Typography variant="h1" gutterBottom style={{ fontWeight: 'normal', fontSize: '2.8rem', fontFamily: 'BariolLight', textAlign: 'left' }}>
				{title}
			</Typography>
			{Component}
		</div>
	</Paper>
)

PrivatePage.propTypes = {
	classes: PropTypes.object.isRequired,
	metaTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(PrivatePage);
