import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DateAndTimePicker from './DateAndTimePicker';


function StartAndEndPicker({ startDate, setStartDate, endDate, setEndDate, onDateSelected }) {

    useEffect(() => {
        if (startDate && endDate) {
            if (moment(startDate).isAfter(endDate)) {
                setEndDate(null)
            } else {
                onDateSelected({ newStartDate: startDate, newEndDate: endDate })
            }

        }
    }, [startDate])

    useEffect(() => {
        if (startDate && endDate) {
            if (moment(startDate).isAfter(endDate)) {
                setStartDate(null)
            } else {
                onDateSelected({ newStartDate: startDate, newEndDate: endDate })
            }
        }
    }, [endDate])

    return (
        <div className="d-flex flex-row justify-content-around align-items-center w-100 flex-fill" style={{ fontSize: '1.1rem' }}>
            <DateAndTimePicker date={startDate} setDate={setStartDate} placeholder="Date de début" disablePast id='startdate' />
            <div>></div>
            <DateAndTimePicker date={endDate} setDate={setEndDate} placeholder="Date de fin" minDate={startDate} id='enddate' />
        </div>
    )
}

StartAndEndPicker.propTypes = {
    startDate: PropTypes.object,
    setStartDate: PropTypes.func,
    endDate: PropTypes.object,
    setEndDate: PropTypes.func,
    onDateSelected: PropTypes.func.isRequired,
}

export default StartAndEndPicker
