import React from 'react';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash, FaExclamationTriangle } from 'react-icons/fa';
import { Tooltip } from 'reactstrap';

import { COLORS } from 'src/stylesheet/colors';

const LABEL_TYPE = {
  OK: {
    background: COLORS.lightWhite,
    icon: <FaEyeSlash color={COLORS.green} size={13} />,
    color: COLORS.green,
    border: COLORS.green,
    tips: "Vous ne pouvez pas recevoir de demandes de location.",
    text: "Non disponible",
  },
  AVAILABLE: {
    background: COLORS.green,
    icon: <FaEye color={COLORS.lightWhite} size={13} />,
    color: COLORS.lightWhite,
    border: COLORS.green,
    tips: 'Vous pouvez recevoir des demandes de location.',
    text: "Disponible",
  },
  INCOMPLETE: {
    background: COLORS.orange,
    icon: <FaExclamationTriangle color={COLORS.lightWhite} size={13} />,
    color: COLORS.lightWhite,
    border: COLORS.orange,
    tips: 'Votre parking est incomplet, mettez à jour ses informations.',
    text: "Incomplet",
  },
  BOOKED: {
    background: COLORS.green,
    color: COLORS.lightWhite,
    border: COLORS.green,
    tips: 'Votre parking est réservé.',
    text: "Réservé",
  }
}

export default class ParkingStatus extends React.Component {
  state = {
    tooltipOpen: false,
  }

  toggle = () => {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen });
  }
  render() {
    const { id, labelType, toolTip } = this.props;
    const e = LABEL_TYPE[labelType];
    return (
      <div className="badge badge-pill d-flex align-items-center" style={{ border: `1px solid ${e.border}`, backgroundColor: e.background }} id={`t-PublishLabel${id}`}>
        {e.icon ? e.icon : ''}
        <div className={e.icon ? "pl-2" : ""} style={{ fontSize: 12, color: e.color }}>{e.text}</div>
        {toolTip &&
          <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={`t-PublishLabel${id}`} toggle={this.toggle}>
            {e.tips}
          </Tooltip>
        }

      </div>
    )
  }
}

ParkingStatus.propTypes = {
  labelType: PropTypes.string,
  id: PropTypes.number,
}
