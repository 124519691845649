import styled from 'styled-components';
import { default as DefaultButton } from '@material-ui/core/IconButton';

const IconButton = styled(DefaultButton)`
  outline: none !important;
`;

export {
  IconButton,
}
