import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'src/stylesheet/colors'
import GLOBAL from 'src/stylesheet';
import LoaderSimple from 'src/components/LoaderSimple';

import './styles.css';

function defaultFunction() {
  // 'You must attach a onClick function to this button.'
}

const Button = ({ text, disable = false, onClick = defaultFunction, resp = false, isLoading = false, fontSize = 16 }) => {
  const backgroundImage = !disable ? GLOBAL.gradient : {};
  const backgroundColor = disable ? COLORS.gray : {};
  const width = resp ? '100%' : 'auto';
  const height = resp ? '100%' : 'auto';
  return (
    <button className="shadow rounded buttonCore d-inline-block" type="button" style={{ minHeight: 35, fontSize: fontSize, border: 'none', width: width, height: height, backgroundColor: backgroundColor, backgroundImage: backgroundImage, color: COLORS.lightWhite, fontWeight: 'bold', outline: 'none', cursor: 'pointer' }} onClick={() => !disable && onClick()}>
      {!isLoading ? text : <LoaderSimple height={25} width={25} />}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button;
