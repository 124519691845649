// import I18n from 'ex-react-native-i18n'

import Api from 'src/services/api'
import { sentryCaptureMessage } from 'src/utils/utils'

const ERRORS = [
  {
    display: "Une erreur est survenue"
  },
  {
    message: "You have already sent a request for this parking",
    display: "Vous avez déjà une demande en cours pour ce parking"
  }
]

const getBookings = async () => {
  try {
    const res = await Api.parkmatch.get('/booking/list')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_BOOKINGS' },
      extra: { error: e },
    })
    return { error: { message: 'genericError'} }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const getBooking = async ({ bookingId: booking_id}) => {
  try {
    const res = await Api.parkmatch.get('/booking/get', { booking_id })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'genericError'} }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const startBooking = async (data) => {
  try {
    const res = await Api.parkmatch.post('/booking', data)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'START_BOOKING' },
      extra: { error: e },
    })
    const errorIndex = ERRORS.findIndex(err => err.message === e.message)
    if (errorIndex > -1) {
      return { error: { message: ERRORS[errorIndex].display } } 
    }
    return { error: { message: ERRORS[0].display } }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const endBooking = async ({ bookingId }) => {
  try {
    const res = await Api.parkmatch.put('/booking/terminate', { booking_id: bookingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'END_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'genericError'} }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const sendEndBookingCode = async ({ booking_id, code }) => {
  try {
    const res = await Api.parkmatch.put('/booking/validate/end', { booking_id, code })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'SEND_END_BOOKING_CODE' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const cancelBooking = async ({ bookingId }) => {
  try {
    await Api.parkmatch.put('/booking/cancel', { booking_id: bookingId })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'CANCEL_BOOKING' },
      extra: { error: e },
    })
    return { error: { message: 'genericError'} }
    // return { error: { message: I18n.t('genericError') } }
  }
}

const getParkingDetails = async ({ parkingId }) => {
  try {
    const res = await Api.parkmatch.get('/parking/details', { parking_id: parkingId })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_PARKING_DETAILS' },
      extra: { error: e },
    })
    return { error: { message: 'genericError'} }
    // return { error: { message: I18n.t('genericError') } }
  }
}

export default {
  getBookings,
  getBooking,
  startBooking,
  cancelBooking,
  endBooking,
  getParkingDetails,
  sendEndBookingCode,
}
