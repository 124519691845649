import styled from 'styled-components'
import { COLORS } from 'src/stylesheet/colors';

const StyledArticle = styled.div`

  text-align: justify;
  max-width: 900px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
  background-color: white;
  padding: 2rem;

  &>p:first-of-type {
    position: relative;
    padding-left: 3.5rem;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1;
    color: #777777;
  }

  &>p:first-of-type:before {
    content: '“';
    font-size: 110px;
    color: #CCCCCC;
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 0;
  }

  h2 {
    padding-top: 6.1rem;
    margin-top: -4rem;
    margin-bottom: 1.9rem;
    color: ${COLORS.green};

  }

  h3 {
    font-size: 2rem;
    margin-top: 1.9rem;
    margin-botton: 1.8rem;
  }

  h4 {
    color: ${COLORS.green}
    text-align: center;
    font-size: 1.4rem;
    margin-top: 1.8rem;
    margin-botton: 1.8rem;
  }

  nav {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }

  nav p{
    margin-bottom: 0.5rem;
    font-decoration: underline;
    text-align: center;
  }

  nav ol {
    margin: auto;
  }

  p {
    font-size: 1.1rem;
  }

  figure, img, table {
    width: 100% !important;
    height: auto !important;
  }

  table tr:first-of-type {
    font-weight: bold;
  }

  @media (max-width: 576px) {
    padding: 0.8rem;
  }
`;


export default StyledArticle
