import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MailIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import TimeIcon from '@material-ui/icons/AccessTime'
import CreditCard from '@material-ui/icons/CreditCard';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa';
import { FaRegComment } from 'react-icons/fa';

import StyledButton from 'src/components/StyledButton'
import { MonthYear } from 'src/utils/DateUtils'
import IconSvg from 'src/svg';
import { Consumer } from 'src/services/store';
import FormatPhone from 'src/utils/FormatPhone'
import BookingApi from 'src/api/booking'
import { COLORS } from 'src/stylesheet/colors'
import Analytics from 'src/services/Analytics'
import moment from 'moment';
import { RENTAL_MODE } from 'src/services/store/SearchStore';

const ConfirmationStep = ({ user, data, goToDetails, fullBooking, isParkingAvailable }) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  async function sendConfirmation() {
    setIsLoading(true)
    const res = await BookingApi.startBooking({
      parking_id: fullBooking.parking.id,
      recurrence_type: fullBooking.rentalMode,
      planning_type: fullBooking.rentalMode === "one_shot" ? "planned" : '',
      planned_start: moment(fullBooking.startDate).unix(),
      planned_end: moment(fullBooking.endDate).unix(),
      comment: (fullBooking.rentalMode === RENTAL_MODE.oneshot && fullBooking.comment) ? fullBooking.comment : ''
    })
    setIsLoading(false)
    if (res && !res.error) {
      if (fullBooking.rentalMode === "one_shot") {
        Analytics.sendOneshotBookingRequest()
      } else {
        Analytics.sendMonthlyBookingRequest()
      }
      data.snackRef.open('success', 'Votre demande de location a été envoyée!')
      history.push('account/reservations')
    } else {
      data.snackRef.open('error', res.error.message)
    }
  }

  return (
    <div className="p-3">
      <Typography variant="h5" color="primary" gutterBottom>
        Confirmation de la demande
      </Typography>
      <Grid container item xs={12} className="mt-4" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Vos informations
        </Typography>
        <Button className="no-outline" style={{ textTransform: 'none' }} color="primary" onClick={goToDetails}>
          Modifier
        </Button>
      </Grid>
      <UserInformations user={user} fullBooking={fullBooking} />
      <StyledDivider />
      <Typography variant="h6" className="mt-4" gutterBottom>
        Votre carte de paiement
      </Typography>
      <Grid container item xs={12}>
        {user && user.credit_card ? <>
          <CreditCard />
          <Typography variant="body1" gutterBottom className='ml-3'>
            {user.credit_card.display_number}
          </Typography>
          <Typography variant="body1" gutterBottom className='ml-5'>
            {MonthYear(user.credit_card.expiration_date)}
          </Typography>
        </>
          : <Typography variant="body1" gutterBottom>
            Vous n'avez pas de carte de crédit enregistrée pour le moment. Ajoutez en une pour profiter pleinement du service Parkmatch.
          </Typography>
        }
      </Grid>
      <Grid container className="mt-4" style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <StyledButton isLoading={isLoading} onClick={sendConfirmation} disabled={!isParkingAvailable}>Envoyer ma demande</StyledButton>
        <Typography variant="caption" className="mt-4" gutterBottom>
          <InformationsButton rentalMode={fullBooking.rentalMode} />
        </Typography>
      </Grid>
    </div>
  )
}

const UserInformations = ({ user, fullBooking }) => (
  <table style={{ marginBottom: 20, tableLayout: 'fixed', width: '100%' }}>
    <tbody>
      <tr>
        <td style={{ width: 50 }}><MailIcon style={{ fontSize: '1.3rem', marginRight: 10 }} /></td>
        <td style={{ fontSize: '1rem' }}>{(user && user.email) || '-'}</td>
      </tr>
      <tr>
        <td><PhoneIcon style={{ fontSize: '1.3rem' }} /></td>
        <td style={{ fontSize: '1rem' }}>{(user && FormatPhone(user.phone)) || '-'}</td>
      </tr>
      <tr>
        <td style={{ paddingRight: 10 }} ><IconSvg name="MEDIUM" size={30} /></td>
        <td style={{ fontSize: '1rem' }}>{(user && user.vehicle && user.vehicle.numberplate) || '-'}</td>
      </tr>
      <tr>
        <td><TimeIcon style={{ fontSize: '1.3rem' }} /></td>
        {fullBooking.rentalMode === RENTAL_MODE.monthly &&
          <td style={{ fontSize: '1rem' }}>Abonnement mensuel</td>
        }
        {fullBooking.rentalMode === RENTAL_MODE.oneshot &&
          <td style={{ fontSize: '1rem' }}>Du {moment(fullBooking.startDate).format('ll')} au {moment(fullBooking.endDate).format('ll')}</td>
        }
      </tr>
      {fullBooking.rentalMode === RENTAL_MODE.oneshot &&
        <tr>
          <td><FaRegComment style={{ fontSize: '1.3rem' }} /></td>

          <td style={{ fontSize: '1rem' }}>
            <EllipsisDiv>
              {fullBooking.comment ? fullBooking.comment : "Pas de commentaire"}
            </EllipsisDiv>
          </td>

        </tr>
      }
    </tbody>
  </table >
)

const InformationsButton = ({ rentalMode }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      {rentalMode === RENTAL_MODE.monthly &&
        <span>Cette étape ne déclenchera pas de paiement.</span>
      }
      {rentalMode === RENTAL_MODE.oneshot &&
        <span>Cette étape ne validera pas encore votre paiement.</span>
      }
      <IconButton onClick={() => setDialogOpen(true)} className="no-outline">
        <FaInfoCircle size={20} color={COLORS.green} />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Déroulement après la demande</DialogTitle>
        <DialogContent>
          <DialogContentText className="my-3">
            Lors de votre demande nous effectuons une préauthorisation de paiement sur votre carte mais vous n'être pas débité.
          </DialogContentText>
          <DialogContentText className="my-3">
            Le propriétaire aura 72h pour examiner votre demande et décider s'il l'accepte ou non.
          </DialogContentText>
          <DialogContentText className="my-3">
            À l'acceptation de votre demande par le propriétaire, le paiement est validé et vous êtes mis en contact avec celui-ci pour régler les derniers détails.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDivider = styled(Divider)`
    margin-top: 30px;
    margin-bottom: 30px;
    height: 2px;
  `

export default (props) =>
  <Consumer>
    {ctx => <ConfirmationStep {...props} user={ctx.user} data={ctx} />}
  </Consumer>

const EllipsisDiv = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
