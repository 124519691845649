import React from 'react';
import idx from 'idx';
import PrivateRoute from 'src/containers/PrivateRoute';
import { withRouter } from 'react-router-dom'

import fetchApi from 'src/services/fetchApi';
import { checkSmsCode, checkErrorValideCode } from 'src/utils/checkAuth';
import StyledButton from 'src/components/StyledButton';
import { Consumer } from 'src/services/store';
import Analytics from 'src/services/Analytics'
import GLOBAL from 'src/stylesheet';
import PublicPage from 'src/templates/PublicPage';

import './styles.css';

class PhonePage extends React.Component {
  state = {
    smsCode: '',
    error: false,
    success: false,
    message: '',
  }

  checkSms = () => {
    const { smsCode } = this.state;
    this.setState({ error: false, success: false });
    if (checkSmsCode(smsCode)) {
      this.fetchPhoneConfirmation();
    } else {
      this.setState({ error: true, message: "Le code saisi est incorrect." });
    }
  }

  fetchPhoneConfirmation = () => {
    const { smsCode } = this.state;
    const { data, checkout } = this.props;
    this.setState({ isLoading: true })
    fetchApi('/user/authentication/validate', 'POST', { sms_code: smsCode }).then(async (res) => {
      this.setState({ isLoading: false })
      if (res && res.error) {
        this.setState({ error: true, message: checkErrorValideCode() });
      } else {
        const { user } = data
        user.phone_validated = true
        await data.handleChangeUser(user)
        Analytics.sendPhoneValidation()
        if (checkout) {
          return
        } else if (data.isAddingParking) {
          this.props.history.push('/add');
        } else {
          this.props.history.push('/account');
        }
      }
    })
  }

  fetchPhone = () => {
    const { checkout, phone: phoneNumber } = this.props
    let phone = idx(this.props, _ => _.location.state)
    if (checkout && phoneNumber) {
      phone = phoneNumber
    }
    fetchApi('/user/authentication/request', 'POST', { phone }).then((res) => {
      if (res && res.error) {
        this.setState({ success: false, error: true, message: "Une erreur est survenue, veuillez contacter le support." });
      } else {
        this.setState({ error: false, success: true, message: "Votre nouveau code a été envoyé." })
      }
    })
  }

  handlerOnChange = (e) => {
    this.setState({ smsCode: e.target.value })
  }

  changePhoneNumber = () => {
    const { checkout, onRegister } = this.props
    if (checkout) {
      onRegister()
    } else {
      this.props.history.push('/register/phone')
    }
  }

  renderForm = () => {
    const { error, smsCode, message, success, isLoading } = this.state;
    return (
      <>
        <div className="form-group input-group-lg text-center">
          <label style={{ color: '#05b65c' }} htmlFor="basic-url">Entrez le code à 4 chiffres reçu par SMS</label>
          <input value={smsCode} onChange={(e) => this.handlerOnChange(e)} type="number"
            className="form-control text-center" id="exampleInputEmail1" aria-describedby="emailHelp"
            placeholder="1234" style={{ letterSpacing: 10 }} />
        </div>
        {error && <div className="text-danger mb-2 text-center">{message}</div>}
        {success && <div className="text-success mb-2 text-center">{message}</div>}
        <div className="form-group text-center">
          <StyledButton onClick={this.checkSms} isLoading={isLoading}>
            Valider
          </StyledButton>
        </div>
        <div className="form-group mb-0 text-center">
          <button onClick={this.fetchPhone} type="button" className="btn btn-link buttonLink">Renvoyer le code</button>
          <button onClick={this.changePhoneNumber} type="button" className="btn btn-link buttonLink">Changer de numéro</button>
        </div>
      </>
    )
  }

  render() {
    const { checkout } = this.props
    const CodeInput = this.renderForm()
    if (checkout) {
      return CodeInput
    }
    return (
      <PublicPage fullHeight footer={false}
        metaTitle="Code de validation"
        metaDescription="Entrez votre code de vérification afin de profiter pleinement du service Parkmatch">
        <div className="row vh-100" style={{ backgroundImage: GLOBAL.gradient, height: '100vh' }}>
          <div className="col-sm-12 my-auto">
            <form className="card card-block mx-auto bg-light p-4 shadow blockForm">
              {CodeInput}
            </form>
          </div>
        </div>
      </PublicPage >
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PrivateRoute component={withRouter(PhonePage)} {...props} data={ctx} ref={ref} />}
  </Consumer>
));
