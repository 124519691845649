import React from 'react'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';
import { COLORS } from 'src/stylesheet/colors';

const Tooltip = ({ style, className, color, children, text }) => (
  <StyledPaper elevation={1} style={style} className={className} color={color} >
    {children && children}
    {text && <Typography component="p">{text}</Typography>}
  </StyledPaper >

)

const StyledPaper = styled(Paper)`
  border-left: 5px solid ${COLORS.green};
  padding: 15px;
  margin: 10px;
  ${props => props.color === 'red' && css`
    border-left: 5px solid ${COLORS.red};
  `}
  ${props => props.color === 'yellow' && css`
    border-left: 5px solid ${COLORS.darkSun};
  `}
  ${props => props.color === 'grey' && css`
    border-left: 5px solid ${COLORS.lightGray};
  `}
  p {
    font-size: 1rem;
  }
`

Tooltip.propTypes = {
  color: PropTypes.string,
}

export default Tooltip
