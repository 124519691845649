function storeData(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data))
}

function getData(name) {
  return JSON.parse(sessionStorage.getItem(name))
}

export default {
  getData,
  storeData
}
