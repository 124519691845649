import React from 'react'
import Popper from '@material-ui/core/Popper';
import { withRouter } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import { Consumer } from 'src/services/store';

import { COLORS } from 'src/stylesheet/colors';
import logout from 'src/utils/logout';
import UserAvatar from 'src/components/UserAvatar';

class RenderAccountButton extends React.Component {
  state = {
    open: false
  }

  toggleProfileMenu = () => this.setState({ open: !this.state.open })

  goToAccountPage = () => {
    this.toggleProfileMenu()
    this.props.history.push('/account')
  }

  logout = () => {
    const { data } = this.props
    this.toggleProfileMenu()
    logout({ handleChangeLogged: data.handleChangeLogged, history: this.props.history })
  }

  render() {
    const { open } = this.state
    const { data: { user } } = this.props
    return (
      <>
        <Button
          className="no-outline"
          onClick={this.toggleProfileMenu}
          style={{ color: COLORS.green, fontWeight: 'normal', textTransform: 'none', fontSize: 18 }}
          buttonRef={node => {
            this.anchorEl = node;
          }}
        >
          <UserAvatar url={user.url_image && `${user.url_image}_small.jpg`} />
          <div className="ml-2">
            {user.first_name} {user.last_name[0]}.
          </div>
        </Button>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.toggleProfileMenu}>
                  <MenuList>
                    <MenuItem onClick={this.goToAccountPage}>Mon compte</MenuItem>
                    <MenuItem onClick={this.logout}>Se déconnecter</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }
}

export default withRouter(React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <RenderAccountButton {...props} data={ctx} ref={ref} />}
  </Consumer>
)));
