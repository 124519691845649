import React from "react";

const SvgSetSmall = props => (
  <svg viewBox="0 0 141.73 85.04" {...props} width={props.size} height={props.size}>
    <defs>
      <clipPath id="set_small_svg__a">
        <path fill="none" d="M5.21 2.6h133.52v82.42H5.21z" />
      </clipPath>
    </defs>
    <g clipPath="url(#set_small_svg__a)">
      <path fill={props.color} d="M133.41 55.69c-.3-.32-1.56-1.27-1.64-1.6-.29-1.17-.63-3.07-.87-4.63-2-13-38.19-35.19-48.31-40.16-16.83-8.3-31.46-7-47-5.88-3 .22-6.61.43-9.32 2a3.32 3.32 0 0 0-.32.2c-4.36 2.32-9.87 7.85-12.84 21a31.92 31.92 0 0 0-1.07 4.52c-.55 3.5-.27 7.08-1.3 10.5-.91 3-2.53 5.82-3.22 8.92-.3 1.3-2.44 7.82-.87 9.11a16.43 16.43 0 0 0 15 23.18 16.74 16.74 0 0 0 15.74-11.58s46.06.05 63.56.05a16.42 16.42 0 0 0 31.44-.32c5.34-2.34 4-11.93 1-15.32zM21.9 17.14c2.59-5.3 6.64-8.85 8.4-7.9s2.12 4.2-1 12.1c-3.82 9.69-6 10.45-9.57 9.49-3.12-.84-1.78-5.05 2.17-13.69zm-.25 57.33a8.06 8.06 0 1 1 8.06-8.06 8.08 8.08 0 0 1-8.06 8.06zm19.21-41.9c-3.45-.27-6.4-3.17-6-6.44.63-4.66 2.42-15.26 7.48-16.66s12 0 17.41.48A60.24 60.24 0 0 1 72 12.56c8.07 2.83 24.11 9.81 31.73 17.86 2.32 2.44.77 4.67-2.68 4.4zm75.74 41.9a8.06 8.06 0 1 1 8.06-8.06 8.08 8.08 0 0 1-8.06 8.06zm0 0" />
    </g>
  </svg>
);

export default SvgSetSmall;
