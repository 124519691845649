import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import DroitsText from './droits'
import PublicPage from 'src/templates/PublicPage';

class DroitsPage extends React.Component {

  render() {
    return (
      <PublicPage
        metaTitle="Vos droits et Obligations"
        metaDescription="Vos droits et Obligations sur Parkmatch.">
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row py-5 gradient-header">
              <div className="col-md-8 py-4 offset-md-2 text-center title-faq">
                <h1>Vos droits et Obligations</h1>
              </div>
            </div>
            <div className="row">
              <DroitsText />
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={DroitsPage} />
