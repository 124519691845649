import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

const StyledDivider = styled(Divider)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
  }
`

export default StyledDivider
