import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'src/stylesheet/colors'
import GLOBAL from 'src/stylesheet';
import './styles.css';

const Button = props => (
  <button className="mt-2 shadow rounded button" style={{ backgroundImage: GLOBAL.gradient, color: COLORS.lightWhite, fontSize: 18, fontWeight: 'bold', border: 'none', outline: 'none', cursor: 'pointer' }} onClick={() => props.onClick()}>
    <div>{props.text}</div>
  </button>
)

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button;