import React from 'react';
import { Link } from 'react-router-dom';

import InputSearch from 'src/components/InputSearch';
import LandingIllustration from './LandingIllustration';

import Car from 'public/assets/img/car.png';
import Parking from 'public/assets/img/parking.png';
import Parkman from 'public/assets/img/parkman.png';
import AppleStore from 'public/assets/img/app-store-transparent.png';
import AndroidStore from 'public/assets/img/google-play-transparent.png';
import TextLoop from 'react-text-loop'

import '../styles.css'


const LandingSection = ({ registerParking }) => (
  <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
    <div className="col">
      <div className="row py-5 gradient-header">
        <div className="col-md-8 py-4 offset-md-2 text-center title-faq">
          <h1>Nous sommes navrés de vous informer que le site Parkmatch ferme
définitivement.</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-2 pt-5">
          <div>
          Nous invitons les propriétaires à :<br /><br />
          1. Récupérer les loyers versés par l'interface prévue à cet effet dans leur <Link to="/account">espace personnel</Link><br /><br />
          2. Contacter leurs locataires pour que le règlement des loyers s'effectue dorénavant sur leurs comptes bancaires<br /><br />
          3. Procéder à la résiliation de la location par le lien prévu à cet effet dans le back-office<br /><br />
          Nous restons à votre disposition pour toute demande d'information : <a href="mailto:contact@parkmatch.eu">contact@parkmatch.eu</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LandingSection
