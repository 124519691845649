import Api from 'src/services/api';

async function fetchApi (
  endPoint,
  method = 'GET',
  payload = {},
  extraHeaders = {},
  authToken = '',
  baseUrl = '',
  wordPress = false,
) {
  if (method === 'GET' && !wordPress) {
    try {
      const res = await Api.parkmatch.get(endPoint, payload);
      return res;
    } catch (e) {
      return { error: e }
    }
  } else if (method === 'POST') {
    try {
      const res = await Api.parkmatch.post(endPoint, payload, extraHeaders);
      return res;
    } catch (e) {
      return { error: e }
    }
  } else if (method === 'GET' && wordPress) {
    try {
      const res = await Api.wordPress.get(endPoint, payload);
      return res;
    } catch (e) {
      return { error: e }
    }
  }
}

export default fetchApi;