import React from 'react';

import SnackBar from 'src/components/SnackBar';
import ParkingApi from 'src/api/parking';
import PmButton from 'src/components/StyledButton'
import IconsInfo from 'src/components/IconsInfo';

export default class VehicleInput extends React.Component {
  state = {
    sizeCar: null,
  }

  componentWillMount() {
    if (this.props.edit) {
      const { parking } = this.props
      this.setState({ id: parking.id, sizeCar: parking.size, ...this.extractInfos(parking) })
    } else {
      let parking = null
      if (typeof window !== 'undefined') { // client
        parking = JSON.parse(sessionStorage.getItem('parking'));
      }
      if (parking && parking.sizeCar) {
        this.setState({ sizeCar: parking.sizeCar, ...this.extractInfos(parking) });
      }
    }
  }

  onChangeVehicle = (sizeCar) => this.setState({ sizeCar });

  extractInfos = (source) => ({
    garage: source.garage || false,
    indoor: source.indoor || false,
    camera: source.camera || false,
    power_supply: source.power_supply || false,
  })

  onChangeInfo = (type) => {
    switch (type) {
      case "BOX":
        this.setState({ garage: !this.state.garage })
        break;
      case "INDOOR":
        this.setState({ indoor: !this.state.indoor })
        break;
      case "POWER":
        this.setState({ power_supply: !this.state.power_supply })
        break;
      case "CAMERA":
        this.setState({ camera: !this.state.camera })
        break;
      default:
        break;
    }
  }

  saveSteps = () => {
    const { sizeCar } = this.state;
    let parking = {}
    if (typeof window !== 'undefined') { // client
      parking = JSON.parse(sessionStorage.getItem('parking'));
    }
    parking = { ...parking, sizeCar: sizeCar, ...this.extractInfos(this.state), currentStep: this.props.step + 1 };
    if (typeof window !== 'undefined') { // client
      sessionStorage.setItem('parking', JSON.stringify(parking));
    }
    this.props.onChangeStep(this.props.step + 1);
  }

  updateVehicle = async () => {
    this.setState({ isLoading: true })
    const { id, sizeCar: size } = this.state
    const res = await ParkingApi.updateParkingInfo(id, { size, ...this.extractInfos(this.state) })
    if (!res.error) {
      if (this.props.onEdition) {
        this.props.onEdition(res)
      }
      this.snack.open('success', 'Informations enregistrées.')
    } else {
      this.snack.open('error')
    }
    this.setState({ isLoading: false })
  }

  saveVehicle = () => {
    if (this.props.edit) {
      this.updateVehicle()
    } else {
      this.saveSteps()
    }
  }

  render() {
    const { sizeCar, isLoading, garage, camera, indoor, power_supply } = this.state;
    const { edit } = this.props;

    return (
      <div className="d-flex flex-column justify-content-around align-items-center h-100 w-100">
        <div className="d-flex flex-column justify-content-between align-items-center h-100">
          <div className="w-100 d-flex flex-column align-items-center h-100 mb-3">
            <div className="pb-2">Pour véhicule de taille :</div>
            <div className="d-flex flex-wrap justify-content-center">
              <IconsInfo edit={edit} onChange={this.onChangeVehicle} name="MOTORBIKE" isSelect={sizeCar === "MOTORBIKE"} />
              <IconsInfo edit={edit} onChange={this.onChangeVehicle} name="SMALL" isSelect={sizeCar === "SMALL"} />
              <IconsInfo edit={edit} onChange={this.onChangeVehicle} name="MEDIUM" isSelect={sizeCar === "MEDIUM"} />
              <IconsInfo edit={edit} onChange={this.onChangeVehicle} name="LARGE" isSelect={sizeCar === "LARGE"} />
            </div>
            <div className="pb-2 mt-4">Caractéristiques de la place :</div>
            <div className="d-flex flex-wrap justify-content-center">
              <IconsInfo edit={edit} onChange={this.onChangeInfo} name="INDOOR" isSelect={indoor} />
              <IconsInfo edit={edit} onChange={this.onChangeInfo} name="BOX" isSelect={garage} />
              <IconsInfo edit={edit} onChange={this.onChangeInfo} name="CAMERA" isSelect={camera} />
              <IconsInfo edit={edit} onChange={this.onChangeInfo} name="POWER" isSelect={power_supply} />
            </div>
          </div>

        </div>
        <PmButton disabled={isLoading || !sizeCar} isLoading={isLoading} onClick={this.saveVehicle}>{edit ? "Enregistrer" : "Suivant"}</PmButton>
        {edit && <SnackBar ref={ref => this.snack = ref} />}
      </div>
    )
  }
}
