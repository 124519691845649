import React from 'react'
import { COLORS } from 'src/stylesheet/colors';

const BOOKINGS_TYPE = {
  monthly: { title: 'abonnement', color: COLORS.green },
  one_shot: { title: 'ponctuel', color: COLORS.green },
}

const BookingType = ({ type }) => (
  <div style={{ color: BOOKINGS_TYPE[type].color }}>
    {BOOKINGS_TYPE[type].title}
  </div>
)

export default BookingType
