// import I18n from 'ex-react-native-i18n'

import Api from 'src/services/api'
// import ErrorCodes from 'src/utils/errorCodes'
import { sentryCaptureMessage } from 'src/utils/utils'

const globalRefresh = async () => {
  try {
    const { booking, parkings, remote } = await Api.parkmatch.get('/user/global')

    return {
      booking,
      parkings: (parkings || []).map(parking => ({
        id: parking.parking_id,
        location: {
          address: parking.address,
        },
        image: parking.image,
        status: parking.status,
        loaded: parking.loaded,
        locked: parking.locked,
        hasConfig: parking.has_config,
        partiallyLoaded: parking.partially_loaded,
      })),
      remote,
    }
  } catch (e) {
    return { error: { message: 'Global refresh failed' } }
  }
}

const requestPhoneValidation = async (phone) => {
  try {
    await Api.parkmatch.post('/user/authentication/request', { phone: phone })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'REQUEST_PHONE_VALIDATION' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const validatePhoneNumber = async (smsCode) => {
  try {
    await Api.parkmatch.post('/user/authentication/validate', { sms_code: smsCode })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'VALIDATE_PHONE_NUMBER' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const resetPassword = async (data) => {
  try {
    await Api.parkmatch.post('/user/reset_password', data)
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'RESET_PASSWORD' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const addPaymentCard = async () => {
  try {
    const res = await Api.parkmatch.get('/credit_card/register/v2')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'ADD_PAYMENT_CARD' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const getPaymentCardInfos = async () => {
  try {
    const res = await Api.parkmatch.get('/credit_card/list')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'error',
      tags: { type: 'API', label: 'GET_PAYMENT_CARD' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const selectCard = async ({ id }) => {
  try {
    const res = await Api.parkmatch.put('/credit_card/select', { card_id: id })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'SELECT_PAYMENT_CARD' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const deleteCard = async ({ cardId }) => {
  try {
    await Api.parkmatch.delete('/credit_card/delete', { card_id: cardId })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'DELETE_PAYMENT_CARD' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const getWallet = async () => {
  try {
    const res = await Api.parkmatch.get('/wallet')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_WALLET' },
      extra: { error: e },
    })
    return { error: { message: "GET WALLET ERROR" } }
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const uploadDocument = async (data) => {
  try {
    const res = await Api.parkmatch.put('/wallet/upload_doc', data)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'UPLOAD_DOCUMENT_WALLET' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const askTransfer = async () => {
  try {
    const res = await Api.parkmatch.post('/wallet/money_out')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'ASK_TRANSFER' },
      extra: { error: e },
    })
    return { error: { message: e.message } }
  }
}

const setUserPosition = async (location) => {
  try {
    const res = await Api.parkmatch.post('/user/location', location)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'SET_USER_LOCATION' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const getTransactionInfos = async (id) => {
  try {
    const res = await Api.parkmatch.get('/transaction/details', id)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_TRANSACTION' },
      extra: { error: e },
    })
    // return { error: { message: I18n.t('genericErrorWithCode', { code: ErrorCodes.LOAD_INIT }) } }
  }
}

const facebookConnect = async (data) => {
  try {
    const res = await Api.parkmatch.post('/user/facebook', data)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'USER_CONNECT_FACEBOOK' },
      extra: { error: e },
    })
    return { error: { message: 'Connection error with facebook' } }
  }
}

const googleConnect = async (data) => {
  try {
    const res = await Api.parkmatch.post('/user/google', data)
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'USER_CONNECT_GOOGLE' },
      extra: { error: e },
    })
    return { error: { message: 'Connection error with google' } }
  }
}

const getUserInformations = async () => {
  try {
    const res = await Api.parkmatch.get('/user')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'USER_GET_INFOS' },
      extra: { error: e },
    })
    return { error: { message: 'Error get user informations' } }
  }
}

const getTransactionsHistory = async () => {
  try {
    const res = await Api.parkmatch.get('/booking/history')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_TRANSACTION_HISTORY' },
      extra: { error: e },
    })
    return { error: { message: 'Error get transaction history' } }
  }
}

const getNotificationsStatus = async () => {
  try {
    const res = await Api.parkmatch.get('/user/notifications')
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_NOTIFICATIONS_STATUS' },
      extra: { error: e },
    })
    return { error: { message: 'Error get notifications status' } }
  }
}

const resetUserPasswordEmail = async ({ email }) => {
  try {
    await Api.parkmatch.post('/user/reset_password', { email })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'RESET_USER_PASSWORD_EMAIL' },
      extra: { error: e },
    })
    return { error: { message: 'Reset user password by email' } }
  }
}

const resetUserPasswordPhone = async ({ phone }) => {
  try {
    await Api.parkmatch.post('/user/reset_password', { phone })
    return { status: true }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'RESET_USER_PASSWORD_PHONE' },
      extra: { error: e },
    })
    return { error: { message: 'Reset user password by phone' } }
  }
}

const updateUserInfo = async ({ first_name, last_name, email, password }) => {
  try {
    const res = await Api.parkmatch.put('/user', {
      ...(first_name && { first_name }),
      ...(last_name && { last_name }),
      ...(email && { email }),
      ...(password && { password }),
    })
    return { status: true, res: res }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'UPDATE_USER_INFO' },
      extra: { error: e },
    })
    return { error: { message: 'Update user info.' } }
  }
}

const executeTokenAction = async ({ token }) => {
  try {
    const res = await Api.parkmatch.get('/action', { token })
    return { status: true, res: res }
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'EXECUTE_TOKEN_ACTION' },
      extra: { error: e },
    })
    return { error: { message: "L'action n'a pas pu être exécutée... Elle a peut être déjà été utilisée ou n'existe pas." } }
  }
}

const getTransactionStatus = async (wkToken) => {
  try {
    const res = await Api.parkmatch.get('/register_card/money_in/status', { 'wk_token': wkToken })
    return res
  } catch (e) {
    sentryCaptureMessage(e.message, {
      level: 'warning',
      tags: { type: 'API', label: 'GET_TRANSACTION_STATUS' },
      extra: { error: e },
    })
    return { error: { message: "Une erreur est survenue." } }
  }
}

export default {
  globalRefresh,
  requestPhoneValidation,
  validatePhoneNumber,
  resetPassword,
  addPaymentCard,
  getPaymentCardInfos,
  selectCard,
  getWallet,
  uploadDocument,
  askTransfer,
  deleteCard,
  setUserPosition,
  getTransactionInfos,
  facebookConnect,
  googleConnect,
  getUserInformations,
  getTransactionsHistory,
  getNotificationsStatus,
  resetUserPasswordEmail,
  resetUserPasswordPhone,
  updateUserInfo,
  executeTokenAction,
  getTransactionStatus,
}
