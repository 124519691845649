import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'src/services/store';

import BreadCrumbStyled from './containers/BreadCrumbStyled';

class Breadcrumb extends React.Component {
  state = {
    items: typeof window !== "undefined" ?
      [] : this.props.data.pageData.breadcrumbData.items
  }


  componentDidMount() {
    const { items } = this.props.data.pageData.breadcrumbData
    this.setState({ items })
  }

  renderItems() {
    const items = this.state.items.filter(i => i !== undefined)
    if (items.length >= 1) {
      return items.map((item, index) =>
        item.isLink ?
          <ItemLink item={item} index={index} key={index} />
          : <Item item={item} index={index} key={index} />
      )
    }
  }

  render() {
    const { items } = this.state
    const { title, url } = this.props
    return (
      <BreadCrumbStyled itemScope itemType="http://schema.org/BreadcrumbList">
        {this.renderItems()}
        {title && <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <button disabled itemProp="item" title={title}>
            <span itemProp="name">{title}</span>
          </button>
          <meta itemProp="position" content={items.length} />
        </li>}
      </BreadCrumbStyled>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <Breadcrumb {...props} data={ctx} ref={ref} />}
  </Consumer>
));

const ItemLink = ({ item, index, length }) => (
  <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
    <a itemProp="item" title={item.name} href={item.url || ""}>
      <span itemProp="name">{item.name}</span>
    </a>
    <meta itemProp="position" content={index + 1} />
  </li>
)

const Item = ({ item, index, length }) => (
  <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
    <span itemProp="name">{item.name}</span>
    <meta itemProp="position" content={index + 1} />
    <meta itemProp="item" content={item.url || ""} />
  </li>
)

Breadcrumb.propTypes = {
  country: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  arr: PropTypes.string,
}
