import React from 'react';
import Logo from 'public/assets/img/parkmatch-light.png';
import Lemonway from 'public/assets/img/lemonway.svg';
import { Link } from 'react-router-dom';


import { COLORS } from 'src/stylesheet/colors';

export default class FooterExit extends React.Component {
  render() {
    return (
      <div className="row bg-dark mx-auto p-4 pt-5" style={{ width: "100%", color: COLORS.colorFooter }}>
        <div className="col-12 col-sm-6 col-md-3">
          <img defer width={150} height={33} src={Logo} alt="ParkmatchFooter" />
          <div className="ml-4">
            <div>6 bis, rue de la Paroisse, GALAXY</div>
            <div>78000 Versailles</div>
            <div><a style={{ color: 'inherit' }} href="mailto:contact@parkmatch.eu">contact@parkmatch.eu</a></div>
            <div>01 80 84 44 20</div>
            <div className="mt-5">
              <div className="my-2">
                <div>Intermédiaire de paiement</div>
                <div><a href="https://www.lemonway.com/"><img defer width={150} src={Lemonway} alt="Lemonway" /></a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
        </div>
        <div className="col-12 col-sm-6 col-md-3 text-center">
          
        </div>
        <div className="col-12 d-flex flex-column text-center mt-4">
          <div style={{ color: COLORS.gray }}>Copyrights Parkmatch 2017 - 2022 - Tous droits réservés</div>
        </div>
      </div>
    )
  }
}
