import { COLORS } from 'src/stylesheet/colors';

export default {
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 15px 15px 0px',
    borderColor: `transparent ${COLORS.lightWhite}`,
    position: 'absolute',
    bottom: 0,
    top: 30,
    left: -11,
  },
}