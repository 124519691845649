import React from 'react'

import OutlinedButton from 'src/components/OutlinedButton';

const title = "Simplifiez-vous la location de votre parking"
const subtitle = "Service de location de parking entre particuliers."

const InformationsSection = () => (
  <>
    <div className="row informations-section">
      <div className="col-12 px-2 my-5" style={{ flexDirection: 'column' }}>
        <div className="title-infos text-center">{title}</div>
        <div className="subtitle-infos text-center">{subtitle}</div>
      </div>
    </div>
    <div className='row informations-section'>
      <div style={{ margin: 'auto' }} className='mb-5'>
        <OutlinedButton text="Plus d'infos" link="/louer-mon-parking" />
      </div>
    </div>
  </>
)

export default InformationsSection
