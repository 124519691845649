import * as Sentry from '@sentry/browser';

export const sentryCaptureMessage = (message, { level, tags, extra }) => {
  console.log(message)
  Sentry.configureScope(scope => {
    if (tags && tags.type) {
      scope.setTag("Type", tags.type)
    }
    if (tags && tags.label) {
      scope.setTag("Label", tags.label)
    }
    if (extra) {
      for (var prop in extra) {
        if (Object.prototype.hasOwnProperty.call(extra, prop)) {
          scope.setExtra(prop, extra[prop])
        }
      }
    }
  })

  Sentry.captureMessage(message, (level ? level : 'error'))
}

export const getDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 1000; // Distance in m
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
