import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import DocumentDialog from './Dialog'
import DocumentStatus from './DocumentStatus'
import Tooltip from 'src/components/Tooltip'
import { DOC_TYPES, TYPES_INFO, PHYSICAL_PERSON } from './DocumentsService'
import { FaPlus } from 'react-icons/fa';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';
import KYCStatus from './KYCStatus'


function Documents({ docs, setDocs, walletData }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogType, setDialogType] = useState(null)
  const [docsValidated, setDocsValidated] = useState(0)

  useEffect(() => {
    let count = 0
    for (let i = 0; i < PHYSICAL_PERSON.length; i++) {
      if (docs[PHYSICAL_PERSON[i].toLowerCase()] &&
        docs[PHYSICAL_PERSON[i].toLowerCase()].status === 'ACCEPTED' &&
        PHYSICAL_PERSON[i].toLowerCase() !== 'iban') {
        count++
      }
    }
    setDocsValidated(count)
  }, [docs])

  function closeDialog() {
    setDialogOpen(false)
    setDialogType(null)
  }

  function openDialog(type) {
    setDialogType(type ? type : false)
    setDialogOpen(true)
  }

  function handleOpenDialog() {
    openDialog(false)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Tooltip>
            <div>
              <div>Veuillez enregistrer votre <strong>Iban</strong> ainsi que <strong>deux documents d'identité</strong> pour valider votre compte.</div>
              <div className="text-right">
                <KYCStatus walletData={walletData} />
                <Chip
                  label={'Documents validés: ' + docsValidated + '/2'}
                  style={{ fontSize: '1rem', margin: 5 }}
                  color={docsValidated >= 2 ? 'primary' : 'secondary'}
                  variant='outlined'
                />
              </div>
            </div>
          </Tooltip>
          {!(walletData && walletData.status === 'KYC2') &&
            <Tooltip color="yellow">
              Tous les documents envoyés doivent être au même nom que votre compte Parkmatch.
              Veuillez mettre à jour vos informations dans la rubrique <strong>Mon compte</strong> si ce n'est pas le cas.
            </Tooltip>
          }
          <Tooltip color="green">
            <strong>Vous êtes une société ?</strong> Veuillez suivre <a href="https://help.parkmatch.eu/hc/paiements-taxes-et-revenus/comment-valider-un-compte-de-paiement-en-sci">cette procédure spécifique</a> pour valider votre compte de paiement.
            </Tooltip>
        </Grid>
        {docs.length === 0 &&
          <Grid item xs={12} md={6} lg={4}>
            <p>Vous n'avez pas encore enregistré de document.</p>
          </Grid>
        }
        {DOC_TYPES.map((docType, index) => (
          (docs[docType.toLowerCase()] && docType.toLowerCase() !== 'IBAN') &&
          <Grid item xs={12} md={6} lg={4} key={index}>
            <RenderDocument doc={docs[docType.toLowerCase()]} openDialog={openDialog} />
          </Grid>
        ))}
        <Grid item xs={12} md={6} lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Fab className="no-outline" color="primary" style={{ color: 'white' }} onClick={handleOpenDialog}>
            <FaPlus style={{ fontSize: '1.5rem' }} />
          </Fab>
        </Grid>
      </Grid>
      <DocumentDialog isOpen={dialogOpen} handleClose={closeDialog} type={dialogType} setType={setDialogType} docs={docs} setDocs={setDocs} />
    </>
  )
}

const RenderDocument = ({ doc, openDialog }) => (
  <Paper style={{ margin: 10 }}>
    <Typography variant="h6" style={{ margin: 15, display: "inline-block" }}>{TYPES_INFO[doc.type].title}</Typography>
    <IconButton onClick={() => openDialog(doc.type)}
      className="no-outline float-right m-2"
      aria-label="Delete"
      color="primary"
      disabled={doc.status === 'ACCEPTED'}>
      <CloudUploadIcon />
    </IconButton>
    <DocumentStatus doc={doc} />
  </Paper>
)

export default Documents
