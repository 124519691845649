import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { COLORS } from 'src/stylesheet/colors';
import styles from './styles';

const ToolTips = ({ data }) => (
  <div className="rounded p-3 d-flex flex-column justify-content-center position-absolute" style={{ backgroundColor: COLORS.lightWhite, width: 206, right: -220 }}>
    <div style={styles.triangle} />
    <div className="d-flex align-content-center mb-2">
      <FaInfoCircle size={30} color={COLORS.green} />
      <div className="ml-2" style={{ fontSize: 22, color: COLORS.green }}>{data && data.title}</div>
    </div>
    <div dangerouslySetInnerHTML={{__html: data && data.text}} />
  </div>
)

export default ToolTips;
