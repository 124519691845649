import React from 'react'
import BookingStatus from 'src/components/BookingStatus';
import { COLORS } from 'src/stylesheet/colors';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AlarmIcon from '@material-ui/icons/Alarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment'
import { RENTAL_MODE } from 'src/services/store/SearchStore'

import { DayMonthCut } from 'src/utils/DateUtils';

const Row = ({ icon, title, value }) => (
  <Grid container item xs={12} style={{ paddingLeft: 40, paddingRight: 40, marginTop: 10, marginBottom: 10 }} alignItems="center" justify="space-between">
    <div>
      {icon}
      <span className="ml-2">
        {title}
      </span>
    </div>
    <span>{value}</span>
  </Grid>
)

const BookingResumeTerminating = ({ booking }) => (
  <Grid container item style={{ backgroundColor: COLORS.lightGray }}>
    <Grid container item xs={12} style={{ paddingLeft: 40, paddingRight: 40, marginTop: 15 }} alignItems="center" justify="space-between">
      <span style={{ color: COLORS.green }}>
        {booking.status === 'ENDED' ? 'Abonnement' : 'Résiliation'}
      </span>
      <BookingStatus status={booking.status} />
    </Grid>
    <Row
      icon={<CreditCardIcon />}
      title="Prorata restant à régler" value={`${booking.left_to_be_paid} €`}
    />
    <Row
      icon={<AlarmIcon />}
      title="Paiement final" value={DayMonthCut(booking.last_payment)}
    />
    <Row
      icon={<CheckCircleIcon color="primary" />}
      title="Fin de l'abonnement" value={DayMonthCut(booking.termination_date)}
    />
  </Grid>
)

const BookingResumeOngoing = ({ booking, userType }) => (
  <Grid container item style={{ backgroundColor: COLORS.lightGray }}>
    <Grid container item xs={12} style={{ paddingLeft: 40, paddingRight: 40, marginTop: 15 }} alignItems="center" justify="space-between">
      <span style={{ color: COLORS.green }}>
        {'Abonnement'}
      </span>
      <BookingStatus status={booking.status} />
    </Grid>
    <Row
      icon={<CreditCardIcon />}
      title={userType === "tenant" ? "Prix" : "Revenu"} value={`${booking.monthly_price} €`}
    />
    <Row
      icon={<AlarmIcon />}
      title="Prochain paiement" value={(booking.next_payment && DayMonthCut(booking.next_payment)) || '-'}
    />
  </Grid>
)

const BookingResumeOneshot = ({ booking, userType }) => (
  <Grid container item style={{ backgroundColor: COLORS.lightGray }}>
    <Grid container item xs={12} style={{ paddingLeft: 40, paddingRight: 40, marginTop: 15 }} alignItems="center" justify="space-between">
      <span style={{ color: COLORS.green }}>
        {'Réservation'}
      </span>
      <BookingStatus status={booking.status} />
    </Grid>
    <Row
      icon={<CreditCardIcon />}
      title={userType === "tenant" ? "Tarif" : "Revenu"} value={`${booking.total_price} €`}
    />
    <Row
      icon={<AccessTimeIcon />}
      title="Dates" value={moment(booking.planned_start * 1000).format('lll') + ' - ' + moment(booking.planned_end * 1000).format('lll')}
    />
  </Grid>
)

const BookingResume = ({ booking, userType }) => {
  if ((booking.status.includes('TERMINATING') || booking.status.includes('ENDED')) && booking.recurrence_type === RENTAL_MODE.monthly) {
    return <BookingResumeTerminating booking={booking} />
  }
  if ((booking.status.includes('NOT_CONFIRMED') || booking.status.includes('ONGOING')) && booking.recurrence_type === RENTAL_MODE.monthly) {
    return <BookingResumeOngoing booking={booking} userType={userType} />
  }
  if (booking.recurrence_type === RENTAL_MODE.oneshot) {
    return <BookingResumeOneshot booking={booking} userType={userType} />
  }
  return "Réservation invalide"
}

export default BookingResume
