import styled from 'styled-components'
import { COLORS } from 'src/stylesheet/colors';


const BreadCrumbStyled = styled.ol`
 padding-top: 1em;
 padding-bottom: 1em;
 padding-left: 0px;
 list-style-type: none;
 color: ${COLORS.green};
 font-size: 1.1em;
 margin-bottom: 0px;

 li {
   display: inline;
   white-space: wrap;
 }

 li + li:before {
    content: ">";
    color: #aaaaaa;
    padding: 0 10px;
  }

 @media (max-width: 992px) {
   padding-left: 0px;
   padding-top: 0.7em;
   padding-bottom: 0.7em;
   font-size: 0.9em;
 }

`;


export default BreadCrumbStyled
