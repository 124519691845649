import React from 'react'
import { COLORS } from 'src/stylesheet/colors';
import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components'

const TOOLTIPS = {
  'price': {
    'title': 'Choisissez votre prix',
    'text': 'Avec Parkmatch, plus de soucis de paiement. Depuis votre compte, demandez le virement de vos revenus vers votre compte bancaire quand vous le souhaitez !'
  },
  'description': {
    'title': 'Soyez attractif !',
    'text': "Votre description aidera votre futur locataire à se projeter et à choisir votre parking !",
  }
}

const ToolTipWithArrow = ({ type }) => (
  <div className="rounded p-3 d-flex flex-column justify-content-center position-absolute" style={{ backgroundColor: COLORS.lightWhite, width: 206, right: -220 }}>
    <div style={styles.triangle} />
    <div className="d-flex align-content-center mb-2">
      <FaInfoCircle size={30} color={COLORS.green} />
      <div className="ml-2" style={{ fontSize: 22, color: COLORS.green }}>title</div>
    </div>
    <p>text</p>
  </div>
)

const ToolTipWithBorder = ({ type }) => (
  <ContainerBorder>
    <div className="mb-2">
      <FaInfoCircle className="mr-2" size={30} color={COLORS.green} style={{ float: 'left' }}/>
      <div style={{ fontSize: 22, color: COLORS.green }}>{TOOLTIPS[type].title}</div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: TOOLTIPS[type].text }} />
  </ContainerBorder>
)

const ContainerBorder = styled.div`
  border: 2px solid ${COLORS.green};
  border-radius: 8px;
  padding: 16px;
  max-width: 240px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

const ToolTip = ({ border, type }) => border ? <ToolTipWithBorder type={type} /> : <ToolTipWithArrow type={type} />



const styles = {
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 15px 15px 0px',
    borderColor: `transparent ${COLORS.lightWhite}`,
    position: 'absolute',
    bottom: 0,
    top: 30,
    left: -11,
  },
}

export default ToolTip
