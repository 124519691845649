import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { FaHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Remote from 'public/assets/img/remoteParkmatch.jpg';
import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors'
import OpenVideo from 'src/components/openVideo';
import Button from 'src/components/Button';
import EmojiSection from 'src/components/EmojiSection'
import PublicPage from 'src/templates/PublicPage';

import './index.css';

const EMOJI_ELEMENTS = [
  {
    emoji: "🕊",
    title: "Des frais clairs",
    text: "15% partagés entre le propriétaire et le locataire dans le cas d'une location ponctuelle et 10% dans le cas d'une location mensuelle"
  }
]

class FindParkingSpaces extends React.Component {

  render() {
    return (
      <PublicPage
        navbarPush
        metaTitle="Louez le parking garage ou box d'un particulier avec ParkMatch"
        metaDescription="Avec ParkMatch, trouvez une place de parking, un box ou un garage à louer en toute simplicité!.">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 py-5">
            <h1 className="text-center">Pourquoi louer une place de parking, un box ou un garage sur <span style={{ color: COLORS.green }}>Parkmatch</span>?</h1>
          </div>
        </div>
        <div className="row py-3" style={{ color: COLORS.blueMarine, backgroundColor: COLORS.realLightGreen, boxShadow: '0 0 12px 0 #cbcbcb80' }}>
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <p className="title-infos">Avec Parkmatch, vous pouvez louer la place d'un particulier, pour la durée que vous souhaitez !</p>
            <p className="subtitle-infos mb-3" style={{ color: COLORS.mediumBlueMarine }}>Pour un jour, un mois, une année...</p>
            <Link to="/search" style={{ width: 250, height: 55, display: 'block', margin: 'auto', marginBottom: '20px' }}>
              <Button fontSize={20} resp={true} text="Rechercher un parking" />
            </Link>
          </div>
        </div>

        <div style={{ backgroundImage: GLOBAL.gradientGray }} className="row d-flex justify-content-center pb-5 pt-3">
          <EmojiSection elements={EMOJI_ELEMENTS} />
        </div>

        <div className="row">
          <div className="col-12 pb-5">
            <h2>Comment avoir accès au parking ?</h2>
            <div style={{ fontFamily: 'BariolLight', fontSize: 26, color: COLORS.green, textAlign: 'center' }}>La solution ParkMatch</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 p-0 mb-4">
            <div className=" m-auto rounded shadow" style={{ width: 275, backgroundColor: 'white' }}>
              <div className="rounded-top" style={{ width: '100%', height: 7, backgroundImage: GLOBAL.gradientBlue }} />
              <div style={{ height: 180, borderBottom: `solid 1px ${COLORS.green}` }} className="d-flex flex-row align-items-center justify-content-around w-100">
                <div style={{ width: 60, fontSize: 18 }}>De main à main</div>
                <div><FaHandshake size="40" color={COLORS.iconsGray} /></div>
              </div>
              <div className="p-3 d-flex flex-column justify-content-center" style={{ textAlign: 'center', height: 320, fontSize: '1.1em' }}>
                <div className="mb-2">
                  Rencontrez le propriétaire, et si le parking vous convient, il vous remettra les accès au parking (clé/télécommande/badge) en échange de votre code de confirmation.
                </div>
                <div className="mb-2">
                  La location démarre dès validation du code par le propriétaire.
                </div>
                {/* <div style={{ color: COLORS.green, textDecoration: 'underline' }}>Procédure détaillée</div> */}
              </div>
            </div>
          </div>

        </div>

        <div className="p-2 mt-3 d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: 20 }}>
          <h2 className="p-2" style={{ textAlign: 'center' }}>Alors, prêt à faire votre demande ? <span role="img" aria-label="Ring">💍</span></h2>
          <Link to="/search" style={{ width: 250, height: 55 }}><Button fontSize={'1.1em'} resp={true} text="C'est parti !" /></Link>
          
        </div>

      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={FindParkingSpaces} />;
