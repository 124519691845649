import { NAV } from './nav';
import { STEP_COMPONENT, STEP_TITLE, STEP_TOOLTIP } from './add';
import { INFOS } from './car';

export default {
  NAV,
  INFOS,
  STEP_COMPONENT,
  STEP_TITLE,
  STEP_TOOLTIP,
}
