import React from 'react';

const LegalText = (props) => (
  <div className="col-md-8 offset-md-2 pt-5">

<p>Nous reconnaissons que le respect de votre vie privée revêt la plus grande importance et nous traitons dès lors cette question avec sérieux. Notre objectif principal est de vous permettre d’avoir une expérience en ligne agréable et en toute sécurité lors de l’utilisation du Service Parkmatch, tel que définis dans les Conditions Générales d’Utilisation de Parkmatch [insérer URL ici].Nous sommes persuadés que le respect de la vie privée de nos utilisateurs permet d'instaurer une relation de confiance au travers de règles transparentes et responsables. Nous souhaitons par conséquent vous donner une vue d’ensemble de nos pratiques en matière de protection des données à caractère personnel, telles que définies ci-dessous.</p>
<p>La présente Politique de Respect de la Vie Priée et de Protection des Données à Caractère Personnel (ci-après la "Politique de Confidentialité) décrit les politiques et procédures de <a href="https://www.parkmatch.eu">Parkmatch</a> en qualité de responsable de traitement en matière de collecte, d’utilisation, de stockage, de diffusion et de partage de vos données à caractère personnel lorsque vous utilisez le Service Parkmatch via, la Plateforme Parkmatch et notamment le site Internet de Parkmatch (<a href="https://www.parkmatch.eu">www.parkmatch.eu/fr</a>), un contenu Parkmatch intégré sur un autre site (tel qu'un bouton Parkmatch "Suivre").</p>
<p>La présente Politique de Confidentialité vous communique l'ensemble des informations dont vous avez besoin sur l'utilisation de vos données à caractère personnel.</p>
<p>Nous avons créé la présente Politique de Confidentialité afin que vous puissiez obtenir toutes les informations dont vous avez besoin. Nous vous invitons à lire attentivement toutes les informations que nous fournissons et si vous avez encore des questions, n’hésitez pas à nous contacter à tout moment en adressant vos demandes aux coordonnées suivantes: privacy@parkmatch.eu ou CGMATCH, 6 bis, rue de la Paroisse, GALAXY, 78000 Versailles.</p>
<p>Les termes précédés d’une lettre majuscule qui ne sont pas définis dans la présente Politique de Confidentialité ont le sens qui leur est attribué dans nos <a href="https://www.parkmatch.eu/fr/cgu">Conditions Générales d'Utilisation</a>.</p>
<h2>1.	Définitions</h2>
<p>Données à caractère personnel: ("Données Personnelles") désigne toute information se rapportant à une personne physique identifiée ou identifiable (ci-après dénommée «personne concernée»); est réputée être une «personne physique identifiable» une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale;<br/>
Les termes, "traitement", "limitation du traitement", "responsable du traitement", "sous-traitant", "destinataire" ont le sens qui leur est donné à l'article 4 du Règlement Général sur la Protection des Données  à caractère personnel n°2016/679 ("RGPD") dont les dispositions sont applicables depuis le 25 mai 2018.</p>
<h2>2.	Quelles Données Personnelles nous collectons?</h2>
<h3>2.1 Les données nécessaires à la création du compte et à l'utilisation du Service</h3>
<p>En utilisant le Service Parkmatch, vous êtes amenés à nous transmettre des informations, dont certaines sont de nature à vous identifier et qui constituent des Données Personnelles. C’est notamment le cas lorsque vous remplissez des formulaires (comme par exemple le formulaire d’inscription), lorsque vous participez à l’un de nos jeux, concours, offres promotionnelles, études ou sondages, lorsque vous nous contactez – que ce soit par téléphone, e-mail ou tout autre moyen de communication.</p>
<p>Nous collectons notamment ces Données Personnelles que vous nous fournissez lorsque vous:</p>
 <ul>
    <li>créez un compte;</li>
    <li>effectuez une réservation;</li>
    <li>ajoutez une annonce;</li>
    <li>utilisez notre outil d'estimation en ligne;</li>
    <li>vos informations bancaires pour la gestion des paiements par le prestataire;</li>
    <li>pour afficher la liste des parkings disponibles dans un lieu donné;</li>
    <li>utilisez notre outil d'estimation en ligne;</li>
    <li>correspondez avec nous; ou
    </li><li>souscrivez à notre newsletter.</li>
</ul>
<p>Tous les Utilisateurs fournissent à Parkmatch les données nécessaires à l'inscription et à l'utilisation du Service et en particulier: nom et prénom, adresse email, lieu de domicile (ville, pays (adresse)), sexe, numéro de téléphone et potentiellement une photographie.</p>
<p>Les Conducteurs fournissent également à Parkmatch des informations relatives à leurs véhicules: immatriculation, taille, marque, modèle, couleur et éventuellement une photographie du véhicule pour les utilisateurs vérifiés.</p>
<p>Les Détenteurs de Place fournissent également à Parkmatch des informations relatives à l'emplacement de leur Parking, les données de la télécommande de leur Parking, une ou plusieurs photographies du Parking.</p>
<h3>2.2 Les données complémentaires que vous nous fournissez en utilisant le Service</h3>
<p>En utilisant certaines fonctionnalités sur la Plateforme, vous pouvez être amenés à nous transmettre volontairement certaines des données complémentaires suivantes:</p>
<ul>
    <li>Les données de votre annonce de Parking;</li>
    <li>Une copie de l’ensemble des réservations ou des publications effectuées sur la Plateforme ;</li>
    <li>Le détail des opérations financières ou comptables effectuées sur la Plateforme, contenant notamment les informations relatives à votre carte de paiement, vos données bancaires, les informations relatives aux parkings réservés ou pour lesquels vous avez publié une annonce sur la Plateforme ;</li>
    <li>Le cas échéant, vos réponses à nos sondages et questionnaires, les avis que vous avez laissés ainsi que votre évaluation, ces informations étant notamment susceptibles d’être utilisées dans le cadre de recherches et d’analyses du comportement utilisateur ;</li>
    <li>Une copie de tous les échanges intervenus entre vous et Parkmatch;</li>
    <li>Les données que nous pouvons vous demander de fournir lorsque vous nous signalez un problème relatif à nos Plateformes ou à nos services, comme par exemple l’objet de votre demande d’assistance ;</li>
    <li>Les données liées à votre localisation lorsque vous avez accepté que nous collections et traitions ces données ;</li>
    <li>Une copie de votre passeport, votre permis de conduite, votre carte d’identité ou tout autre document similaire que vous avez accepté de nous fournir.</li>
</ul>
<h3>2.3 Les données que nous collectons automatiquement</h3>
<p>Lors de chacun de vos accès à la Plateforme, nous sommes susceptibles de collecter, conformément à la législation applicable et avec votre accord, le cas échéant, des informations relatives aux appareils sur lesquels vous utilisez le Service ou aux réseaux depuis lesquels vous accédez au Service, tels que notamment vos adresses IP, données de connexion, types et versions de navigateurs internet utilisés, types et versions des plugins de votre navigateur, systèmes et plateformes d’exploitation, données concernant votre parcours de navigation sur la Plateforme, notamment votre parcours sur les différentes pages, le contenu auquel vous accédez ou que vous consultez, les termes de recherches utilisés, les erreurs de téléchargement, la durée de consultation de certaines pages. Afin de collecter ces informations, nous avons recours à certains Cookies. Pour plus d'information sur l'installation et l'utilisation des Cookies, veuillez-vous référer à l'article 9 infra.</p>
<p>Nous collectons également des informations sur votre utilisation de la Plateforme (telles que le nombre d’annonces publiées ou de réservations effectuées, votre date d’inscription, vos avis et votre évlauation, etc.) qui sont susceptibles d’être affichées sur votre profil public.</p>
<p>Si vous vous connectez à nos services via les fonctionnalités de réseaux sociaux mises à votre disposition, Parkmatch aura accès à certaines de vos Données personnelles notamment, vos prénom, nom de famille, photographie, adresse e-mail et nombre d’amis sur ledit réseau social conformément aux conditions générales d’utilisation du réseau social concerné. Nous sommes également susceptibles de recueillir certaines Données personnelles lorsque vous interagissez avec des fonctionnalités de ces réseaux sociaux, tel que les fonctionnalités « J’aime » ou « Suivre ».</p>
<h2>3.	Comment utilisons-nous les données que nous collectons ?</h2>
<p>Nous utilisons et conservons les Données Personnelles que nous collectons sur et grâce à vous uniquement pour les finalités suivantes :</p>
<ul>
    <li>Vous fournir le Service Parkmatch et vous permettre l'accès au Parking réservé;</li>
    <li>Conclure et exécuter les contrats conclus avec nous;</li>
    <li>Améliorer le Service et vous permettre de personnaliser votre profil;</li>
    <li>Vous envoyez des informations sur le Service et vos Réservations (par exemple, une notification de confirmation de réservation par e-mail);</li>
    <li>Vous permettre de communiquer avec d'autres Utilisateurs;</li>
    <li>percevoir vos paiements ou vous transmettre les sommes collectées pour votre compte;</li>
    <li>Vous informer des modifications apportées à nos services;</li>
    <li>Répondre à vos requêtes;</li>
    <li>Mieux personnaliser les contenus, publicités et fonctionnalités, la performance et le maintien du Service;</li>
</ul>
<p>Nous utilisons également vos Données Personnelles afin de vous envoyer conformément aux dispositions légales applicables et avec votre consentement lorsque nécessaire, des messages marketing, promotionnels et publicitaires ainsi que des informations relatives à l'utilisation du Service ou vous suggérez des biens et services fournis par nos partenaires, à moins que vous nous ayez demandé de ne pas recevoir de telles communications promotionnelle.</p>
<p>Nous pouvons également réaliser certains traitements sur vos Données Personnelles à des fins d'évaluation de vos Annonces et de votre utilisation du Service Parkmatch. Ces traitements nous permettent notamment d'évaluer les Parkings (leur accessibilité, leur description, la qualité de l'annonce) ainsi que sur l'Utilisation du Service en tant que Conducteur (fréquence d'utilisation, nombre et durées des retards et dépassements des Réservations). Nous pourrons attribuer une note à l'Utilisateur en fonction de ces critères qui sera visible pour les Détenteurs de place au moment de l'acceptation d'une Réservation.</p>
<p>Nous collectons et traitons vos Données Personnelles conformément au RGPD tel qu'implémenté en France en se fondant sur les bases légales suivantes:</p>
<ul>
    <li>La conclusion et l'exécution de contrats nécessaires à la fourniture du Service Parkmatch;</li>
    <li>L'intérêt légitime de Parkmatch à réaliser certains traitements de Données Personnelles; et notamment afin d'améliorer le Service;</li>
    <li>Dans certaines hypothèses et notamment pour l'envoi de communication commerciales [et localisation] dans les cas où vous avez consentis à de tels traitements de vos Données Personnelles.</li>
</ul>

<h2>4.	Comment partageons-nous vos Données Personnelles?</h2>
<p>A moins qu'il ne soit stipulé autrement dans la présente Politique de Confidentialité, nous n'échangeons, ne louons, ou ne partageons avec ou à des tiers aucune de vos Données Personnelles, à moins que vous nous ayez demandé ou autorisés à le faire.</p>
<p>Dans le cadre de l'utilisation de nos services, nous communiquons certaines Données Personnelles vous concernant aux autres utilisateurs de notre communauté (par exemple, nous communiquons certaines données de votre profil public, tel que l'emplacement de votre parking pour permettre les réservations).</p>
<p>Nous publions les avis que vous rédigez dans le cadre de notre système d’avis sur nos Plateformes. Ces avis, qui contiennent votre prénom et la première lettre de votre nom ainsi que votre photographie sont visibles par tous les visiteurs de nos Plateformes.</p>
<p>Nous pouvons également afficher sur notre Plateforme certaines informations relatives aux Parkings lorsque les Utilisateurs effectuent des recherches telles que l'emplacement du Parking, la description qui en est faite par le Détenteur de Place, les horaires de disponibilités et/ou le prénom et la première lettre du nom du Détenteur de Place.</p>
<p>Nous pouvons également être amenés à partager certaines de vos Données Personnelles à certains de nos prestataires de services, sous-traitant le traitement de vos données pour le compte et sous les instructions de Parkmatch. Certaines de vos données partagées sont notamment partagées avec nos prestataires de services afin de mettre en place des processus de paiement pour nos services, héberger sur des serveurs distants certaines données ainsi que pour certaines actions liées à la gestion de la relation client et à la gestion des réclamations. Dans ce cadre nous avons mis en place des contrats avec ses prestataires pour s'assurer que vos données sont protégées avec un niveau de sécurité adaptée au risque du traitement.</p>
<p>Nous pouvons également transmettre certaines de vos Données Personnelles à certains tiers dont notamment:</p>
<ul>
    <li>Des réseaux sociaux qui peuvent vous permettre de vous identifier sur la Plateforme et pour enrichir votre profil sur la Plateforme;</li>
    <li>Des partenaires commerciaux qui font la promotion de leurs produits et services sur la Plateforme et pour lesquels vous avez décidez de souscrire.</li>
    <li>Des partenaires commerciaux afin de faire la promotion des Annonces des Détenteurs de Place sur leurs plateformes respectives pour faciliter la recherche de Conducteurs.</li>
</ul>
<p>Si Parkmatch ou tout ou partie de ses actifs sont rachetés par un tiers, les Données Personnelles en notre possession seront, le cas échéant, transmises au nouveau propriétaire.</p>
<p>Conformément aux dispositions légales en vigueur, Parkmatch peut être tenu de fournir des informations aux autorités compétentes et tribunaux. Dans ce cas, Parkmatch peut ne pas avoir la possibilité de vous informer de telles divulgations de vos Données Personnelles.</p>
<h2>5.	Comment protégeons-nous vos Données Personnelles?</h2>
<p>Afin d'assurer la sécurité de vos Données Personnelles, nous avons mis en place des mesures de sécurité techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au risque et d'éviter toute destruction, perte, altération et divulgation non autorisée de vos Données Personnelles.</p>
<p>Dans ce cadre nous avons notamment mis en place des mesures permettant le chiffrement de vos Données Personnelles, des moyens permettant de garantir la confidentialité, l'intégrité de nos systèmes d'informations et la disponibilité de nos Services, des procédures vidant à accéder et récupérer vos Données Personnelles en cas d'incident technique et physique ainsi que des limitations d'accès à nos serveurs. Nos systèmes sont également régulièrement testés et évaluer afin de toujours avoir en place un dispositif conforme à l'état de l'art en matière de sécurité physique et informatique.</p>
<h2>6.	Vos Données Personnelles sont-elles transférées en dehors de l'Union Européenne?</h2>
<p>Nous pouvons être amenés à transférer certaines de vos Données Personnelles à certains de nos prestataires de services et partenaires commerciaux situés dans un pays n'offrant pas un niveau adéquat de protection des Données Personnelles. Afin d'assurer la sécurité et l'intégrité de vos Données Personnelles lors de tels transferts nous avons mis en place auprès de ces destinataires des garanties appropriées afin de garantir un niveau de protection équivalent à celui de l'Union Européenne et notamment au moyen des Clauses Contractuelles Types de la Commission Européenne ainsi que le mécanisme de certification Privacy Shield.</p>
<p>Vous pouvez obtenir une copie de ces garanties en nous contactant à l'adresse privacy@parkmatch.eu.</p>

<h2>7.	Prospection commerciale et publicité ciblée</h2>
<p>Conformément à la législation en vigueur et si applicable, lorsque vous avez donné votre consentement, nous pourrons utiliser vos Données Personnelles que vous nous fournissez à des fins de prospection commerciale et notamment pour vous adresser nos newsletters, afficher des publicités ciblées sur notre plateforme, sur les réseaux sociaux et sur des sites de tiers et pour vous envoyer des invitations à nos évènements.</p>
<p>En ce qui concerne les emails promotionnels et newsletters : Vous pouvez à tout moment retirer votre consentement en cliquant sur le lien de désinscription fourni dans chacune de nos communications ou en décochant la case afférente dans votre compte ou en nous contactant à l'adresse privacy@parkmatch.eu.</p>
<p>S'agissant des messages commerciaux par SMS, vous pouvez vous opposer à tout moment en envoyant le message STOP.</p>
<p>En matière de publicité ciblée :</p>
<ul>
    <li>Sur les plateformes de réseaux sociaux (par exemple Facebook, Twitter): vous pouvez vous opposer à tout moment à ce traitement en configurant les paramètres relatifs à la publicité de votre compte ;</li>
    <li>Sur des sites tiers via le paramétrage de vos cookies (voir article 9).</li>
</ul>
<h2>8.	Comment conservons-nous vos Données Personnelles?</h2>
<p>Nous conservons uniquement vos Données Personnelles pour la période nécessaire  à l'exécution de nos Services et à la durée de votre adhésion. Toutefois, les données de votre carte bancaire sont traitées et conservées par notre partenaire gérant les paiements. uniquement pour la durée nécessaire à la réalisation de vos transactions, sauf si vous acceptez que nous les enregistrions de manière sécurisée pour faciliter vos prochaines transactions pour la réservation de véhicules sur notre Plateforme.</p>
<p>Les données de connexion ne sont conservés que pour une durée maximale de un an et les cookies sont stockées pendant un maximum de 13 mois pour nos besoins statistiques et sont ensuite supprimées.</p>
<p>Nous conservons les Données personnelles de nos Utilisateurs pour la durée de la relation contractuelle et s'agissant des prospects pour une durée n'excédant pas 3 ans depuis le dernier contact avec Parkmatch.</p>
<p>Lorsque les durées de conservation mentionnées ci-dessus sont expirées, nous pouvons archiver certaines de vos Données Personnelles dans des bases d'archivage sécurisées avec un accès restreint.</p>
<h2>9.	Notre politique concernant l'utilisation des Cookies</h2>
<p>Lorsque vous accéder à la Plateforme ou utiliser nos Services, nous sommes susceptibles d'utiliser des Cookies pour collecter et conserver certaines informations vous concernant. Les Cookies sont des fichiers de données placés sur vos appareils lorsqu'ils sont utilisés pour accéder à la Plateforme ou pour utiliser le Service ("Cookies").</p>
<p>Nous sommes susceptibles d'utiliser à la fois des Cookies de session (qui sont supprimés lorsque vous fermez votre navigateur internet) et des Cookies persistants (qui sont conservés sur votre équipement jusqu'à ce que vous les supprimiez ou pour une durée maximale de 13 mois) ainsi que d’autres technologies tels que les pixels invisibles, afin de : (i) stocker votre nom d’utilisateur, votre mot de passe et générer le remplissage automatique de formulaires; (ii) personnaliser le Service en fonction de vos préférences, (iii) analyser l’usage que vous faites du Service; et (iv) contrôler les publicités affichées sur la Plateforme.</p>
<p>Nous pouvons également inclure des pixels invisibles dans des emails que nous vous envoyons, afin de déterminer si le message a été ouvert.</p>
<p>Cookies de session:</p>
<ul>
    <li>Objet: Les Cookies de Session s'assurent que vous êtes identifié lorsque vous naviguez d'une page à l'autre sur la Plateforme et que toutes les informations que vous avez entrées sont enregistrées. Vous pouvez choisir de ne pas accepter les Cookies de Session en modifiant les paramètres de votre navigateur.</li>
    <li>Durée: Les Cookies de Session disparaissent une fois que vous avez fermé votre navigateur internet.</li>
</ul>
<p>Cookies Persistants:</p>
<ul>
    <li>Objet: Les Cookies Persistants permettent de se souvenir de vos informations et paramètres lors de vos prochaines consultations. Cela permet d'accélérer et de faciliter votre accès. Les Cookies Persistants rendent possible les fonctionnalités suivantes : l'authentification, le choix de la langue, le choix du thème, les préférences relatives au menu, les signets ou favoris internes à la Plateforme. Les Cookies peuvent améliorer votre expérience en ligne en sauvegardant vos préférences.</li>
</ul>
<p>Les Cookies Persistants nous permettent également de sélectionner les publicités ou les offres susceptibles de vous intéresser le plus et de les afficher lorsque vous utilisez le Service.</p>
<ul>
    <li>Durée: Maximum 13 mois s'ils ne sont pas supprimés auparavant par l'Utilisateur.</li>
</ul>
<p>Nous avons recours à ces technologies afin de recueillir et d'analyser certains types d’informations, parmi lesquelles :</p>
<ul>
    <li>des informations relatives aux systèmes ou navigateurs que vous utilisez pour accéder au Service ou interagir avec celui-ci, notamment les adresses IP et MAC, les informations relatives à la géolocalisation, les identifiants uniques et autres informations concernant votre appareil mobile, types de navigateurs, langue utilisée par le navigateur; et</li>
    <li>des informations concernant les modalités d'interaction avec la Plateforme et notamment les pages visitées, les pages de renvoi et de sortie, le nombre de clics, les noms de domaine, l’ordre de visite et le temps passé sur lesdites pages, la date et l’heure à laquelle vous avez accéder à la Plateforme ainsi que d’autres informations similaires.</li>
    <li>Si vous préférez refuser les Cookies, la plupart des navigateurs vous autoriseront à : (i) changer les paramètres de navigation pour vous alerter lorsque vous recevez un Cookie, et vous pourrez alors choisir de l'accepter ou non ; (ii) désactiver les Cookies existants ; ou (iii) configurer votre navigateur pour qu'il rejette automatiquement tous les Cookies.</li>
</ul>
<p>Pour en savoir plus sur la manière de paramétrer votre navigateur internet, vous-pouvez consulter les pages suivantes:</p>
<ul>
    <li>Internet Explorer</li>
    <li>Firefox</li>
    <li>Chrome</li>
    <li>Safari</li>
    <li>Opera</li>

<p>Pour en savoir plus sur les Cookies, veuillez consulter le site <a href="http://www.allaboutcookies.org/fr/">http://www.allaboutcookies.org/fr/</a> comprenant des informations complémentaires utiles à propos des Cookies et comment les bloquer en fonction des différents types de navigateur.</p>
<p>De plus, Firefox et Chrome possèdent des vastes catalogues d'extensions pouvant vous aider à améliorer le contrôle de votre confidentialité.</p>
<p>Sachez que si vous désactivez ou refusez des Cookies, certaines fonctionnalités sont susceptibles de ne pas fonctionner correctement car il est possible que nous ne soyons pas en mesure de vous identifier et de vous associer à votre Compte Parkmatch.</p>
<h2>10.	Quels sont vos droits sur vos Données Personnelles?</h2>
<p>Conformément aux dispositions applicables en France, vous disposez du droit d'accéder à vos Données Personnelles et de demander à Parkmatch de rectifier ou de supprimer vos Données Personnelles. Vous disposez également du droit à la limitation du traitement ainsi que de vous opposer à un tel traitement sous réserve que des motifs légitimes de Parkmatch ne s'opposent pas à une telle requête.</p>
<p>Vous disposez également du droit de recevoir les Données Personnelles vous concernant, dans un format structuré, couramment utilisé et lisible au moyen d'une machine, et de transmettre ces données à un autre responsable de traitement.</p>
<p>En outre, vous disposez du droit d'introduire une réclamation auprès de la CNIL, si vous considérez que vos Données Personnelles ont été traitées de manière déloyale.</p>
<p>Lorsque vous avez donné votre consentement à un traitement particulier, vous avez le droit de le retirer à tout moment, sans affecter la licéité du traitement fondé sur ce consentement avant son retrait.</p>
<p>Par ailleurs, vous disposez également du droit de définir des directives sur le sort de vos Données Personnelles après la mort, conformément à la loi n°2016-1321 pour une République Numérique.</p>
<p>Pour exercer l'un ou l'autre de vos droits, vous pouvez adresser votre demande accompagnée d'une photocopie d'une pièce d'identité aux coordonnées suivantes:</p>
<p>privacy@parkmatch.eu</p>
<p>Ou</p>
<p>Délégué à la Protection des Données<br/>
CGMATCH SAS<br/>
6 bis, rue de la Paroisse, GALAXY, 78000 Versailles.</p>

<h2>11.	Modification de la Politique de Confidentialité</h2>
<p>Parkmatch se réserve le droit de modifier, à sa seule discrétion, la présente Politique de Confidentialité à tout moment et sans notification préalable, en tenant compte des modifications des dispositions en vigueur en matière de protection des données à caractère personnel. Si nous procédons à des modifications de la présente Politique de Confidentialité nous publierons la version modifiée sur la Plateforme et nous vous adresserons une notification de la modification par e-mail. Nous actualiserons également la « Date de dernière mise à jour » figurant en haut de la présente Politique de Confidentialité et vous aurez le droit de vous y opposer dans les prévues aux Conditions Générales d'Utilisation.</p>

            </ul>
  </div>
)

export default LegalText
