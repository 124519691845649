import React from 'react'
import Helmet from 'react-helmet'

function Iframe({ src, date, cardNumber, code, onIframeEndProcess }) {
  let iframeRef = null
  let firstTimeLoad = 0

  function onLoadUrl(data) {
    // do this to know if the iframe form is over
    // when an error is caught, close iframe and refresh card list
    if (firstTimeLoad >= 1) {
      try {
        // do not remove this console.log
        console.log(iframeRef.contentWindow.location.href)
      } catch (e) {
        onIframeEndProcess()
      }
    } else {
      firstTimeLoad++
    }
  }
  // Helmet required to access iframe url when it gets redirected to parkmatch.eu domain
  return (
    <iframe src={src} title="Card Add"
      id="iframe-test"
      height="625px"
      width="100%"
      ref={node => iframeRef = node}
      onLoad={onLoadUrl}
    />
  )
}

export default Iframe
