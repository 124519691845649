import React, { useState, useEffect } from 'react'
import PrivateRoute from 'src/containers/PrivateRoute'
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components'

import Tooltip from 'src/components/Tooltip';
import { Consumer } from 'src/services/store';
import PrivatePageTemplate from 'src/templates/PrivatePage';
import BookingStatus from 'src/components/BookingStatus';
import BookingType from 'src/components/BookingType'
import BookingDrawer from 'src/components/BookingDrawer';
import { formatBookingDates } from 'src/utils/DateUtils';
import { RENTAL_MODE } from 'src/services/store/SearchStore'

const BookingsPage = ({ data: { getBookingsListForTenant, bookingsTenant: bookings } }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [bookingId, setBookingId] = useState(null)

  async function getBookingsList() {
    const res = await getBookingsListForTenant()
    setIsLoading(false)
    if (res.error) {
      setError(true)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getBookingsList()
  }, [])

  const toggleDrawer = (bookingId = null) => {
    setDrawerVisible(!drawerVisible)
    setBookingId(bookingId)
    getBookingsListForTenant()
  }

  return (
    <PrivatePageTemplate isLoading={isLoading} error={error} title="Mes réservations" metaTitle="Mes réservations">
      {bookings.length > 0 &&
        <Paper>
          <Table>
            <TableHeader />
            <TableBody>
              {bookings.map((row, index) => (
                <StyledTableRow key={row.booking_id} hover
                  onClick={() => toggleDrawer(row.booking_id)}>
                  <Hidden smDown>
                    <TableCell align="left">
                      <BookingType type={row.type} />
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="right">
                      {formatBookingDates(row)}
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="right">
                      {row.total_price} €{row.type === RENTAL_MODE.monthly && '/mois'}
                    </TableCell>
                  </Hidden>
                  <TableCell align="right">
                    {row.address}, {row.city}
                  </TableCell>
                  <TableCell align="right">
                    <BookingStatus status={row.status} />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      }
      {
        bookings.length === 0 &&
        <Tooltip text="Vous n'avez aucune réservation pour le moment." />
      }
      <Drawer anchor="right" open={drawerVisible} onClose={toggleDrawer}>
        <BookingDrawer closeDrawer={toggleDrawer} bookingId={bookingId} userType='tenant' />
      </Drawer>
    </PrivatePageTemplate>
  )
}

const TableHeader = ({ deleteMode }) => (
  <TableHead>
    <TableRow>
      <Hidden smDown>
        <TableCell align="left">Type</TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="right">Dates</TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="right">Prix</TableCell>
      </Hidden>
      <TableCell align="right">Adresse</TableCell>
      <TableCell align="right">Status</TableCell>
    </TableRow>
  </TableHead>
)

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`

export default () => (
  <Consumer>
    {ctx => <PrivateRoute component={BookingsPage} data={ctx} />}
  </Consumer>
);
