import React from 'react'
import StyledButton from 'src/components/StyledButton';
import PhoneIcon from '@material-ui/icons/Phone';
import FormatPhone from 'src/utils/FormatPhone'

const PhoneButton = ({ phone }) => (
  <a href={`tel:${phone}`}>
    <StyledButton onClick={() => null}>
      <PhoneIcon />
      <span className="ml-2">
        {FormatPhone(phone)}
      </span>
    </StyledButton>
  </a>
)

export default PhoneButton
