import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoaderSimple from 'src/components/LoaderSimple';

import NavigationBar from 'src/components/NavigationBar'
import Footer from 'src/components/Footer';
import FooterExit from 'src/components/FooterExit';
import DrawerMenu from 'src/components/DrawerMenu'
import { Helmet } from 'react-helmet'

const styles = theme => ({
  content: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
});

class PublicPage extends React.Component {

  state = {
    drawerMenuOpen: false,
  }

  handleDrawerToggle = () => this.setState({ drawerMenuOpen: !this.state.drawerMenuOpen })

  head() {

    return (
      <Helmet>
        <title>{this.props.metaTitle}</title>
        <meta name="description" content={'🚗 ' + this.props.metaDescription} />
      </Helmet>
    )
  }

  render() {
    const { classes, children, isLoading = false, error = false, footer = true, navbarPush = false, noContainer = false } = this.props;
    const { drawerMenuOpen } = this.state

    return (
      <>
        {this.head()}
        <NavigationBar openMenu={this.handleDrawerToggle} navbarPush={navbarPush} />
        <DrawerMenu openMenu={this.handleDrawerToggle} drawerMenuOpen={drawerMenuOpen} />
        <main className={classes.content} style={{ minHeight: "100vh" }}>

          {!isLoading && !error && noContainer &&
            <>
              {children}
            </>
          }
          {!isLoading && !error && !noContainer &&
            <div className="container-fluid">
              {children}
            </div>
          }
          {isLoading &&
            <div className="container-fluid">
              <div className="row justify-content-center vh-100" style={{ alignItems: 'center' }}>
                <LoaderSimple />
              </div>
            </div>
          }
          {error &&
            <div className="container-fluid">
              <div className="row justify-content-center vh-100" style={{ alignItems: 'center' }}>
                Une erreur est survenue
              </div>
            </div>
          }
          {footer && <FooterExit />}
        </main>
      </>
    );
  }
}

PublicPage.propTypes = {
  classes: PropTypes.object.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default withStyles(styles)(PublicPage);
