import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import BookingApi from 'src/api/booking'
import ParkingApi from 'src/api/parking'
import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';
import Dialog from './Dialog';
import PhoneButton from 'src/components/PhoneButton';
import { RENTAL_MODE } from 'src/services/store/SearchStore'
import Tooltip from 'src/components/Tooltip'

const TEXT = {
  titleDialog: 'Demander la fin de la location?',
  textDialogOwner: "Le préavis de résiliation d'un mois sera effectif dès que vous aurez confirmé. Le locataire sera informé par mail et vous devrez rentrer en contact avec lui afin de récupérer vos accès au parking.",
  textDialogTenant: "Le préavis de résiliation d'un mois sera effectif dès que vous aurez confirmé. Le propriétaire sera informé par mail et vous devrez rentrer en contact avec lui afin de lui rendre les accès au parking.",
}

function OngoingBooking({ booking, parkingId, refreshBooking, data, userType }) {
  const [dialog, setDialog] = useState(false)
  function switchEndBookingDialog() { setDialog(!dialog) }

  async function endBooking() {
    const res = await ParkingApi.endBooking({ parkingId, bookingId: booking.booking_id })
    if (!res.error) {
      switchEndBookingDialog()
      refreshBooking()
      data.getBookingsListForParking(parkingId)
    }
  }

  async function endBookingTenant() {
    const res = await BookingApi.endBooking({ bookingId: booking.booking_id })
    if (!res.error) {
      switchEndBookingDialog()
      refreshBooking()
      data.getBookingsListForTenant()
    }
  }

  return (
    <div className="h-100 text-center w-100 pt-5 px-4">
      <div>
        {(userType === 'owner' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
          <>
            <span style={styles.title}>Note du locataire</span>
            <StyledP>{booking.comment ? booking.comment : "Le locataire n'a pas laissé de note."}</StyledP>
          </>
        }
        {(userType === 'tenant' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
          <>
            <span style={styles.title}>Votre note au propriétaire</span>
            <StyledP>{booking.comment ? booking.comment : "Vous n'avez pas laissé de note."}</StyledP>
          </>
        }
      </div>
      <div>
        {booking.status !== 'ENDED' &&
          <PhoneButton phone={userType === 'tenant' ? booking.owner_phone : booking.tenant_phone} />
        }
        {booking.status === 'ENDED' &&
          <p style={{ color: COLORS.green, fontSize: '1.1rem' }}>Le revenu lié à cette réservation sera disponible sur votre compte Parkmatch 48h après la date de fin.</p>
        }

        {booking.recurrence_type === RENTAL_MODE.monthly &&
          <>
            <Tooltip color="green" text="Préavis de 30 jours pour toute résiliation d'abonnement." />
            <StyledButton onClick={switchEndBookingDialog} className="no-outline">
              Résilier l'abonnement
      </StyledButton>
            <Dialog
              closeDialog={switchEndBookingDialog}
              visible={dialog}
              action={userType === 'tenant' ? endBookingTenant : endBooking}
              title={TEXT.titleDialog}
              text={userType === 'tenant' ? TEXT.textDialogTenant : TEXT.textDialogOwner}
            />
          </>
        }
      </div>

    </div>
  )
}

const StyledButton = styled(Button)`
  && {
    font-size: 22px;
    color: ${COLORS.red};
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    font-weight: normal;
    text-transform: none;
  }
`

export default (props) => (
  <Consumer>
    {ctx => <OngoingBooking {...props} data={ctx} />}
  </Consumer>
)

const styles = {
  title: {
    fontSize: 25,
    color: COLORS.green,
    textAlign: 'center',
    marginTop: 20,
  },
}

const StyledP = styled.p`
  white-space: pre-wrap;;
`

