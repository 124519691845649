import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress';

import StyledButton from 'src/components/StyledButton'
import Tooltip from 'src/components/Tooltip'
import { COLORS } from 'src/stylesheet/colors';
import { DayMonthYear } from 'src/utils/DateUtils'
import KYCStatus from './KYCStatus'

const TRANSFER_STATUS = {
  'ACCEPTED': 'Accepté',
  'REFUSED': 'Refusé',
}

const TransferPanel = ({ transfers = [], walletData = null, ...rest }) => (
  <>
    {walletData && <BalanceHeader {...rest} walletData={walletData} />}
    <Paper>
      {transfers && transfers.length > 0 && <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Montant</TableCell>
            <Hidden smDown>
              <TableCell align="right">Statut</TableCell>
              <TableCell align="right">Référence</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {transfers.map(row => (
            <TableRow key={row.reference}>
              <TableCell component="th" scope="row">
                {DayMonthYear(row.date)}
              </TableCell>
              <TableCell align="right">{row.amount} €</TableCell>
              <Hidden smDown>
                <TableCell align="right">{TRANSFER_STATUS[row.status]}</TableCell>
                <TableCell align="right">{row.reference}</TableCell>
              </Hidden>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
    </Paper>
  </>
)

const BalanceHeader = ({ askTransfer, isLoading, walletData }) => (
  <>
    {walletData.status === "KYC1" &&
      <Tooltip text="Vous devez renseigner vos informations bancaires avant de pouvoir faire un virement."
        className="mb-4"
        color="yellow"
      />
    }
    <StyledPaper className="mb-4">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <p style={{ marginBottom: 0, fontSize: '1.1rem' }}>
          Votre solde actuel:
        <span className="ml-2" style={{ fontSize: 25, lineHeight: 1.2, fontWeight: 'bold', color: COLORS.green }}>
            {walletData.balance || '-'} €
        </span>
          {walletData.pending_earnings !== '0.00' &&
            <>
              <br /><span style={{ fontSize: '1rem', color: 'grey' }}>+ {walletData.pending_earnings} bientôt disponible.</span>
            </>
          }
        </p>
        <KYCStatus walletData={walletData} />
      </div>
      {walletData.status === "KYC2" &&
        <TransferButton balance={walletData.balance} askTransfer={askTransfer} isLoading={isLoading} />}
    </StyledPaper>
  </>
)

const TransferButton = ({ balance, askTransfer, isLoading }) => (
  <>
    {!isLoading && <StyledButton onClick={askTransfer} disabled={parseFloat(balance) < 20}>
      Effectuer un virement {(parseFloat(balance) < 20) && '(min. 20€)'}
    </StyledButton>}
    {isLoading && <CircularProgress color="primary" />}
  </>
)

const StyledPaper = styled(Paper)`
  border-left: 5px solid ${COLORS.green};
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default TransferPanel
