import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import MapBoxGeocoder from './MapBoxGeocoder'
import { COLORS } from 'src/stylesheet/colors'



const useStyles = makeStyles(theme => ({
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
}));

export default function IntegrationAutosuggest({ setLocation }) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        single: '',
        popper: '',
    });

    const [stateSuggestions, setSuggestions] = React.useState([]);

    const handleSuggestionsFetchRequested = async ({ value }) => {
        setSuggestions(await getSuggestions(value));
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleChange = name => (event, { newValue }) => {
        setState({
            ...state,
            [name]: newValue,
        });
    };

    function getSuggestionValue(suggestion) {
        setLocation({
            lon: suggestion.center[0], lat: suggestion.center[1],
            address: suggestion['place_name_fr']
        })
        return suggestion['place_name_fr'];
    }


    function renderInputComponent(inputProps) {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <TextField
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                }}
                {...other}
            />
        );
    }

    function renderSuggestion(suggestion, { query, isHighlighted }) {
        const matches = match(suggestion.place_name_fr, query);
        const parts = parse(suggestion.place_name_fr, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => (
                        <span key={index} style={{
                            color: part.highlight ? COLORS.green : 'black',
                            fontWeight: part.highlight ? 'bold' : 'normal'
                        }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            </MenuItem>
        );
    }

    async function getSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        if (inputLength === 0) {
            return []
        }
        else {
            const res = await MapBoxGeocoder(inputValue)
            if (res.data.features) {
                return res.data.features
            }
            else { return [] }
        }

    }

    const autosuggestProps = {
        renderInputComponent,
        suggestions: stateSuggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
    };

    return (
        <Autosuggest
            {...autosuggestProps}
            inputProps={{
                classes,
                id: 'react-autosuggest-simple',
                label: 'Adresse',
                placeholder: "Entrez l'adresse du bien",
                value: state.single,
                onChange: handleChange('single'),
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                    {options.children}
                </Paper>
            )}
        />
    );
}