import React from 'react'
import { FaTimes, FaCheck } from 'react-icons/fa';
import Chip from '@material-ui/core/Chip';

const KYCStatus = ({ walletData }) => (
    <Chip
        label={(walletData && walletData.status === 'KYC2') ? "Compte validé" : "Compte non validé"}
        icon={(walletData && walletData.status === 'KYC2') ?
            <FaCheck color='white' style={{ paddingLeft: 2 }} /> :
            <FaTimes color='white' style={{ paddingLeft: 2 }} />}
        style={{ color: 'white', fontSize: '1rem', margin: 5 }}
        color={(walletData && walletData.status === 'KYC2') ? 'primary' : 'secondary'}
    />
)

export default KYCStatus