import React from 'react';
import PropTypes from 'prop-types';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Typography from '@material-ui/core/Typography';
// import styled from "styled-components";
import { withStyles } from '@material-ui/core/styles';

import GLOBAL from 'src/stylesheet';
import ImageParking from 'src/components/ImageParking';
import InfoParking from 'src/components/InfoParking';
import Button from '../button';
import ParkingStatus from 'src/components/ParkingStatus/ParkingStatus';
import Badge from '@material-ui/core/Badge';
import { COLORS } from 'src/stylesheet/colors'

// import { FaImage } from 'react-icons/fa';
// import { COLORS } from 'src/stylesheet/colors';

const CardParking = ({ data, history }) => (
  <div className="shadow p-3 rounded w-auto" style={{ border: GLOBAL.borderGray }}>
    <div className="d-flex justify-content-around">
      <h5 className="">{data.address}</h5>
      <div className="pl-2"><ParkingStatus id={data.parking_id} labelType={data.status} toolTip={true} /></div>
    </div>
    <div className="d-flex flex-row">
      <ImageParking image={data.cover} price={data.monthly_price} />
      <div className="d-none d-md-block">
        <InfoParking typeCar={data.size && data.size.toUpperCase()} monthly_price={data.monthly_price} />
      </div>
    </div>
    <div className="d-flex mt-3 justify-content-around">
      <StyledBadge badgeContent={data.nb_awaiting_validation} invisible={data.nb_awaiting_validation === 0}>
        <Button
          onClick={() => history.push(`parking/${data.parking_id}/bookings`)}
          resp
          textButton="Réservations" key="btn1" label={data.nb_awaiting_validation} />
      </StyledBadge>
      <Button
        onClick={() => history.push(`parking/${data.parking_id}/edit`)}
        resp
        textButton="Editer"
        icon="iFaRegEdit"
        key="btn2"
      />
      <Button
        onClick={() => history.push(`parking/${data.parking_id}/availability`)}
        resp
        textButton="Disponibilités"
        icon="iFaRegClock"
        key="btn3"
      />
    </div>
  </div>
)

// const LabelContainer = styled.div`
//   position: absolute;
//   top: 20px;
//   right: 20px;
// `

// const _CardParking = ({ data, publishParking, navigate }) => (
//   <CardStyled>
//     <CardActionArea
//       className="no-outline"
//       onClick={() => history.push(`parking-details/${data.parking_id}`)}
//     >
//       <LabelContainer>
//         <LabelPublish id={data.parking_id} labelType={data.status} />
//       </LabelContainer>
//       <ParkingImage data={data} />
//       <CardContent>
//         <Typography>
//           {data.address}
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </CardStyled>
// )

// const ParkingImage = ({ data }) => (
//   <>
//     {data.cover && data.cover.medium ?
//       <MediaStyled
//         image={data.cover && data.cover.medium}
//         title="Parking cover"
//       />
//       : <div style={{ width: '100%', height: 160, borderRadius: 5, backgroundColor: COLORS.lightGray, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
//           <FaImage size="50" color={COLORS.lightWhite} />
//         </div>
//     }
//   </>
// )
//
// const CardStyled = styled(Card)`
//   max-width: 345px;
//   margin: auto;
// `

// const MediaStyled = styled(CardMedia)`
//   height: 160px;
// `

CardParking.propTypes = {
  data: PropTypes.object,
}

export default CardParking;


const StyledBadge = withStyles(
  {
    badge: {
      backgroundColor: COLORS.red,
      color: 'white',
      top: '50%',
      right: -12,
      paddingBottom: -3,
    }
  }
)(Badge)
