import React from 'react'

const extractImagesFromParking = (images) => {
    const imagesArray = []
    images.forEach(elem => {
        imagesArray.push(`"${elem.large}"`)
    })
    return imagesArray
}

export const parkingMarkup = (parking) => (
    <script type="application/ld+json">
        {
            `{
                "@context": "http://schema.org",
                "@type": "Product",
                "name": "Parking ${parking.street}",
                "url": "https://www.parkmatch.eu/${parking.parking_url}",
                "description": "Parking à louer ${parking.address} sur Parkmatch: location de parking et garage entre particuliers. Réservation gratuite sans caution !",
                "sku": "1",
                "mpn": "${parking.id}",
                "image":  {
                    "@type": "ImageObject",
                    "contentUrl": [${extractImagesFromParking(parking.images)}]
                  },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "EUR",
                    "price": "${parking.monthly_price}",
                    "availability": "http://schema.org/InStock",
                    "url": "https://www.parkmatch.eu/${parking.parking_url}"
                },
                "brand": {
                    "@type": "Thing",
                    "name": "Parkmatch"
                }
            }`
        }</script>
)

export const serviceMarkup = ({ parkingsAround, article, breadcrumbData }) => (
    <script type="application/ld+json">
        {
            `{
                "@type": "Service",
                "@id": "${breadcrumbData.currentUrl}",
                "serviceType": "Location de parking, box et garage entre particuliers.",
                "description": "${article.yoast_meta.yoast_wpseo_metadesc}",
                "name": "${article.yoast_meta.yoast_wpseo_title.replace(' - Parkmatch', '').replace(' - ParkMatch', '')}",
                "broker": {
                    "@type": "Organization",
                    "name": "Parkmatch"
                },
                "areaServed": {
                    "@type": "Place",
                    "addressLocality": "${article.acf.link_text}",
                    "addressCountry": {
                        "@type": "Country",
                        "name": "FR"
                    }
                },
                ${generateOffers({ parkingsAround })}
                "aggregateRating": {
                    "@type" : "AggregateRating",
                    "ratingCount": "${getRatingCountfromTitle(article.yoast_meta.yoast_wpseo_title)}",
                    "bestRating": "5",
                    "ratingValue": "4.${getRatingfromTitle(article.yoast_meta.yoast_wpseo_title)}"
                }
            }`
        }
    </script>
)

const generateOffers = ({ parkingsAround }) => {
    if (parkingsAround) {
        return `"offers": {
        "@type": "AggregateOffer",
        "lowPrice": "${parkingsAround.lowPrice}",
        "highPrice": "${parkingsAround.highPrice}",
        "priceCurrency": "EUR"
    },`
    } else { return "" }
}

const getRatingCountfromTitle = (title) => (title.length)
const getRatingfromTitle = (title) => (Math.round(title.length / 10))
