import React from 'react';
import { Helmet } from 'react-helmet'

const MentionsText = (props) => (
    <>
        <Helmet>
            <title>Mentions légales</title>
            <meta name="description" content="Mentions légales de Parkmatch" />
        </Helmet>
        <div className="col-md-8 offset-md-2 pt-5">

                    <h2>Editeur</h2>

                        <p>Conformément à la loi du 21 juin 2004, intitulée loi pour la confiance dans l’économie numérique, le site www.parkadom.com est édité par CGMATCH SAS, au capital de 8 000 Euros, représentée par M. Emmanuel SIMON agissant en qualité de Président.</p>

<p>La société a son siège social au 6 bis, rue de la Paroisse, GALAXY, 78000 Versailles et est enregistrée au Registre du Commerce et des Sociétés de Versailles sous le numéro 894 743 210.</p>

<p>SIREN : 894 743 210</p>
<p>Coordonnées : contact@parkmatch.eu - 01 80 84 44 20</p>
<p>Numéro de TVA : FR52894743210</p>
<p>Code NAF : 6312Z</p>

<p>Directeur de la publication : M. Emmanuel SIMON</p>

                        <h2>Hébergeur</h2>

                        <p>Le site est hébergé par NEXYLAN, 10 Rue du Chateau , 59100 ROUBAIX. </p>

        </div>
    </>
);

export default MentionsText
