import React, { useState } from 'react'
import { Consumer } from 'src/services/store';
import UserApi from 'src/api/user'
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PmButton from 'src/components/StyledButton'
import { validateEmail } from 'src/utils/checkAuth';


function NameEdit({ data, onSubmit, onCancel }) {
    const [isLoading, setIsLoading] = useState(false)

    const [email, setEmail] = useState(data.user.email)

    function handleChangeEmail(e) { setEmail(e.target.value) }

    async function emailUpdate() {
        if (!validateEmail(email)) {
            data.snackRef.open('error', 'Veuillez entrer une adresse email valide.')
            return
        }
        setIsLoading(true)
        const res = await UserApi.updateUserInfo({ email: email })
        setIsLoading(false)
        if (res && !res.error) {
            data.snackRef.open('success', 'Adresse email mise à jour.')
            data.updateUserData(res.res)
            onSubmit()
        }
        else {
            data.snackRef.open('error', "Une erreur est survenue, veuillez rééssayer plus tard.")
        }
    }

    return (
        <>
            <form noValidate autoComplete="off" className="d-flex flex-row justify-content-around w-100">
                <TextField
                    id="email"
                    label="Adresse email"
                    type="email"
                    value={email}
                    onChange={e => handleChangeEmail(e)}
                    margin="normal"
                    fullWidth
                />
            </form>
            <div className="w-100 text-right mt-4">
                <Button onClick={onCancel}>Annuler</Button>
                <PmButton style={{ marginLeft: 20 }} onClick={emailUpdate} isLoading={isLoading}>Enregistrer</PmButton>
            </div>
        </>

    )
}

NameEdit.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default (props) => (
    <Consumer>
        {ctx => <NameEdit {...props} data={ctx} />}
    </Consumer>
)
