import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LoaderSimple from 'src/components/LoaderSimple';

const OutlinedButton = ({ text, disable = false, isLoading = false, link = "", externalLink }) => {
  return (
    <>
      {externalLink &&
        <a className='outlined-button' href={externalLink}>
          {!isLoading ? text : <LoaderSimple height={25} width={25} />}
        </a>
      }
      {!externalLink &&
        <Link className='outlined-button' to={link}>
          {!isLoading ? text : <LoaderSimple height={25} width={25} />}
        </Link>
      }
    </>
  )
}

OutlinedButton.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
}

export default OutlinedButton;
