import React from 'react';
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Consumer } from 'src/services/store';
import Loader from 'src/components/Loader';

function PrivateRoute({ component: Component, history, data, ...rest }) {
	const { user, isLogged, isConnecting } = data;

	if (isConnecting) {
		return <Loader />
	}
	if (isLogged && user && user.phone_validated) {
		return <Component history={history} data={data} {...rest} />
	} else if (isLogged && user && !user.phone_validated) {
		if (!history.location.pathname.includes("/register/phone")) {
			return <Redirect to={{ pathname: "/register/phone" }} />
		}
		return <Component history={history} data={data} {...rest} />
	} else {
		return (typeof window !== 'undefined'
			? <><Loader /><Redirect to={{ pathname: "/login" }} /></>
			: <Loader />)
	}
}

PrivateRoute.propTypes = {
	component: PropTypes.any,
	isLogged: PropTypes.bool,
	rest: PropTypes.object,
}

export default withRouter(React.forwardRef((props, ref) => (
	<Consumer>
		{ctx => <PrivateRoute {...props} data={ctx} ref={ref} />}
	</Consumer>
)));
