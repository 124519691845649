import styled from 'styled-components'
import { COLORS } from 'src/stylesheet/colors';

const LinkButton = styled.span`
    color: ${COLORS.green};
    cursor: pointer;
    padding: 5px;

    &:hover, &:active {
        text-decoration: underline;
    }
`

export default LinkButton