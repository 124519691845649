import React from 'react'
import OutlinedButton from 'src/components/OutlinedButton';

import CardParking from './cardParking';

export const ParkingsList = ({ parkings, article, searchLink }) => (
    <>
        {
            parkings && parkings.length > 0 &&
            <div className="col-12 my-2" style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "2em" }}>Parkings à louer près de <span className="font-weight-bold">{article.acf.link_text}</span></h2>
                <div className="row mb-2">
                    {
                        parkings.slice(0, 3).map((item) =>
                            <div key={item.parking_id.toString()} className="col-md-4 col-sm-4 offset-sm-0 col-10 offset-1 px-2 my-2">
                                <CardParking parking={item} />
                            </div>
                        )
                    }
                </div>
                <div className="d-flex justify-content-center my-2" style={{ fontSize: "14px" }}>
                    <OutlinedButton text="Tous les parkings" link={searchLink} />
                </div>
            </div>
        }
    </>
)