import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import { COLORS } from 'src/stylesheet/colors';
import { RENTAL_MODE } from 'src/services/store/SearchStore';

const monthly = [
  "Aucun paiement avant la remise des accès",
  "Annulation gratuite si le parking ne convient pas",
  "Pas de caution",
  "La passation d'une réservation vous engage à son règlement si vous finalisez la réservation avec le propriétaire de ce parking",
]

const oneshot = [
  "Paiement validé seulement à l'acceptation du propriétaire",
  "Pas de caution",
]


const getText = (rentalMode = RENTAL_MODE.monthly) => {
  if (rentalMode === RENTAL_MODE.monthly) {
    return monthly
  } else {
    return oneshot
  }
}

const Safety = ({ rentalMode }) => (
  <div className="my-3">
    <div style={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>Réservez en toute confiance</div>
    <table className="my-3 mx-5 w-auto">
      <tbody>
        {
          getText(rentalMode).map(e =>
            <tr key={e} style={{ listStyleType: 'none', alignItems: 'center', display: 'flex' }}>
              <td>
                <FaCheckCircle style={{ float: 'left' }} size={20} color={COLORS.green} />
              </td>
              <td className="ml-3" style={{ fontSize: '1.1rem', color: COLORS.middleGray }}>{e}</td>
            </tr>
          )
        }
      </tbody>
    </table>
  </div>
)

export default Safety;
