import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { Consumer, RENTAL_MODE } from 'src/services/store/SearchStore';


function SearchTypeTabs({ rentalMode, setRentalMode }) {
  return (
    <div style={{ alignItems: 'center', display: 'flex', height: '100%', width: '100%', justifyContent: 'center' }}>
      <Tabs value={rentalMode === RENTAL_MODE.monthly ? 0 : 1}
        onChange={setRentalMode} indicatorColor="primary">
        <TabStyled label="Par mois" />
        <TabStyled label="Par jour" />
      </Tabs>
    </div>
  )
}

export default (props) => (
  <Consumer>
    {ctx => <SearchTypeTabs {...props}
      rentalMode={ctx.rentalMode}
      setRentalMode={ctx.setRentalMode}
    />}
  </Consumer>
)

const TabStyled = styled(Tab)`
  && {
    min-width: unset;
    font-size: 16px;
    outline: none !important;
    text-transform: none;
  }
`
