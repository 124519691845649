import styled from 'styled-components'

const ArticlePageHeader = styled.div`
  position: relative;
  height: 35vh;

  h1 {
    font-size: 3.3rem;
    text-shadow: 1px 1px 20px black;
    margin-bottom: 0px;
    font-family: Bariol;
    font-weight: 500;
  }

  .article-subtitle {
    font-size: 1.8rem;
    text-shadow: 1px 1px 20px black;
  }

  .article-title-container {
    position: absolute;
    width: 100%;
    bottom: 5vh;
    text-align: center;
    color: white;

  }

  @media (max-width: 992px) {
    h1 {
      font-size: 2.7rem;
    }

    .article-subtitle {
      font-size: 1.4rem;
    }
  }
`;


export default ArticlePageHeader
