import React, { useState, useEffect, useRef } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';

import { FaSearch, FaRegClock } from 'react-icons/fa';
import styled from 'styled-components';
import { Consumer, RENTAL_MODE } from 'src/services/store/SearchStore';
import { COLORS } from 'src/stylesheet/colors';
import StartAndEndPicker from 'src/components/DatePickers/StartAndEndPicker'
import SearchTypeTabs from './SearchTypeTabs'

function SearchBar({ searchParkings, center, rentalMode, startDate, setStartDate, endDate, setEndDate }) {
  const [searchValue, setSearchValue] = useState('')
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      callSearchParkings(center)
    } else {
      didMountRef.current = true
    }
  }, [rentalMode])

  const handleChange = (value) => setSearchValue(value)

  const handleAutocompleteSelect = async (item) => {
    setSearchValue(item)
    const res = await geocodeByAddress(item)
    if (res && res.length > 0) {
      const location = await getLatLng(res[0])
      if (location && searchParkings) {
        callSearchParkings(location)
      }
    }
  }

  function onDateSelected() {
    callSearchParkings(center)
  }

  function callSearchParkings(location) {
    searchParkings({
      location: {
        latitude: location.lat || location.latitude,
        longitude: location.lng || location.longitude,
      },
    })
  }

  return (
    <Paper square style={{ width: '100%', zIndex: 10, minHeight: 64, flexDirection: 'row', display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={2}>
          <SearchTypeTabs />
        </Grid>
        <Grid item xs={12} md={rentalMode === RENTAL_MODE.oneshot ? 5 : 10} lg={rentalMode === RENTAL_MODE.oneshot ? 5 : 10} style={{ zIndex: 1 }}>
          <StyledDiv>
            <FaSearch size="30" color={COLORS.lightGray} style={{ alignSelf: 'center', marginRight: 20 }} />
            <PlacesAutocomplete onChange={handleChange} value={searchValue} onSelect={handleAutocompleteSelect} >
              {RenderSearchInput}
            </PlacesAutocomplete>
          </StyledDiv>
        </Grid>
        {rentalMode === RENTAL_MODE.oneshot && <Grid item xs={12} md={5} lg={5} style={{ zIndex: 0 }}>
          <StyledDiv className="py-2">
            <FaRegClock size="30" color={COLORS.lightGray} style={{ alignSelf: 'center' }} />
            <StartAndEndPicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} onDateSelected={onDateSelected} />
          </StyledDiv>
        </Grid>}
      </Grid>
    </Paper>
  )
}

const RenderSearchInput = ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => {
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <InputBase
        id="search-input"
        placeholder="Rechercher"
        {...getInputProps()}
        style={{ width: '100%', minHeight: 53, paddingTop: 15, paddingBottom: 15 }}
      />
      {suggestions.length > 0 && <Popper open transition disablePortal style={{ position: 'absolute', top: '65px', left: '0px' }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <MenuList>
                {suggestions.map(suggestion => (
                  <MenuItem
                    style={{ backgroundColor: suggestion.active && COLORS.lightGray }}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>}
    </div>
  )
}

const StyledDiv = styled.div`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  height: 100%;
  align-items: center;
  border-right: 1px solid ${COLORS.lightGray};
`

export default (props) => (
  <Consumer>
    {ctx => <SearchBar {...props} center={ctx.center}
      searchParkings={ctx.searchParkings}
      rentalMode={ctx.rentalMode}
      startDate={ctx.startDate}
      setStartDate={ctx.setStartDate}
      endDate={ctx.endDate}
      setEndDate={ctx.setEndDate}
    />}
  </Consumer>
);
