import React from 'react';
import { withRouter } from 'react-router';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import UserApi from 'src/api/user'
import BookingApi from 'src/api/booking'
import ParkingApi from 'src/api/parking'
import Analytics from 'src/services/Analytics'
import * as Sentry from '@sentry/browser';
import { getPageContent } from 'src/utils/pagesContent'


const { Provider, Consumer } = React.createContext()

class AppProvider extends React.Component {
  state = {
    /* user context */
    user: null,
    isLogged: false,
    isConnecting: typeof window !== 'undefined',
    handleChangeLogged: (newState = null) => {
      if (newState) {
        this.setState({ isLogged: newState });
        return
      }
      const { isLogged } = this.state;
      if (!isLogged) {
        this.setState({ isLogged: true });
      } else {
        this.setState({ user: null, isLogged: false })
      }
    },
    handleChangeUser: (data) => {
      if (data && data.id) {
        Analytics.setUserId(data.id)
        Sentry.configureScope(scope => {
          scope.setUser("id", data.id)
          scope.setUser("email", data.email)
        })
      }
      this.setState({ user: data })
    },
    handleIsConnecting: (isConnecting) => {
      this.setState({ isConnecting })
    },
    refreshUserData: async () => {
      const res = await UserApi.getUserInformations()
      if (!res.error) {
        this.setState({ user: res })
      }
    },
    updateUserData: (user) => {
      this.setState({ user: user })
    },
    /* End user context */

    /*
      globally mounted and usable snack
      use snackRef.open(*snack type*, *your custom message*)
      more info @ 'src/components/SnackBar'
    */
    snackRef: null,
    setSnackRef: (ref) => this.setState({ snackRef: ref }),
    /* End Snackbar */

    /* user is doing the action, redirect after login */ // NEED REWORK TO WORK WITH ALL POSSIBLE ACTIONS
    isAddingParking: false,
    toggleIsAddingParking: (bool) => {
      this.setState({ isAddingParking: bool })
    },
    /* End user action */

    /* Bookings List Owner */
    bookings: [],
    getBookingsListForParking: async (parkingId) => {
      const res = await ParkingApi.getParkingBookings({ parkingId })
      if (!res.error) {
        this.setState({ bookings: res })
        return { error: false }
      } else {
        return { error: true }
      }
    },
    /* End bookings owner list */

    /* Bookings List Owner */
    bookingsTenant: [],
    getBookingsListForTenant: async () => {
      const res = await BookingApi.getBookings()
      if (!res.error) {
        this.setState({ bookingsTenant: res })
        return { error: false }
      } else {
        return { error: true }
      }
    },
    /* End bookings owner list */

    /* Notifications */
    hasNewTenantBooking: false,
    hasNewParkingBooking: false,
    hasNewParkingInAlert: false,
    refreshNotifications: async () => {
      const res = await UserApi.getNotificationsStatus()
      if (res && !res.error) {
        this.setState({
          hasNewTenantBooking: res.notif_booking,
          hasNewParkingBooking: res.notif_awaiting_validation,
          hasNewParkingInAlert: res.notif_alert
        })
      } else {
        this.setState({ hasNewTenantBooking: false, hasNewParkingBooking: false })
      }
    },
    /* End Notifications */

    /* Search context || need to clean this */
    handleSelectAddress: async (address) => {
      const results = await geocodeByAddress(address)
      const center = await getLatLng(results[0])
      this.props.history.push(`/search?latitude=${center.lat}&longitude=${center.lng}`);
    },
    handleChangeAddress: (address) => {
      this.setState({ address });
    },
    /* End Search Context */

    /* BlogPage & Parking context */

    pageData: (this.props.value && this.props.value.pageData)
      ? this.props.value.pageData
      : {
        isParking: false,
        isArticle: false,
        notFound: false,
        // Content
        article: null,
        parking: null,
        // Links
        linksAround: [],
        linksInTree: [],
        parkingsAround: [],
        // Breadcrumb
        breadcrumbElements: null,
        breadcrumbData: null,
        // Seo Meta Data
        seoTitle: "Parkmatch",
        seoDescription: "Des milliers de parkings, garages et box partout en France: location tous types de durée, contrat automatique, paiement sécurisé.",
      },
    loadPageData: async () => {
      const newPageData = await getPageContent(this.props.history.location.pathname)
      this.setState({
        pageData: newPageData
      })
      return newPageData
    },
    resetPageContent: () => {
      this.setState({
        pageData: {
          ...this.state.pageData,
          parking: null,
          article: null
        }
      })
    }
    /* BlogPage context */
  }
  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

export default withRouter(AppProvider);
export { Consumer };
