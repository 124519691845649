import React, { useState, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';
import { GOOGLE_API_KEY } from 'src/environement';
import { MapImage } from './MapImage'

const styles = {
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 10px 0 10px',
    borderColor: 'black transparent transparent transparent',
    position: 'absolute',
    bottom: 0,
    top: 28,
    left: 30,
  },
}

const MarkerComponent = ({ selected, price, func }) => (
  <div style={{ zIndex: selected ? 2 : 1, position: 'absolute', width: 80, height: 40, marginLeft: -40, marginTop: -40 }}>
    <button style={{ position: 'relative', border: !selected ? `1px solid ${COLORS.green}` : 'none', backgroundImage: selected && GLOBAL.gradientBlue, backgroundColor: !selected && 'white', outline: 'none', cursor: 'pointer', width: 80, height: 30, borderRadius: 5, color: selected ? COLORS.realLightGray : COLORS.mediumGreen, fontWeight: 'bold', fontSize: 16 }}>
      {Math.trunc(price)} €
      <div style={styles.triangle} />
    </button>
  </div>
)

const createMapOptions = (maps) => ({
  clickableIcons: false,
  disableDefaultUI: true,
  draggable: false,
})

export const Map = ({ coordinates, style, zoom = 14, parkings, article }) => {

  const [dimensions, setDimensions] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const width = containerRef.current.clientWidth
    const height = containerRef.current.clientHeight
    setDimensions({ width, height })
  }, [containerRef])

  return (
    <div style={{ ...style, overflow: 'hidden' }}
      ref={containerRef}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={coordinates}
        zoom={zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
      >
        {parkings &&
          parkings.map((item) => (
            <MarkerComponent
              key={item.parking_id.toString()}
              price={item.monthly_price}
              lat={item.coordinates.latitude}
              lng={item.coordinates.longitude}
            />
          ))
        }
      </GoogleMapReact> */}

      {(typeof window !== 'undefined' && article && parkings && dimensions) && // Render only on client
        <MapImage article={article} zoom={zoom} parkings={parkings} dimensions={dimensions} />
      }
    </div>
  )
}

