import WordpressApi from 'src/api/wordpress';
import ParkingApi from 'src/api/parking';
import capitalize from 'src/utils/capitalizeFirstLetter';
import SearchApi from 'src/api/search';

export const getPageContent = async (location) => {

    let pageData = {
        isParking: false,
        isArticle: false,
        notFound: false,
        // Content
        article: null,
        parking: null,
        // Links
        linksAround: [],
        linksInTree: [],
        parkingsAround: {},
        // Breadcrumb
        breadcrumbElements: null,
        breadcrumbData: null,
        // Seo Meta Data
        seoTitle: "Parkmatch",
        seoDescription: "Des milliers de parkings, garages et box partout en France: location tous types de durée, contrat automatique, paiement sécurisé.",
    }

    // Parse location to determine page type
    const urlArray = locationToArray(location)
    pageData = { ...pageData, ...parsePageType(urlArray) }

    // Get article or parking if exist
    pageData = { ...pageData, ...(await getDataIfExist(pageData, urlArray, location)) }

    // Get data to generate links and parkings on article page
    if (pageData.isArticle) {
        [
            pageData.linksAround,
            pageData.linksInTree,
            pageData.parkingsAround,
            pageData.breadcrumbData,
        ] =
            await Promise.all([
                getLinksAround(pageData.article),
                getLinksInTree(pageData.article),
                searchParkingsInArea(pageData.article),
                getBreadcrumbData(pageData, urlArray),
            ])
    }

    if (pageData.isParking) {
        [pageData.breadcrumbData] = await Promise.all([
            getBreadcrumbData(pageData, urlArray)
        ])
    }

    return pageData
}

function locationToArray(location) {
    let urlArray = location.split('/')
    urlArray = urlArray.filter(item => item !== "")
    if (urlArray.length > 1) {
        urlArray = urlArray.filter(item => item !== "location")
    }
    return urlArray
}


function parsePageType(urlArray) {
    const arr = urlArray[urlArray.length - 1].split('-')
    if (arr[0] === 'parking' && !isNaN(arr[arr.length - 1])) {
        return { isParking: true }
    }
    return { isArticle: true }
}

const getDataIfExist = async (pageData, urlArray, location) => {
    try {
        // Get parking data
        if (pageData.isParking) {
            // Get parking ID at the end of url's last element
            const arr = urlArray[urlArray.length - 1].split('-')
            const parkingId = parseInt(arr[arr.length - 1], 10)
            const res = await ParkingApi.getParkingWithoutAuth({ parkingId })
            if (!res.error) {
                if ('/' + res.parking_url !== location) {
                    return { notFound: true, isArticle: false, isParking: false }
                }
                return { isParking: true, parking: res, isArticle: false, notFound: false }
            } else {
                return { notFound: true, isParking: false, isArticle: false }
            }
        }

        // Get Article data
        const articleTree = urlArray.join('_')
        const res = await WordpressApi.getArticleByTree({ tree: articleTree })
        if (!res.error) {
            if (res && res.length > 0) {
                return { isArticle: true, article: res[0], notFound: false, isParking: false }
            }
        }
        return { notFound: true, isArticle: false, isParking: false }
    } catch (err) {
        console.log(err)
    }
}

const getBreadcrumbData = async (pageData, urlArray) => {
    const baseItem = {
        name: "Location parking",
        isLink: true,
        slug: "location",
        url: '/location',
    }

    let treeArray = urlArray
    const tree = treeArray.join('_')
    const currentUrl = "https://www.parkmatch.eu/" + urlArray.join('/')

    let promises = []
    treeArray.pop()
    treeArray.forEach((item, index) => {
        promises.push(getBreadcrumbItem(item, index, treeArray, pageData))
    });
    let items = await Promise.all(promises)
    if (pageData.isParking) {
        items.push({
            isLink: false,
            name: pageData.parking.street,
            tree: tree,
            url: "https://www.parkmatch.eu/" + pageData.parking.parking_url
        })
    }
    else if (pageData.isArticle) {
        items.push({
            isLink: false,
            name: pageData.article.acf.link_text,
            tree: tree,
            url: currentUrl
        })
    }

    return { items: [baseItem, ...items], tree: tree, currentUrl: currentUrl }

}

const getBreadcrumbItem = async (item, index, treeArray, pageData) => {
    const currentTreeArray = treeArray.filter((item, lIndex) => {
        if (lIndex <= index) { return item }
    })

    const currentTree = currentTreeArray.join('_')
    // Check if an article exists for the current part of the tree
    const res = await WordpressApi.getDataByTree({ tree: currentTree })

    if (!res.error) {
        if (res && res.length > 0) { // An article exist for currentTree
            return {
                isLink: true,
                name: res[0].acf.link_text,
                tree: currentTree,
                url: `/location/${currentTreeArray.join("/")}`,
            }
        }
        else { // There is no article for currentTree, therefore, no link
            return {
                isLink: false,
                name: capitalize(item.replace(/-/g, " ")),
                tree: currentTree,
                url: "",
            }
        }
    }

}

const getLinksAround = async (article) => {
    return sortLinks(await WordpressApi.getArticlesAround(article.id, article.location.lat, article.location.lng))
}

const getLinksInTree = async (article) => {
    if (article.acf.article_type === 'pays') {
        return sortLinks(await WordpressApi.getArticlesInCountry(article.acf.link_text))
    }
    else {
        return sortLinks(await WordpressApi.getArticlesInTree(article.acf.tree))
    }
}

const sortLinks = (links) => {
    if (links && links.length > 0) {
        const sortedLinks = {}
        links.forEach(item => {
            if (!sortedLinks[item.acf.article_type]) {
                sortedLinks[item.acf.article_type] = []
            }
            item.formattedLink = `/location/${item.acf.tree.replace(/_/g, "/")}`
            sortedLinks[item.acf.article_type].push(item)
        })
        return sortedLinks
    }
}

const searchParkingsInArea = async (article) => {
    const res = await SearchApi.searchParkings({
        latitude: article.location.lat,
        longitude: article.location.lng,
        diameter: 10000000,
        limit: 30,
    })
    let lowPrice = 200
    let highPrice = 20
    if (res) {
        res.forEach(item => {
            if (parseInt(item.monthly_price) > highPrice) {
                highPrice = parseInt(item.monthly_price)
            }
            if (parseInt(item.monthly_price) < lowPrice) {
                lowPrice = parseInt(item.monthly_price)
            }
        })
    }
    return { lowPrice, highPrice, parkings: res }
}
