import React from "react";

const SvgCamera = props => (
  <svg viewBox="0 0 648 648" {...props} width={props.size} height={props.size}>
    <path fill={props.color} d="M560.9 238.4c-6-2.9-13-2.1-18.2 2.2l-67.1 54.3v-68.5c0-22.4-18.2-40.6-40.6-40.6H100.1c-22.4 0-40.6 18.2-40.6 40.6v175.2c0 22.4 18.2 40.6 40.6 40.6h334.8c22.4 0 40.6-18.2 40.6-40.6v-58.3l67.1 54.3c3.1 2.5 6.9 3.9 10.8 3.9 2.5 0 5.1-.6 7.5-1.7 6-2.9 9.8-8.8 9.8-15.5V254c0-6.7-3.8-12.7-9.8-15.6zM454.5 401.6c0 10.8-8.8 19.6-19.6 19.6H100.1c-10.8 0-19.6-8.8-19.6-19.6V226.4c0-10.8 8.8-19.6 19.6-19.6h334.8c10.8 0 19.6 8.8 19.6 19.6v175.2zm95.2-25.3L479 319.1l70.7-57.2v114.4z" />
    <path fill={props.color} d="M152 253.5h-38c-5.8 0-10.5-4.7-10.5-10.5s4.7-10.5 10.5-10.5h38c5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5z" />
  </svg>
);

export default SvgCamera;
