import React from 'react'
import { FaUserAlt } from 'react-icons/fa';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import { COLORS } from 'src/stylesheet/colors';

const AccountIcon = styled(FaUserAlt)`
  font-size: 40px;
  padding: 5px;
  border: 1px solid ${COLORS.green};
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const UserAvatar = ({ url, small }) => (
  <>
    {url && <Avatar alt="User Avatar" src={url} />}
    {!url && <AccountIcon size={small && 30} />}
  </>
)

export default UserAvatar
