import React from 'react';
import PropTypes from 'prop-types';
import { FaHandshake } from 'react-icons/fa';
import Icons from 'src/components/ListParkings/CardParking/icons';
import Button from 'src/components/Button';

import { COLORS } from 'src/stylesheet/colors';

const InfoParking = ({ monthly_price, button = false, callback, typeCar }) => (
  <div className="col h-100 d-flex align-items-center justify-content-center">
    <div className="info">
      <div className="item-info" style={{ fontSize: 18, fontWeight: 'bold', color: COLORS.mediumGreen }}>{Math.trunc(monthly_price)}€/mois</div>
      { typeCar && <div className="item-info"> <Icons name={typeCar} /></div> }
      <div className="item-info"><span className="mr-2">Remise d'accès</span><FaHandshake size="20" /></div>
      { button && <div><Button text="LOUER" onClick={() => this.callback()} /></div> }
    </div>
  </div>
)

InfoParking.propTypes = {
  monthly_price: PropTypes.string,
}

export default InfoParking;
