import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import GLOBAL from 'src/stylesheet';
import InfoUser from './InfoUser';
import { FaSignOutAlt, FaSignInAlt, FaHome, FaSearch, FaLightbulb, FaQuestion } from 'react-icons/fa';
import LocalParking from '@material-ui/icons/LocalParking'
import { Consumer } from 'src/services/store';

import { DrawerItems } from './DrawerService'
import logoutFunc from 'src/utils/logout';



const DrawerPrivateContent = withRouter(({ data, history }) => {

  useEffect(() => {
    if (data.isLogged) {
      data.refreshNotifications()
    }
  }, [])

  const navigatetoPage = async (routeName) => {
    if (data.isLogged) {
      data.refreshNotifications()
    }
    history.push(routeName)
  }

  return (
    <>
      {data.isLogged &&
        <InfoUser />
      }
      {!data.isLogged &&
        <ListItem button onClick={() => navigatetoPage('/login')}>
          <ListItemIcon style={{ color: 'white', marginLeft: 5 }}><FaSignInAlt /></ListItemIcon>
          <ListItemTextStyled disableTypography primary="Se connecter" />
        </ListItem>
      }

      {data.isLogged && DrawerItems.map((item, index) => {
        if (item.title === "DIVIDER") {
          return <Divider key={index} />
        }
        return (

          <ListItem button key={index} onClick={() => navigatetoPage(item.routeName)}>

            <ListItemIcon style={{ color: 'white' }}>
              <StyledBadge color="primary" variant='dot' invisible={
                ((item.routeName === '/account/reservations' && data.hasNewTenantBooking) ||
                  (item.routeName === '/account/parkings' && data.hasNewParkingBooking)) ? false : true}
              >{item.icon}</StyledBadge>
            </ListItemIcon>
            <ListItemTextStyled disableTypography primary={item.title} />
          </ListItem>

        )
      })}
      {data.isLogged &&
        <ListItem button onClick={() => logoutFunc(data.handleChangeLogged)}>
          <ListItemIcon style={{ color: 'white', marginLeft: 5 }}><FaSignOutAlt /></ListItemIcon>
          <ListItemTextStyled disableTypography primary="Se déconnecter" />
        </ListItem>
      }
      <Divider />
    </>
  )
})

const DrawerPublicContent = withRouter(({ history }) => (
  <>
    <ListItem button onClick={() => history.push("/")}>
      <ListItemIcon style={{ color: 'white' }}><FaHome /></ListItemIcon>
      <ListItemTextStyled disableTypography primary="Accueil" />
    </ListItem>
    /*
    <ListItem button onClick={() => history.push("/search")}>
      <ListItemIcon style={{ color: 'white' }}><FaSearch /></ListItemIcon>
      <ListItemTextStyled disableTypography primary="Trouver un parking" />
    </ListItem>
    <ListItem button onClick={() => history.push("/add")}>
      <ListItemIcon style={{ color: 'white' }}><LocalParking /></ListItemIcon>
      <ListItemTextStyled disableTypography primary="Enregistrer mon parking" />
    </ListItem>
    <ListItem button onClick={() => history.push("/trouver-un-parking")}>
      <ListItemIcon style={{ color: 'white' }}><FaLightbulb /></ListItemIcon>
      <ListItemTextStyled disableTypography primary="Concept" />
    </ListItem>
    <ListItem button onClick={() => window.location.href = 'https://help.parkmatch.eu/hc?lang=fr'}>
      <ListItemIcon style={{ color: 'white' }}><FaQuestion /></ListItemIcon>
      <ListItemTextStyled disableTypography primary="Aide" />
    </ListItem>
    */
  </>
))

const ListItemTextStyled = styled(ListItemText)`
  color: white;
`

const DrawerMenu = ({ classes, drawerMenuOpen, openMenu, data, privatePage = false, navigatetoPage }) => (
  <>
    <Hidden smUp implementation="css">
      <Drawer
        variant="temporary"
        anchor='right'
        open={drawerMenuOpen}
        onClose={openMenu}
        classes={{ paper: classes.drawerPaper }}
      >
        <DrawerPrivateContent data={data} />
        {!privatePage &&
          <DrawerPublicContent />
        }
      </Drawer>
    </Hidden>
    {privatePage &&
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.toolbar} />
          <List>
            <DrawerPrivateContent data={data} />
          </List>
        </Drawer>
      </Hidden>
    }
  </>
)

const drawerWidth = 260;

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundImage: GLOBAL.gradient,
    width: drawerWidth,
    color: 'white',
  },
})

const DrawerWithStyles = withStyles(styles)(DrawerMenu)
export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <DrawerWithStyles {...props} data={ctx} ref={ref} />}
  </Consumer>
));

const StyledBadge = withStyles(
  {
    badge: {
      backgroundColor: 'red',
      left: 15,
      top: 5,
      width: 8,
      height: 8,
      maxWidth: 8,
      maxHeight: 8,
      minWidth: 8,
      minHeight: 8,
      borderRadius: 4,
      border: "1px solid white",
      boxShadow: "0px 0px 3px white",
    }
  }
)(Badge)