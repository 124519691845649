import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import formatMetaData from 'src/utils/formatMetaData';
import LegalText from './legals'
import PublicPage from 'src/templates/PublicPage';

class LegalsPage extends React.Component {
  componentWillMount() {
    formatMetaData({ title: "Informations légales", description: "La page avec l'ensemble des informations légales." });
  }

  render() {
    return (
      <PublicPage>
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row py-5 gradient-header">
              <div className="col-md-8 py-4 offset-md-2 text-center title-faq">
                <h1>Politique de confidentialité des données et de respect de la vie privée</h1>
              </div>
            </div>
            <div className="row">
              <LegalText />
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={LegalsPage} />
