const validateEmail = (email) => {
  const re = /^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
  return re.test(email);
}

const checkPassword = (str) => {
  const re = /^.{8,64}$/;
  return re.test(str);
}

const checkNames = (str) => {
  const re = /^([\u00c0-\u01ffa-zA-Z' -]){2,24}$/;
  return re.test(str);
}

const checkPhones = (phone) => {
  const re = /^\+[0-9]+$/;
  return re.test(phone);
}

const checkSmsCode = (sms) => {
  const re = /^\d{4}$/;
  return re.test(sms);
}

const checkError = (first, last, password, email) => {
  if (!validateEmail(email)) {
    return "Format d'adresse email incorrect, veuillez vérifier à nouveau.";
  } else if (!checkPassword(password)) {
    return "Format de mot de passe incorrect, veuillez vérifier à nouveau (8 - 64 caractères).";
  } else if (!checkNames(first) || !checkNames(last)) {
    return "Format de nom et prénom incorrect, veuillez vérifier à nouveau.";
  }
}

const checkErrorServer = (type) => {
  if (type === 'EmailAlreadyUsed') {
    return "Cet email est déjà utilisé.";
  } else if (type === 'InvalidFormatParams') {
    return "Veuillez vérifier votre nom et prénom (min 2 caractères)";
  } else if (type === 'MissingParams') {
    return "Champs manquant pour valider votre compte."
  }
}

const checkErrorValideCode = (type) => {
  if (type === 'MissingParams') {
    return "Veuillez saisir un code.";
  } else if (type === 'InvalidAction') {
    return "Le code que vous avez saisie est invalide.";
  }
  return "Une erreur c'est produite veuillez réessaier ";
}

export {
  validateEmail,
  checkPassword,
  checkNames,
  checkPhones,
  checkSmsCode,
  checkError,
  checkErrorServer,
  checkErrorValideCode,
}
