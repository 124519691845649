import React from 'react';
import { Helmet } from 'react-helmet'

const DroitsText = (props) => (
    <>
        <Helmet>
            <title>Vos droits et Obligations - Parkmatch</title>
            <meta name="description" content="Vos droits et Obligations sur Parkmatch." />
        </Helmet>
        <div className="col-md-8 offset-md-2 pt-5">

<h2>Qu'est ce que Parkmatch ?</h2>
<p>
    La Plateforme Parkmatch est une place de marché en ligne qui permet aux utilisateurs enregistrés propriétaires d'une place de parking de publier la mise à disposition de leur place de parking sur la Plateforme Parkmatch et de communiquer et traiter directement avec des utilisateurs conducteurs qui souhaitent réserver ces places de parking.
<br /><br />
Vous devez créer un compte pour pouvoir publier une Annonce.
</p>

<h2>Combien ça coute ?</h2>

<p>
Parkmatch reverse au propriétaire l'argent de la location en prélevant une commission de 10 % TTC pour les locations mensuelles et de 15% TTC sur les réservations ponctuelles.
<br />Cette commission est partagée 50% à la charge du propriétaire et 50% à la charge du conducteur.
<br />Le tarif indiqué sur chaque annonce est "tout compris" (il comprend donc le montant reversé au propriétaire, la commission Parkmatch pour le propriétaire et la commission Parkmatch à votre charge) c'est-à-dire que vous n'aurez aucun frais supplémentaire à supporter.
<br />Le montant des frais de service qui vous est appliqué est explicitement indiqué avant toute demande de réservation sur la page récapitulative.
</p>

<h2>Modalités de paiement </h2>

<p>
Les transactions financières sur notre plateforme sont opérées et gérées par le prestataire de paiement Lemonway.
</p>

<h2>Vos obligations</h2>

<h3>Etes-vous un particulier ou un professionnel ?</h3>

<p>
Il est important de noter que certaines activités pourraient vous classer en tant que professionnel, notamment si :<br /><br />

- Vous achetez des articles dans le but de les revendre, plutôt que pour votre usage personnel ;<br />
- Vous vendez des articles que vous avez fabriqués vous-même ;<br />
- Vous effectuez des ventes ou des prestations de services ou de locations fréquentes et en grand volume ;<br />
- Vos ventes ou vos prestations de services ou vos locations vous procurent des bénéfices significatifs et contribuent à vos revenus.<br /><br />

Si vous hésitez sur votre statut de professionnel ou de particulier, nous vous encourageons à consulter les lois et réglementations applicables qui peuvent vous aider à clarifier votre situation.

Seuls les particuliers sont autorisés à publier une annonce sur notre plateforme. Les annonceurs professionnels ont interdiction formelle de communiquer sur Parkmatch.

Tout abus sera sanctionné par la suppression immédiate des annonces concernées, la suppression du compte.

</p>

<h3>Annonceur particulier</h3>

<h4>Déclarer vos revenus générés sur notre site</h4>
<p>
Selon l'article 87 de la loi de finances de 2016, il est important de rappeler que vos activités sur notre site peuvent vous soumettre à l'obligation de payer l'impôt sur le revenu.<br /><br />

Il est de votre responsabilité de déclarer à l'administration fiscale les revenus générés par ces activités.<br /><br />

Nous tenons également à vous informer des risques associés à la fraude fiscale, qui incluent :<br /><br />

Un redressement fiscal pour régulariser les montants dus, incluant des pénalités de retard.<br />
Des amendes forfaitaires variant de 1,5% à 5% des montants non déclarés.<br />
Des sanctions pénales comprenant :<br />
Une peine de prison pouvant aller jusqu'à cinq ans, sans compter les circonstances aggravantes.<br />
Une amende pouvant atteindre 500 000 euros, sans compter les circonstances aggravantes.<br />
L'interdiction d'exercer certaines professions libérales ou de diriger une entreprise.<br />
La perte de droits civiques, civils et de famille.<br />
Des peines de confiscation.<br />
Pour obtenir davantage d'informations concernant les obligations fiscales, vous pouvez consulter le site <a href="https://www.impots.gouv.fr/portail/node/10841">Impots.gouv.fr</a>.
<br />
Pour obtenir davantage d'informations concernant les obligations sociales, vous pouvez consulter le site <a href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">Urssaf.fr</a>.
<br />
</p>


<h2>Vos droits</h2>

<h3>Utilisateur</h3>

<p>
    Comme utilisateur individuel de notre site, vous possédez des droits concernant le traitement de vos données personnelles. Nous vous encourageons à les examiner dans nos conditions générales d'utilisation.<br /><br />

Si vous êtes un annonceur professionnel, vous n'avez pas le droit de publier sur notre site.
</p>

<h3>Acheteur / Client</h3>

<h4>Votre droit de rétractation</h4>

<h5>Entre CGMatch et vous : Cadre contractuel de la mise en relation </h5>
<p>Conformément aux articles L221-18 et suivants du Code de la consommation, et
notamment aux articles L221-28 1° et L221-28 3 : <br />
Le Client bénéficie d'un droit de rétractation. Il peut exercer ce droit, sans avoir à justifier de motifs ni à payer
de pénalités à l'exception, le cas échéant, des frais de retour, dans un délai de quatorze (14) jours à compter
du lendemain de la conclusion du contrat.<br />
Le Client doit exercer son droit de rétractation avant l’expiration du délai précité soit depuis son Interface de
gestion via le formulaire prévu à cet effet, soit en complétant et en adressant à CGMATCH par courrier
postal, le formulaire de rétractation ci-dessous ou toute autre déclaration,
dénuée d'ambiguïté, exprimant sa volonté de se rétracter.</p>
<p>Le remboursement interviendra sous 20 jours ouvrables.</p>
<p>Si vous avez demandé de commencer la prestation de services pendant le délai de rétractation, vous devrez nous payer un montant proportionnel à ce qui vous a été fourni jusqu'au moment où vous nous avez informé de votre rétractation du présent contrat, par rapport à l'ensemble des prestations prévues par le contrat.</p>

<p>Formulaire de rétractation<br />
« A l'attention de CGMATCH SAS, 6 bis, rue de la Paroisse, GALAXY, 78000 Versailles – France :<br />
Je / nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat portant sur la vente
du bien (*) / pour la prestation de services (*) ci-dessous :<br />
Contracté(e) le (*) / reçu(e) le (*) : Nom du (des) consommateur(s) :<br />
Adresse du (des) consommateur(s) :<br />
Signature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire sur papier) :<br />
Date :<br /><br />
(*) Rayez la mention inutile. »<br /><br />
Le droit de rétractation, s'il a été valablement exercé, permet au Client d'obtenir le remboursement des
biens et Services concernés, déduction faite d’un montant proratisé à proportion du nombre de jours
pendant lesquels lesdits biens et Services ont été mis à disposition du Client.<br />
</p>

<h5>Entre propriétaire et conducteur : hors cadre du droit de la consommation </h5>

<p>La société CGMatch n'intervient pas dans vos relations contractuelles entre propriétaires et conducteurs. Nous vous invitons à vous référer aux conditions de rétractation de droit commun entre particuliers.</p>

<h4>Votre droit dans le cadre des règlements des litiges</h4>
<h5>Règlement des litiges entre un conducteur et un propriétaire</h5>
<p>En cas de litige entre un conducteur et un propriétaire suite à une réservation effectuée sur Parkmatch les parties sont invitées à intégrer Parkmatch dans les discussions (contact@Parkmatch.com).</p>

<p>Parkmatch n'intervient dans les litiges entre les conducteurs et les propriétaires que pour vous fournir les justificatifs qui seraient utiles à la résolution du litige et il vous reviendra de suivre les procédures de règlement des litiges de droit commun entre particuliers en cas de litige avec le propriétaire ou le conducteur.</p>

<h5>Règlement des litiges entre vous et Parkmatch</h5>
                    <p>
Conformément à l'article L. 612-1 du Code de la consommation, le consommateur, sous réserve de l'article L.612.2 du code de la consommation, a la faculté d'introduire une demande de résolution amiable par voie de médiation, dans un délai inférieur à un an à compter de sa réclamation écrite auprès du professionnel.<br />
CGMATCH a désigné, par adhésion enregistrée sous le numéro 52710/VM/2403 la SAS Médiation Solution comme entité de médiation de la consommation.<br />
Pour saisir le médiateur, le consommateur doit formuler sa demande :<br />
- Soit par écrit à : Sas Médiation Solution - 222 chemin de la bergerie 01800 Saint Jean de Niost<br />
- Soit par mail à : contact@sasmediationsolution-conso.fr<br />
- Soit en remplissant le formulaire en ligne intitulé « Saisir le médiateur » sur le site https://www.sasmediationsolution-conso.fr<br />
Quel que soit le moyen de saisine utilisé, la demande doit impérativement contenir :<br />
- Les coordonnées postales, téléphoniques et électroniques du demandeur,<br />
- Le nom, l'adresse et le numéro d'enregistrement chez Sas Médiation Solution, du professionnel concerné,<br />
- Un exposé succinct des faits,<br />
- Copie de la réclamation préalable,<br />
- Tous documents permettant l'instruction de la demande (bon de commande, facture, justificatif de paiement, etc.)<br />
                    </p>
<p>Vous pouvez également  utiliser la <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR">plateforme de règlement en ligne des litiges (RLL) à l'adresse https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR</a></p>
        </div>
    </>
);

export default DroitsText
