import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

import BookingApi from 'src/api/booking'
import ParkingApi from 'src/api/parking'
import StyledButton from 'src/components/StyledButton';
import PhoneButton from 'src/components/PhoneButton';
import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';
import BookingTip from './BookingTip';
import Dialog from './Dialog';
import { RENTAL_MODE } from 'src/services/store/SearchStore'


const getText = ({ userType, textKey, rentalMode }) => {
  let parsedRentalMode = rentalMode
  if (rentalMode === 'one_shot' || rentalMode === 'punctual') {
    parsedRentalMode = 'oneshot'
  }
  return TEXTS[parsedRentalMode][userType][textKey]
}

const TEXTS = {
  monthly: {
    owner: {
      Title: "Pour valider l'abonnement",
      One: "Contactez le locataire pour remettre l'accès au parking (clé / télécommande / badge).",
      Two: "En échange, le locataire vous fournira un code permettant de valider l'abonnement.",
      titleDialogRefuse: 'Refuser la réservation',
      textDialogRefuse: 'Le locataire sera immédiatement prévenu du refus. Nous continuerons de chercher un autre locataire pour votre parking.',
    },
    tenant: {
      Title: "Pour valider l'abonnement",
      One: "Contactez le propriétaire pour récupérer l'accès au parking (clé / télécommande / badge).",
      Two: "En échange des accès, donnez le code permettant de valider l'abonnement.",
      titleDialogCancel: 'Annuler la demande',
      textDialogCancel: "Vous pouvez annuler la demande à tout moment, nous nous chargerons d'informer le propriétaire. Vous ne serez pas facturé.",
    }
  },
  oneshot: {
    owner: {
      Title: "Pour valider la réservation",
      OwnerOne: "",
      OwnerTwo: "",
      titleDialogRefuse: 'Refuser la réservation',
      textDialogRefuse: 'Le locataire sera immédiatement prévenu du refus et aucun frais ne lui sera prélevé.',
      titleDialogAccept: 'Accepter la réservation',
      textDialogAccept: 'En acceptant cette demande de réservation, vous acceptez de fournir une place de stationnement à l\'utilisateur aux dates demandées.',
    },
    tenant: {
      Title: "Pour valider la réservation",
      One: "Attendez la réponse du propriétaire à votre demande (sous 72h).",
      Two: "Si le propriétaire accepte, vous serez mis en contact pour organiser le déroulement de la réservation.",
      titleDialogCancel: 'Annuler la demande',
      textDialogCancel: "Vous pouvez annuler la demande tant qu'elle n'est pas acceptée, nous nous chargerons d'informer le propriétaire. Vous ne serez pas facturé.",
    }
  }
}


function PendingBooking({ booking, refreshBooking, parkingId, data, closeDrawer, userType }) {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [dialog, setDialogState] = useState(false);
  const [action, setAction] = useState('');

  function openDialogForAction(action) {
    setAction(action)
    setDialogState(true)
  }

  function setValidationCode(code) {
    setCodeError(false)
    setCode(code.toUpperCase())
  }

  function validateAction() {
    if (action === 'Accept') { acceptBooking() }
    if (action === 'Refuse') { refuseBooking() }
    if (action === 'Cancel') { cancelBooking() }
  }

  async function sendCode() {
    if (code.length === 4) {
      const res = await ParkingApi.submitTenantCode({
        parkingId,
        bookingId: booking.booking_id,
        code,
      })
      if (res && !res.error) {
        setCodeError(false)
        refreshBooking()
        data.getBookingsListForParking(parkingId)
        return
      }
    }
    setCodeError(true)
  }

  async function refuseBooking() {
    const res = await ParkingApi.refuseBooking({
      parkingId,
      bookingId: booking.booking_id,
    })
    if (res && !res.error) {
      setDialogState(false)
      data.getBookingsListForParking(parkingId)
      closeDrawer()
    } else {
      data.snackRef.open('error', 'Une erreur est survenue.')
    }
  }

  async function cancelBooking() {
    const res = await BookingApi.cancelBooking({ bookingId: booking.booking_id })
    if (res && !res.error) {
      setDialogState(false)
      data.getBookingsListForTenant()
      closeDrawer()
    } else {
      data.snackRef.open('error', 'Une erreur est survenue.')
    }
  }

  async function acceptBooking() {
    const res = await ParkingApi.acceptBooking({ parkingId: parkingId, bookingId: booking.booking_id })
    if (res && !res.error) {
      setDialogState(false)
      refreshBooking()
    } else {
      data.snackRef.open('error', 'Une erreur est survenue.')
    }
  }



  return (
    <div className="h-100 text-center w-100 pt-3 px-4">

      {(userType === 'owner' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
        <>
          <span style={styles.title}>Note du locataire</span>
          <StyledP>{booking.comment ? booking.comment : "Le locataire n'a pas laissé de note."}</StyledP>
        </>
      }
      {(userType === 'tenant' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
        <>
          <span style={styles.title}>Votre note au propriétaire</span>
          <StyledP>{booking.comment ? booking.comment : "Vous n'avez pas laissé de note."}</StyledP>
        </>
      }
      {!(userType === 'owner' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
        <>
          <span style={styles.title}>{getText({ textKey: 'Title', userType: userType, rentalMode: booking.recurrence_type })}</span>
          <BookingTip number="1" text={getText({ textKey: 'One', userType: userType, rentalMode: booking.recurrence_type })} />
          {
            booking.recurrence_type === RENTAL_MODE.monthly &&
            <PhoneButton phone={userType === 'tenant' ? booking.owner_phone : booking.tenant_phone} />
          }
          <BookingTip number="2" text={getText({ textKey: 'Two', userType: userType, rentalMode: booking.recurrence_type })} />
          {
            booking.recurrence_type === RENTAL_MODE.monthly &&
            <ValidationCode userType={userType} booking={booking} code={code} setValidationCode={setValidationCode} codeError={codeError} sendCode={sendCode} />
          }
        </>
      }
      {(userType !== 'tenant' && booking.recurrence_type === RENTAL_MODE.oneshot) &&
        <div>
          <StyledButton onClick={() => openDialogForAction('Accept')}>
            Accepter la demande
            </StyledButton>
        </div>
      }

      <div>
        <RefuseButton onClick={() => openDialogForAction(userType === 'tenant' ? "Cancel" : "Refuse")} className="no-outline">
          {userType === 'tenant' ? "Annuler la demande" : "Refuser la réservation"}
        </RefuseButton>
      </div>

      <Dialog
        closeDialog={() => setDialogState(false)}
        visible={dialog}
        action={validateAction}
        title={getText({ textKey: 'titleDialog' + action, userType: userType, rentalMode: booking.recurrence_type })}
        text={getText({ textKey: 'textDialog' + action, userType: userType, rentalMode: booking.recurrence_type })}
      />
    </div >
  )
}

const ValidationCode = ({ userType, booking, code, setValidationCode, codeError, sendCode }) => (
  <Grid container spacing={4} className="pt-2">
    <Grid item xs={12}>
      <StyledCodeTitle>Code de validation</StyledCodeTitle>
    </Grid>
    {userType === 'tenant' && <Grid item xs={12} style={{ fontSize: 40, letterSpacing: 6 }}>
      {booking.code}
    </Grid>}
    {!(userType === 'tenant') &&
      <>
        <Grid item xs={12}>
          <CodeInput code={code} setCode={setValidationCode} error={codeError} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton onClick={sendCode}>
            Démarrer l'abonnement
              </StyledButton>
        </Grid>
      </>
    }
  </Grid>
)

const StyledCodeTitle = styled.span`
  color: ${COLORS.green};
  font-size: 20px;
`

const RefuseButton = styled(Button)`
  && {
    font-size: 22px;
    color: ${COLORS.red};
    margin-top: 30px;
    bottom: 20px;
    font-weight: normal;
    text-transform: none;
  }
`

const TextCodeInput = ({ code, setCode, classes, error }) => (
  <TextField
    id="outlined-name"
    label="Code"
    value={code}
    error={error}
    onChange={event => setCode(event.target.value)}
    margin="normal"
    variant="outlined"
    autoComplete="off"
    inputProps={{ maxLength: 4, size: 7, className: classes.input }}
    InputLabelProps={{ className: classes.label }}
  />
)

const StylesInput = {
  input: {
    fontSize: 30,
    textAlign: 'center',
    letterSpacing: 2,
  },
  label: {
    fontSize: 30,
  }
};

const CodeInput = withStyles(StylesInput)(TextCodeInput)

const styles = {
  title: {
    fontSize: 25,
    color: COLORS.green,
    textAlign: 'center',
    marginTop: 20,
  },
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PendingBooking {...props} data={ctx} ref={ref} />}
  </Consumer>
));

const StyledP = styled.p`
  white-space: pre-wrap;;
`
