import React from 'react';
import PropTypes from 'prop-types';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

export default class CarouselDetail extends React.Component {
  state = {
    activeIndex: 0,
  }

  onExiting = () => {
    this.animating = true;
  }

  onExited = () => {
    this.animating = false;
  }

  setDataKeys = (data) => {
    data.forEach(elem => {
      elem.key = elem.image_id.toString()
    })
    return data
  }

  next = () => {
    const { data } = this.props;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === data.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous = () => {
    const { data } = this.props;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? data.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;
    const items = this.setDataKeys(data)

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />

        {items.map((item) => (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={item.key}
            >
              <div style={{ width: '100%', height: 300, backgroundImage: `url(${item.medium})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%' }} />
              <CarouselCaption captionText={item.caption || ""} captionHeader={item.caption || ""} />
            </CarouselItem>
        ))}

        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}

CarouselDetail.propTypes = {
  data: PropTypes.array,
}
