import React, { useState, useEffect } from 'react'
import PrivateRoute from 'src/containers/PrivateRoute'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton';
import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import PrivatePageTemplate from 'src/templates/PrivatePage';
import UserApi from 'src/api/user';
import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';
import { MonthYear } from 'src/utils/DateUtils';

import CardAddDialog from './CardAddDialog';
import CreditCardModalPage from './CreditCardModalPage'

export const sleep = (miliseconds = 100) => {
  return new Promise(resolve => setTimeout(() => resolve(), miliseconds))
}

function CreditCardPage({ data, modal }) {
  const [cards, setCards] = useState([])
  const [actionLoading, setActionLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false)
  const [wkToken, setWkToken] = useState("")

  async function getPaymentsCards() {
    const res = await UserApi.getPaymentCardInfos()
    setActionLoading(false)
    if (!res.error) {
      setCards(res)
      return res
    }
    return null
  }

  useEffect(() => {
    getPaymentsCards()
  }, [])

  async function onClickSelectCard(id) {
    setActionLoading(true)
    const res = await UserApi.selectCard({ id })
    if (!res.error) {
      data.refreshUserData()
      getPaymentsCards()
    }
  }

  async function onClickDeleteCard(id) {
    setActionLoading(true)
    const res = await UserApi.deleteCard({ cardId: id })
    if (!res.error) {
      getPaymentsCards()
      data.snackRef.open('success', 'Carte supprimée')
      setDeleteMode(false)
    } else {
      data.snackRef.open()
    }
    setActionLoading(false)
  }

  function switchDialogOpen() { setOpenDialog(!openDialog) }

  function switchDeleteMode() { setDeleteMode(!deleteMode) }

  async function onIframeEndProcess() {
    const oldLenght = cards.length

    await sleep(1000)
    let res = await UserApi.getTransactionStatus(wkToken)
    if (res['status'] === 'PENDING') { return }
    if (res['status'] !== 'PENDING' && res['status'] !== 'ACCEPTED') {
      data.snackRef.open()
      switchDialogOpen()
      return
    }

    const list = await getPaymentsCards()
    if (list && (oldLenght < list.length)) {
      res = await UserApi.getUserInformations()
      if (!res.error) {
        data.handleChangeUser(res)
      }
      switchDialogOpen()
      data.snackRef.open('success', 'Carte enregistrée')
    } else {
      // Should never happen
      data.snackRef.open()
    }
  }

  const RenderCardsList = () => (
    <>
      {cards.length > 0 &&
        <Paper>
          <Table>
            <TableHeader />
            <TableBody>
              {cards.map((row, index) => (
                <TableRow key={row.card_id}>
                  <Hidden smDown>
                    <TableCell>
                      {index + 1}
                    </TableCell>
                  </Hidden>
                  <TableCell padding="checkbox" align="right">{row.display_number}</TableCell>
                  <Hidden smDown>
                    <TableCell align="right">{MonthYear(row.expiration_date)}</TableCell>
                  </Hidden>

                  {!deleteMode && <TableCell align="right">
                    {!actionLoading &&
                      <IconButton disabled={row.selected} className="no-outline" onClick={() => onClickSelectCard(row.card_id)}>
                        {row.selected ? <FaCheckCircle color={COLORS.green} /> : <FaCheckCircle />}
                      </IconButton>
                    }
                    {actionLoading && <CircularProgress size={55} />}
                  </TableCell>}

                  {deleteMode && <TableCell align="right">
                    {!actionLoading &&
                      <IconButton className="no-outline" onClick={() => onClickDeleteCard(row.card_id)}>
                        <FaTrash />
                      </IconButton>
                    }
                    {actionLoading && <CircularProgress size={55} />}
                  </TableCell>}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      }
    </>
  )

  if (modal) {
    return (
      <>
        <CreditCardModalPage cardsNb={cards.length} switchAddCard={switchDialogOpen}>
          <RenderCardsList />
        </CreditCardModalPage>
        <CardAddDialog visible={openDialog}
          onIframeEndProcess={onIframeEndProcess}
          switchDialogOpen={switchDialogOpen}
          setWkToken={setWkToken}
        />
      </>
    )
  }

  return (
    <PrivatePageTemplate title="Mes cartes de paiement" metaTitle="Mes cartes de paiement">
      <RenderCardsList />
      <Paper className="mb-4 mt-4">
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} >
          <StyledButton variant="contained" onClick={switchDialogOpen} className="no-outline">
            Ajouter une carte
          </StyledButton>
          <StyledButton variant="contained" delete="true" onClick={switchDeleteMode} className="no-outline">
            Supprimer une carte
          </StyledButton>
        </Grid>
      </Paper>
      {openDialog && <CardAddDialog visible={openDialog}
        onIframeEndProcess={onIframeEndProcess}
        switchDialogOpen={switchDialogOpen}
        setWkToken={setWkToken}
      />}
    </PrivatePageTemplate>
  )
}

const StyledButton = styled(Button)`
  && {
    background-color: ${COLORS.green};
    &:hover {
      background-color: ${COLORS.darkGreen};
    }
    ${props => props.delete && css`
      background-color: ${COLORS.red};
      &:hover {
        background-color: ${COLORS.darkRed};
      }
      `}
      color: white;
      font-size: 24px;
      cursor: pointer;
      margin: 15px;
      text-transform: none;
  }
`

const TableHeader = ({ deleteMode }) => (
  <TableHead>
    <TableRow>
      <Hidden smDown>
        <TableCell></TableCell>
      </Hidden>
      <TableCell align="right">Numéro de carte</TableCell>
      <Hidden smDown>
        <TableCell align="right">Date d'expiration</TableCell>
      </Hidden>
      <TableCell align="right">{deleteMode ? "Supprimer" : "Carte séléctionnée"}</TableCell>
    </TableRow>
  </TableHead>
)

export default (props) => (
  <Consumer>
    {ctx => <PrivateRoute component={CreditCardPage} {...props} data={ctx} />}
  </Consumer>
)
