import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import PublicPage from 'src/templates/PublicPage';
import MessageLogo from 'public/assets/img/message.png'
import './style.css'

class ContactPage extends React.Component {

  render() {
    return (
      <PublicPage navbarPush
        metaTitle="Contact"
        metaDescription="La page pour nous contacter, par mail.">
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row pt-5">
              <div className="col-md-6 offset-md-3 text-center">
                <h1 className="title">Contact</h1>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-6 offset-lg-3 text-center subtitle">Des questions? Des suggestions? Écrivez-nous !</div>
            </div>
            <div className="row contact-row">
              <div className="col-12 col-sm-6 contact-link text-center">
                <a className="mailto" href="mailto:contact@parkmatch.eu">contact@parkmatch.eu</a>
                <br />01 80 84 44 20
              </div>
              <div className="col-12 col-sm-6" >
                <div className="image-container">
                  <img className="contact-image" src={MessageLogo} alt="Mailto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={ContactPage} />
