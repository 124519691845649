import AddressInput from './AddressInput'
import DescriptionInput from './DescriptionInput'
import VehicleInput from './VehicleInput'
import PriceInput from './PriceInput'
import ToolTip from './components/ToolTip'
import ImagesInput from './ImagesInput'
import AvailabilityInput from './AvailabilityInput'

export default {
  AddressInput,
  DescriptionInput,
  VehicleInput,
  PriceInput,
  ToolTip,
  ImagesInput,
  AvailabilityInput
}
