import React from 'react';
import PropTypes from 'prop-types';

import CONSTANTS from 'src/constants';
import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors';
import IconSvg from 'src/svg';

function defaultClick() { }

const IconsInfos = ({ onChange = defaultClick, name, isSelect, edit, numberplate }) => {
  const width = edit ? 100 : 74
  const height = edit ? 80 : 60
  return (
    <div onClick={() => onChange(name)} style={{ width: width, height: height, backgroundImage: isSelect ? GLOBAL.gradientBlue : '', backgroundColor: !isSelect ? COLORS.lightWhite : '', border: !isSelect ? `1px solid ${COLORS.green}` : '' }} className="d-flex m-2 rounded flex-column justify-content-center align-items-center">
      <IconSvg name={name} size={width / 2.5} color={isSelect ? COLORS.lightWhite : COLORS.green} />
      <div style={{ color: isSelect ? COLORS.lightWhite : COLORS.green }}>
        {!numberplate && CONSTANTS.INFOS[name]}
        {numberplate}
      </div>
    </div>
  )
}

IconsInfos.propTypes = {
  typeCar: PropTypes.string,
  isSelect: PropTypes.bool,
  onChange: PropTypes.func,
}

export default IconsInfos;
