import React from 'react';
import GoogleMapReact from 'google-map-react';

import { GOOGLE_API_KEY } from 'src/environement';
import MapMarker from 'src/components/MapMarker';
import { Consumer } from 'src/services/store/SearchStore';
import AlertIcon from 'src/components/AlertIcon';

const createMapOptions = (maps) => ({
  clickableIcons: false,
  disableDefaultUI: true,
})

class MapView extends React.Component {
  // WITH THIS, CREATE A DEFAULT MARKER AND RETURN IT
  createSearchMarker = (location) => {
    if (this.marker) {
      this.marker.setMap(null)
    }
    if (this.map && this.mapsApi && location) {
      const map = this.map
      const marker = new this.mapsApi.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        map,
        title: 'Search position'
      })
      this.marker = marker
    }
  }

  // GET AND STORE REQUIRED TOOLS TO CREATE DEFAULT MARKER
  getMapsApiMarkers = ({ map, maps }) => {
    this.map = map
    this.mapsApi = maps
    this.setState({ mapLoaded: true })
  }

  onEndMapZoom = () => {
    const { onEndMapDrag } = this.props
    onEndMapDrag({ center: this.mapRef.map_.center, zoom: this.mapRef.map_.zoom })
  }

  render() {
    const { parkings, onPressItem, selectedId, onEndMapDrag,
      data: { center, alertsMarkers, searchPosition, z } } = this.props
    this.createSearchMarker(searchPosition)
    return (
      <GoogleMapReact
        ref={node => this.mapRef = node}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={{ lat: center.latitude, lng: center.longitude }}
        zoom={z}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={this.getMapsApiMarkers}
        onDrag={onEndMapDrag}
        onZoomAnimationEnd={this.onEndMapZoom}
      >
        {parkings.map(item => (
          <MapMarker
            key={item.parking_id.toString()}
            price={item.total_price}
            lat={item.coordinates.latitude}
            lng={item.coordinates.longitude}
            onPressItem={() => onPressItem(item)}
            selected={selectedId === item.parking_id}
          />
        ))}
        {alertsMarkers.map((item, index) => (
          <AlertIcon
            lat={item.latitude}
            lng={item.longitude}
            key={index.toString()}
          />
        ))}
      </GoogleMapReact>
    )
  }
}

export default props => (
  <Consumer>
    {ctx => <MapView {...props} data={ctx} />}
  </Consumer>
)
