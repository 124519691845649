import React from 'react';
import { withRouter } from 'react-router-dom'

const PublicRoute = ({ component: Component, history, ...rest }) => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }

  return (
    <Component {...rest} history={history} />
  )

}

export default withRouter(PublicRoute);
