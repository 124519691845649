import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PmButton from 'src/components/StyledButton'
import StorageApi from 'src/api/storage'
import Analytics from 'src/services/Analytics'
import ParkingPanel from 'src/components/ParkingPanel'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import StartAndEndPicker from 'src/components/DatePickers/StartAndEndPicker'
import { FaRegClock } from 'react-icons/fa';
import { COLORS } from 'src/stylesheet/colors';
import ParkingApi from 'src/api/parking'
import { Consumer } from 'src/services/store/SearchStore';
import Safety from 'src/components/safety';

import { RENTAL_MODE } from 'src/services/store/SearchStore';
import LoaderSimple from 'src/components/LoaderSimple';
import Tooltip from 'src/components/Tooltip';
import Button from "../../../../components/Button";
import {Link} from "react-router-dom";


const storeBookingData = ({ fullBooking, history }) => {
  if (fullBooking.rentalMode === "one_shot") {
    Analytics.clickOneshotBookParking()
  } else {
    Analytics.clickMonthlyBookParking()
  }
  StorageApi.storeData('fullBookingData', fullBooking)
  history.push('/checkout')
}


const ParkingBook = ({ selectedParking, rentalMode, setRentalMode, startDate, setStartDate, endDate, setEndDate, updateParkingPageParams, isParkingAvailable, setIsParkingAvailable, history }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [bookingPrices, setBookingPrices] = useState({
    "parkingPrice": 0,
    "serviceFee": 0,
    "totalPrice": selectedParking.total_price,
  })

  useEffect(() => {
    getBookingPrices()
  }, [rentalMode]);

  const handleChangeRentalMode = (event, newRentalMode) => {
    setRentalMode(null, newRentalMode)
    updateParkingPageParams({ pRentalMode: newRentalMode === 0 ? RENTAL_MODE.monthly : RENTAL_MODE.oneshot })
  }

  const onDateSelected = () => {
    getBookingPrices()
    updateParkingPageParams()
  }

  const getBookingPrices = async () => {
    if ((rentalMode === RENTAL_MODE.oneshot && startDate && endDate) || rentalMode === RENTAL_MODE.monthly) {
      setIsLoading(true)
      const res = await ParkingApi.parkingPrices({ parkingId: selectedParking.id, rentalMode, startDate: startDate ? startDate.unix() : null, endDate: endDate ? endDate.unix() : null })
      setIsLoading(false)
      if (res && !res.error) {
        setBookingPrices({
          "parkingPrice": res.displayed_price,
          "serviceFee": res.service_fee,
          "totalPrice": res.total_price,
        })
        setIsParkingAvailable(res.available)
      } else {
        resetBookingPrices()
      }
    } else if (rentalMode !== RENTAL_MODE.monthly) {
      resetBookingPrices()
    }
  }

  const resetBookingPrices = () => {
    setBookingPrices({
      "parkingPrice": 0,
      "serviceFee": 0,
      "totalPrice": 0,
    })
  }

  const isModeAllowed = (mode) => {
    if (mode === RENTAL_MODE.oneshot) {
      return (selectedParking.allow_one_shot !== null && selectedParking.allow_one_shot && selectedParking.daily_price !== null && selectedParking.daily_price !== '0')
    }
    if (mode === RENTAL_MODE.monthly) {
      return (selectedParking.allow_subscription !== null && selectedParking.allow_subscription && selectedParking.monthly_price !== null && selectedParking.monthly_price !== '0')
    }
  }

  return (
    <ParkingPanel parking={selectedParking}>
      <div className="d-flex flex-column align-items-center mb-3">
        <div style={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>Demande de location</div>
        <Tabs value={rentalMode === RENTAL_MODE.monthly ? 0 : 1}
          onChange={handleChangeRentalMode} indicatorColor="primary">
          <TabStyled label="Mensuelle" />
          <TabStyled label="Ponctuelle" />
        </Tabs>
        <BookingTab
          isParkingAvailable={isParkingAvailable}
          isModeAllowed={isModeAllowed(rentalMode)}
          rentalMode={rentalMode}
          monthly={rentalMode === RENTAL_MODE.monthly}
          oneshot={rentalMode === RENTAL_MODE.oneshot}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onDateSelected={onDateSelected}
          totalPrice={bookingPrices.totalPrice}
          isLoading={isLoading}
          selectedParking={selectedParking}
          bookingPrices={bookingPrices}
          history={history} />
      </div>
      <Safety rentalMode={rentalMode} />

    </ParkingPanel>
  )
}

ParkingBook.propTypes = {
  selectedParking: PropTypes.object,
}

export default (props) => (
  <Consumer>
    {ctx => <ParkingBook {...props}
      rentalMode={ctx.rentalMode}
      setRentalMode={ctx.setRentalMode}
      startDate={ctx.startDate}
      setStartDate={ctx.setStartDate}
      endDate={ctx.endDate}
      setEndDate={ctx.setEndDate}
      selectedParking={ctx.selectedParking}
      updateParkingPageParams={ctx.updateParkingPageParams}
      isParkingAvailable={ctx.isParkingAvailable}
      setIsParkingAvailable={ctx.setIsParkingAvailable}
    />}
  </Consumer>
);

const TabStyled = styled(Tab)`
  && {

        min - height: unset;
      font-size: 16px;
      outline: none !important;
      text-transform: none;
    }
  .MuiTab-root {
        background: red;
    }
    `

const BookingTab = ({ isModeAllowed, monthly, oneshot, rentalMode, startDate, setStartDate, endDate, setEndDate, onDateSelected, totalPrice, isLoading, isParkingAvailable, selectedParking, bookingPrices, history }) => {
  if(selectedParking.status === "OK") {
    return (<div>
          <Tooltip color="yellow" text="Le parking n'est pas disponible pour le moment."/>
          <Link to={`/search?latitude=${selectedParking.coordinates.latitude}&longitude=${selectedParking.coordinates.longitude}&rentalMode=${monthly ? RENTAL_MODE.monthly: RENTAL_MODE.oneshot}&z=13`}>
            <Button fontSize={20} resp text="Trouver un parking à proximité" />
          </Link>
        </div>);
  }

  return (
      <>
        {(isModeAllowed && oneshot) &&
            <div className="d-flex flex-row justify-content-between w-100 m-auto" style={{maxWidth: 400}}>
              <FaRegClock className="flex-fill" size="30" color={COLORS.lightGray} style={{alignSelf: 'center'}}/>
              <StartAndEndPicker startDate={startDate} setStartDate={setStartDate} endDate={endDate}
                                 setEndDate={setEndDate} onDateSelected={onDateSelected}/>
            </div>
        }
        {
            (!isModeAllowed && oneshot) &&
            <Tooltip color="yellow" text="Ce parking n'accepte pas les locations ponctuelles."/>
        }
        {
            (!isModeAllowed && monthly) &&
            <Tooltip color="yellow" text="Ce parking n'accepte pas les locations mensuelles."/>
        }
        {
            (isModeAllowed && isParkingAvailable) &&
            <div className='d-flex flex-row'
                 style={{fontFamily: 'BariolLight', fontSize: 24, color: COLORS.green, textAlign: 'center'}}>
              {isLoading && <LoaderSimple height={25} width={25}/>}
              {rentalMode === RENTAL_MODE.monthly &&
                  <span>{!isLoading && selectedParking.total_price} €/mois tout compris</span>
              }
              {(oneshot && totalPrice === 0) &&
                  <span>{!isLoading && selectedParking.daily_price} €/jour tout compris</span>
              }
              {(oneshot && totalPrice !== 0) &&
                  <span>{!isLoading && totalPrice} €</span>
              }
            </div>
        }
        {
            !isParkingAvailable &&
            <Tooltip color="yellow" text="Le parking n'est pas disponible pour la période demandée."/>
        }
        <PmButton style={{margin: 10}}
                  onClick={() => storeBookingData({
                    fullBooking: {
                      rentalMode,
                      startDate,
                      endDate,
                      bookingPrices,
                      parking: selectedParking
                    }, history
                  })}
                  disabled={!isParkingAvailable || ((!startDate || !endDate) && oneshot) || !isModeAllowed}>Envoyer une
          demande</PmButton>
      </>
  );
}
