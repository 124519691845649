
let RAZZLE_SERVER_URL = ""
let RAZZLE_SENTRY_DSN = ""
let RAZZLE_GOOGLE_API_KEY = ""


if (typeof window !== 'undefined') {
  RAZZLE_SERVER_URL = window.env.RAZZLE_SERVER_URL
  RAZZLE_SENTRY_DSN = window.env.RAZZLE_SENTRY_DSN
  RAZZLE_GOOGLE_API_KEY = window.env.RAZZLE_GOOGLE_API_KEY
} else {
  RAZZLE_SERVER_URL = process.env.RAZZLE_SERVER_URL
  RAZZLE_SENTRY_DSN = process.env.RAZZLE_SENTRY_DSN
  RAZZLE_GOOGLE_API_KEY = process.env.RAZZLE_GOOGLE_API_KEY
}

export const GOOGLE_API_KEY = RAZZLE_GOOGLE_API_KEY;
export const SENTRY_DSN = RAZZLE_SENTRY_DSN;

export const CONTENT_TYPE = 'multipart/form-data'

/* SERVER URL */
export const FULL_URL = RAZZLE_SERVER_URL
export const WORDPRESS_URL = 'https://articles.parkmatch.eu/'

/* PARKINGS */
export const PARKING = `${FULL_URL}/user`
