import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { Tooltip } from 'reactstrap';

import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors';

import './index.css';

export default class AddButton extends React.Component {
  state = {
    tooltipOpen: false,
  }

  toggle = () => {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen });
  }

  render() {
    return (
      <div className="w-auto my-2">
          <div className="d-none d-sm-block">
            <button onClick={() => this.props.history.push('/add')} className="buttonAdd rounded-circle shadow" id="t-addButton" style={{ outline: 'none', backgroundImage: GLOBAL.gradient }}>
              <FaPlus size="25" color={COLORS.lightWhite} />
              <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="t-addButton" toggle={this.toggle}>
                Enregistrer un parking
              </Tooltip>
            </button>
          </div>
      </div>
    )
  }
}

AddButton.propTypes = {
  history: PropTypes.object,
}
