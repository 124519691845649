import React from "react";

const SvgSetMedium = props => (
  <svg viewBox="0 0 141.73 85.04" {...props} width={props.size} height={props.size}>
    <defs>
      <clipPath id="set_medium_svg__a">
        <path fill="none" d="M1.22 21.19h142.02v53.88H1.22z" />
      </clipPath>
    </defs>
    <g clipPath="url(#set_medium_svg__a)">
      <path fill={props.color} d="M140.28 45.2c0-6.45-23-7.56-33.52-8.68C59.94 6.43 26.24 28.1 15.44 38.6 7.23 43.94 1.26 37.52 1.26 48.75c0 9.6-1.09 12.22 8.95 12.79h.44a12.24 12.24 0 0 0 24.47 0l71.3.07v.74a12.25 12.25 0 1 0 24.5 0v-.72h7.67c2.03-.15 1.69.15 1.69-16.43zM22.88 67.85a6.17 6.17 0 1 1 6.17-6.17 6.19 6.19 0 0 1-6.17 6.17zm33.77-30.74a.91.91 0 0 1-.91.91c-10.84 0-20.55-.15-25-.26a.92.92 0 0 1-.57-1.62 43.66 43.66 0 0 1 25.52-10.35.91.91 0 0 1 1 .91c-.04 2.3-.04 8.15-.04 10.41zm36.67.62c-5.66.06-18.22.19-30.82.26a.92.92 0 0 1-.92-.91V26.72a.92.92 0 0 1 1-.92c14 1 26.07 7.18 31.21 10.22a.91.91 0 0 1-.46 1.7zm25.3 31.2a6.17 6.17 0 1 1 6.17-6.17 6.19 6.19 0 0 1-6.16 6.16z" />
    </g>
  </svg>
);

export default SvgSetMedium;
