import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import VehicleApi from 'src/api/vehicle'
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconsInfo from 'src/components/IconsInfo'
import Button from '@material-ui/core/Button';

function VehicleInputSection({ handleClose, edit, getVehicleList }) {
  const [sizeCar, setSizeCar] = useState('')
  const [numberPlate, setNbplate] = useState('')

  function onChangeNbplate(event) {
    setNbplate(event.target.value)
  }

  async function createVehicle() {
    if (numberPlate.length > 0 && sizeCar.length > 0) {
      const res = await VehicleApi.addVehicle({
        numberplate: numberPlate.toUpperCase(),
        size: sizeCar
      })
      if (!res.error) {
        await getVehicleList()
        handleClose()
      }
    }
  }

  async function editVehicle() {
    const { vehicle_id } = edit
    if (numberPlate.length > 0 && sizeCar.length > 0) {
      const res = await VehicleApi.editVehicle({
        vehicle_id,
        numberplate: numberPlate.toUpperCase(),
        size: sizeCar
      })
      if (!res.error) {
        await getVehicleList()
        handleClose()
      }
    }
  }

  useEffect(() => {
    if (edit) {
      setSizeCar(edit.size)
      setNbplate(edit.numberplate)
    }
  }, [edit])

  return (
    <>
      <DialogContent>
        <Divider className="my-2" />
        <DialogTitle>{edit ? "Editer le véhicule" : "Créer un véhicule"}</DialogTitle>
        <TextField
          value={numberPlate}
          onChange={onChangeNbplate}
          margin="dense"
          label="Plaque d'immatriculation"
          fullWidth
          inputProps={{ style: { textTransform: "uppercase" }, maxLength: 14 }}
        />
        <div className="d-flex flex-wrap justify-content-center my-3">
          <IconsInfo edit onChange={setSizeCar} name="MOTORBIKE" isSelect={sizeCar === "MOTORBIKE"} />
          <IconsInfo edit onChange={setSizeCar} name="SMALL" isSelect={sizeCar === "SMALL"} />
          <IconsInfo edit onChange={setSizeCar} name="MEDIUM" isSelect={sizeCar === "MEDIUM"} />
          <IconsInfo edit onChange={setSizeCar} name="LARGE" isSelect={sizeCar === "LARGE"} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" className="no-outline">
          Annuler
      </Button>
        <Button onClick={edit ? editVehicle : createVehicle} color="primary" className="no-outline">
          {edit ? "editer" : "Créer"}
        </Button>
      </DialogActions>
    </>
  )
}

export default VehicleInputSection
