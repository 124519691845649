import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import CguText from './cgu'
import PublicPage from 'src/templates/PublicPage';

class CguPage extends React.Component {

  render() {
    return (
      <PublicPage
        metaTitle="CGU"
        metaDescription="La page avec l'ensemble des CGU.">
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row py-5 gradient-header">
              <div className="col-md-8 py-4 offset-md-2 text-center title-faq">
                <h1>Conditions générales d'utilisation du service ParkMatch</h1>
              </div>
            </div>
            <div className="row">
              <CguText />
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={CguPage} />
