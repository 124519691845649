import moment from 'moment'

const DayMonthCut = (date) => moment(date * 1000).format('Do MMM')
const DayMonth = (date) => moment(date * 1000).format('Do MMMM')
const DayMonthYear = (date) => moment(date * 1000).format('Do MMMM YYYY')
const MonthYear = (date) => moment(date * 1000).format('MM/YY')

function formatBookingDates({ started, ended, planned_start, planned_end }) {
  if (started) {
    const startDate = DayMonthCut(started)
    if (ended) {
      return `${startDate} - ${DayMonthCut(ended)}`
    }
    return startDate
  }
  if (planned_start && planned_end) {
    return DayMonthCut(planned_start) + " - " + DayMonthCut(planned_end)
  }
  return '-'
}

export {
  DayMonthCut,
  DayMonth,
  DayMonthYear,
  MonthYear,
  formatBookingDates,
}
