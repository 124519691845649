import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import Fab from '@material-ui/core/Fab';
import { FaBell } from 'react-icons/fa';
import _ from 'lodash';

import PublicPage from 'src/templates/PublicPage';
import { Consumer } from 'src/services/store/SearchStore';
import SearchProvider from 'src/services/store/SearchStore';
import { withStyles } from '@material-ui/core/styles';


import SearchBar from './components/SearchBar';
import ListParkings from './components/ListParkings';
import MapView from './components/MapView';
import ListAlertPanel from './components/ListAlertPanel';
import CreateAlertPanel from './components/CreateAlertPanel';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
});

class SearchPage extends React.Component {
  state = {
    value: '',
    parkings: [],
    isLoading: true,
    selectedId: null,
    alertDrawerVisible: false,
    alertListVisible: false,
    alertSelected: null,
  }

  componentWillMount() {
    this.getParkingsForLocation({ location: null }) // Search default location located in SearchStore
  }
  _getParkingsForLocation = async ({ location, keepMapData }) => {
    const { data: { searchParkings } } = this.props
    const res = await searchParkings({ location, keepMapData })
    if (res && !res.error) {
      this.setState({ isLoading: false })
    } else {
      this.setState({ error: true })
    }
  }

  getParkingsForLocation = _.debounce(this._getParkingsForLocation, 150)

  onPressItem = (item) => {
    const { data: { setMapCenter } } = this.props
    setMapCenter(item.coordinates)
    this.setState({ selectedId: item.parking_id })
  }

  switchAlertDrawerState = () => this.setState({ alertDrawerVisible: !this.state.alertDrawerVisible })

  switchAlertListState = () => this.setState({
    alertListVisible: !this.state.alertListVisible,
    alertDrawerVisible: false,
  })

  _onEndMapDrag = (mapData) => {
    const { center, zoom } = mapData
    if (center && zoom) {
      const location = { latitude: center && center.lat(), longitude: center && center.lng() }
      this.props.data.setDiameter(location, zoom)
      this.props.data.setMapCenter(location)
      this.getParkingsForLocation({
        location: location,
        keepMapData: true
      })
    }
  }

  onEndMapDrag = _.debounce(this._onEndMapDrag, 300)

  onPressAlert = alert => this.setState({ alertSelected: alert })

  render() {
    const { selectedId, error, isLoading,
      alertDrawerVisible, alertListVisible, alertSelected } = this.state
    const { data: { parkings }, classes } = this.props
    return (
      <PublicPage footer={false} isLoading={isLoading} error={error} noContainer
        metaTitle="Trouvez un parking, un box ou un garage de particulier à louer"
        metaDescription="Garages, parkings et box pas chers, à louer entre particuliers sur Parkmatch. Location automatique, paiements sécurisés, sans caution.">
        <div className='container-fluid min-vh-100 d-flex flex-column vh-100'>
          <div className={classes.toolbar} style={{ height: 64 }} /> {/*Additionnal style for older browsers*/}
          <div className="row p-0 d-block" style={{ zIndex: 2 }}>
            <SearchBar getParkings={this.getParkingsForLocation} />
          </div>
          <div className="row d-flex flex-row flex-grow-1 h-100" ref={node => this.div = node} style={{ position: "relative" }}>
            <ListAlertPanel
              switchAlertDrawerState={this.switchAlertDrawerState}
              drawerVisible={alertListVisible}
              switchDrawerState={this.switchAlertListState}
              anchor={this.div}
              onPressAlert={this.onPressAlert}
            />
            <CreateAlertPanel drawerVisible={alertDrawerVisible} switchDrawerState={this.switchAlertDrawerState} anchor={this.div} />
            <div className="p-0 h-100 mh-100 shadow" style={{ zIndex: 1, width: 435, minHeight: '100%' }}>
              <div><small>Annonces triées selon la distance par rapport à votre recherche du plus proche au plus lointain. <a href="/modalites" target="_blank">+ d'infos</a></small></div>
              <ListParkings parkings={parkings} onPressItem={this.onPressItem} selectedId={selectedId} openAlertPanel={this.switchAlertListState} parkingPageParams={this.props.data.parkingPageParams} />
            </div>
            <div className="p-0 d-none d-sm-block" style={{ zIndex: 0, flex: 1 }}>
              <MapView
                parkings={parkings}
                onPressItem={this.onPressItem}
                selectedId={selectedId}
                alertSelected={alertSelected}
                onEndMapDrag={this.onEndMapDrag}
              />
            </div>
            <div style={{ position: 'absolute', top: 20, right: 20 }}>
              <Fab color="primary" aria-label="Add" className="no-outline" onClick={this.switchAlertListState}>
                <FaBell size={30} color="white" />
              </Fab>
            </div>
          </div>
        </div>
      </PublicPage >
    )
  }
}

const SearchPageWithStyles = withStyles(styles)(SearchPage);


export default (props) => (
  <SearchProvider>
    <Consumer>
      {ctx => <PublicRoute component={SearchPageWithStyles} {...props} data={ctx} />}
    </Consumer>
  </SearchProvider>
);
