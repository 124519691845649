import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import PublicRoute from 'src/containers/PublicRoute';
import PrivateRoute from 'src/containers/PrivateRoute';
import Routes from './Routes'
import SnackBar from 'src/components/SnackBar'

import LocationParkingMiddleware from 'src/components/LocationParkingMiddleware';
import NotFound from 'src/components/NotFound';
import SearchPage from 'src/views/SearchPage';
import LoginPage from 'src/views/LoginPage';
import RegisterPage from 'src/views/RegisterPage';
import AddPhonePage from 'src/views/AddPhonePage';
import PhonePage from 'src/views/PhonePage';
import AccountPage from 'src/views/AccountPage/AccountPage';
import AddParkingQuickPage from 'src/views/AddParkingQuickPage';
import LandingPage from 'src/views/LandingPage';
import ContactPage from 'src/views/ContactPage';
import FaqPage from 'src/views/FaqPage';
import FindParkingSpaces from 'src/views/FindParkingSpaces';
import RentMyParking from 'src/views/RentMyParking';
import CguPage from 'src/views/CguPage';
import LegalsPage from 'src/views/LegalsPage';
import EditParkingPage from 'src/views/EditParkingPage';
import MyParkingsPage from 'src/views/MyParkingsPage';
import BankInfosPage from 'src/views/BankInfosPage';
import Loader from 'src/components/Loader';
import CreditCardPage from 'src/views/CreditCardPage';
import EstimationPage from 'src/views/EstimationPage';
import CheckoutPage from 'src/views/CheckoutPage'
import BookingsPage from 'src/views/BookingsPage'
import ActionPage from 'src/views/ActionPage'

import Api from 'src/services/api';
import { Consumer } from 'src/services/store';
import Analytics from './services/Analytics';

class App extends React.Component {
  state = {
    isLoading: false
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    const auth = localStorage.getItem('auth');
    if (auth) {
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Basic ${auth}`,
        }
      };
      try {
        const res = await Api.parkmatch.post('/connect', { notification_token: "totototo" }, options);
        Api.parkmatch.setAccessToken(res.token);
        localStorage.setItem('token', res.token);
        this.props.data.handleChangeLogged(true);
        this.props.data.handleChangeUser(res)
      } catch (e) {
        this.props.data.handleChangeLogged(false);
        this.props.data.handleChangeUser(null)
        localStorage.clear()
      }
    }
    this.props.data.handleIsConnecting(false)
  }

  setSnackRef = (ref) => {
    const { data } = this.props
    data.setSnackRef(ref)
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {
          !isLoading ?
            <Analytics>
              <Switch>
                {renderRoutes(Routes)}
              </Switch>
              <SnackBar ref={this.setSnackRef} />
            </Analytics>
            : <Loader />
        }
      </>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <App {...props} data={ctx} ref={ref} />}
  </Consumer>
));
