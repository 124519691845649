import React from 'react';
import { FaRegEyeSlash, FaRegEye, FaRegEdit, FaRegClock } from 'react-icons/fa';

const iFaRegEdit = ({ color }) => (<FaRegEdit size={20} color={color} />);
const iFaRegEyeSlash = ({ color }) => (<FaRegEyeSlash size={20} color={color} />);
const iFaRegEye = ({ color }) => (<FaRegEye size={20} color={color} />);
const iFaRegClock = ({ color }) => (<FaRegClock size={20} color={color} />);

const ICONS = {
  iFaRegEdit,
  iFaRegEyeSlash,
  iFaRegEye,
  iFaRegClock,
}

export default ICONS;