import React from 'react'
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import { COLORS } from 'src/stylesheet/colors';

const BookingTip = ({ number, text, date = '' }) => (
  <Grid container style={{ padding: 20 }}>
    <NumberContainer>
      {number}
    </NumberContainer>
    <Grid item xs={9} className="pl-3">
      <StyledSpan>
        {text}{date}
      </StyledSpan>
    </Grid>
  </Grid>
)

const NumberContainer = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid ${COLORS.green};
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: ${COLORS.green};
`

const StyledSpan = styled.div`
  font-size: 20px;
  text-align: left;
`

export default BookingTip
