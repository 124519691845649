import { COLORS } from './colors';

const GLOBAL = {
  gradient: `linear-gradient(90deg, ${COLORS.greenGradient1}, ${COLORS.greenGradient2})`,
  gradientBlue: `linear-gradient(311deg, ${COLORS.blueGradient1}, ${COLORS.blueGradient2})`,
  gradientGray: `linear-gradient(2deg, ${COLORS.white}, ${COLORS.grayGradient2})`,
  separator: `1px solid ${COLORS.lightGray}`,
  borderGray: `1px solid ${COLORS.lightGray}`,
}

export default {
  ...GLOBAL,
}
