import React, { useState } from 'react'
import { Consumer } from 'src/services/store';
import UserApi from 'src/api/user'
import PropTypes from 'prop-types';
import { checkPassword } from 'src/utils/checkAuth';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PmButton from 'src/components/StyledButton'



function PasswordEdit({ data, onSubmit, onCancel }) {
    const [isLoading, setIsLoading] = useState(false)

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    function handleChangePassword1(e) { setPassword1(e.target.value) }
    function handleChangePassword2(e) { setPassword2(e.target.value) }

    const resetFields = () => {
        setPassword1('')
        setPassword2('')
    }

    async function passwordUpdate() {
        if (password1 !== password2) {
            data.snackRef.open('error', 'Les deux mots de passe ne correspondent pas.')
            resetFields()
            return
        }
        if (!checkPassword(password1)) {
            data.snackRef.open('error', 'Veuillez entrer un mot de passe valide (min 8 caractères).')
            return
        }
        setIsLoading(true)
        const res = await UserApi.updateUserInfo({ password: password1 })
        setIsLoading(false)
        if (res && !res.error) {
            data.snackRef.open('success', 'Mot de passe mis à jour.')
            data.updateUserData(res.res)
            onSubmit()
        }
        else {
            data.snackRef.open('error', "Une erreur est survenue, veuillez rééssayer plus tard.")
        }
    }

    return (
        <>
            <form noValidate autoComplete="off" className="d-flex flex-column w-100">
                <TextField
                    id="password1"
                    label="Mot de passe"
                    placeholder="Entrez votre nouveau mot de passe."
                    value={password1}
                    onChange={e => handleChangePassword1(e)}
                    margin="normal"
                    fullWidth
                    type="password"
                />
                <TextField
                    id="password2"
                    label="Vérification"
                    value={password2}
                    placeholder="Entrez une nouvelle fois votre nouveau mot de passe."
                    onChange={e => handleChangePassword2(e)}
                    margin="normal"
                    fullWidth
                    type="password"
                />
            </form>
            <div className="w-100 text-right mt-4">
                <Button onClick={onCancel}>Annuler</Button>
                <PmButton style={{ marginLeft: 20 }} onClick={passwordUpdate} isLoading={isLoading}>Enregistrer</PmButton>
            </div>
        </>

    )
}

PasswordEdit.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default (props) => (
    <Consumer>
        {ctx => <PasswordEdit {...props} data={ctx} />}
    </Consumer>
)
