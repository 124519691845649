import React, { useState, useEffect } from 'react'
import PrivatePageTemplate from 'src/templates/PrivatePage';
import PrivateRoute from 'src/containers/PrivateRoute'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoaderSimple from 'src/components/LoaderSimple';
import UserApi from 'src/api/user';
import { COLORS } from 'src/stylesheet/colors';
import { Consumer } from 'src/services/store';
import Documents from './components/Documents';
import TransfersPanel from './components/Transfers'


function BankInfosPage({ data }) {
  const [tabValue, setTabValue] = useState(0)
  const [docs, setDocs] = useState([])
  const [transfers, setTransfers] = useState([])
  const [walletData, setWalletData] = useState(null)
  const [transferLoading, setTransferLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function onChangeTabValue(event, value) { setTabValue(value) }

  async function getWalletData() {
    setTransferLoading(true)
    const res = await UserApi.getWallet()
    setIsLoading(false)
    if (!res.error) {
      const { doc, transfers: sourceTransfers } = res
      setDocs(doc)
      setTransfers(sourceTransfers)
      setWalletData(res)
      setTransferLoading(false)
    }
  }

  async function askTransfer() {
    //setTransferLoading(true)
    const res = await UserApi.askTransfer()
    if (!res.error) {
      await getWalletData()
      setTransferLoading(false)
      data.snackRef.open('success', 'Virement effectué !')
    } else {
      setTransferLoading(false)
      data.snackRef.open('error', 'Une erreur est survenue durant le virement.')
    }
  }

  useEffect(() => {
    getWalletData()
  }, [])

  function HeaderChildren() {
    return (
      <Tabs
        style={{ color: COLORS.green }}
        indicatorColor="primary"
        value={tabValue}
        onChange={onChangeTabValue}
      >
        <Tab label="Virements" className="no-outline" />
        <Tab label="Informations bancaires" className="no-outline" />
      </Tabs>
    )
  }

  return (
    <PrivatePageTemplate title="Mes virements" headerComponent={HeaderChildren()} metaTitle="Mes virements">
      {isLoading && <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100%' }}><LoaderSimple /></div>}
      {tabValue === 0 && <TransfersPanel walletData={walletData} transfers={transfers} askTransfer={askTransfer} isLoading={transferLoading} />}
      {tabValue === 1 && <Documents docs={docs} setDocs={setDocs} walletData={walletData} />}
    </PrivatePageTemplate>
  )
}

export default (props) => (
  <Consumer>
    {ctx => <PrivateRoute component={BankInfosPage} {...props} data={ctx} />}
  </Consumer>
)
