import React from 'react'

export const MapImage = ({ article, zoom = 14, parkings, dimensions }) => {
    const width = dimensions.width < 640 ? dimensions.width : 640
    const height = Math.round(dimensions.height * width / dimensions.width + 30)

    let url = 'https://maps.googleapis.com/maps/api/staticmap'
    url += '?center=' + article.location.lat + ',' + article.location.lng
    url += '&zoom=' + zoom
    url += '&scale=2'
    url += '&size=' + width + 'x' + height
    url += '&maptype=' + 'terrain'
    url += '&format=' + 'jpg-baseline'
    url += '&style=feature:all|lightness:-10|saturation:-50'
    url += '&visual_refresh=' + 'true'
    parkings.forEach((pkg) => {
        url += '&markers=anchor:57,114%7Cicon:http://static.parkmatch.eu/assets/icons/apple-icon-60x60.png'
        url += '%7Cscale:2'
        url += '%7C' + pkg.coordinates.latitude + ',' + pkg.coordinates.longitude
    })
    url += '&key=' + 'AIzaSyCNSldO79c40EZCOLKx5YCfQtSHgZLNsLE'

    return (<img src={url}
        alt={'Parkings à louer à ' + article.acf.link_text}
        style={{ width: "100%", marginTop: '-15px' }} />)

}