import React from 'react';
import PropTypes from 'prop-types';

import InputSearch from 'src/components/InputSearch';

import Car from 'public/assets/img/car.png';

const CardSearchParking = ({ placeholder }) => (
  <div className="col-12 col-md-6 text-center bg-white" style={{ zIndex: 2 }}>
    <div className="m-2 p-4 text-center border rounded h-md-100">
      <img className="py-2" style={{ width: '30%' }} src={Car} alt="car" />
      <div className="py-2">Rechercher un parking à louer</div>
      <InputSearch placeholder="ex: 2 rue de la Place" />
    </div>
  </div>
)

CardSearchParking.propTypes = {
  placeholder: PropTypes.string,
}

export default CardSearchParking;
