import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import PublicRoute from 'src/containers/PublicRoute';

import PublicPage from 'src/templates/PublicPage';
import { Consumer } from 'src/services/store';
import StorageApi from 'src/api/storage'
import ParkingPanel from 'src/components/ParkingPanel'

import LoginStep from './components/LoginStep';
import CheckoutSteps from './components/Stepper'
import DetailsStep from './components/DetailsStep'
import ConfirmationStep from './components/ConfirmationStep'
import AccessPanel from './components/AccessPanel'
import BookingResume from './components/BookingResume'
import Safety from 'src/components/safety';

const CHECKOUT_STEPS = {
  login: 0,
  details: 1,
  confirmation: 2
}

function CheckoutPage({ user }) {

  const [checkoutStep, setCheckoutStep] = useState(CHECKOUT_STEPS.login)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [fullBooking, setFullBooking] = useState(null)
  const [isParkingAvailable, setIsParkingAvailable] = useState(true)


  useEffect(() => {
    if (!fullBooking) {
      const fullBooking = StorageApi.getData('fullBookingData')
      if (fullBooking) {
        setFullBooking(fullBooking)
      } else {
        setError(true)
      }
    }
    setIsLoading(false)
    if (user && user.phone_validated) {
      setCheckoutStep(CHECKOUT_STEPS.details)
    }
  }, [(user && user.phone_validated) || false, user])

  useEffect(() => {
    if (fullBooking) {
      StorageApi.storeData('fullBookingData', fullBooking)
    }
  }, [fullBooking])

  function goToConfirmation() {
    if (user) {
      console.log('ok')
      if (user.credit_card) {
        setCheckoutStep(CHECKOUT_STEPS.confirmation)
      } else {
        setError("Vous devez enregistrer votre carte de paiement pour continuer.")
      }
    }
  }

  function goToDetailsStep() {
    if (user) {
      setCheckoutStep(CHECKOUT_STEPS.details)
    }
  }

  function saveComment(newComment) {
    setFullBooking({
      ...fullBooking,
      comment: newComment
    })
  }

  return (
    <PublicPage footer={false} isLoading={isLoading}
      metaTitle="Envoi d'une demande - Parkmatch"
      metaDescription="">
      <div className="row mt-5">
        <div className="col-12 col-sm-7 p-sm-5">
          <CheckoutSteps step={checkoutStep} goToDetails={goToDetailsStep} goToConfirmation={goToConfirmation} />
          <div className="m-lg-3 mb-3">
            <Paper className="mx-lg-5 p-lg-4 mt-0">
              {checkoutStep === CHECKOUT_STEPS.login && <LoginStep />}
              {checkoutStep === CHECKOUT_STEPS.details && <DetailsStep onClick={goToConfirmation} error={error} fullBooking={fullBooking} saveComment={saveComment} />}
              {checkoutStep === CHECKOUT_STEPS.confirmation && <ConfirmationStep fullBooking={fullBooking} goToDetails={goToDetailsStep} isParkingAvailable={isParkingAvailable} />}
            </Paper>
            {checkoutStep !== CHECKOUT_STEPS.login && <AccessPanel />}
          </div>
        </div>
        <div className="col-12 col-sm-5 p-lg-5 py-5">
          <ParkingPanel parking={fullBooking ? fullBooking.parking : null}>
            <BookingResume fullBooking={fullBooking} setFullBooking={setFullBooking} isParkingAvailable={isParkingAvailable} setIsParkingAvailable={setIsParkingAvailable} />
            <Safety rentalMode={fullBooking ? fullBooking.rentalMode : null} />
          </ParkingPanel>
        </div>
      </div>
    </PublicPage>
  )
}

export default () => (
  <Consumer>
    {ctx => <PublicRoute component={CheckoutPage} user={ctx.user} />}
  </Consumer>
);
