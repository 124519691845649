import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import AlertApi from 'src/api/alert';
import { Consumer as AuthConsumer } from 'src/services/store';
import { Consumer as SearchConsumer } from 'src/services/store/SearchStore';
import StyledButton from 'src/components/StyledButton';
import AlertItem from './AlertItem';

class AlertPanel extends React.Component {
  componentWillMount() {
    const { auth } = this.props
    if (auth && auth.user) {
      this.props.data.getAlertsList()
    }
  }

  deleteAlert = async (id) => {
    const res = await AlertApi.deleteAlert({ alertId: id })
    if (res && !res.error) {
      this.props.data.getAlertsList()
    }
  }

  onClickAlertItem = (location) => {
    const { data: { setMapCenter, addAlertMarker }, switchDrawerState } = this.props
    setMapCenter(location)
    addAlertMarker(location)
    switchDrawerState()
  }

  render () {
    const { switchDrawerState, anchor, drawerVisible, switchAlertDrawerState,
      data: { alertList }, auth } = this.props
    return (
      <Drawer open={drawerVisible} onClose={switchDrawerState}
        variant="temporary"
        PaperProps={{ style: { position: 'absolute', zIndex: 3, width: 435, maxWidth: '100%' } }}
        BackdropProps={{ style: { position: 'absolute', zIndex: 1 } }}
        ModalProps={{
          container: anchor,
          style: { position: 'absolute', zIndex: 'auto' },
          disableRestoreFocus: true,
          disableEnforceFocus: true, // DON'T REMOVE THIS, used to enable search input with open drawer
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IconButton className="no-outline" onClick={switchDrawerState}>
            <ArrowBackIos style={{ fontSize: 40 }} />
          </IconButton>
          <Typography variant="h4" style={{ padding: 20 }}>
            Mes alertes
          </Typography>
        </div>
        {
          auth && auth.user ?
            <AlertListWithButton
              alertList={alertList}
              onClickAlertItem={this.onClickAlertItem}
              deleteAlert={this.deleteAlert}
              switchAlertDrawerState={switchAlertDrawerState}
            />
          : <RenderNoAuth />
        }

      </Drawer>
    )
  }
}

const AlertListWithButton = ({ alertList, switchAlertDrawerState, deleteAlert, onClickAlertItem }) => (
  <>
    {alertList.length > 0 &&
      <List>
        {alertList.map(item => (
          <AlertItem
            key={item.alert_id.toString()}
            item={item}
            onDelete={deleteAlert}
            onPressAlert={onClickAlertItem}
          />
        ))}
      </List>
    }
    {alertList.length === 0 &&
      <Typography gutterBottom style={{ padding: 20 }}>
        Vous n'avez pas encore d'alertes!
      </Typography>
    }
    <StyledButton style={{ marginLeft: 'auto', marginRight: 'auto', marginVertical: 20 }} onClick={switchAlertDrawerState}>
      Créer une alerte
    </StyledButton>
  </>
)

const RenderNoAuth = () => (
  <StyledDiv>
    <span style={{ fontSize: 20 }}>
      Inscivez-vous pour créer un alerte
    </span>
    <Link to="/register">
      <StyledButton style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={() => null}>
        S'inscrire
      </StyledButton>
    </Link>
  </StyledDiv>
)

const StyledDiv = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default (props) => (
  <AuthConsumer>
    {auth => <SearchConsumer>
      {ctx => <AlertPanel {...props} data={ctx} auth={auth} />}
    </SearchConsumer>}
  </AuthConsumer>
);
