import React from 'react';
import PropTypes from 'prop-types';

import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors';

const Input = ({ value, onChange, placeholder, id = '', disabled }) => (
  <div className="input-group">
    <input id={id} disabled={disabled} type="number" className="form-control" placeholder={placeholder} value={value} onChange={onChange} />
    <div className="input-group-append">
      <span style={{ backgroundImage: GLOBAL.gradientBlue, color: COLORS.lightWhite }} className="input-group-text" id="basic-addon2">€</span>
    </div>
  </div>
);

Input.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.number,
}

export default Input;
