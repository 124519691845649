import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import styled, { css } from 'styled-components';

import AlertPanelContent from './AlertPanelContent'

const innerWidth = 426
if (process.env.BROWSER) {
  innerWidth = window.innerWidth
}

const StyledDiv = styled.div`
  padding-left: 435px;
  width: 100vw;
  height: 100%;
  ${innerWidth <= 425 && css`
    padding-left: 0px;
  `}
`

const CreateAlertPanel = ({ switchDrawerState, anchor, drawerVisible, location }) => {
  return (
    <Drawer open={drawerVisible} onClose={switchDrawerState}
      anchor="left"
      variant="temporary"
      PaperProps={{ style: { position: 'absolute', zIndex: window.innerWidth <= 425 ? 4 : 2 } }}
      BackdropProps={{ style: { position: 'absolute', zIndex: 1 }, invisible: true }}
      ModalProps={{
        container: anchor,
        style: { position: 'absolute', zIndex: 'auto' },
        disableRestoreFocus: true,
        disableEnforceFocus: true, // DON'T REMOVE THIS, used to enable search input with open drawer
      }}
    >
      <StyledDiv>
        <AlertPanelContent
          location={location}
          switchDrawerState={switchDrawerState}
          responsive={window.innerWidth}
        />
      </StyledDiv>
    </Drawer>
  )
}

export default CreateAlertPanel
