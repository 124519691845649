import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const CustomDialog = ({ closeDialog, visible, action, title, text }) => (
  <Dialog
    open={visible}
    onClose={closeDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className="no-outline" onClick={closeDialog} color="primary">
        Annuler
      </Button>
      <Button className="no-outline" onClick={action} color="primary" autoFocus>
        Continuer
      </Button>
    </DialogActions>
  </Dialog>
)

export default CustomDialog
