import React from 'react'
import Slider from 'react-slick';

import Woman from 'public/assets/img/users/woman.jpg'
import Man from 'public/assets/img/users/man.jpg'
import OtherMan from 'public/assets/img/users/other-man.jpg'

const settings = {
   infinite: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   speed: 700,
   cssEase: "linear",
   arrows: false,
   dots: true,
   autoplay: true,
   autoplaySpeed: 4000,
 };

 const USERS_OLD = [
   {
     photo: Woman,
     name: 'Chloé',
     parking: 'Loue un parking à Paris 6',
     commentary: 'Expérience utilisateur optimale. Service bien fait et facile d’utilisation. Location auprès du propriétaire parfaite et fluide. Je recommande.',
   },
   {
     photo: Man,
     name: 'Charles',
     parking: 'Loue un parking à Marseille',
     commentary: 'Je suis très satisfait de Parkmatch. La plateforme est sérieuse et je loue en confiance dessus.',
   },
   {
     photo: OtherMan,
     name: 'Henri',
     parking: 'Loue un parking à Lyon 7',
     commentary: "Tout s'est bien passé, tant au niveau du parking qu'au niveau du propriétaire. Je recommande vivement !",
   }
 ]
 const USERS = [

 ]

const UsersSection = () => (
  <div className="row">
    {/*
    <div className="col pb-5" style={{ flexDirection: 'column', backgroundColor: "#f7f9f9" }}>
      <div className="slick-title pt-5 pb-2 text-center">Des utilisateurs heureux</div>
      <Slider {...settings} >
        {USERS.map(user => <RenderUser key={user.name} user={user} />)}
      </Slider>
    </div>
    */}
  </div>
)

const RenderUser = ({ user }) => (
  <div className="text-center col-md-6 offset-md-3" style={{ flexDirection: 'column' }}>
    <img alt={user.name} src={user.photo} className="user-image" />
    <div className="user-title pt-2">
      {user.name}
    </div>
    <div className="user-info pb-2">
      {user.parking}
    </div>
    <div className="user-text px-5">
      {user.commentary}
    </div>
  </div>
)

export default UsersSection
